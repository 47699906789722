import { atom } from 'recoil';
import { ExchangeRateType, VerticalCardType } from 'types/types';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();
export const searchListState = atom<Array<VerticalCardType>>({
  key: 'searchListState',
  default: [],
});

export const filterDataState = atom<any>({
  key:'filterDataState',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const selectedFilterDataState = atom<Array<string>>({
  key:'selectedFilterDataState',
  default: [],
})

export const selectedFilterDataState_ = atom<Set<any>>({
  key:'selectedFilterDataState_',
  default: new Set<any>(),
})

export const itemListState = atom<Array<VerticalCardType>>({
  key:'itemListState',
  default: [],
})

export const selectedYear = atom<Array<number>>({
  key:'yearMinMaxState',
  default: [0, 0],
})

export const payableItemsState = atom<Array<VerticalCardType | undefined>>({
  key:'payableItemsState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const shippingMemoState = atom<string>({
  key:'shippingMemoState',
  default: "",
  effects_UNSTABLE: [persistAtom],
})

export const exchangeRateState = atom<ExchangeRateType | undefined>({
  key: "exchangeRateState",
  default: {
    basePrice: 0,
    currencyCode: "",
    provider: "",
    timestamp: 0,
  },
  effects_UNSTABLE: [persistAtom],
});

export const itemsToPurchaseState = atom<Array<any>>({
  key:'itemsToPurchaseState',
  default: [],
})
export const categoryState = atom({
  key:'categoryState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const scrollRecoilState = atom({
  key: 'scrollRecoilState',
  default: 0,
})

export const mainTabState = atom({
  key: 'mainTabState',
  default: '스포츠 카드',
})
