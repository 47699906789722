import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import React from "react";
import "./App.css";
import { RecoilRoot } from "recoil";
import OnboardingPage from "pages/OnboardingPage";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import SignupRouter from "pages/signup/SignupRouter";
import FindpwdRouter from "pages/findpwd/FindpwdRouter"
import LoginPage from "pages/LoginPage";
import { useEffect, useState } from "react";
import SidePage from "pages/SidePage";
import MypageRouter from "pages/mypage/MypageRouter";
import PurchaseHistRouter from "pages/puchaceHist/PurchaceHistRouter";
import MainRouter from "pages/main/MainRouter";
import CartPage from "pages/cart/CartPage";
import ExploreRouter from "pages/explore/ExploreRouter";
import ItemRouter from "pages/item/ItemRouter";
import AuthPage from "pages/AuthPage";
import AddProductRouter from "pages/addProduct/AddProductRouter";
import AuctionHistoryRouter from "pages/auctionHistory/AuctionHistoryRouter";
import SellerHistoryRouter from "pages/sellerHistoryPage/SellerHistoryRouter";
import ScrollToTop from "ScrollToTop";
import ItemCategoryPage from "./pages/item/ItemCategoryPage";

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}

const theme = extendTheme({ colors })

function App() {
  const [bodyWidth, setBodyWidth] = useState('100%');
  const [isShowDownload, setIsShowDownload] = useState(false);

  const updateBodyWidth = () => {
    
    if (window.innerWidth > 768) {
      setIsShowDownload(true)
      setBodyWidth('400px');
    } 
    else if(window.innerWidth > 480){
      setBodyWidth('400px');
      setIsShowDownload(false)
    }
    else {
      setIsShowDownload(false)
      setBodyWidth('100%');
    }
  };
  useEffect(() => {
    updateBodyWidth();
    window.addEventListener('resize', updateBodyWidth);

    return () => {
      window.removeEventListener('resize', updateBodyWidth);
    };
  }, []);
  return (
    <Router>
      <meta name="format-detection" content="date=no"></meta>
      <RecoilRoot>
        <ChakraProvider theme={theme}>
          {isShowDownload && <SidePage />}
          <div
            className="application"
            id="application"
            style={{ width: bodyWidth, height: "100vh" }}
          >
            <style>
              {`
                #root {
                  display: flex;
                  justify-content: center;
                  background-color: #F7FAFC;
                  
                }
                #root > .application{
                  overflow: scroll;
                  background-color: white;
                  border-width: ${window.innerWidth <= 480 ? "0" : "1px 1px 0px 1px"};
                  border-style: solid;
                  border-color: #CBD5E0;

                }

                .footer, .Filter-button {
                  width: calc(${bodyWidth} - 2px);
                }
              `}
            </style>
            <ScrollToTop />
            <Routes>
              {/* Marketplace */}
              <Route path="/onboarding" element={<OnboardingPage  />} />
              {/* <Route path="/listing/" element={<ListingPage />} /> */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/tags" element={<ItemCategoryPage />} />
            </Routes>
            <SignupRouter />
            <FindpwdRouter />
            <MypageRouter />
            <PurchaseHistRouter />
            <MainRouter />
            <ExploreRouter />
            <AddProductRouter />
            <AuctionHistoryRouter />
            <ItemRouter />
            <SellerHistoryRouter />
          </div>
        </ChakraProvider>
      </RecoilRoot>
    </Router>
  );
}

export default App;