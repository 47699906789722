import { styled } from "styled-components";
import { Input } from '@chakra-ui/react';
import { useRecoilState } from "recoil";
import { useState } from "react";
import { colors } from "styled/colors";

interface NameInputTypes {
    name: string;
    placeholder: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
}

const NameInputComponent = (props: NameInputTypes) => {
    const { name, setName } = props;
    const handleNameChange = (event: any) => setName(event.target.value);
    return (
        <NameInput>
            <Input 
            placeholder={props.placeholder}
            _placeholder={{color: colors.GREY[400]}}
            size='lg'
            value={name}
            onChange={handleNameChange}/>
        </NameInput>
    )
}

const NameInput = styled.div`

`

export default NameInputComponent;