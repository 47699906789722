import { styled } from "styled-components";
import card_img from "assets/card_img.svg";
import { colors } from "styled/colors";
import { DeliverStatus, Size } from "types/types";
import DeliverProgressComponent from "./DeliverProgressComponent";
import SmallButtonComponent from "components/common/buttons/SmallButtonComponent";
import { Font_xs, Font_sm } from "styled/fonts";
import ModalComponent from "components/common/ModalComponent";
import { useDisclosure } from "@chakra-ui/react";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import { useRecoilState } from 'recoil';
import { useEffect } from "react";
import { changeKrwToUsd, formatCurrency } from 'api/StringUtils';
import { langState, loginState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { PurchaseData } from "types/types";
import { useState } from "react";
import { addDaysToDate } from "api/StringUtils";
import { useNavigate } from "react-router-dom";
import { getPresetData } from "api/api";
import { MakeIsDoneDataTrueForBidding } from "api/UserHistoryApi";
import { exchangeRateState } from "recoil/homeRecoil";
import { checkAndConvertCurrency } from "api/StringUtils";

interface CardIProps {
  purchaseData : PurchaseData;
  isDetail: boolean;
}

const HorizontalCard_Buy = (props: CardIProps) => {
  const {purchaseData, isDetail} = props;

  const navigate = useNavigate();

  const user: any = useRecoilValue(loginState);
  const exchangeRate = useRecoilValue(exchangeRateState);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(isCompletePurchaceState);
  const [deliveryPrice, setDeliverPrice] = useState(0);
  const [status, setStatus]= useState<string>('');

  const fetchPresetData = async() => {
    const data = await getPresetData();
    const findDeliveryPrice = data.filter((item)=> item.key == "delivery");
    setDeliverPrice(Number(findDeliveryPrice[0].value));
  }

  const { t, i18n } = useTranslation("history");
  const language = useRecoilValue(langState);

  useEffect(()=>{
    fetchPresetData();
    i18n.changeLanguage(language);
    setIsCompletePurchace(false);
  }, [])
  
  const onClickCompliteBtn = () => {
    onOpen();
  };

  const handleModalBtn = async() => {
    const response = await MakeIsDoneDataTrueForBidding(user.access, purchaseData);
    setIsCompletePurchace(true);
  }

  useEffect(()=> {
    identifyStatus(purchaseData);
  },[purchaseData])

  const identifyStatus = (purchaseData : PurchaseData) => {
    if(purchaseData.is_done) setStatus("구매확정");
    else return setStatus(DeliverStatus[purchaseData.product.status]);
  }

  const onClickToDeliverPage = () => {
    navigate('/deliver', {state: purchaseData})
  }

  return (
    <HorizontalCard>
      {(!isDetail ||
        (isDetail && purchaseData.is_done)) && (
        <div style={{ marginTop: "1rem" }}>
          <Font_sm
            isBold
            color={
              status === "배송완료" ||
              purchaseData.is_done
                ? colors.GREY[700]
                : colors.GREEN[500]
            }
          >
            {t(status)}
          </Font_sm>
        </div>
      )}
      <CardSection>
        <ImageSection image={purchaseData.product.image1}/>
        <TextSection>
          <Text className={"title"}>{purchaseData.product.name}</Text>
          <Text className="itemPrice">{checkAndConvertCurrency(language, purchaseData.product.price, purchaseData.currency, exchangeRate?.basePrice)}</Text>
          <Text>{t("배송비")} {checkAndConvertCurrency(language, deliveryPrice, purchaseData.currency, exchangeRate?.basePrice)}</Text>
        </TextSection>
      </CardSection>
      {!isDetail ? 
        status === "배송중" || status === "배송완료" &&
        <>
         <OneButtonWrapper>
            <SmallButtonComponent
              cnt={1}
              onClick={onClickCompliteBtn}
              isReverse={true}
              text={t("구매확정")}
            />
          </OneButtonWrapper>
        </>
       : status === "배송완료" ? (
        <>
          <ButtonWrapper>
            <SmallButtonComponent cnt={3} isReverse={true} text={t("배송조회")} onClick={onClickToDeliverPage}/>
            <SmallButtonComponent cnt={3} isReverse={true} text={t("1:1 문의")} onClick={() => {navigate('/mypage/ask')}}/>
            <SmallButtonComponent
              cnt={3}
              onClick={onClickCompliteBtn}
              isReverse={false}
              text={t("구매확정")}
            />
          </ButtonWrapper>
          <div style={{ textAlign: "center", marginBottom: "0.5rem" }}>
            <Font_xs color={colors.GREEN[500]}>{addDaysToDate(purchaseData.product.carried_date, 7, false, false)} {t("자동 구매확정")}</Font_xs>
          </div>
          <DeliverProgressComponent deliverStatus={DeliverStatus[purchaseData.product.status]} />
        </>
      ) : status === "배송중" ? (
        <>
          <ButtonWrapper>
            <SmallButtonComponent cnt={3} isReverse={true} text={t("배송조회")} onClick={onClickToDeliverPage}/>
            <SmallButtonComponent cnt={3} isReverse={true} text={t("1:1 문의")} onClick={() => {navigate('/mypage/ask')}}/>
            <SmallButtonComponent
              cnt={3}
              onClick={onClickCompliteBtn}
              isReverse={false}
              text={t("구매확정")}
            />
          </ButtonWrapper>
          <DeliverProgressComponent deliverStatus={DeliverStatus[purchaseData.product.status]} />
        </>
      ) : purchaseData.is_done ? (
        <></>
      ) : (
        <DeliverProgressComponent deliverStatus={DeliverStatus[purchaseData.product.status]} />
      )}
      <hr style={{ marginTop: "1rem" }} />
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        content={t(`상품 확인 후 구매확정을 눌러주세요! 판매자에게 정산이 이뤄집니다.`)}
        leftBtnString={t("취소")}
        rightBtnString={t("구매확정")}
        onClickSubmit={handleModalBtn}
      />
    </HorizontalCard>
  );
};

const HorizontalCard = styled.div`
  width: calc(100% - 2rem);
`;

const CardSection = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 1rem 0;
`;

const ImageSection = styled.div<{image: string}>`
  min-width: 64px;
  height: 64px;
  border-radius: 8px;
  background-image: url(${(props)=> props.image});
  background-repeat: no-repeat;
  background-size: cover;
`;

const TextSection = styled.div`
  width: calc(100%-100px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Text = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.GREY[700]};
  font-size: 14px;
  font-weight: 400;
  &.title {
    font-size: 16px;
  }
  &.itemPrice {
    font-weight: 600;
    color: ${colors.GREY[900]};
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  width: calc(100% - 1rem);
`;

const OneButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default HorizontalCard_Buy;
