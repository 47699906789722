import { styled } from "styled-components";
import {colors} from "../../styled/colors";
import { Font_lg, Font_xs } from "styled/fonts";
import profile_default_img from "../../assets/profile_default_img.svg";

interface ProfileTypes {
    photo: string;
    nickname: string;
    code: number;
}

const ProfileComponent = (props:ProfileTypes) => {

    return (
        <ProfileWrapper>
            <ProfilePhoto src={props.photo || profile_default_img }/>
            <Contents>
                <Font_lg isBold>{props.nickname}</Font_lg>
                <Font_xs color={colors.GREY[400]}>#{props.code}</Font_xs>
            </Contents>
        </ProfileWrapper>
    )
}

const ProfileWrapper = styled.div`
    display: flex;
    gap: 12px;
`;

const ProfilePhoto = styled.img`
    width: 3rem;
    height: 3rem;
    border: 1px solid;
    border-color: ${colors.GREY[100]};
    border-radius: 70%;
    overflow: hidden;
`;

const Contents = styled.div`

`;

export default ProfileComponent;