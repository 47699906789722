import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { langState, loginState } from "../../recoil/loginRecoil";
import { VerticalCardType } from "../../types/types";
import { isNotLogin } from "../../api/UserUtil";
import { getItemDetail } from "../../api/api";
import { getChart } from "../../api/api";
import ProcessHeaderComponent from "../../components/common/Headers/ProcessHeader";
import { Font_md } from "../../styled/fonts";
import { Tab, TabList, Tabs } from "@chakra-ui/react";
import ChartComponent from "../../components/item/ChartComponent";
import TradingHistory, {ChartProps} from "../../components/item/TradingHistory";
import { colors } from "styled/colors";
import { exchangeRateState } from "recoil/homeRecoil";

const TradingHistoryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const originPath: string = params.get("origin-path") || "";
  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState);
  const cardId: string = params.get("id") || "1";
  const [itemInfo, setItemInfo] = useState<VerticalCardType>();
  const [chartData, setChartData] = useState<ChartProps[]>([]);
  const [grading, setGrading] = useState<string | undefined>("");
  const [filterData, setFilterData] = useState<ChartProps[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const exchangeRate = useRecoilValue(exchangeRateState);

  const user: any = useRecoilValue(loginState);

  useEffect(() => {
    i18n.changeLanguage(language);
    (async () => {
      const data = await isNotLogin(user);
      let response;
      if (data) response = await getItemDetail(cardId);
      else response = await getItemDetail(cardId, user.access);
      if (response.error) {
        console.error(response.error);
      } else {
        setItemInfo(response.data);
      }
      if (response.data) {
        const res = await getChart(response.data.value_product);
        const currentDate = new Date();
        const threeMonthsAgo = new Date(currentDate);
        threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
        const filterData = res.filter((item: any) => new Date(item.created) >= threeMonthsAgo);
        const gradingOption = response.data.value_product.find(
          (item) => item.option.name === "그레이딩"
        );
        const gradingValue = gradingOption ? gradingOption.value : undefined;
        setGrading(gradingValue);
        setChartData(res);
        setFilterData(filterData);
      }
    })();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate);
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    if (tabIndex === 0) {
      const filteredData = chartData.filter(
        (item) => new Date(item.created) >= threeMonthsAgo
      );
      setFilterData(filteredData);
    } else if (tabIndex === 1) {
      const filteredData = chartData.filter(
        (item) => new Date(item.created) >= sixMonthsAgo
      );
      setFilterData(filteredData);
    } else if (tabIndex === 2) {
      const filteredData = chartData.filter(
        (item) => new Date(item.created) >= oneYearAgo
      );
      setFilterData(filteredData);
    } else if (tabIndex === 3) {
      setFilterData(chartData);
    }
  }, [tabIndex]);

  return (
    <Container>
      {filterData.length && exchangeRate ? (
        <>
          { language === "ko-KR" ? <ProcessHeaderComponent title="거래내역" isSearchForm={false} /> : <ProcessHeaderComponent title="Trading History" isSearchForm={false} /> }
          <div
            style={{
              width: "100%",
              padding: "0 16px",
              boxSizing: "border-box",
            }}
          >
            <Tabs
              variant="line"
              size="md"
              isFitted
              colorScheme="black"
              width={"100%"}
              style={{ marginTop: "1rem" }}
            >
              <TabList>
                <TabItem
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(0)}
                >
                  {t("3개월")}
                </TabItem>
                <TabItem
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(1)}
                >
                  {t("6개월")}
                </TabItem>
                <TabItem
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(2)}
                >
                  {t("1년")}
                </TabItem>
                <TabItem
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(3)}
                >
                  {t("전체")}
                </TabItem>
              </TabList>
            </Tabs>
          </div>
          <ChartComponent
            chartData={filterData.sort(
              (a, b) =>
                new Date(b.created).getTime() - new Date(a.created).getTime()
            )}
            exchangeRate={exchangeRate}
            language={language}
          />
          <TradingHistory exchangeRate={exchangeRate} language={language} chartData={filterData} sellingType={itemInfo?.selling_type} />
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TabItem = styled(Tab)`
  font-weight: 400;
`
export default TradingHistoryPage;
