import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Button, useDisclosure } from '@chakra-ui/react';
import OutlineButtonComponent from 'components/common/OutlineButtonComponent';
import styled from 'styled-components';
import { Font_md } from 'styled/fonts';
import { Size } from 'types/types';

interface OneButtonModalTypes {
    text: string;
    buttonText: string;
    onClose: () => void;
    isOpen: boolean;
}
const OneButtonModal = (props: OneButtonModalTypes) => {
    return (
        <Modal size='xs'onClose={props.onClose} isOpen={props.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <OneButtonModalBody>
            <Text>
            {props.text}
            </Text>
            <ButtonWrapper>
            <CloseButton
            text={props.buttonText}
            size={Size.LARGE}
            onClick={props.onClose}/>
            </ButtonWrapper>
            
          </OneButtonModalBody>
          {/* <ModalFooter>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    )
}

const OneButtonModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled(Font_md)`
  padding: 1rem 0;
`

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
  width: calc(100% + 2rem);
  display: flex;
  justify-content:center;
`
const CloseButton = styled(OutlineButtonComponent)`
  width: 100%;
`

export default OneButtonModal;