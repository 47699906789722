import { styled } from "styled-components";
import addon from "assets/addon.svg";
import { Font_md } from "styled/fonts";
import { Input } from "@chakra-ui/react";
import { colors } from "styled/colors";
import addon_dollar from "assets/addon_dollar.svg";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";

interface PriceInputType {
    title: string;
    price: number | undefined;
    setPrice: React.Dispatch<React.SetStateAction<number>>;
    isKRW: boolean;
}

const PriceInputComponent = (props: PriceInputType) => {
    const { title, price, setPrice } = props;

    const handlePriceChange = (event: any) => setPrice(event.target.value);

    return (
        <ComponentWrapper>
            <PriceInputWrapper>
                <Font_md isBold>{title}</Font_md>
                <PriceInput>
                    <PriceIcon src={props.isKRW ? addon : addon_dollar}/>
                    <Input
                    size="lg"
                    placeholder="0"
                    _placeholder={{color: colors.GREY[400]}}
                    value={price}
                    onChange={handlePriceChange}
                    />
                </PriceInput>
            </PriceInputWrapper>
        </ComponentWrapper>
    )
}

const ComponentWrapper = styled.div`
`

const PriceInputWrapper = styled.div`
    padding: 1.5rem 1rem;
`

const PriceInput = styled.div`
    display: flex;
    margin-top: 0.5rem;
`

const PriceIcon = styled.img`
`

export default PriceInputComponent;