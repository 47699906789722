import { atom, useRecoilState, useRecoilValue } from "recoil";
import { OptionState } from "types/types";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const sellingTypeState = atom<number | undefined>({
    key: 'sellingTypeState',
    default: undefined,
});

export const categoryState = atom<number | undefined>({
    key: 'saleMethodState',
    default: undefined,
});

export const productNameState = atom<string>({
    key: 'productNameState',
    default: '',
});

export const productContentState = atom<string>({
    key: 'productContentState',
    default: '',
});

export const priceState = atom<number>({
    key: 'priceState',
    default: undefined,
});

export const periodState = atom<number | undefined>({
    key: 'periodState',
    default: 0,
});

export const startDateState = atom<Date | null>({
    key: 'startDateState',
    default: null,
});

export const imageProductState = atom<File[]>({
    key: 'imageProductState',
    default: [],
});

export const sportsState = atom<string>({
    key: 'sportsState',
    default: ''
});

export const yearState = atom<string>({
    key: 'yearState',
    default: ''
});

export const playerNameState = atom<string>({
    key: 'playerNameState',
    default: ''
});

export const gradingState = atom<string>({
    key: 'gradingState',
    default: ''
});

export const producerState = atom<string>({
    key: 'produceState',
    default: ''
});

export const optionsState = atom<OptionState[]>({
    key: 'optionsState',
    default: [],
})

export const useSharedAtoms = ()  => {
    const sellingType = useRecoilValue(sellingTypeState);
    const category = useRecoilValue(categoryState);
    const productName = useRecoilValue(productNameState);
    const productContent = useRecoilValue(productContentState);
    const price = useRecoilValue(priceState);
    const period = useRecoilValue(periodState);
    const startDate = useRecoilValue(startDateState);
    const imageProduct = useRecoilValue(imageProductState);
    const sports = useRecoilValue(sportsState);
    const year = useRecoilValue(yearState);
    const playerName = useRecoilValue(playerNameState);
    const grading = useRecoilValue(gradingState);
    const producer = useRecoilValue(producerState);

    return {
        sellingType,
        category,
        productName,
        productContent,
        price,
        period,
        startDate,
        imageProduct,
        sports,
        year,
        playerName,
        grading,
        producer,
    }
};

// export const resetRecoilStates = () => {
    
//     useRecoilState(sellingTypeState)[1](0);
//     useRecoilState(categoryState)[1](0);
//     useRecoilState(productNameState)[1]('');
//     useRecoilState(productContentState)[1]('');
//     useRecoilState(priceState)[1](0);
//     useRecoilState(periodState)[1](0);
//     useRecoilState(startDateState)[1](null);
//     useRecoilState(imageProductState)[1]([]);
//     useRecoilState(sportsState)[1]('');
//     useRecoilState(yearState)[1]('');
//     useRecoilState(playerNameState)[1]('');
//     useRecoilState(gradingState)[1]('');
//     useRecoilState(producerState)[1]('');
  
//   }

