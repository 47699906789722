import { styled } from "styled-components";
import SuccessContent from "components/common/SuccessContent";
import ButtonComponentGreen from "components/common/buttons/ButtonComponentGreen";
import { Size } from "types/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";

const SuccessPage = () => {
    const navigate = useNavigate();
    const handleButton = () => {
      navigate('/');
    }

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <SuccessPageWrapper>
            <SuccessContent
            mainText={t("탈퇴가 완료되었습니다.")}
            subText={t("리스트잇을 이용해주셔서 감사합니다.")}/>
            <ButtonComponentGreen
            text={t("확인")}
            size={Size.LARGE}
            onClick={handleButton}
            />
        </SuccessPageWrapper>
    )
};

const SuccessPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  padding-bottom: 16px;
`

export default SuccessPage;