import { styled } from "styled-components";
import { Input, InputGroup } from "@chakra-ui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import { nicknameState, isValidNicknameState, emailState } from "recoil/signupRecoil";
import { useEffect, useState } from "react";
import { Font_sm } from "styled/fonts";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";

interface NicknameIProps {
  email?: string;
}

// 재사용 가능성이 있어 component 로 구현합니다. 
const NicknameValidComponent = (props: NicknameIProps) => {
  const { t, i18n } = useTranslation("signup");
  const language = useRecoilValue(langState);

  const [isValid, setIsValid] = useRecoilState(isValidNicknameState);
  const [nickname, setNickname] = useRecoilState(nicknameState);
  const email = useRecoilValue(emailState);
  
  const handleChangeNickname = (event: any) => setNickname(event.target.value);

  useEffect(()=>{
    const specialCharsAndWhitespace = /[!@#$%^&*()+\=[\]{};':"\\|,.<>/?\s]/;

    if(nickname.length >= 20 || specialCharsAndWhitespace.test(nickname) || nickname === '')
      setIsValid(false)
    else
      setIsValid(true)
  }, [nickname])

  useEffect(()=>{
    setTimeout(() => {
      if (props.email) setNickname(props.email.split("@")[0]);
    }, 10);
    if(nickname === null || nickname === undefined){
      setNickname('');
    }
    i18n.changeLanguage(language);
  }, [])

  return (
    <NicknameValid>
      <Input
        size="lg"
        style={{ width: "calc(100% - 2rem)" }}
        placeholder={t("닉네임 (공백, 특수문자 불가)")}
        value={nickname}
        onChange={handleChangeNickname}
        isInvalid={(!isValid && !(nickname.length === 0))}
      />
      {(!isValid && !(nickname.length === 0)) && (
        <ErrMsg>
          <Font_sm color="#E53E3E">
            {t("공백 없이 20자 이내로 한글, 영문, 숫자만 입력해주세요.")}
          </Font_sm>
        </ErrMsg>
      )}
    </NicknameValid>
  );
};

const NicknameValid = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ErrMsg = styled.div`
  width:100%;
  padding: 0.5rem 0 0 1rem;
`

export default NicknameValidComponent;
