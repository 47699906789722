import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_md } from "styled/fonts";

const EmptyMessage = () => {
    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <EmptyMessageWrapper>
            <Font_md color={colors.GREY[400]}>{t("내역이 없습니다.")}</Font_md>
        </EmptyMessageWrapper>
    )
};

const EmptyMessageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height:calc(100vh - 106px);
`

export default EmptyMessage;