import { styled } from "styled-components";
import HorizontalCard_Buy from "./HorizontalCard_Buy";
import { Font_sm } from "styled/fonts";
import next_img from "assets/chevron_right.svg";
import { colors } from "styled/colors";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import { DeliverStatus, PurchaseData, PurchaseListData } from "types/types";
import { isoStringToDate } from "api/StringUtils";
import { useNavigate } from "react-router-dom";

interface CardListProps {
  cardList: PurchaseListData[];
  isDetail: boolean;
}

const CardListComponent = (props: CardListProps) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation("history");
  const language = useRecoilValue(langState);

  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])

  return (
    <CardList>
      {props.cardList.map((list) => (
        <>
          <ListTitle onClick={()=> {navigate(`/purchace-detail/${list.id}`, {state: list})}}>
            <Font_sm>{isoStringToDate(list.created, false, false)} {t("결제")}</Font_sm>
            {props.isDetail ? (
              <Font_sm color={colors.GREY[500]}>{t("주문번호")} {list.order_id}</Font_sm>
            ) : (
              <img src={next_img} />
            )}
          </ListTitle>
          {list.purchases.map((info : PurchaseData) => (
            <HorizontalCard_Buy key={info.id}
              purchaseData = {info}
              isDetail={props.isDetail}
            />
          ))}
        </>
      ))}
    </CardList>
  );
}

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: calc(100% - 2rem);
  margin-top: 1rem;
  cursor: pointer;
`

export default CardListComponent;