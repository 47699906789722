import styled from "styled-components";
import PasswordValidComponent from "components/common/PasswordValidComponent";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useRecoilValue } from 'recoil';
import { isValidPasswordState } from "recoil/signupRecoil";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";
import { Font_sm } from "styled/fonts";

const isShowBtnStyle = {
  right:"12px",
}

const WithDrawPage = () => {
  
  const isValid = useRecoilValue(isValidPasswordState);
  const navigate = useNavigate();
  const [desc, setDesc] = useState('');

  const HandleClick = async() => {
    // something to do
    navigate('/mypage/personal');
  };
  const handleChangeNum = async (event: any) => setDesc(event.target.value);
  return (
    <WithDraw>
      <ProcessHeader title={"회원 탈퇴"} isSearchForm={false} />
      <StringWrapper>
      <Contents>
        <p>정말로 탈퇴하시겠어요?</p>
        <p>탈퇴 시 모든 정보가 삭제 됩니다.</p>
      </Contents>
    </StringWrapper>
      <InputWrapper>
        <Input
          size="lg"
          placeholder="탈퇴 사유"
          value={desc}
          onChange={handleChangeNum}
          style={{width:'calc(100% - 2rem)'}}
        />
      </InputWrapper>
      <ButtonWrapper>
        <ButtonComponent
          onClick={HandleClick}
          isValid={isValid}
          text={"탈퇴하기"}
          size={Size.LARGE}
        />
      </ButtonWrapper>
    </WithDraw>
  );
};

const WithDraw = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  margin:32px 0px 32px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  .origin{
    width: calc(100% - 2rem);
    
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
`

const StringWrapper = styled.div`
  margin: 25px 1rem 25px 1rem;
  font-size: 20px;
  font-weight: 400;
  .highlight {
    font-weight: 600;
  }
`;

const Contents = styled.div`
  width: 328px;
`

export default WithDrawPage;