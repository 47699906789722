import NameInputComponent from "components/NameInputComponent";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import { Font_md, Font_sm, Font_xl, Font_lg } from "styled/fonts";
import { Input, Textarea } from "@chakra-ui/react";
import { colors } from "styled/colors";
import PhoneInputComponent from "components/PhoneInputComponent";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import ChipComponent from "components/common/ChipComponent";
import PostingPictures from "components/common/PostingPictures";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { useEffect, useState } from "react";
import { Size } from "types/types";
import { useNavigate } from "react-router-dom";
import { register } from "api/SellerApi";
import { langState, loginState } from "recoil/loginRecoil";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import chevron from "assets/chevron_right.svg";
import TermsofUseForSeller from "../TermsofUseForSellerPage";
import {isNotLogin} from "../../../api/UserUtil";

const SellerApplicationPage = () => {
  const { t, i18n } = useTranslation("mypage");
  const language = useRecoilValue(langState);

  const [name, setName] = useState("");
  const [birth, setBirth] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [bankName, setBankName] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [account, setAccount] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [mailordersalesNumber, setMailordersalesNumber] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isShowTerms, setIsShowTerms] = useState(false);

  const handleBirthChange = (event: any) => setBirth(event.target.value);
  const handleAddressChange = (event: any) => setAddress(event.target.value);
  const handleIntroductionChange = (event: any) =>
    setIntroduction(event.target.value);
  const handleBusinessNumber = (event: any) =>
    setBusinessNumber(event.target.value);
  const handleMailordersalesNumber = (event: any) =>
    setMailordersalesNumber(event.target.value);
  const handleAccountHolderChange = (event: any) =>
    setAccountHolder(event.target.value);
  const handlebankNameChange = (event: any) => setBankName(event.target.value);
  const handleSwiftCodeChange = (event: any) =>
    setSwiftCode(event.target.value);
  const handleAccountChange = (event: any) => setAccount(event.target.value);
  const handleIsShow = () => setIsShowTerms(!isShowTerms);

  const handleCategoryToggle = (name: string) => {
    if (categories.includes(name)) {
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category != name)
      );
    } else {
      setCategories((prevCategories) => [...prevCategories, name]);
    }
  };
  const handleChangePhoneNumber = (event: any) => {
    if (event !== undefined) setPhoneNumber(event);
  };

  const navigate = useNavigate();

  const convertToISO = (date: string): string => {
    const isoDateString = new Date(date).toISOString();
    return isoDateString;
  };

  const user: any = useRecoilValue(loginState);

  const handleButton = async () => {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("birthday", convertToISO(birth));
    formData.append("phone_number", phoneNumber);
    formData.append("introduce", introduction);
    formData.append("account_owner", accountHolder);
    formData.append("account_bank", bankName);
    swiftCode && formData.append("swift_code", swiftCode);
    formData.append("account_number", account);
    formData.append("user", user.user.id);
    formData.append("category", categories.join(", ").toString());
    address && formData.append("address", address);
    businessNumber && formData.append("business_number", businessNumber);
    mailordersalesNumber &&
      formData.append("mailordersales_number", mailordersalesNumber);
    selectedFiles.forEach((image, index) => {
      const fieldName = `image${index + 1}`;
      formData.append(fieldName, image);
    });
    setLoading(true);
    const sellerRes: any = await register(formData, user.access);
    setLoading(false);
    if (sellerRes.error) {
      console.log("register error:", sellerRes.error);
    } else {
      navigate("/mypage/seller-application/success");
    }
  };

  const isCategoryButtonClick = categories.length !== 0;
  const photoExists = selectedImages.length !== 0;

  const inputs = [
    name,
    birth,
    phoneNumber,
    introduction,
    accountHolder,
    bankName,
    account,
    isCategoryButtonClick,
    photoExists,
    isChecked,
  ];

  useEffect(()=>{
    (async () => {
      const data = await isNotLogin(user);
      if (data) {
        navigate("/onboarding", { replace: true });
      }
      if (user.user.is_seller) {
        navigate("/mypage");
      }
    })();
  }, [])

  useEffect(() => {
    i18n.changeLanguage(language);
    setBirth("yyyy-mm-dd");
  }, []);
  const isValid = inputs.every((input) => Boolean(input));

  const phoneNumberStyle = {
    width: "100%",
    border: "1px solid #E2E8F0",
    borderRadius: "6px 6px 6px 6px",
    height: "48px",
    padding: "0px 12px 0px 16px",
    gap: "1rem",
  };

  return isShowTerms ? (
    <TermsofUseForSeller onClick={handleIsShow} title={t("판매자 신청")} />
  ) : (
    <ApplicationWrapper>
      {loading ? (
        <Loading />
      ) : (
        <MainWrapper>
          <ProcessHeader title={t("판매자 신청")} isSearchForm={false} />
          <SellerInfo>
            <Title>
              {language === "ko-KR" ? (
                <>
                  <Font_xl>
                    <Highlight>판매자 신청</Highlight>을 위한
                  </Font_xl>
                  <Font_xl>정보를 입력해주세요</Font_xl>
                </>
              ) : (
                <>
                  <Font_xl>Please fill out</Font_xl>
                  <Font_xl>the following fields</Font_xl>
                </>
              )}
            </Title>
            <Inputs>
              <NameInputComponent
                name={name}
                placeholder={t("이름(실명)")}
                setName={setName}
              />
              <BirthInput
                value={t(birth)}
                onChange={handleBirthChange}
                placeholder="yyyy-mm-dd"
                data-placeholder={t("생년월일")}
                aria-required="true"
                size="lg"
                type="date"
                lang="en-US"
                required
              />
              <PhoneInput
                defaultCountry="KR"
                style={phoneNumberStyle}
                placeholder={t("휴대폰 번호")}
                value={phoneNumber}
                _placeholder={{ color: colors.GREY[400] }}
                onChange={handleChangePhoneNumber}
              />
              <Input
                size="lg"
                placeholder={t("주소 (선택)")}
                _placeholder={{ color: colors.GREY[400] }}
                value={address}
                onChange={handleAddressChange}
              />
              {/* <PhoneInputComponent/> */}
              <Textarea
                placeholder={t("소개글")}
                _placeholder={{ color: colors.GREY[400] }}
                value={introduction}
                onChange={handleIntroductionChange}
                size="lg"
                rows={4}
              />
            </Inputs>
            {language == "ko-KR" &&
            <Inputs>
            <Font_md isBold>사업자 정보(선택)</Font_md>
            <Input
              size="lg"
              placeholder="사업자등록번호"
              _placeholder={{ color: colors.GREY[400] }}
              value={businessNumber}
              onChange={handleBusinessNumber}
            />
            <Input
              size="lg"
              placeholder="통신판매업신고번호"
              _placeholder={{ color: colors.GREY[400] }}
              value={mailordersalesNumber}
              onChange={handleMailordersalesNumber}
            />
          </Inputs>}
            <CategoryWrapper>
              <Font_md isBold>{t("주요 판매 카테고리")}</Font_md>
              <Categorys>
                <ChipComponent
                  name={t("스포츠 카드")}
                  onClick={() => handleCategoryToggle("스포츠 카드")}
                />
                <ChipComponent
                  name={t("스포츠 컬렉션")}
                  onClick={() => handleCategoryToggle("스포츠 컬렉션")}
                />
              </Categorys>
            </CategoryWrapper>
            <PhotoWrapper>
              <Font_md isBold>{t("상품 예시 사진")}</Font_md>
              <PostingPictures
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </PhotoWrapper>
            <AccountWrapper>
              <Font_md isBold>{t("정산계좌")}</Font_md>
              <Inputs>
                <Input
                  placeholder={t("예금주명")}
                  size="lg"
                  _placeholder={{ color: colors.GREY[400] }}
                  value={accountHolder}
                  onChange={handleAccountHolderChange}
                />
                {language !== "ko-KR" && (
                  <Input
                    placeholder={t("스위프트 코드")}
                    size="lg"
                    _placeholder={{ color: colors.GREY[400] }}
                    value={swiftCode}
                    onChange={handleSwiftCodeChange}
                  />
                )}
                <Input
                  placeholder={t("은행명")}
                  size="lg"
                  _placeholder={{ color: colors.GREY[400] }}
                  value={bankName}
                  onChange={handlebankNameChange}
                />
                <Input
                  placeholder={t("계좌번호")}
                  inputMode="numeric"
                  size="lg"
                  _placeholder={{ color: colors.GREY[400] }}
                  value={account}
                  onChange={handleAccountChange}
                />
              </Inputs>
              <Font_sm color={colors.GREY[400]}>
                {t("정산계좌에 따라 상품의 판매 통화가 설정됩니다.")}
              </Font_sm>
            </AccountWrapper>
          </SellerInfo>
          <GreyBorder />
          <Notification>
            <Font_md isBold>{t("판매자 신청 안내사항")}</Font_md>
            <Font_sm>{t("· 리스트잇 기본 판매 수수료는 5%입니다.")}</Font_sm>
            <Font_sm>
              {t("· 판매자 신청 절차 중 전화 및 문자 안내를 드릴 예정입니다.")}
            </Font_sm>
            <Font_sm>
              {t("· 판매자 신청을 위해 개인정보를 수집합니다.")}
            </Font_sm>
          </Notification>
          <NotificationCheckBox
            colorScheme="green"
            size="lg"
            isChecked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          >
            <CheckboxContent onClick={() => handleIsShow()}>
              <Font_md>
                {t(
                  "안내사항을 모두 확인했으며, 개인정보수집 및 판매자 서비스 이용약관에 동의합니다."
                )}
              </Font_md>
              <Chrevron src={chevron} />
            </CheckboxContent>
          </NotificationCheckBox>
          <ButtonWrapper>
            <ButtonComponent
              text={t("판매자 신청하기")}
              isValid={isValid}
              size={Size.LARGE}
              onClick={handleButton}
            />
          </ButtonWrapper>
        </MainWrapper>
      )}
    </ApplicationWrapper>
  );
};

const ApplicationWrapper = styled.div`
  padding-bottom: 16px;
`;

const MainWrapper = styled.div``;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Highlight = styled.span`
  font-weight: 600;
`;

const Title = styled.div``;

const SellerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem 0;
  padding: 0 1rem;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BirthInput = styled(Input)`
  &::before {
    content: attr(data-placeholder);
    width: 100%;
    color: ${colors.GREY[400]};
  }
  &:focus::before,
  &:valid::before {
    display: none;
    color: ${colors.GREY[700]};
  }
  ime-mode: inactive;
`;

const CategoryWrapper = styled.div``;

const Categorys = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 12px;
`;

const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const GreyBorder = styled.div`
  height: 0.5rem;
  background-color: ${colors.GREY[50]};
`;

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
`;

const NotificationCheckBox = styled(Checkbox)`
  padding: 1.5rem 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CheckboxContent = styled.div`
  display: flex;
  justify-content: center;
`;

const Chrevron = styled.img``;

export default SellerApplicationPage;
