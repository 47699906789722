import {useEffect, useState} from "react";
import { styled } from "styled-components";
import backArrow from "../../../assets/backArrow.svg";
import { useNavigate } from "react-router-dom";
import { Input, Divider } from "@chakra-ui/react";
import { Font_md } from "styled/fonts";
import {useRecoilState, useSetRecoilState} from "recoil";
import { itemListState } from "recoil/homeRecoil";
import { getItemList } from "api/api";
import { useTranslation } from "react-i18next";
import {searchState} from "../../../recoil/searchRecoil";
import {filterPageState} from "../../../recoil/filterRecoil";

interface HearderIProps{
  handleInput: any;
  searchVal: any;
  HandleSearchClick: any;
}

const SearchHeaderComponent = (props: HearderIProps) => {
  const { t } = useTranslation("main");
  const navigate = useNavigate();
  const [itemList, setItemList] = useRecoilState(itemListState);
  const setPage = useSetRecoilState(filterPageState);
  const HandleBackClick = () => {
    setItemList([]);
    setPage(1);
    navigate(-1);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.HandleSearchClick('');
    }
  };

  return (
    <>
      <Header>
        <ArrowBtnWrapper>
          <ArrowBtn onClick={HandleBackClick} src={backArrow}></ArrowBtn>
        </ArrowBtnWrapper>
        <TextWrapper style={{ width: "100%", marginLeft:'0.5rem' }}>
          <Input
            onChange={props.handleInput}
            onKeyPress={handleKeyPress}
            value={props.searchVal}
            variant="unstyled"
            placeholder={t("상품명 입력")}
          />
        </TextWrapper>
        <SearchBtn
          onClick={() => props.HandleSearchClick('')}
          style={{ width: "15%" }}
        >
          <Font_md isBold>{t("검색")}</Font_md>
        </SearchBtn>
      </Header>
      <Divider />
    </>
  );
};

const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  z-index: 10;
`;

const ArrowBtn = styled.img``;

const ArrowBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 10%;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
`;

export default SearchHeaderComponent;
