import { styled } from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import DetailCard from "components/auctionHistory/DetailCard";
import { AuctionType, BiddingData, CartData, DeliverStatus, Size, VerticalCardType } from "types/types";
import card_img from "assets/card_img.svg";
import { Font_md, Font_sm, Font_xs } from "styled/fonts";
import { addDaysToDate, checkAndConvertCurrency, formatCurrency, isoStringToDate } from 'api/StringUtils';
import { colors } from "styled/colors";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { getOneBiddingData } from "api/UserHistoryApi";
import { loginState } from "recoil/loginRecoil";
import { useRecoilState } from "recoil";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import notify from "components/common/toast/ToastComponent";
import { ToastContainer } from "react-toastify";
import { CalculateStatus } from "types/types";
import { getPresetData } from "api/api";
import { exchangeRateState, payableItemsState } from "recoil/homeRecoil";

const BidDetailPage = () => {
    const { id } = useParams();
    const user : any = useRecoilValue(loginState);

    const navigate = useNavigate();

    const [payableItems, setPayableItems] = useRecoilState(payableItemsState);
    
    const cartDataToVerticalData = (data: CartData) => {
        return {
          id: data.product.id.toString(),
          author: {},
          name: data.product.name,
          price: data.product.price,
          isAuction: false,
          created: data.created,
          is_selled: data.product.is_selled,
          image_product: [],
          value_product: [],
          content: "",
          end_date: "",
          selling_type: 1,
          start_date: "",
          image1: data.product.image1,
          is_bidded: false,
          is_pending: false,
          bidder:0,
          dollar_price: data.dollar_price,
        } as VerticalCardType;
      };


    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const [status, setStatus] = useState<string>();
    const [bidData, setBidData] = useState<BiddingData>();
    const [hasDeliveryStatus, setHasDeliveryStatus] = useState<boolean>();
    const [deliveryPrice, setDeliverPrice] = useState(0);

    const exchangeRate = useRecoilValue(exchangeRateState);

    const fetchPresetData = async() => {
      const data = await getPresetData();
      const findDeliveryPrice = data.filter((item)=> item.key == "delivery");
      setDeliverPrice(Number(findDeliveryPrice[0].value));
    }

    const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(
        isCompletePurchaceState
      );

    const fetchBidData = async () => {
        console.log(user.access);
        if(id) {
            try {
                const bidRes = await getOneBiddingData(user.access, Number(id));
                identifyBidStatus(bidRes);
                setBidData(bidRes);
            } catch(error) {
                console.log(error);
            }
        }
    }

    const onClickPayBtn = () => {
        if(bidData) {
            navigate("/pay");
            const selectedItem : CartData= {
                created: "",
                id: 0,
                modified: "",
                product: {
                    id: bidData.product.id,
                    name: bidData.product.name,
                    image1: bidData.product.image1,
                    is_selled: bidData.product.is_selled,
                    price: bidData.price,
                },
                dollar_price: bidData.dollar_price,
            }
            const tmpItem: VerticalCardType = cartDataToVerticalData(selectedItem);
            const newPayableItems = [];
            newPayableItems.push(tmpItem);
    
            setPayableItems(newPayableItems);
        }
    }

    useEffect(()=> {
        fetchPresetData();
        i18n.changeLanguage(language);
        fetchBidData();
        console.log(bidData?.dollar_price);
    },[])

    useEffect(()=> {
        fetchBidData();
    },[isCompletePurchace])

    useEffect(()=> {
        if(status){
            setHasDeliveryStatus((status == "결제대기" || status == "선정취소") ? false : true);
        }
    },[status])

    const identifyBidStatus = (data : BiddingData | undefined) => {
        if(data) {
            if(data.purchase && data.purchase.is_done) {
                setStatus("구매확정");
            } else {
                setStatus(DeliverStatus[data.product.status]);
            }
        }
    }


    return (
        <DetailWrapper>
            <ToastContainer />
            <ProcessHeaderComponent
            title={t("선정 상세 내역")}
            isSearchForm={false}/>
            {bidData &&
            <>
            <DetailCard
            date={isoStringToDate(bidData.product.end_date, false, false)}
            name={bidData.product.name}
            auctionType={AuctionType.선정}
            image={bidData.product.image1}
            deliverStatus={hasDeliveryStatus && status !== "구매확정" ? status : undefined}
            auctionStatus={status}
            data={bidData}
            />
            {/* <AuctionInfoWrapper>
                <Font_md isBold>{t("선정 정보")}</Font_md>
                <PriceInfo>
                    <Font_sm>{t("최종 낙찰가")}</Font_sm>
                    <Font_sm>{checkAndConvertCurrency(language,bidData.product.success_price,exchangeRate?.basePrice)}</Font_sm>
                </PriceInfo>
            </AuctionInfoWrapper> */}
            {(hasDeliveryStatus && bidData.purchase) &&
            <AuctionInfoWrapper>
            <Font_md isBold>{t("배송 정보")}</Font_md>
            <InfoList>
                <InfoTitle>
                    <Font_sm>{t("수령인")}</Font_sm>
                    <Font_sm>{t("연락처")}</Font_sm>
                    <Font_sm>{t("주소")}</Font_sm>
                    <Font_sm>{t("배송메모")}</Font_sm>
                </InfoTitle>
                <InfoContent>
                    <Font_sm>{bidData.purchase.name}</Font_sm>
                    <Font_sm>{bidData.purchase.phone}</Font_sm>
                    <Font_sm>{bidData.purchase.address}</Font_sm>
                    <Font_sm>{bidData.purchase.memo}</Font_sm>
                </InfoContent>
            </InfoList>
        </AuctionInfoWrapper>
        }
            <PayInfoWrapper>
                <PayContentWrapper>
                    <Font_md isBold>{t("결제 정보")}</Font_md>
                    <InfoList>
                        <InfoTitle>
                            <Font_sm>{t("상품금액")}</Font_sm>
                            <Font_sm>{t("배송비")}</Font_sm>
                            <Font_sm isBold>{t("총 결제금액")}</Font_sm>
                        </InfoTitle>
                        <PayInfoContent>
                            <Font_sm>{checkAndConvertCurrency(language, bidData.product.success_price, bidData.purchase?.currency, exchangeRate?.basePrice)}</Font_sm>
                            <Font_sm>{checkAndConvertCurrency(language, deliveryPrice, bidData.purchase?.currency, exchangeRate?.basePrice)}</Font_sm>
                            <Font_sm isBold>{checkAndConvertCurrency(language, bidData.product.success_price + deliveryPrice, bidData.purchase?.currency, exchangeRate?.basePrice)}</Font_sm>
                        </PayInfoContent>
                    </InfoList>
                </PayContentWrapper>
                {!hasDeliveryStatus &&
                <>
                <ButtonWrapper>
                    <ButtonComponent
                        isValid={status == "결제대기" ? true : false}
                        text={t("결제하기")}
                        size={Size.LARGE}
                        onClick={onClickPayBtn}
                        />
                </ButtonWrapper>
                {status == "결제대기" ? (
                    <PayMessage>
                        <Font_sm color={"#E53E3E"}>{t("결제기한")} {addDaysToDate(bidData.product.end_date, 3, true, false)}</Font_sm>
                        <Font_xs>{t("기한 내 결제를 진행하지 않으면 다음 제안 시")}</Font_xs>
                        <Font_xs>{t("패널티가 적용됩니다. 기한 내에 꼭 결제해주세요.")}</Font_xs>
                    </PayMessage>
                ) : (
                    <PayMessage>
                        <Font_sm color={colors.GREY[400]}>{t("결제기한")} {addDaysToDate(bidData.product.end_date, 3, true, false)}</Font_sm>
                        <Font_xs>{t("결제기한이 지나 선정이 취소되었습니다.")}</Font_xs>
                    </PayMessage>
                )}
                </>}
            </PayInfoWrapper>
            </>}
        </DetailWrapper>
    )
}

const DetailWrapper = styled.div`
    padding-bottom: 3rem;
`

const AuctionInfoWrapper = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const PriceInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
`

const InfoList = styled.div`
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
`

const InfoTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
`

const PayInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    align-items: flex-end;
`

const PayInfoWrapper = styled.div`
    border-bottom: 1px solid ${colors.GREY[100]};
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const PayContentWrapper = styled.div`
    padding: 1rem;
`

const PayMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 0;
`

export default BidDetailPage;