import { Routes, Route } from "react-router-dom";
import ChooseCategory from "./ChooseCategory";
import AddProductInfo from "./AddProductInfo";
import PriceSettingDirectlyPage from "./PriceSettingDirectlyPage";
import PriceSettingForAuction from "./PriceSettingForAuction";

const AddProductRouter = () => {
    return (
        <Routes>
            <Route path="/add-product/category" element={<ChooseCategory/>}/>
            <Route path="/add-product/info" element={<AddProductInfo/>}/>
            <Route path="/add-product/price/buy-now" element={<PriceSettingDirectlyPage/>}/>
            <Route path="/add-product/price/auction" element={<PriceSettingForAuction/>}/>
        </Routes>
    )
}

export default AddProductRouter;