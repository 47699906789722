import { Size } from "types/types";
import { styled } from "styled-components";
import { colors } from "styled/colors";

interface ButtonTypes {
  isReverse: boolean;
  text: string;
  cnt: number;
  onClick?: Function;
}

// 같은 크기의 버튼을 수평으로 배치할 때 사용하는 버튼입니다.
// cnt 변수로 갯수를 지정하고 갯수에 맞게 버튼 사이즈를 조절합니다.
const SmallButtonComponent = (props: ButtonTypes) => {
  const tmpFunction = () => {};
  const buttonHandler = props.onClick || tmpFunction;
  return (
    <Button
      cnt={props.cnt}
      isReverse={props.isReverse}
      onClick={()=>buttonHandler()}
    >
      {props.text}
    </Button>
  );
};

const Button = styled.div<{ isReverse: boolean; cnt: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isReverse ? "white" : colors.GREY[700]};
  color: ${(props) => (props.isReverse ? colors.GREY[700] : "white")};
  border: ${(props) =>
    props.isReverse ? `1px solid ${colors.GREY[200]}` : "none"};
  min-width: ${(props) => `calc(100% / ${props.cnt})`};
  font-weight: 600;
  font-size: 18px;
  height: 48px;
  padding: 0px 24px;
  border-radius: 6px;
  cursor: pointer;
`;

export default SmallButtonComponent;
