export const fixedExchangePrice = 1100;

export function formatCurrency(amount: number): string {
  const formattedAmount = amount.toLocaleString('en-US');
  return formattedAmount;
}
export function formatCurrencyUsd(amount: number): string {
  const formattedAmount = amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return formattedAmount;
}
export function changeKrwToUsd(krw?: number, exchangeRate?: number, useApi?: boolean){
  const krw_ = krw === undefined ? 0 : krw;
  if (!useApi) return Math.round((krw_ / fixedExchangePrice) * 100)/100;
  const exchangeRate_ = exchangeRate === undefined ? 1 : exchangeRate;
  return Math.round((krw_ / exchangeRate_) * 100)/100;
}
export function changeFixedUsdToKrw(usd: number) {
  return Math.round(fixedExchangePrice * usd);
}

export function changeUsdToKrw(usd: number, exchangeRate?: number) : number {
  const usd_ = usd === undefined ? 0 : usd;
  const exchangeRate_ = exchangeRate === undefined ? 1 : exchangeRate;
  return Math.round(exchangeRate_ * usd_);
}

export function checkAndConvertCurrency(langauge: string, price: number, currency: string, exchangeRate?: number) {
  if(currency === 'USD') {
    if(langauge === "ko-KR") {
      return "US $" + price;
    } else {
      return "$" + price;
    }
  } else {
    if(langauge === "ko-KR") {
      return formatCurrency(price) + "원";
    } else {
      return "KRW " + formatCurrency(price);
    }
  }
  // if(langauge === "ko-KR") {
  //   return formatCurrency(krw) + '원';
  // } else {
  //   return '$' + Math.round(changeKrwToUsd(krw, exchangeRate)*100)/100;
  // }
}

export function formatDateToKorean(stringDate?: string): string{
  if(stringDate){
    const outputDate = new Date(stringDate);

    const month = outputDate.getMonth() + 1; // 월은 0부터 시작하므로 +1 해줍니다.
    const day = outputDate.getDate();
    const hours = outputDate.getHours();
    const minutes = outputDate.getMinutes();
  
    return `${month}월 ${day}일 ${hours}시 ${minutes}분`
  }
  return "";
}

export function formatDateStringToEnglish(stringDate?: string) {
  if (stringDate) {
    const date = new Date(stringDate);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    return date.toLocaleString('en-US', options);
  }
  return "";
}

const inputString = "2023-10-26T02:56:05";
const englishDate = formatDateStringToEnglish(inputString);

function addZeroToFront(item : number) {
  if(item.toString().length == 1) return '0' + item;
  else return item;
}

export function isoStringToDate(date: string, hasTime: boolean, hasYear: boolean) : string {
  const thisDate = new Date(date);
  const onlyDate = thisDate.toLocaleDateString().split(' ').join(''); // 날짜만 가져오기
  const month = addZeroToFront(thisDate.getMonth() + 1); // 월 가져오기 (0부터 시작하므로 1을 더함)
  const day = addZeroToFront(thisDate.getDate()); // 일 가져오기
  const hours = addZeroToFront(thisDate.getHours()); // 시간 가져오기 (0부터 23까지)
  const minutes = addZeroToFront(thisDate.getMinutes()); // 분 가져오기
  if(hasTime) {
    if(hasYear) {
      // ex. 2020.8.10 16:00
      return onlyDate + ' ' + hours + ':' + minutes;
    } else {
      // ex. 8.10 16:00
      return month + '.' + day + ' ' + hours + ':' + minutes;
    }
  } else if(hasYear) {
    //ex. 2020.8.10
      return onlyDate;
  } else {
    // ex. 8.10
    return month + '.' + day;
  }

}

export function addDaysToDate (date : string, days: number, hasTime: boolean, hasYear: boolean) : string {
  const thisDate = new Date(date);
  const newDate = new Date(date);
  newDate.setDate(thisDate.getDate() + days);
  const dateToString = newDate.toISOString();
  return isoStringToDate(dateToString, hasTime, hasYear);
}

export function generateOrderId() {
  const length = 10;
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  const charactersLength = characters.length;
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }
  
  return result;
}