import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { countdownState } from 'recoil/signupRecoil';

export function StartCountdown() {
  const [time, setTime] = useRecoilState<string>(countdownState);
  const [isCounting, setIsCounting] = useState<boolean>(true);

  const updateCountdown = () => {
    if (!isCounting) return;

    setTime(prevTime => {
      const currentTime = prevTime.split(':');
      let minutes = parseInt(currentTime[0]);
      let seconds = parseInt(currentTime[1]);

      if (minutes === 0 && seconds === 0) {
        setIsCounting(false);
        return prevTime; // 카운트다운 종료
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      const newTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      return newTime;
    });
  };

  const resetCountdown = () => {
    setTime('10:00');
    setIsCounting(true);
  };

  useEffect(() => {
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [isCounting]);
}
