import SaleCard from "components/sellerHistory/SaleCard";
import styled from "styled-components";
import card_img from "assets/card_img.svg"
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { Font_lg, Font_md, Font_sm } from "styled/fonts";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { langState, loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import { UploadData } from "types/types";
import { useNavigate, useParams } from "react-router-dom";
import { getProduct, selectBidder } from "api/SellerApi";
import { colors } from "styled/colors";
import ProposalList from "components/sellerHistory/ProposalList";
import { exchangeRateState } from "recoil/homeRecoil";
import { checkAndConvertCurrency } from "api/StringUtils";
import ModalComponent from "components/common/ModalComponent";
import Loading from "components/Loading";

const SellerAuctionDetailPage = () => {
    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const { id } = useParams();

    const navigate = useNavigate();

    const user: any = useRecoilValue(loginState);
    const [productData, setProductData] = useState<UploadData>();
    const [selectedListId, setSelectedListId] = useState<number | undefined>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const exchangeRate = useRecoilValue(exchangeRateState);
    

    const fetchProductData = async () => {
        try {
            setLoading(true);
            const productRes = await getProduct(user.access, Number(id));
            setProductData(productRes);
            console.log(productRes);
            setLoading(false);
        } catch (error){
            console.log(error);
        }
    }

    const listButtonHandler = (id: number) => {
        if(selectedListId == id) setSelectedListId(undefined);
        else setSelectedListId(id);
    }

    const selectButtonHandler = () => {
        setIsModalOpen(true);
    }

    const submitButtonHandler = async() => {
        try {
            const selectionRes = await selectBidder(user.access, selectedListId);
            console.log(selectionRes);
            navigate('/seller-auction-history/proposal-success');
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(()=> {
        console.log(selectedListId);
    },[selectedListId])

    useEffect(()=> {
        i18n.changeLanguage(language);
        fetchProductData();
    },[])

    return (
        <AuctionDetailWrapper>
            <ProcessHeaderComponent
            title={t("제안 목록")}
            isSearchForm={false}/>
            {loading ?
            <Loading/> :
            <>
            <ProposalMessage>
                <Font_lg isBold>{t("제안을 선정해주세요!")}</Font_lg>
                <Font_md>{t("구매하려는 이유와 제안가를 확인하고 마음에 드는 제안을 하나 선정해 판매할 수 있어요.")}</Font_md>
            </ProposalMessage>
            <ListWrapper>
                {productData && productData.product.biddings.map((list)=> 
                <ProposalList
                key={list.id}
                isvalid={selectedListId == list.id}
                image={list.user.thumbnail}
                text={list.cause}
                price={language !== 'ko-KR' ? '$' + list.dollar_price: list.price + '원'}
                onClick={() => {listButtonHandler(list.id)}}
                />)}
            </ListWrapper>
            <ButtonWrapper>
                <ButtonComponent
                isValid={Boolean(selectedListId)}
                text={t("선정하기")}
                size={Size.LARGE}
                onClick={selectButtonHandler}/>
            </ButtonWrapper>
            <ModalComponent
            title={t("제안을 선정하시겠어요?")}
            content={t("선정 후에는 바꿀 수 없습니다.")}
            leftBtnString={t("취소")}
            rightBtnString={t("제안 선정")}
            isOpen={isModalOpen}
            onClose={()=> {setIsModalOpen(false)}}
            onClickSubmit={submitButtonHandler}/>
            </>}
        </AuctionDetailWrapper>
    )
};

const AuctionDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`

const ProposalMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem;
    text-align: center;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const ListWrapper = styled.div`
    flex:1;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 50px;
`

export default SellerAuctionDetailPage;