import { authAccessToken } from "./UserApi";

export async function isNotLogin(user: any):Promise<boolean>  {
  let isValidToken = false
  if(user)
    isValidToken = (await authAccessToken(user.access || "")).data;
  else return true;
  return user === undefined
  || user === null
  || !user.access 
  || Object.keys(user.user).length === 0 
  || user.user === undefined
  || !isValidToken;
}