import { atom } from "recoil";
import { SellerApplicationStatus } from "types/types";

export const sellerApplicationStatus = atom<string>({
    key: 'sellerApplicationStatus',
    default: SellerApplicationStatus.NORESISTERED,
});

export const deleteProductState = atom<boolean>({
    key: 'deleteProductState',
    default: false
})

export const editProductState = atom<boolean>({
    key: 'editProductState',
    default: false
})
