import { styled } from "styled-components";
import { Font_xl } from "styled/fonts";
import shopping_cart from "assets/shopping_cart.svg";
import main_logo from "assets/main_logo.svg";
import badgeGreen from "assets/badge_green.svg";
import { useRecoilValue } from "recoil";
import { langState, loginState } from "recoil/loginRecoil";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import ModalComponent from "../ModalComponent";
import { isNotLogin } from "api/UserUtil";
import LoginReqImg from "assets/login_req.svg"
import { useTranslation } from "react-i18next";
import {getCartData} from "api/UserApi";
import { useEffect, useState } from "react";
interface StaticHeaderTypes {
    name?: string;

}

const StaticHeader = (props: StaticHeaderTypes) => {
  const navigate = useNavigate();
  const language = useRecoilValue(langState);
	const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation("home");
  const [isHaveCart, setIsHaveCart] = useState(false);
    const user = useRecoilValue(loginState)
    const handleClickCart = async () => {
      if (await isNotLogin(user)) {
        onOpen();
      } else {
        navigate("/cart");
      }
    };
  useEffect(()=>{
    (async ()=>{
      if(await isNotLogin(user)){
        setIsHaveCart(false);
      }else{
        const cartData = await getCartData(user.access);
        if(cartData.length > 0) setIsHaveCart(true);
        else setIsHaveCart(false);
      }
    })()
  },[])
    return (
      <StaticHeaderWrapper>
        {props.name ? (
          <Font_xl isBold>{props.name}</Font_xl>
        ) : (
          <MainLogo src={main_logo} />
        )}
        <ShoppingCart onClick={handleClickCart}>
          {
            isHaveCart && <GreenPoint src={badgeGreen} />
          }
          <CartImg src={shopping_cart} />
        </ShoppingCart>
        <ModalComponent
          isOpen={isOpen}
          onClose={onClose}
          content={
            language === "ko-KR"
              ? `로그인하고 더 많은 기능을`
              : `Sign up today!`
          }
          bottomContent={language === "ko-KR" ? `이용해보세요!` : ""}
          leftBtnString={t("돌아가기")}
          rightBtnString={t("로그인")}
          onClickSubmit={() => navigate("/onboarding")}
          imageUrl={LoginReqImg}
        />
      </StaticHeaderWrapper>
    );
}

const StaticHeaderWrapper = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    padding: 13px 16px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
`

const ShoppingCart = styled.div`
`

const GreenPoint = styled.img`
    margin: 0 100%;
`

const CartImg = styled.img`
`

const MainLogo = styled.img`
    height: 16px;
`

export default StaticHeader;