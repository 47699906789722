import { useEffect, useState } from "react";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import { Font_2xl, Font_md, Font_sm } from "styled/fonts";
import { RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Select } from "@chakra-ui/react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useLocation, useNavigate } from "react-router-dom";
import {useRecoilValue, useRecoilState, useSetRecoilState} from "recoil";
import {
  filterDataState, itemListState,
  selectedFilterDataState,
  selectedFilterDataState_,
  selectedYear,
} from "recoil/homeRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import {filterPageState} from "../../recoil/filterRecoil";

const FilterPage = () => {
  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState)
  const navigate = useNavigate();
  const location = useLocation();
  const setPage = useSetRecoilState(filterPageState);
  const sch = location.search;
  const param = new URLSearchParams(sch);
  const category_ = param.get('category')
  const isBuy = param.get('isBuy');
  const isAuction = param.get('isAuction');
  const setItemList = useSetRecoilState(itemListState);
  const [curYear, setCurYear] = useRecoilState(selectedYear);
  const filterData = useRecoilValue(filterDataState);
  const [selectedFilter, setSelectedFilter] = useRecoilState(selectedFilterDataState);
  const [category, setCategory] = useState<number>(Number(category_) - 1);
  const [params, setParams] = useRecoilState(selectedFilterDataState_);
  // local filter variable
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const [paramsLocal, setParamsLocal] = useState(new Set());
  const [yearLimit, setYearLimit] = useState([0, 0]);

  const handleSetCategory = (cate: string) => {
    if(cate === filterData[0].name || cate === filterData[0].eng_name) 
      setCategory(0);
    else 
      setCategory(1);
  }

  const isKor = language === "ko-KR";

  const handleFilterAttrClick = async (choice: string, optionId: string, choice_kor: string) => {
    var value_json:any = {"option":optionId, "value": choice_kor};
    const value = JSON.stringify(value_json);
    const newSet = new Set(paramsLocal)
    if(paramsLocal.has(value)){
      newSet.delete(value);
    }
    else{
      newSet.add(value);
    }
    setParamsLocal(newSet);
    if (selectedItems.includes(optionId + '_' +choice)) {
      // 이미 선택한 아이템인 경우 선택 해제
      setSelectedItems(selectedItems.filter((item) => item !== optionId + '_' + choice));
    } else {
      // 선택되지 않은 아이템인 경우 선택
      setSelectedItems([...selectedItems, optionId + '_' +choice]);
    }
  };

  const handleApplyBtn = () => {
    const comma = paramsLocal.size >= 1 ? ',' : '';
    let yearOptionNum = 0;
    filterData[category].options.map((option : any)=> {
      if(option.name === "연도"){
        yearOptionNum = option.id;
      }
    })
    setSelectedFilter(
      selectedItems.filter(
        (item) =>{
          if(item === "연도" || item === 'year'){
            return yearLimit[0] !== curYear[0] || yearLimit[1] !== curYear[1];
          } else{
            return true;
          }
        }
      )
    );
    setParams(paramsLocal);
    if (category === 1) {
      setItemList([]);
      setPage(1);
      navigate(`/explore?category=${filterData[1].id}&from=filter&isBuy=${isBuy}&isAuction=${isAuction}&value_product=[`+ Array.from(paramsLocal).toString() + `]`)
    } else {
      setItemList([]);
      setPage(1);
      navigate(`/explore?category=${category === 0 ? filterData[0].id : filterData[1].id}&from=filter&isBuy=${isBuy}&isAuction=${isAuction}&value_product=[`+ Array.from(paramsLocal).toString() + comma + `{"option":${yearOptionNum},"value":{"lte":${curYear[1].toString()},"gte":${curYear[0].toString()}}}` +']')
    }
  }

  const handleYear = (step: number[], choice: string, min:string, max:string) => {
    if((step[0] !== Number(min) || step[1] !== Number(max)) && !selectedItems.includes(choice)){
      setSelectedItems([...selectedItems, choice]);
    }
    setCurYear([step[0], step[1]]);
  }

  const handleReset = () => {
    setParams(new Set());
    setParamsLocal(new Set());
    setSelectedItems([]);
    setCurYear([yearLimit[0], yearLimit[1]]);
  }

  useEffect(()=>{
    setSelectedItems([]);
    setParamsLocal(new Set());
  }, [category])

  useEffect(()=>{
    if(!filterData)
      navigate('/explore')
    filterData[category].options.map((option : any)=> {
      if(option.name === "연도" && (curYear[0] === 0 && curYear[1] === 0)){
        setCurYear([option.selects[0].minVal, option.selects[0].maxVal])
        setYearLimit([option.selects[0].minVal, option.selects[0].maxVal]);
      } else if(option.name === "연도"){
        setYearLimit([option.selects[0].minVal, option.selects[0].maxVal]);
      }
    })
    setSelectedItems(selectedFilter);
    setParamsLocal(params);
    i18n.changeLanguage(language);
  }, [])

  return (
    <Filter>
      <ProcessHeaderComponent title={t("필터")} isSearchForm={false} />
      <ContentsWrapper>
        <ContentSection>
          <Font_md isBold>{t("카테고리")}</Font_md>
          <Select
            onChange={(e) => handleSetCategory(e.target.value)}
            defaultValue={t(filterData[category]["name"])}
          >
            {filterData.map((category: any) => (
              <option value={t(category.name)} key={t(category.name)}>
                {t(category.name)}
              </option>
            ))}
          </Select>
        </ContentSection>
        {filterData &&
          filterData[category].options.map((option: any) =>
            option.name !== "연도" && option.name !== '그레이딩' && option.name !== '제조사' ? (
              <ContentSection key={option.name}>
                <Font_md isBold>
                  {isKor ? option.name : option.eng_name}
                </Font_md>
                <FilterList>
                  {option.selects.map((select: any) => (
                    <FilterAttr
                      className={
                        selectedItems.includes(
                          isKor ? option.id + "_" + select.name : option.id + "_" + select.eng_name
                        )
                          ? "click"
                          : ""
                      }
                      key={select.name}
                      onClick={() =>
                        handleFilterAttrClick(
                          isKor ? select.name : select.eng_name,
                          option.id,
                          select.name
                        )
                      }
                    >
                      <Font_sm
                        color={
                          selectedItems.includes(
                            isKor ? option.id + "_" + select.name : option.id + "_" + select.eng_name
                          )
                            ? "#0AB88C"
                            : ""
                        }
                        isBold
                      >
                        {isKor ? select.name : select.eng_name}
                      </Font_sm>
                    </FilterAttr>
                  ))}
                </FilterList>
              </ContentSection>
            ) : (
              option.name === "연도" && category !== 1 &&(
                <ContentSection key={option.name}>
                  <Font_md isBold>
                    {isKor ? option.name : option.eng_name}
                  </Font_md>
                  <Font_2xl isBold>{`${curYear[0]} ~ ${curYear[1]}`}</Font_2xl>
                  {curYear[1] !== 0 && (
                    <RangeSlider
                      aria-label={["min", "max"]}
                      colorScheme="green"
                      defaultValue={curYear}
                      min={option.selects[0].minVal}
                      max={option.selects[0].maxVal}
                      step={option.selects[0].pivotVal}
                      onChange={(step: number[]) =>
                        handleYear(
                          step,
                          isKor ? option.name : option.eng_name,
                          option.selects[0].minVal,
                          option.selects[0].maxVal
                        )
                      }
                    >
                      <RangeSliderTrack>
                        <RangeSliderFilledTrack />
                      </RangeSliderTrack>
                      <RangeSliderThumb boxSize={6} index={0} />
                      <RangeSliderThumb boxSize={6} index={1} />
                    </RangeSlider>
                  )}
                </ContentSection>
              )
            )
          )}
      </ContentsWrapper>
      <ButtonSection className="Filter-button">
        <ButtonWrapper>
          <ResetBtn onClick={handleReset}>
            {t("초기화")}
          </ResetBtn>
          <ButtonComponent
            onClick={handleApplyBtn}
            isValid={true}
            text={t("필터 적용하기")}
            size={Size.LARGE}
          />
        </ButtonWrapper>
      </ButtonSection>
    </Filter>
  );
};

const Filter = styled.div`
  min-height: 100vh;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 7rem;
`;

const ContentSection = styled.div`
  margin: 1.5rem 0 1rem 0;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
`;

const FilterAttr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 9999px;
  border: 1px solid #E2E8F0;
  white-space: nowrap;
  min-width: auto;
  height: 32px;

  &.click {
    border: 1px solid #0AB88C;
  }
`;

const ButtonSection = styled.div`
  position: fixed;
  bottom:0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #EDF2F7;
  padding: 1rem 0;
  background-color: white;
  z-index: 10;
`

const ResetBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 50%;
  font-weight: 600;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  width: calc(100% - 2rem);
`

export default FilterPage;
