import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { styled } from "styled-components";
import { Font_md } from "styled/fonts";
import addon from "assets/addon.svg";
import { Input } from "@chakra-ui/react";
import { colors } from "styled/colors";
import { useState } from "react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { SellerType, Size } from "types/types";
import PriceInputComponent from "components/addproduct/PriceInputComponent";
import { useRecoilState, useRecoilValue } from "recoil";
import { priceState, useSharedAtoms, optionsState, categoryState, productNameState, productContentState, imageProductState, sellingTypeState } from "recoil/addProductRecoil";
import { createProduct, getSellerInfo } from "api/SellerApi";
import { loginState } from "recoil/loginRecoil";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";
import Loading from "components/Loading";
import { changeKrwToUsd, changeUsdToKrw, fixedExchangePrice } from "api/StringUtils";

const PriceSettingDirectlyPage = () => {
    const [price, setPrice] = useRecoilState(priceState);
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useRecoilState(categoryState);
    const [productName, setProductName] = useRecoilState(productNameState);
    const [productContent, setProductContent] = useRecoilState(productContentState);
    const [imageProduct, setImageProduct] = useRecoilState(imageProductState);
    const [options, setOptions] = useRecoilState(optionsState);
    const [sellingType, setSellingType] = useRecoilState(sellingTypeState);
    const [sellerInfo, setSellerInfo] = useState<SellerType>();

    const isValid = price != 0 && price != undefined;
    
    

    const user : any = useRecoilValue(loginState);
    const navigate = useNavigate();

    const { t, i18n } = useTranslation("addProduct");
    const language = useRecoilValue(langState);

    const fetchSellerInfo = async () => {
        const sellerRes = await getSellerInfo(user.access);
        setSellerInfo(sellerRes.data[0]);
    }

    useEffect(()=> {
        i18n.changeLanguage(language);

        //새로고침해서 이전 페이지 데이터가 없을 경우
        if(!category && category !== 0) navigate('/add-product/category');

        fetchSellerInfo();
    },[]);

    useEffect(()=> {
        console.log(sellerInfo?.swift_code);
    },[sellerInfo]);


    const handleButton = async() => {
        const formData = new FormData();
        imageProduct.forEach((image, index) => {
            const fieldName = `image${index + 1}`;
            formData.append('product.' + fieldName, image);
        })

        options.forEach((item, index) => {
            formData.append(`product.value_product[${index}]option`, item.id.toString());
            formData.append(`product.value_product[${index}]value`, item.value);
            formData.append(`product.value_product[${index}]eng_value`, item.eng_value);
        })

        formData.append('product.selling_type', '1');
        formData.append('product.name', productName);
        formData.append('product.content', productContent);
        if (sellerInfo?.swift_code) {
            formData.append('product.price', changeUsdToKrw(price, fixedExchangePrice).toString());
        } else {
            formData.append('product.price', price.toString());
        }
        ( category !== undefined || category == 0 ) && formData.append('product.category', (category+3).toString());
        formData.append('user', user.user.id);

        setLoading(true);
        const productRes: any = await createProduct({
            access: user.access,
            formData: formData,
          });
        setLoading(false);

        setOptions([]);
        setProductName('');
        setProductContent('');
        setCategory(undefined);
        setImageProduct([]);
        setSellingType(undefined);

        window.location.replace(`/item-detail${sellingType === 1 ? '-bid' : ''}?id=${productRes.data.product.id}&origin-path=/mypage`)        
    }

    return (
        <PriceSettingWrapper>
            {loading ?
            <Loading/>
            :
            <>
            <ProcessHeaderComponent
            title={t("상품등록")}
            isSearchForm={false}
            path="/mypage"
            />
            <PriceInputComponent
            title={t("판매가")}
            price={price}
            setPrice={setPrice}
            isKRW={sellerInfo?.swift_code ? false : true}/>
            <ButtonWrapper>
                <ButtonComponent
                text={t("상품 등록")}
                size={Size.LARGE}
                isValid={isValid}
                onClick={handleButton}
                />
            </ButtonWrapper>
            </>}
        </PriceSettingWrapper>
    )
}

const PriceSettingWrapper = styled.div`
`

const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export default PriceSettingDirectlyPage;