import { styled } from "styled-components";
import { colors } from "../../styled/colors";
import { Font_md, Font_sm } from "styled/fonts";
import {useLocation, useNavigate} from "react-router-dom";
import { VerticalCardType } from "types/types";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";
import { exchangeRateState } from "recoil/homeRecoil";
import {changeKrwToUsd, changeUsdToKrw, fixedExchangePrice, formatCurrency, formatCurrencyUsd} from "api/StringUtils";
import SellingType from "./SellingTypeComponent";
import {HandleNavigateType} from "../../pages/explore/ExplorePage";

interface CardIProps {
  item:VerticalCardType;
  isVisible: boolean;
  onClickHandler: HandleNavigateType;
}

// function formatCurrency(num: number) {
//   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원";
// }


const CardComponent = (props: CardIProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState);
  const exchangeRate = useRecoilValue(exchangeRateState);
  const getIsEnd = (end : string):boolean => {
    const end_date = new Date(end);
    const now_date = new Date();
    if(end_date < now_date || props.item.is_selled){
      return true
    }
    return false;
  }
  useEffect(()=> {
    i18n.changeLanguage(language);
  },[])

  return (
    <Card
      onClick={() => props.onClickHandler(props.item.selling_type, props.item.id)}
    >
      {props.isVisible && (
        <>
          {/* {
              isEnd && <Curtain />
            } */}
          <CardImg className={"cardImg"} background_img={props.item.image1}>
            {props.item.selling_type === 2 && !getIsEnd(props.item.end_date) && (
              <AuctionTitle>{t("제안중")}</AuctionTitle>
            )}
            {props.item.selling_type === 2 && getIsEnd(props.item.end_date) ? (
              <>
                <Curtain />
                <TextSection>{t("제안완료")}</TextSection>
              </>
            ) : (
              props.item.selling_type === 1 &&
              props.item.is_selled && (
                <>
                  <Curtain />
                  <TextSection>{t("판매완료")}</TextSection>
                </>
              )
            )}
          </CardImg>
          <SellingType sellingType={props.item.selling_type} />
          <CardTitle>
            <Font_md color={colors.GREY[800]}>{props.item.name}</Font_md>
          </CardTitle>
          <Font_sm color={colors.GREY[800]} isBold>
            {language === "ko-KR"
              ? props.item.author?.swift_code ? changeUsdToKrw(props.item.price / fixedExchangePrice, exchangeRate?.basePrice).toLocaleString() + '원' : formatCurrency(props.item.price) + '원'
              : props.item.author?.swift_code ? '$' + formatCurrencyUsd(
                    changeKrwToUsd(props.item.price, exchangeRate?.basePrice)
                ) : '$' + formatCurrencyUsd(
                  changeKrwToUsd(props.item.price, exchangeRate?.basePrice, true)
                )}
          </Font_sm>
        </>
      )}
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(50% - 1.5rem);
  border-radius: 8px;
`;

const CardImg = styled.div<{background_img: any}>`
  position:relative;  
  width: 100%;
  aspect-ratio: 4 / 5;
  background-image: url(${props => (props.background_img)});
  background-size: cover;
  border-radius: 10px;
  background-position: center;
`;



const CardTitle = styled.div`
  margin-top: 0.5rem;
  width: 100%; /* 또는 원하는 너비 설정 */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 두 줄까지만 표시 */
  -webkit-box-orient: vertical;
`;

const AuctionTitle = styled.div`
  display: inline-block;
  height: 24px;
  text-align: center;
  background-color: ${colors.GREEN[500]};
  color: white;
  font-weight: 600;
  font-size: 14px;
  margin: 8px 0 0 8px;
  border-radius: 2px;
  padding: 0 6px;
`;

const Curtain = styled.div`
  border-radius: 8px;
  position: absolute;
  top:0;
  width:100%;
  height: 100%;
  background-color: #171823;
  opacity: 0.4;
`

const TextSection = styled.div`
  width:100%;
  height: 100%;
  position: absolute;
  top:0;
  display:flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
`

export default CardComponent;
