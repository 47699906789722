import { addSeconds, parseISO, formatISO } from 'date-fns';

export function calculateEndDate(startDateString: string, period: number): Date {
  // start_date를 파싱하여 Date 객체로 변환
  console.log(startDateString);
  const startDate = new Date(startDateString);
  console.log(startDate);
  // const offset = startDate.getTimezoneOffset() * 60000;
  // const startDateAboutKR = new Date(startDate.getTime() - offset);
  // console.log(startDateAboutKR);
  // period (초)를 더하여 end_date를 계산
  const endDate = addSeconds(startDate, period);
  console.log(endDate);
  // const offset = endDate.getTimezoneOffset() * 60000;
  // console.log(endDate.getTime());
  // const endDateAboutKR = new Date(endDate.getTime() - offset);
  // console.log(endDateAboutKR);

  return endDate;
}
