import { Input } from "@chakra-ui/react";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import { Font_xl } from "styled/fonts";
import { colors } from "styled/colors";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langState, loginState } from "recoil/loginRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { inactiveUserAccount, logout } from "api/UserApi";

const SecessionPage = () => {
    const [reason, setReason] = useState('');
    const handleReasonChange = (event:any) => setReason(event.target.value);
    const navigate = useNavigate();

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    const [user, setUser] = useRecoilState(loginState);

    const buttonClickHandler = async () => {
        try {
            const logoutRes = await logout(user.access);
            const dataRes = await inactiveUserAccount(user.access, user.user.id);
            setUser({access:null, user:{}})
            navigate('/mypage/secession/success');
        } catch(error) {
            console.log(error);
        }
     }

    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <SecessionWrapper>
            <ProcessHeaderComponent
            title={t("회원 탈퇴")}
            isSearchForm={false}/>
            <Title>
                <Font_xl>{t("정말로 탈퇴하시겠어요?")}</Font_xl>
                <Font_xl>{t("탈퇴 시 모든 정보가 삭제됩니다.")}</Font_xl>
            </Title>
            <SecessionInput>
                <Input
                placeholder={t("탈퇴 사유")}
                size="lg"
                _placeholder={{ color: colors.GREY[400] }}
                value={reason}
                onChange={handleReasonChange}/>
            </SecessionInput>
            <ButtonWrapper>
                <ButtonComponent
                isValid={Boolean(reason)}
                text={t("탈퇴하기")}
                size={Size.LARGE}
                onClick={buttonClickHandler}/>
            </ButtonWrapper>
        </SecessionWrapper>
    )
};

const SecessionWrapper = styled.div`
`

const Title = styled.div`
    padding: 1.5rem;
`

const SecessionInput = styled.div`
    padding: 0 1rem;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
`

export default SecessionPage;