import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// 1 : 비밀번호, 2 : 핸드폰 번호, 3 : 배송지
export const isChangeSuccessState = atom<number>({
    key: 'isChangeSuccessState',
    default: 0,
});

export const isCompletePurchaceState = atom<boolean>({
    key: 'isCompletePurchaceState',
    default: false,
})

export const mypageTabIndexState = atom<number>({
    key: 'mypageTabIndexState',
    default: 0,
    effects_UNSTABLE: [persistAtom],
})