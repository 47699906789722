import { styled } from "styled-components";
import card_img from "assets/card_img.svg";
import { colors } from "styled/colors";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect } from "react";
import {changeKrwToUsd, formatCurrency, formatCurrencyUsd} from "api/StringUtils";
import { exchangeRateState } from "recoil/homeRecoil";
import { langState } from "recoil/loginRecoil";

interface CardIProps {
  title: string;
  itemPrice: number;
  deliverPrice: number;
  isSelling: boolean;
  image: string;
}

const HorizontalCard_Cart = (props: CardIProps) => {
  const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(
    isCompletePurchaceState
  );
  const exchangeRate = useRecoilValue(exchangeRateState);
  const language = useRecoilValue(langState);
  const cutText = (text : string) => {
    if(text.length > 30) {
        return text.substring(0,23) + "...";
    }
    return text;
  }

  useEffect(() => {
    setIsCompletePurchace(false);
  }, []);

  return (
    <HorizontalCard>
      <CardSection>
        <ImageSection isSelling={props.isSelling} image={props.image} />
        <TextSection>
          <Text isSelling={props.isSelling} className={"title"}>
            {cutText(props.title)}
          </Text>
          <Text isSelling={props.isSelling} className="itemPrice">
            {language === "ko-KR"
              ? formatCurrency(props.itemPrice) + "원"
              : "$" +
                formatCurrencyUsd(
                  changeKrwToUsd(props.itemPrice, exchangeRate?.basePrice)
                )}
          </Text>
          {props.isSelling ? (
            <Text isSelling={true}>{language === "ko-KR"
            ? "배송비 " + formatCurrency(props.deliverPrice) + "원"
            : "Shipping $" +
                formatCurrencyUsd(
                changeKrwToUsd(props.deliverPrice, exchangeRate?.basePrice)
              )}</Text>
          ) : (
            "판매 종료"
          )}
        </TextSection>
      </CardSection>
    </HorizontalCard>
  );
};

const HorizontalCard = styled.div`
  width: 100%;
`;

const CardSection = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 1rem 0;
`;

const ImageSection = styled.div<{ isSelling: boolean, image: string }>`
  min-width: 64px;
  height: 64px;
  border-radius: 8px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  opacity: ${(props) => (props.isSelling ? "" : "0.4")};
`;

const TextSection = styled.div`
  width: calc(100%-100px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Text = styled.div<{ isSelling: boolean }>`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => (props.isSelling ? colors.GREY[700] : colors.GREY[400])};
  font-size: 14px;
  font-weight: 400;
  &.title {
    font-size: 16px;
  }
  &.itemPrice {
    font-weight: 600;
    color:  ${(props) => (props.isSelling ? colors.GREY[900] : colors.GREY[400])};
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  width: calc(100% - 1rem);
`;

export default HorizontalCard_Cart;
