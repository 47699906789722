import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import styled from "styled-components"
import { Font_lg } from "styled/fonts";
interface ISignupTextProps {
  topString: string;
  bottomString: string;
  highlight: string;
}

const SignupTextWrapper = (props: ISignupTextProps) => {

  const { i18n, t } = useTranslation("main");
  const language = useRecoilValue(langState)

  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])
  return (
    <StringWrapper>
      {i18n.language === "ko-KR" ? (
        <Contents>
          <p>{props.topString}</p>
          <span className="highlight">{props.highlight}</span>
          <span>{props.bottomString}</span>
        </Contents>
      ) : (
        <Font_lg isBold>{t(props.topString)}</Font_lg>
      )}
    </StringWrapper>
  );
};

const StringWrapper = styled.div`
  margin: 25px 1rem 25px 1rem;
  font-size: 20px;
  font-weight: 400;
  .highlight {
    font-weight: 600;
  }
`;

const Contents = styled.div`
  width: calc(100% - 2rem);
`

export default SignupTextWrapper;
