import main from "./main.json";
import signup from "./signup.json";
import findpwd from "./findpwd.json";
import home from "./home.json";
import accountManagement from "./accountManagement.json";
import mypage from "./mypage.json";
import serviceUsage from "./serviceUsage.json";
import addProduct from "./addProduct.json";
import history from "./history.json";

export { main, signup, findpwd, home, accountManagement, mypage, serviceUsage, addProduct, history};

