import styled from "styled-components";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import appleLogo from "assets/appleLogo.svg";
import googleLogo from "assets/googleLogo.svg";
import chevron_right from "assets/chevron_right.svg";
import { Font_md, Font_xs, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { ToastContainer } from 'react-toastify';
import notify from "components/common/toast/ToastComponent";
import { useEffect, useState } from "react";
import { isChangeSuccessState } from 'recoil/mypageRecoil';
import { loginState } from 'recoil/loginRecoil';
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { isNotLogin } from "api/UserUtil";

const LogoImg = styled.img<{ type: string }>`
  width: 22px;
  height: 22px;
`;

const PersonalHomePage = () => {
  let Lock = false;
  const [isChangeSuccess, setIsChangeSuccess] = useRecoilState(isChangeSuccessState);
  const user : any = useRecoilValue(loginState);

  LogoImg.defaultProps = {
    src: user.user.account_type === 'apple' ? appleLogo :
    user.user.account_type === 'google' ? googleLogo : `<div></div>`
  };

  const navigate = useNavigate();

  const { t, i18n } = useTranslation("mypage");
  const language = useRecoilValue(langState);

  useEffect(()=>{
    isNotLogin(user).then((data) => {
      if(data)
        window.location.replace("/");
    });
    i18n.changeLanguage(language);
    setIsChangeSuccess(0);
    if(!Boolean(user))
      window.location.replace("/");
  }, [])

  const HandleClickPwd = () => {
    // something to do
    navigate('/mypage/change-pwd');
  }; 

  const HandleClickNum = () => {
    // something to do
    navigate('/mypage/change-num');
  };

  useEffect(()=>{
    if(!Lock && isChangeSuccess !== 0){
      if(isChangeSuccess == 1){
        notify(t('비밀번호가 변경되었습니다.'));
      } else {
        notify(t("휴대폰 번호가 변경되었습니다."));
      } 
      Lock = true
    }
  }, [isChangeSuccess])

  return (
    <PersonalHome>
      <ToastContainer/>
      <ProcessHeader title={t("개인정보 설정")} isSearchForm={false} path='/mypage' />
      <Section>
        <TextWrapper>
          <Font_md isBold>{t("이메일(아이디)")}</Font_md>
          <LogoWrapper>
            {user.user.account_type !== "email" && <LogoImg type="google" />}
            <Font_md>{user.user.email}</Font_md>
          </LogoWrapper>
        </TextWrapper>
      </Section>
      <Section
        onClick={user.user.account_type === "email" ? HandleClickPwd : ()=>{}}>
        <TextWrapper>
          <Font_md isBold>{t("비밀번호 변경")}</Font_md>
          {user.user.account_type !== "email" && (
            <Font_xs color={colors.GREY[400]}>
              {t("SNS 간편 가입자는 비밀번호 없이 로그인 할 수 있습니다.")}
            </Font_xs>
          )}
        </TextWrapper>
        {
          user.user.account_type === 'email' && 
          <ModifyBtn>
            <img src={chevron_right}></img>
          </ModifyBtn>
        }
      </Section>
      <Section
        onClick={HandleClickNum}>
        <TextWrapper>
          <Font_md isBold>{t("휴대폰 번호")}</Font_md>
          <Font_md>{user.user.number}</Font_md>
        </TextWrapper>
        <ModifyBtn>
          <img src={chevron_right}></img>
        </ModifyBtn>
      </Section>
      <Section>
        <TextWrapper>
          &nbsp;
        </TextWrapper>
        <WithDrowTxt onClick={() => {navigate('/mypage/secession')}}>
            {t("탈퇴하기")}
        </WithDrowTxt>
      </Section>
    </PersonalHome>
  );
};

const PersonalHome = styled.div`

`;

const Section = styled.div`
  display:flex;
  justify-content: space-between;
  padding:1rem;
`
const LogoWrapper = styled.div`
  display:flex;
`
const ModifyBtn = styled.div`
  display:flex;
  align-items: center;
`

const TextWrapper = styled.div`

`

const WithDrowTxt = styled.p`
  font-size:14px;
  font-weight: 400;
  line-height:20px;
  color: ${colors.GREY[500]};
  text-decoration-line: underline;
`

export default PersonalHomePage;