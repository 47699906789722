import React, {useEffect, useState} from "react";
import {styled} from "styled-components";
import CircleComponent from "./CircleComponent";
import {getItemList, getRecommendItemList} from "api/api";
import {VerticalCardType} from "types/types";
import {useTranslation} from "react-i18next";

const CircleListComponent = () => {
    const [storyList, setStoryList] = useState<any>([]);
    const {t, i18n} = useTranslation("main");

    useEffect(() => {
        (async () => {
            const now = new Date();
            now.setDate(now.getDate() + 3); // 현재 날짜에 3일을 더함
            const futureString = now.toISOString().split("T")[0];
            // const response = await getItemList({
            //     selling_type: "2",
            //     page_size: "10",
            //     page: "1",
            //     endDate: futureString,
            // });
            const response = await getRecommendItemList();
            if (response && response.items) {
                setStoryList(response.items);
            }
        })();
    }, [])
    const currentDate = new Date();
    // const expiredStory = storyList.filter((story: any) => {
    //     console.log(story);
    //     return story.product.endDate < currentDate;
    // });
    return (
        <>
            <BannerTextContainer>
                {t("지금 주목해야 할 컬렉션")}
            </BannerTextContainer>
            {
                storyList.length > 0 ?
                    <CircleList className="CircleList">
                        {storyList.map((story: any) => {
                            return (
                                <CircleComponent
                                    imageUrl={story.product.image1}
                                    name={story.product.name}
                                    startDate={story.product.start_date}
                                    endDate={story.product.end_date}
                                    type={story.product.selling_type}
                                    id={story.product.id}
                                />
                            )
                        }
                        )}
                    </CircleList>
                    :
                    <></>
            }
        </>
    );
};

const BannerTextContainer = styled.div`
  width: 100%;
  padding: 4px 16px 4px 16px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
`;

const CircleList = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0 0.5rem 1rem;
  overflow: scroll;
`

export default CircleListComponent;
