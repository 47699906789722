import SoldCard from "components/sellerHistory/SoldCard";
import styled from "styled-components";
import card_img from "assets/card_img.svg";
import SaleCard from "components/sellerHistory/SaleCard";
import { SaleHistoryStatus, UploadData } from "types/types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { getProducts } from "api/SellerApi";
import { isoStringToDate } from "api/StringUtils";
import Loading from "components/Loading";
import EmptyMessage from "components/common/EmptyMessage";

const SellerEndHistory = () => {
    const navigate = useNavigate();

    const [sellerEndData, setSellerEndData] = useState<UploadData[]>();
    const [loading, setLoading] = useState(false);
    const user: any = useRecoilValue(loginState);

    const fetchEndData = async() => {
        try {
            setLoading(true);
            const dataRes = await getProducts(user.access);
            const filterData = dataRes.filter((item)=> item.product.selling_type == 2 && item.product.is_pending && item.product.status !== 8).reverse();
            setSellerEndData(filterData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const identifyStatus = (list : UploadData) : SaleHistoryStatus => {
        if(list.product.status == 1) {
          return SaleHistoryStatus.결제예정
        } else if(list.product.status == 6) {
            return SaleHistoryStatus.선정취소
        } else if(list.product.status == 7) {
            return SaleHistoryStatus.제안없음
        } else if(list.product.calculate_status == 1) {
          return SaleHistoryStatus.정산예정
        } else if(list.product.calculate_status == 2) {
          return SaleHistoryStatus.정산완료
        } else {
          return SaleHistoryStatus.발송요청
        }
      }

    useEffect(()=> {
        fetchEndData();
    },[])

    return (
        <EndHistoryWrapper>
            {sellerEndData && !loading ?
            <>
            {sellerEndData.length !== 0 ?
            sellerEndData.map((list)=>
            <SoldCard key={list.id}
            date={isoStringToDate(list.product.end_date, false, false)}
            name={list.product.name}
            price={list.product.price}
            image={list.product.image1}
            isAuction={true}
            saleHistoryStatus={identifyStatus(list)}
            onClick={()=> {navigate(`/auction-closed/${list.id}`)}}
            currency={list.product.author.swift_code ? 'USD' : 'KRW'}/>) :
            <EmptyMessage/>}
            </> : <Loading/>}
        </EndHistoryWrapper>
    )
};

//todo: 선정된 날짜가 end_date가 아니지 않을까

const EndHistoryWrapper = styled.div`
`

export default SellerEndHistory;