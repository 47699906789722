import React, {useState, useEffect, useLayoutEffect} from "react";
import StaticHeader from "components/common/Headers/StaticHeader";
import styled from "styled-components";
import CircleListComponent from "components/main/CIrcleListComponent";
import { ScrollRestoration } from "react-router-dom";
import CardListComponent from "components/main/CardListComponent";
import {Button} from "@chakra-ui/react";
import FooterComponent from "components/common/FooterComponent";
import MainFooterComponent from "components/main/MainFooter";
import ImageSlide from "components/common/ImageSlide";
import {useTranslation} from "react-i18next";
import {langState} from "recoil/loginRecoil";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {useNavigate, useParams} from "react-router-dom";
import {getBannerImages, getExchangeRate, getTags} from "api/api";
import {categoryState, exchangeRateState, itemListState, mainTabState} from "recoil/homeRecoil";

const tmpAds = [
    "https://search.pstatic.net/common/?src=http%3A%2F%2Fblogfiles.naver.net%2FMjAyMjA0MjZfMTk2%2FMDAxNjUwOTU3NDczNTU1.uT-EGFZkd56lp8MZPRUwEV1yQA7kj31jlZeked3OvxMg._g9VFCtnkMO8kgxn6RHO_fpqBIbVfAZWdfTze0Gnzm4g.JPEG.crowdpic%2F%25C7%25CF%25B4%25C3_%25B1%25D7%25B6%25F3%25B5%25A5%25C0%25CC%25BC%25C7_%25BB%25E7%25C1%25F8.jpg&type=sc960_832",
    "https://search.pstatic.net/sunny/?src=https%3A%2F%2Fcdn.crowdpic.net%2Fdetail-thumb%2Fthumb_d_0E4755472C57174E8EB4BD7B9754B578.jpg&type=sc960_832",
]

const MainPage = () => {
    const [size, setSize] = useState(10);
    const [sideText, setSideText] = useRecoilState(mainTabState);
    const [bannerImages, setBannerImages] = useState([]);
    const [bannerUrls, setBannerUrls] = useState([]);
    const setItemList = useSetRecoilState(itemListState);
    const {t, i18n} = useTranslation("home");
    const language = useRecoilValue(langState);
    const navigate = useNavigate();
    const setExchangeRate = useSetRecoilState(exchangeRateState);
    const [category, setCategory] = useRecoilState<any>(categoryState);
    useLayoutEffect(() => {
        console.log(sessionStorage.y);
        window.scroll(0, sessionStorage.y);
    }, []);
    useEffect(() => {
        (async () => {
            const exchangeRes = await getExchangeRate();
            const bannerRes = await getBannerImages();
            const tags = await getTags();
            const tmpImages = bannerRes.data?.map(((imageObj: any) => imageObj.image))
            const tmpUrls = bannerRes.data?.map(((imageObj: any) => imageObj.url))
            setBannerImages(tmpImages);
            setBannerUrls(tmpUrls);
            setExchangeRate(exchangeRes.data)
            if (tags.length > 0) {
                setCategory(tags);
            }
        })();
        i18n.changeLanguage(language);
    }, [])

    return (
        <Main>
            <StaticHeader/>
            <CircleListComponent/>
            <div style={{marginTop: "0.5rem", marginBottom: "1rem"}}>
                <ImageSlide
                    urls={bannerUrls}
                    images={bannerImages || tmpAds}
                    denominator={1}
                    molecule={2}
                    isClick={true}
                    arrow={true}
                    autoplay={true}
                    isBanner={true}
                />
            </div>
            <CategoryContainer>
                {category.length > 0 ? category.map((e: any) => {
                    return (
                        <Category onClick={() => {
                            setItemList([]);
                                navigate(
                                    `/tags?tagId=${e.id}`
                                )
                            }
                        }>
                            <CategoryImg>
                                <img src={e.image} alt=""/>
                            </CategoryImg>
                            <span>{e.name}</span>
                        </Category>
                    )
                }) : <></>}
            </CategoryContainer>
            <CardListComponent
                size={size}
            />
            <ButtonWrapper>
                <Button
                    onClick={() =>
                        window.location.replace(
                            `/explore?category=${sideText === "스포츠 카드" ? 3 : 4}`
                        )
                    }
                    width="calc(100% - 2rem)"
                    size="lg"
                    variant="outline"
                >
                    {t(`상품 더보기`)}
                </Button>
            </ButtonWrapper>
            <MainFooterComponent/>
            <FooterComponent isClick={0}/>
        </Main>
    );
};

const Main = styled.div`
  margin-bottom: 64px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 40px 0;
  width: 100%;
`;

const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  box-sizing: border-box;
  padding: 0 16px 16px 16px;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  > span {
    font-weight: 400;
    font-size: 14px;
  }
`;

const CategoryImg = styled.div`
  width: 64px;
  height: 64px;
  background-color: rgba(247, 250, 252, 1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MainPage;
