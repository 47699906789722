import styled from "styled-components";
import Slider from "react-slick";
import main_first from "../assets/main_first.svg";
import main_second from "../assets/main_second.svg";
import main_third from "../assets/main_third.svg";
import { colors } from "styled/colors";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";

const ImagesSlide = () => {
  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);
  return (
    <SliderWrapper>
      <Slider {...settings}>
        <SliderItem>
          <TextWrapper>
            {i18n.language === "ko-KR" ? (
              <>
                <GreyText>다양한 한정판</GreyText>
                <GreenText> 스포츠 굿즈</GreenText>
              </>
            ) : (
              <GreyText>Sports Collectibles</GreyText>
            )}
            <br />
            <Description>
              {t("스포츠 카드, 사인볼 등 여러 굿즈가 한 곳에!")}
            </Description>
          </TextWrapper>
          <SliderItemImg src={main_first} alt="Image 1" />
        </SliderItem>
        <SliderItem>
          <TextWrapper>
            {i18n.language === "ko-KR" ? (
              <>
                <GreyText>거래가</GreyText>
                <GreenText> 시세차트</GreenText>
              </>
            ) : (
              <GreyText>Price Charts</GreyText>
            )}
            <br />
            <Description>{t("시세차트로 상품의 가치를 파악해요!")}</Description>
          </TextWrapper>
          <SliderItemImg src={main_second} alt="Image 2" />
        </SliderItem>
        <SliderItem>
          <TextWrapper>
            {i18n.language === "ko-KR" ? (
              <>
                <GreyText>한정판 굿즈</GreyText>
                <GreenText> 제안</GreenText>
              </>
            ) : (
              <GreyText>Collectibles Market</GreyText>
            )}

            <br />
            <Description>
              {t("제안으로 한정판 굿즈를 사거나 팔아요!")}
            </Description>
          </TextWrapper>
          <SliderItemImg src={main_third} alt="Image 3" />
        </SliderItem>
      </Slider>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div``;
const SliderItem = styled.div`
  font-weight: 600;
  font-size: 20px;
  text-align: center;
`;

const SliderItemImg = styled.img`
  display: block;
  margin: 0 auto;
`;

const TextWrapper = styled.div`
  margin-bottom: 24px;
`;

const GreenText = styled.span`
  color: ${colors.GREEN[500]};
`;

const GreyText = styled.span`
  color: ${colors.GREY[800]};
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${colors.GREY[600]};
`;

export default ImagesSlide;
