import SoldCard from "components/sellerHistory/SoldCard";
import styled from "styled-components";
import card_img from "assets/card_img.svg";
import { SaleHistoryStatus } from "types/types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UploadData } from "types/types";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { getProducts } from "api/SellerApi";
import { isoStringToDate } from "api/StringUtils";
import { CalculateStatus } from "types/types";
import EmptyMessage from "components/common/EmptyMessage";
import Loading from "components/Loading";

const SoldHistory = () => {

    const [soldData, setSoldData] = useState<UploadData[]>([]);
    const [loading, setLoading] = useState(false);
    
    const user: any = useRecoilValue(loginState);

    const navigate = useNavigate();

    const fetchSoldData = async () => {
        try {
            setLoading(true);
            const dataRes = await getProducts(user.access);
            const filterData = dataRes.filter((item)=> item.product.selling_type == 1 && item.purchase !== null && item.product.is_selled).reverse();
            setSoldData(filterData);
            setLoading(false);
        } catch(error) {
            console.log(error);
        }
    }

    const identfyPurChaseStatus = (calculate_status : number) => {
        if(calculate_status == 0) return "발송요청"
        return CalculateStatus[calculate_status];
    }

    useEffect(()=> {
        fetchSoldData();
    },[])

    return (
        <SoldHistoryWrapper>
            {!loading ?
            soldData.length !== 0 ?
            soldData.map((list)=>
            <SoldCard
            date={isoStringToDate(list.purchase.created, false, false)}
            name={list.product.name}
            price={list.product.price}
            image={list.product.image1}
            saleHistoryStatus={identfyPurChaseStatus(list.product.calculate_status)}
            onClick={()=> {navigate(`/seller-history-detail/${list.id}`)}}
            currency={list.purchase.currency}/>) :
            <EmptyMessage/> :
            <Loading/>}
        </SoldHistoryWrapper>
    )
}

const SoldHistoryWrapper = styled.div`
`

export default SoldHistory;