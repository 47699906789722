import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { Font_2xs } from "styled/fonts";
import PrevIcon from '../../assets/backSlide.svg';
import NextIcon from '../../assets/nextSlide.svg';

interface SlideIProps {
  molecule: number;
  denominator: number;
  images: Array<string>;
  isClick?: boolean;
  urls?: Array<string>;
  autoplay?: boolean;
  arrow?: boolean;
  isBanner? : boolean;
}
const ImagesSlide = (props: SlideIProps) => {
  const navigate = useNavigate();
  const [slideState, setSlideState] = useState({
    activeSlide: 0,
    activeSlide2: 0,
  });
  const CustomPrevButton = ({ onClick }: any) => (
      <button onClick={onClick} style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
        <img src={PrevIcon} alt=""/>
      </button>
  );

  const CustomNextButton = ({ onClick }: any) => (
      <button onClick={onClick} style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
        <img src={NextIcon} alt=""/>
      </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: props.autoplay || false,
    autoplaySpeed: 3000,
    beforeChange: (current: number, next: number) =>
      setSlideState({ activeSlide: next, activeSlide2: current }),
    prevArrow: props.arrow ? <CustomPrevButton onClick={() => console.log('!')}/> : <></>,
    nextArrow: props.arrow ? <CustomNextButton onClick={() => console.log('!')}/> : <></>,
  };
  return (
    <SliderWrapper>
      <Slider {...settings}>
        {props.images.map((url: string, index: number) => (
            props.urls ? <a href={ props.urls[index] || ""} target="_self">
              <ImageWrapper
                molecule="4"
                denominator={ props.isBanner ? '3' : '5'}
                imageUrl={url}
              ></ImageWrapper>
            </a> : <a>
              <ImageWrapper
                  molecule="4"
                  denominator={ props.isBanner ? '3' : '5'}
                  imageUrl={url}
              ></ImageWrapper>
            </a>
        ))}
      </Slider>
      <PageInfoSection>
        <Font_2xs color="white" isBold>
          {slideState.activeSlide + 1}/{props.images.length}
        </Font_2xs>
      </PageInfoSection>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const ImageWrapper = styled.div<{ molecule: string; denominator: string, imageUrl: string }>`
  aspect-ratio: ${(props) => props.molecule + "/" + props.denominator};
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const PageInfoSection = styled.div`
  position: absolute;
  bottom: 1.4rem;
  right: 1rem;
  background-color: rgba(26, 32, 44, 0.4); /* 투명도 조절 */
  color: white;
  padding: 2px 6px 2px 6px;
  border-radius: 9999px;
`;

export default ImagesSlide;
