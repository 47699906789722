import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { styled } from "styled-components";
import { useParams } from "react-router-dom";
import SaleCard from "components/sellerHistory/SaleCard";
import card_img from "assets/card_img.svg";
import { colors } from "styled/colors";
import { Font_lg, Font_md, Font_sm, Font_xs } from "styled/fonts";
import { formatCurrency } from 'api/StringUtils';
import DeliveryMessage from "components/sellerHistory/DeliveryMessage";
import { CalculateStatus, SaleHistoryStatus } from "types/types";
import CalculateInfo from "components/sellerHistory/CalculateInfo";
import { langState, loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { getProduct } from "api/SellerApi";
import { UploadData } from "types/types";
import { useState } from "react";
import { isoStringToDate } from "api/StringUtils";

const SellerHistoryDetailPage = () => {

    const user: any = useRecoilValue(loginState);
    const [productData, setProductData] = useState<UploadData>();
    const [status, setStatus] = useState("");

    const { id } = useParams();

    const fetchProductData = async () => {
        try {
            const productRes = await getProduct(user.access, Number(id));
            setProductData(productRes);
            console.log(productRes);
        } catch (error){
            console.log(error);
        }
    }

    const identfyPurChaseStatus = (calculate_status : number) => {
        console.log(CalculateStatus[calculate_status]);
        if(calculate_status == 0) return "발송요청"
        return CalculateStatus[calculate_status];
    }

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
        fetchProductData();
    },[])

    useEffect(()=> {
        if(productData) setStatus(identfyPurChaseStatus(productData.product.calculate_status));
    },[productData])

    return (
        <DetailWrapper>
            <ProcessHeaderComponent
            title={t("판매 상세 내역")}
            isSearchForm={false}/>
            {( productData && status )&&
            <>
            <Title>
                <Font_sm>{isoStringToDate(productData.purchase.created, false, false)} {t("판매")}</Font_sm>
                <Font_sm color={colors.GREY[500]}>{t("주문번호")} {productData.purchase.order_id}</Font_sm>
            </Title>
            <SaleCard
            name={productData.product.name}
            price={productData.product.price}
            image={productData.product.image1}
            currency={productData.purchase.currency}/>
            <DetailInfo>
                {status === "발송요청" ?
                <DeliveryMessage/> :
                <>
                <CalculateInfo
                price={productData.product.price}
                currency={productData.purchase.currency}/>
                {status === "정산예정" &&
                <SubText>
                    <GrayBorder/>
                    <Font_sm isBold color={colors.GREEN[500]}>{t("구매확정 후 정산 예정")}</Font_sm>
                </SubText>
                }
                {(status === "정산완료" && productData.product.calculated_date)&&
                <Info>
                    <GrayBorder/>
                    <Font_sm>{t("정산일")}</Font_sm>
                    <Font_sm>{productData.product.calculated_date}</Font_sm>
                </Info>}
                </>}
            </DetailInfo>
            </>}
        </DetailWrapper>
    )
};

//todo: 정산일 데이터 연동

const DetailWrapper = styled.div`
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const DetailInfo = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const SubText = styled.div`
    text-align: center;
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
`

const GrayBorder = styled.div`
    height: 1px;
    background-color: ${colors.GREY[50]};
    margin: 1rem 0;
`

export default SellerHistoryDetailPage;