import ProcessHeader from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import CardListComponent from "components/puchaceHist/CardListComponent";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useEffect } from "react";
import { isNotLogin } from "api/UserUtil";
import { useLocation } from "react-router-dom";

const DeliverStatusPage = () => {
  const user : any = useRecoilValue(loginState);

  const { state } = useLocation();

  useEffect(()=>{
    isNotLogin(user).then((data) => {
      if(data)
        window.location.replace("/");
    });
  }, [])
  return (
    <DeliverStatus>
      <ProcessHeader title='' isSearchForm={false}/>
      {state &&
      <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        title="Delivery Tracker"
        src={`https://tracker.delivery/#/${state.product.carrier_id}/${state.product.track_id}`}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>}
    </DeliverStatus>
  );
};

const DeliverStatus = styled.div`

`

export default DeliverStatusPage;