import BidCard from "components/auctionHistory/BidCard";
import styled from "styled-components";
import { CalculateStatus, DeliverStatus } from "types/types";
import card_img from "assets/card_img.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useState } from "react";
import { BiddingData } from "types/types";
import { getBiddingData } from "api/UserHistoryApi";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { isoStringToDate } from "api/StringUtils";
import { addDaysToDate } from "api/StringUtils";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import EmptyMessage from "components/common/EmptyMessage";
import Loading from "components/Loading";


const BidHistory = () => {
    const user : any = useRecoilValue(loginState);
    const [bidData, setBidData] = useState<BiddingData[]>([]);
    const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(isCompletePurchaceState);
    const [loading, setLoading] = useState(false);

    const fetchBiddingData = async() => {
        setLoading(true);
        const data = await getBiddingData(user.access);
        setBidData(data.filter((item) => item.is_success).reverse());
        setLoading(false);
    }

    const navigate = useNavigate();

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const identifyBidStatus = (list: BiddingData | undefined) : string => {
        if(list) {
            if(list.purchase && list.purchase.is_done) {
                return "구매확정";
            } else {
                return DeliverStatus[list.product.status];
            }
        }
        return '';
    }

    useEffect(()=> {
        fetchBiddingData();
    },[])

    useEffect(()=> {
        fetchBiddingData();
    },[isCompletePurchace])

    return (
        <BidHistoryWrapper>
            {!loading ?
            bidData.length !== 0 ?
            bidData.map((list)=> 
            <BidCard
            date={isoStringToDate(list.product.end_date, false, false)}
            name={list.product.name}
            price={list.product.price}
            auctionStatus={identifyBidStatus(list)}
            payStatus={identifyBidStatus(list) == "결제대기" ? addDaysToDate(list.product.end_date, 3, true, false) : ''}
            image={list.product.image1}
            onClick={()=> {navigate(`/bid-detail/${list.id}`)}}
            bidData = {list}
            />):
            <EmptyMessage/> :
            <Loading/>}
        </BidHistoryWrapper>
    )
}

const BidHistoryWrapper = styled.div`
`

export default BidHistory;