import styled from "styled-components";
import { Font_md, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import next_img from "assets/chevron_right.svg";
import { checkAndConvertCurrency, fixedExchangePrice, formatCurrency } from 'api/StringUtils';
import { useNavigate } from "react-router-dom";
import { SaleHistoryStatus } from "types/types";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { exchangeRateState } from "recoil/homeRecoil";

interface SoldCardType {
    date: string;
    name: string;
    price: number;
    image: string;
    isAuction?: boolean;
    saleHistoryStatus: SaleHistoryStatus | string;
    onClick: Function;
    currency: string;
}

const SoldCard = (props: SoldCardType) => {
    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const exchangeRate = useRecoilValue(exchangeRateState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    const navigate = useNavigate();
    const cutText = (text : string) => {
        if(text.length > 30) {
            return text.substring(0,30) + "...";
        }
        return text;
    }

    const chooseAuctionStatusColor = (status : string) => {
        if(status === "발송요청") {
            return colors.GREEN[500];
        } else if(status === "선정취소" || status === "제안없음") {
            return colors.GREY[400];
        }
        return colors.GREY[700];
    }

    return (
        <SoldCardWrapper onClick={()=> {props.onClick()}}>
            {props.saleHistoryStatus !== "제안종료" &&
            <ListTitle onClick={()=> {}}>
                <Font_sm>{props.date} {t(props.isAuction ? '선정' : '판매')}</Font_sm>
                <Arrow src={next_img}/>
            </ListTitle>}
            <ListMain>
                <ProductImg src={props.image}/>
                <ListInfo>
                    <ProductName>{cutText(props.name)}</ProductName>
                    <Font_sm isBold>{checkAndConvertCurrency(language, (props.currency === 'USD' ? Math.round(props.price / fixedExchangePrice * 100) / 100 : props.price), props.currency, exchangeRate?.basePrice)}</Font_sm>
                    <Font_sm isBold color={chooseAuctionStatusColor(props.saleHistoryStatus)}>{t(props.saleHistoryStatus)}</Font_sm>
                </ListInfo>
            </ListMain>
        </SoldCardWrapper>
    )
};

const SoldCardWrapper = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`
const ListTitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const Arrow = styled.img`
`

const ListMain = styled.div`
    display: flex;
    gap: 1rem;
`

const ProductImg = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    object-fit: cover;
`

const ListInfo = styled.div`
    flex: 1;

`

const ProductName = styled(Font_md)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 
`

export default SoldCard;