import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_sm } from "styled/fonts";
import {DeliverStatus} from 'types/types';
import { langState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

interface ProgressIProps {
  deliverStatus: string;
}

const DeliverProgressComponent = (props: ProgressIProps) => {
  const percent = 
    props.deliverStatus === '배송준비' ? '25%' : 
    props.deliverStatus === '검수중' ? '50%' :
    props.deliverStatus === '배송중' ? '75%' : '100%'

  const ProgressBar = styled.div`
    margin-bottom: 0.5rem;
    width:100%;
    height: 4px;
    background: linear-gradient(to right, ${colors.GREEN[500]} ${percent}, ${colors.GREY[100]} ${percent});
  `

  const { t, i18n } = useTranslation("history");
  const language = useRecoilValue(langState);

  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])

  return (
    <DeliverProgress>
      <ProgressBar />
      <TextGroup>
        <TextSection>
          <Font_sm color={props.deliverStatus === '배송준비' ? colors.GREEN[500] : colors.GREY[400]} className='readyDeliver'>{t("배송준비")}</Font_sm>
        </TextSection>
        <TextSection>
          <Font_sm color={props.deliverStatus === '검수중' ? colors.GREEN[500] : colors.GREY[400]} className='inspecting'>{t("검수중")}</Font_sm>
        </TextSection>
        <TextSection>
          <Font_sm color={props.deliverStatus === '배송중' ? colors.GREEN[500] : colors.GREY[400]} className='delivering'>{t("배송중")}</Font_sm>
        </TextSection>
        <TextSection>
          <Font_sm color={props.deliverStatus === '배송완료' ? colors.GREEN[500] : colors.GREY[400]} className='compliteDeliver'>{t("배송완료")}</Font_sm>
        </TextSection>
      </TextGroup>
    </DeliverProgress>
  );
};

const DeliverProgress = styled.div`
  width: 100%;
`;

const TextGroup = styled.div`
  display: flex;
  width:100%;
`

const TextSection = styled.div`
  display: flex;
  justify-content: center;
  width:25%;
  color: red;
`


export default DeliverProgressComponent;