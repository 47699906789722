import { useState, useEffect } from "react";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import PasswordValidComponent from "components/common/PasswordValidComponent"
import SignupTextWrapper from "components/SignupTextComponent";
import styled from "styled-components";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isValidPasswordState, isEqualPasswordState, passwordState, emailState } from "recoil/signupRecoil";
import { Size } from "types/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";

const PasswordPage = () => {
  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState);
  const email = useRecoilValue(emailState);

  const navigate = useNavigate();
  const HandleClick = () => {
    navigate('/signup/personal');
  };
  const setPassword = useSetRecoilState(passwordState);
  useEffect(()=>{
    if(!email){
      navigate('/onboarding');
    }
    i18n.changeLanguage(language);
    setPassword('');
  }, [])

  const isEqualPassword = useRecoilValue(isEqualPasswordState);
  const isValidPassword = useRecoilValue(isValidPasswordState);

  return (
    <Password>
      <ProcessHeader isSearchForm={false} title={t("회원가입")} stepPercent={75} />
      <SignupTextWrapper 
        topString="로그인에 사용할"
        highlight="비밀번호"
        bottomString="를 입력해주세요."/>
      <InputWrapper>
        <PasswordValidComponent isChange={false}/>
      </InputWrapper>
      <ButtonWrapper>
        <ButtonComponent 
          size={Size.LARGE} 
          text={t("다음")}
          isValid={isValidPassword && isEqualPassword}
          onClick={() => HandleClick()}/>
      </ButtonWrapper>
    </Password>
  );
};

const Password = styled.div`

`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  margin:0px 16px 24px 0px;
`

const ButtonWrapper = styled.div`
  display:flex;
  justify-content: center;
`

export default PasswordPage;