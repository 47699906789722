import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { styled } from "styled-components";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import SellerBiddingHistory from "components/sellerHistory/SellerBiddingHistory";
import SellerBidHistory from "components/sellerHistory/SellerBidHistory";
import SellerEndHistory from "../../components/sellerHistory/SellerEndHistory";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { colors } from "styled/colors";

const SellerAuctionHistoryPage = () => {
    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(0);
    const { id } = useParams();

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    useEffect(()=> {
        setTabIndex(Number(id));
    },[id]);

    return (
      <AuctionHistoryWrapper>
        <ProcessHeaderComponent
          title={t("제안 판매 내역")}
          isSearchForm={false}
          path="/mypage"
        />
        <Tabs
          variant="line"
          size="md"
          isFitted
          colorScheme="gray"
          index={tabIndex}
        >
          <TabList>
            <TabItem
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => navigate("/seller-auction-history/0")}
            >
              {t("진행중")}
            </TabItem>
            <TabItem
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => navigate("/seller-auction-history/1")}
            >
              {t("마감")}
            </TabItem>
            <TabItem
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => navigate("/seller-auction-history/2")}
            >
              {t("종료")}
            </TabItem>
          </TabList>
          <TabPanels>
            <TabPanel padding={0}>
              <SellerBiddingHistory />
            </TabPanel>
            <TabPanel padding={0}>
              <SellerBidHistory />
            </TabPanel>
            <TabPanel padding={0}>
              <SellerEndHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </AuctionHistoryWrapper>
    );
};

const AuctionHistoryWrapper = styled.div`
`

const TabItem = styled(Tab)`
  font-weight: 400;
`

export default SellerAuctionHistoryPage;