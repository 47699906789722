import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_lg, Font_md } from "styled/fonts";
import auction from "assets/auction.svg";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

const BidMessage = () => {
    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <BidMessageWrapper>
            <AuctionIcon src={auction}/>
            <Font_lg isBold>{t("상품이 선정되었습니다!")}</Font_lg>
            <RequestToSeller>
                <Font_md>{t("선정자 결제 후 빠른 배송을 위해")}</Font_md>
                <Font_md>{t("상품을 미리 준비해주세요.")}</Font_md>
            </RequestToSeller>
        </BidMessageWrapper>
    )
};

const BidMessageWrapper = styled.div`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const AuctionIcon = styled.img`
    width: 5rem;
    height: 5rem;
`

const RequestToSeller = styled.div`
    text-align: center;
`


export default BidMessage;