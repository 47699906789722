import styled from 'styled-components';
import {changeFixedUsdToKrw, changeKrwToUsd, formatCurrency} from "../../api/StringUtils";
import {ExchangeRateType} from "../../types/types";

export interface ChartProps {
    price: number;
    created: string;
    product: {
        value_product: any;
    };
    currency: string;
}


const TradingHistory = (props: { chartData: ChartProps[], summary?: boolean, language: string, exchangeRate: ExchangeRateType, sellingType: number | undefined}) => {
    const {chartData, summary, language, exchangeRate, sellingType } = props;
    const isManufacturer = chartData.map((e) => e.product.value_product)[0].filter((a: any) => a.option.name === "제조사");
    const isGrading = chartData.map((e) => e.product.value_product)[0].filter((a: any) => a.option.name === "그레이딩");
    return (
        <Container>
            <HeaderContainer>
                <HeaderItem width={20}>{ language === 'ko-KR' ? '날짜' : 'Date'}</HeaderItem>
                <HeaderItem width={10} isRight>{ language === 'ko-KR' ? '연도' : 'Year'}</HeaderItem>
                <HeaderItem width={25} isRight>{ isManufacturer.length ? (language === 'ko-KR' ? '제조사' : 'Mfr.') : ''}</HeaderItem>
                <HeaderItem width={20} isRight>{ isGrading.length ? (language === 'ko-KR' ? '그레이딩' : 'Grading') : ''}</HeaderItem>
                <HeaderItem width={25} isRight>{ language === 'ko-KR' ? '거래가' : 'Price'}</HeaderItem>
            </HeaderContainer>
            { chartData && chartData.map((e, index) => {
                const year = e.product.value_product.filter((t: any) => t.option.name === '연도')[0].value;
                const manufacturer = e.product.value_product.filter((t: any) => t.option.name === '제조사')[0]?.value;
                const eng_manufacturer = e.product.value_product.filter((t: any) => t.option.name === '제조사')[0]?.eng_value;
                const grading = e.product.value_product.filter((t: any) => t.option.name === '그레이딩')[0]?.value;
                const eng_grading = e.product.value_product.filter((t: any) => t.option.name === '그레이딩')[0]?.eng_value;
                if (summary && index < 3) {
                    return (
                        <ItemContainer>
                            <ItemValue width={20}>{new Date(e.created).toLocaleDateString("ko", { year: '2-digit', month: '2-digit', day: '2-digit' })
                                .replace(/\//g, '')}</ItemValue>
                            <ItemValue width={10} isRight>{year}</ItemValue>
                            <ItemValue width={25} isRight>{ isManufacturer.length ? (manufacturer ? (language === "ko-KR" ? manufacturer : eng_manufacturer) : '-') : ''}</ItemValue>
                            <ItemValue width={20} isRight>{ isGrading.length ? (grading ? (language === "ko-KR" ? grading : eng_grading) : '-') : ''}</ItemValue>
                            {/*{ e.currency === 'USD' ?*/}
                            {/*    <ItemValue width={20} isRight>{ language === 'ko-KR' ? `US $${e.price.toLocaleString()}` : `$${e.price.toLocaleString()}`}</ItemValue>*/}
                            {/*    :*/}
                            {/*    <ItemValue width={20} isRight>{ language === 'ko-KR' ? `${e.price.toLocaleString()}원` : `KRW ${e.price.toLocaleString()}`}</ItemValue>*/}
                            {/*}*/}
                            { language === 'ko-KR' ?
                                <ItemValue width={25} isRight>{ e.currency === 'USD' ? `약 ${changeFixedUsdToKrw(e.price).toLocaleString()}원` : `${e.price.toLocaleString()}원`}</ItemValue>
                                :
                                <ItemValue width={25} isRight>
                                    {e.currency === 'USD' ? (
                                        `$${e.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '20px', alignItems: 'flex-end'}}>
                                            <span style={{ fontSize: 12 }}>Approx.</span> $
                                            {changeKrwToUsd(e.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </div>
                                    )}
                                </ItemValue>
                            }
                        </ItemContainer>
                    )
                } else if (!summary) {
                    return (
                        <ItemContainer>
                            <ItemValue width={20}>{new Date(e.created).toLocaleDateString("ko", { year: '2-digit', month: '2-digit', day: '2-digit' })
                                .replace(/\//g, '')}</ItemValue>
                            <ItemValue width={10} isRight>{year}</ItemValue>
                            <ItemValue width={25    } isRight>{ isManufacturer.length ? (manufacturer ? (language === "ko-KR" ? manufacturer : eng_manufacturer) : '-') : ''}</ItemValue>
                            <ItemValue width={20} isRight>{ isGrading.length ? (grading ? (language === "ko-KR" ? grading : eng_grading) : '-') : ''}</ItemValue>
                            {/*{ e.currency === 'USD' ?*/}
                            {/*    <ItemValue width={20} isRight>{ language === 'ko-KR' ? `US $${e.price.toLocaleString()}` : `$${e.price.toLocaleString()}`}</ItemValue>*/}
                            {/*    :*/}
                            {/*    <ItemValue width={20} isRight>{ language === 'ko-KR' ? `${e.price.toLocaleString()}원` : `KRW ${e.price.toLocaleString()}`}</ItemValue>*/}
                            {/*}*/}
                            { language === 'ko-KR' ?
                                <ItemValue width={25} isRight>{ e.currency === 'USD' ? `약 ${changeFixedUsdToKrw(e.price).toLocaleString()}원` : `${e.price.toLocaleString()}원`}</ItemValue>
                                :
                                <ItemValue width={25} isRight>
                                    {e.currency === 'USD' ? (
                                        `$${e.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '20px', alignItems: 'flex-end'}}>
                                            <span style={{ fontSize: 12 }}>Approx.</span> $
                                            {changeKrwToUsd(e.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </div>
                                    )}
                                </ItemValue>
                            }
                        </ItemContainer>
                    )
                }

            })}
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
`;
const HeaderContainer = styled.div`
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #EDF2F7;
  display: flex;
  align-items: center;
`;
const HeaderItem = styled.div<{ width: number, isRight?: boolean }>`
  width: ${(props) => `${props.width}%`};
  font-weight: 600;
  font-size: 13px;
  color: #2D3748;
  display: flex;
  justify-content: ${(props) => (props.isRight && 'flex-end')};
`;
const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const ItemValue = styled.div<{ width: number, isRight?: boolean }>`
  width: ${(props) => `${props.width}%`};
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.isRight ? '#2D3748' : '#718096')};
  display: flex;
  justify-content: ${(props) => (props.isRight && 'flex-end')};;
`;
export default TradingHistory;
