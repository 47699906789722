const HomeSvgComponent = ({ isClicked }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.32916 0.601537C8.63377 0.104826 7.69961 0.104826 7.00421 0.601537L1.00421 4.88725C0.478618 5.26267 0.166687 5.86882 0.166687 6.51472V15.0001C0.166687 16.1047 1.06212 17.0001 2.16669 17.0001H14.1667C15.2713 17.0001 16.1667 16.1047 16.1667 15.0001V6.51472C16.1667 5.86882 15.8548 5.26267 15.3292 4.88725L9.32916 0.601537ZM2.16669 6.51472L8.16669 2.229L14.1667 6.51472V15.0001H9.16669V10.0001C9.16669 9.44784 8.71897 9.00012 8.16669 9.00012C7.6144 9.00012 7.16669 9.44784 7.16669 10.0001V15.0001H2.16669L2.16669 6.51472Z"
      fill={ isClicked ? '#0AB88C' : 'black' }
    />
  </svg>
);

export default HomeSvgComponent;
