import MenuItemTwoLinesComponent from "components/MenuItemTwoLinesComponent";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_sm, Font_2xl, Font_md } from "styled/fonts";
import auction from "assets/auction.svg";
import shopping_bag from "assets/shopping_bag.svg";
import PaidListItem from "components/mypage/PaidListItem";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { SellerType, UploadData } from "types/types";
import { getProducts, getSellerInfo } from "api/SellerApi";
import { loginState } from "recoil/loginRecoil";
import { useEffect } from "react";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { checkAndConvertCurrency, isoStringToDate } from "api/StringUtils";
import { getPresetData } from "api/api";
import { getUserInfo } from "api/UserApi";
import Loading from "components/Loading";
import { exchangeRateState } from "recoil/homeRecoil";

const CalculatePage = () => {

    const user : any = useRecoilValue(loginState);

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    const [seller, setSeller] = useState<SellerType>();
    const [calculatedData, setCalculatedData] = useState<UploadData[]>([]);
    const [loading, setLoading] = useState(false);
    const [charge, setCharge] = useState(0);
    const [auctionTotalPrice, setAuctionTotalPrice] = useState(0);
    const [forSaletotalPrice, setForSaleTotalPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const exchangeRate = useRecoilValue(exchangeRateState);


    const fetchPresetData = async() => {
      const data = await getPresetData();
      const findCharge = data.filter((item)=> item.key == "fee");
      setCharge(Number(findCharge[0].value));
    }

    const fetchSellerData = async() => {
        const response = await getSellerInfo(user.access);
        if(response instanceof Error) {
            console.error('API 호출 중 에러 발생:', response.message);
        } else {
            setSeller(response.data[0]);
        }
    };

    const fetchUploadData = async() => {
        const response = await getProducts(user.access);
        const filterData = response.filter((item)=> item.product.calculate_status == 2);
        setCalculatedData(filterData);
    }

    const fetchUserData = async() => {
        const dataRes = await getUserInfo(user.access);
        if(dataRes && dataRes.data.calculated_total) setTotalPrice(dataRes.data.calculated_total);
    }

    const roundToCharge = (list: UploadData) : number => {
        if(list.product.selling_type == 1) return Math.round(list.product.price * (charge/100));
        else return Math.round(list.product.success_price * (charge/100));
    }

    const calculateTotal = (filterData : UploadData[]) => {
        const totalAuctionPrice = filterData.filter((item)=> item.product.selling_type == 2).map((item)=> item.product.success_price - roundToCharge(item)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        const totalPrice = filterData.filter((item)=> item.product.selling_type == 1).map((item)=> item.product.price - roundToCharge(item)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setAuctionTotalPrice(totalAuctionPrice);
        setForSaleTotalPrice(totalPrice);
    }

    

    useEffect(()=> {
        i18n.changeLanguage(language);
        setLoading(true);
        fetchSellerData();
        fetchUploadData();
        fetchPresetData();
        fetchUserData();
        setLoading(false);
    },[]);

    useEffect(()=> {
        if(calculatedData) calculateTotal(calculatedData);
    },[calculatedData])




    return (
        <Calculate>
            {loading ?
            <Loading/>
            :
            <>
            <ProcessHeader title={t("정산")} isSearchForm={false}/>
            <CalculateInfo>
                <AccountInfo>
                    <Font_md isBold>{t("정산계좌")}</Font_md>
                    {seller && <Font_md>{seller.account_owner}  |  {seller.account_bank} {seller.account_number}</Font_md>}
                </AccountInfo>
                <GrayBorder/>
                <TotalPrice>
                    <Font_sm isBold>{t("총 정산금액")}</Font_sm>
                    <Font_2xl isBold>{seller?.swift_code ? checkAndConvertCurrency(language, totalPrice, 'USD', exchangeRate?.basePrice) : checkAndConvertCurrency(language, totalPrice, 'KRW', exchangeRate?.basePrice)}</Font_2xl>
                </TotalPrice>
                <DetailPrice>
                    <PriceList>
                        <Icon src={auction}/>
                        <ListName>{t("제안 판매")}</ListName>
                        <Font_md isBold>{seller?.swift_code ? checkAndConvertCurrency(language, auctionTotalPrice, 'USD', exchangeRate?.basePrice) : checkAndConvertCurrency(language, auctionTotalPrice, 'KRW', exchangeRate?.basePrice)}</Font_md>
                    </PriceList>
                    <PriceList>
                        <Icon src={shopping_bag}/>
                        <ListName>{t("일반 판매")}</ListName>
                        <Font_md isBold>{seller?.swift_code ? checkAndConvertCurrency(language, forSaletotalPrice, 'USD', exchangeRate?.basePrice) : checkAndConvertCurrency(language, forSaletotalPrice, 'KRW', exchangeRate?.basePrice)}</Font_md>
                    </PriceList>
                </DetailPrice>
            </CalculateInfo>
            <DetailInfo>
                {calculatedData.length !==0 ?
                <>
                {
                    calculatedData.map((list)=>
                    list.product.selling_type == 1 ?
                        <PaidListItem
                        date={isoStringToDate(list.created, false, true)}
                        saleType={t("일반 판매")}
                        name={list.product.name}
                        price={checkAndConvertCurrency(language, list.product.price, list.purchase.currency, exchangeRate?.basePrice)}
                        charge={checkAndConvertCurrency(language, roundToCharge(list), list.purchase.currency, exchangeRate?.basePrice)}
                        calculatePrice={checkAndConvertCurrency(language, list.product.price - roundToCharge(list), list.purchase.currency, exchangeRate?.basePrice)}/> 
                        :
                        <PaidListItem
                        date={isoStringToDate(list.created, false, true)}
                        saleType={t("제안 판매")}
                        name={list.product.name}
                        price={checkAndConvertCurrency(language, list.product.success_price, list.purchase.currency, exchangeRate?.basePrice)}
                        charge={checkAndConvertCurrency(language, roundToCharge(list), list.purchase.currency, exchangeRate?.basePrice)}
                        calculatePrice={checkAndConvertCurrency(language, list.product.success_price - roundToCharge(list), list.purchase.currency, exchangeRate?.basePrice)}/> 
                    )
                }
                </> :
                <NoItems>
                    <Font_md color={colors.GREY[400]}>{t("정산 내역이 없습니다.")}</Font_md>
                </NoItems>}
            </DetailInfo>

            </>}
        </Calculate>
    )
};

const Calculate = styled.div`
`

const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const CalculateInfo = styled.div`
    margin-bottom: 1rem;
`

const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 1rem;
`

const TotalPrice = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 1rem;
`
const DetailPrice = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: ${colors.GREY[50]};
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 1rem;

`
const GrayBorder = styled.div`
    height: 0.5rem;
    background-color: ${colors.GREY[50]};
`

const PriceList = styled.div`
    display: flex;
    gap: 1rem;
`

const Icon = styled.img`
    height: 24px;
`

const ListName = styled(Font_md)`
    flex: 1;
`

const DetailInfo = styled.div`
`

const NoItems = styled.div`
    display: flex;
    justify-content: center;
    height: calc(100vh - 355px);
    align-items: center;
`

export default CalculatePage;