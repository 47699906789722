import { getPresetData } from "api/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_md, Font_sm } from "styled/fonts";

interface PaidListItemTypes {
    date: string;
    saleType: string;
    name: string;
    price: string;
    charge: string;
    calculatePrice : string;
};

const PaidListItem = (props: PaidListItemTypes) => {

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])


    return (
        <ItemWrapper>
            <Font_sm color={colors.GREY[500]}>{props.date} ・ {props.saleType}</Font_sm>
            <Font_md color={colors.GREY[900]}>{props.name}</Font_md>
            <PriceWrapper>
                <PriceInfo>
                    <PriceType>{t("상품금액")}</PriceType>
                    <Font_sm>{props.price}</Font_sm>
                </PriceInfo>
                <PriceInfo>
                    <PriceType>{t("수수료")}</PriceType>
                    <Font_sm>{props.charge}</Font_sm>
                </PriceInfo>
                <PriceInfo>
                    <PriceType isBold>{t("정산금액")}</PriceType>
                    <Font_sm isBold>{props.calculatePrice}</Font_sm>
                </PriceInfo>
            </PriceWrapper>
        </ItemWrapper>
    )
};

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
`

const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const PriceInfo = styled.div`
    display: flex;
`

const PriceType = styled(Font_sm)`
    flex: 1;
`



export default PaidListItem;