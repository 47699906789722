import { styled } from "styled-components";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import PriceInputComponent from "components/addproduct/PriceInputComponent";
import { useEffect, useState } from "react";
import RadioButtons from "components/common/RadioButtons";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { Font_md, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import { Input } from "@chakra-ui/react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Categories, CategoryData, ReUploadData, Size, UploadData } from "types/types";
import { categoryState, imageProductState, optionsState, periodState, priceState, productContentState, productNameState, startDateState } from "recoil/addProductRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import ModalComponent from "components/common/ModalComponent";
import { useSharedAtoms } from "recoil/addProductRecoil";
import { createProduct, getSellerInfo, reUpload } from "api/SellerApi";
import { loginState } from "recoil/loginRecoil";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useLocation, useNavigate } from "react-router-dom";
import { calculateEndDate } from "api/DateUtils";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import Loading from "components/Loading";
import { changeUsdToKrw } from "api/StringUtils";
import { SellerType } from "types/types";
import { fixedExchangePrice } from "api/StringUtils";

interface LocationData {
    uploadData: UploadData;
}

const PriceSettingForAuction = () => {
    const [price, setPrice] = useRecoilState(priceState);
    const [period, setPeriod] = useRecoilState(periodState);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [startDateString, setStartDateString] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useRecoilState(optionsState);
    const [category, setCategory] = useRecoilState(categoryState);
    const [productName, setProductName] = useRecoilState(productNameState);
    const [productContent, setProductContent] = useRecoilState(productContentState);
    const [imageProduct, setImageProduct] = useRecoilState(imageProductState);
    const [reUploadData, setReUploadData] = useState<UploadData>();
    const [sellerInfo, setSellerInfo] = useState<SellerType>();
    
    const location = useLocation();
    const state = location.state as LocationData;
    const navigate = useNavigate();

    const handleStartingDateStringChange = (event: any) => setStartDateString(event.target.value);

    const reservationValid = () => {
        if(isChecked) {
            return Boolean(startDateString)
        } else {
            return true
        }
    }

    const currentDate = () => {
        const now = new Date();
        const offset = now.getTimezoneOffset() * 60000; 
        const date = new Date(Date.now()-offset).toISOString().slice(0,16);
        return date;
    }

    const setEndTime = (start_date: string) => {
        if(period) {
            const afterSevenDays = calculateEndDate(start_date, 604800);
            const offset = afterSevenDays.getTimezoneOffset() * 60000;
            return new Date(afterSevenDays.getTime() - offset).toISOString();
        }
        const afterThreeDays = calculateEndDate(start_date, 259200);
        const offset = afterThreeDays.getTimezoneOffset() * 60000;
        return new Date(afterThreeDays.getTime() - offset).toISOString();
    }

    const fetchSellerInfo = async () => {
        const sellerRes = await getSellerInfo(user.access);
        setSellerInfo(sellerRes.data[0]);
    }

    const { t, i18n } = useTranslation("addProduct");
    const language = useRecoilValue(langState);

    useEffect(()=> {
        i18n.changeLanguage(language);
        setReUploadData(state ? state.uploadData : undefined);
        //새로고침해서 이전 페이지 데이터가 없을 경우
        if((!category && category !== 0) && !state?.uploadData) navigate('/add-product/category');

        console.log(currentDate());
        const now = new Date();
        console.log(setEndTime(currentDate()));
        fetchSellerInfo();
    },[])

    useEffect(()=> {
        console.log(startDateString);
        if(startDateString) console.log(setEndTime(startDateString))
    },[startDateString])

    const isValid = price != 0 && price != undefined && period != undefined && reservationValid();

    const user : any = useRecoilValue(loginState);

    const handleButton = () => {
        setIsModalOpen(true);
    }

    const handleSubmitButton = async() => {
        const formData = new FormData();
        imageProduct && imageProduct.forEach((image, index) => {
            const fieldName = `image${index + 1}`;
            formData.append('product.' + fieldName, image);
        })
        const start_date = (isChecked && startDateString) ? startDateString : currentDate();
        const end_date = setEndTime(start_date);

        if(reUploadData) {
            const reUploadObject : ReUploadData = {
                product: {
                    id: reUploadData.product.id,
                    price: sellerInfo?.swift_code ? changeUsdToKrw(price, fixedExchangePrice) : price,
                    start_date: start_date,
                    end_date: end_date
                },
                user: user.user.id
            }
            try {
                setLoading(true);
                const dataRes = await reUpload(user.access, reUploadObject);
                setLoading(false);

                //recoil state 초기화
                setOptions([]);
                setProductName('');
                setProductContent('');
                setCategory(undefined);
                setImageProduct([]);

                window.location.replace(`/item-detail-bid?id=${dataRes.product.id}&origin-path=/mypage`);
            } catch(error) {
                console.log(error);
            }
        } else {
            options.forEach((item, index) => {
                formData.append(`product.value_product[${index}]option`, item.id.toString());
                formData.append(`product.value_product[${index}]value`, item.value);
                formData.append(`product.value_product[${index}]eng_value`, item.eng_value);
            })
    
            formData.append('product.selling_type', '2');
            formData.append('product.name', productName);
            formData.append('product.content', productContent);

            if(sellerInfo?.swift_code) {
                formData.append('product.price', changeUsdToKrw(price, fixedExchangePrice).toString());
            } else {
                formData.append('product.price', price.toString());
            }

            ( category !== undefined || category == 0 ) && formData.append('product.category', (category+3).toString());
            formData.append('user', user.user.id);
    
            formData.append('product.start_date', start_date);
            formData.append('product.end_date', end_date);
    
            setLoading(true);
            const productRes: any = await createProduct({
                access: user.access,
                formData: formData,
              });
            setLoading(false);
            //recoil state 초기화
            setOptions([]);
            setProductName('');
            setProductContent('');
            setCategory(undefined);
            setImageProduct([]);

            window.location.replace(`/item-detail-bid?id=${productRes.data.product.id}&origin-path=/mypage`);
        }
    }

    return (
        <PriceSettingWrapper>
            {loading ?
            <Loading/>
            :
            <>
            <ProcessHeaderComponent
            title={t("상품등록")}
            isSearchForm={false}
            path="/mypage"
            />
            <PriceInputComponent
            title={t("제안시작가")}
            price={price}
            setPrice={setPrice}
            isKRW={sellerInfo?.swift_code ? false : true}/>
            <AuctionInfoWrapper>
                <RadioButtons
                title={t("제안 마감")}
                firstButton={t("3일")}
                secondButton={t("7일")}
                item={period}
                setItem={setPeriod}
                />
                <SettingDateWrapper>
                    <StartAuctionCheckBox
                    colorScheme='green'
                    size="md"
                    isChecked={isChecked}
                    onChange={()=> setIsChecked(!isChecked)}>
                        <Font_md>{t("제안 시작 예약하기")}</Font_md>
                    </StartAuctionCheckBox>
                    {isChecked &&
                    <DateInput
                    value={startDateString}
                    onChange={handleStartingDateStringChange}
                    data-placeholder={t("제안 시작 일시")}
                    required
                    aria-required="true"
                    size="lg"
                    type="datetime-local"
                    />
                    }
                </SettingDateWrapper>
                <InformationWrapper>
                    <Font_md isBold>{t("가격 제안은 어떻게 이뤄지나요?")}</Font_md>
                    <InformationTable>
                        <Information>  • {t("구매를 원하는 사람들에게 가격을 제안 받을 수 있습니다.")}</Information>
                        <Information>  • {t("제안이 마감된 후에 가격 제안을 확인할 수 있습니다.")}</Information>
                        <Information>  • {t("마음에 드는 가격 제안을 선택하여 판매할 수 있습니다.")}</Information>
                </InformationTable>
                </InformationWrapper>
            </AuctionInfoWrapper>
            <ButtonWrapper>
                <ButtonComponent
                isValid={isValid}
                text={t("상품 등록")}
                size={Size.LARGE}
                onClick={handleButton}/>
            </ButtonWrapper>
            <ModalComponent
            title={t("상품을 등록하시겠어요?")}
            content={t("제안 상품 등록 후에는")}
            bottomContent={t("수정・삭제할 수 없습니다.")}
            leftBtnString={t("취소")}
            rightBtnString={t("상품 등록")}
            isOpen={isModalOpen}
            onClose={()=> {setIsModalOpen(false)}}
            onClickSubmit={handleSubmitButton}/>
            </>}
        </PriceSettingWrapper>
    )
}

const PriceSettingWrapper = styled.div`
`

const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const AuctionInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 1rem;
`

const SettingDateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const StartAuctionCheckBox = styled(Checkbox)`
`

const DateInput = styled(Input)`
    &::before {
        content: attr(data-placeholder);
        width: 100%;
        color: ${colors.GREY[400]};
    }
    &:focus::before,
    &:valid::before {
    display: none;
    color: ${colors.GREY[700]}
    }
`

const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const InformationTable = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`

const Information = styled(Font_sm)`
    white-space: pre-wrap
`

const PointFont = styled.span`
    color: ${colors.GREEN[500]};
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export default PriceSettingForAuction;