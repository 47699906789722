import axios from "axios";
import {
  CategoryData,
  CategoryOptionData,
  CreateCartResType,
  GetExchangeRateType,
  GetItemDetailResType,
  GetItemListParams,
  GetItemListResType,
  LoginResType,
  LoginType,
  NoticeData,
  PresetData,
  VerticalCardType
} from "types/types";
import {isNotLogin} from "./UserUtil";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const login = async (props: LoginType) => {
  try {
    const response = await axios.post(API_ENDPOINT + '/accounts/login/', {
      email:props.email,
      password:props.password,
    });
    const loginRes : LoginResType = {
      access:response.data.access,
      user:response.data.user,
      error:'',
    }
    return loginRes;
  } catch (error:any) {
    const loginRes : LoginResType = {
      access:'',
      user:'',
      error:error.response.data,
    }
    return loginRes;
  }
}

export const googleLogin = async (code: string, idToken: string) => {
  let body: any = {};
  if(code === ''){
    body['access_token'] = idToken;
    body['id_token'] = idToken;
  }
  else
    body['code'] = code;

  try{
    const response = await axios.post(API_ENDPOINT + '/accounts/social/google/login/', body);
    const loginRes : LoginResType = {
      access:response.data.access,
      user:response.data.user,
      error:'',
    }
    return loginRes;
  } catch (error: any){
    const loginRes : LoginResType = {
      access:'',
      user:'',
      error:error.response.data['non_field_errors'][0],
    }
    return loginRes;
  }
}

export const getFilterData = async () => {
  try{
    const response = await axios.get(API_ENDPOINT + '/service/category/');
    let filterData: any = [];

    for (let i = 0; i < 2; i++) {
      if (!!response.data[i].option_category) {
        const category_kr = response.data[i].name || "";
        const option_category = response.data[i]["option_category"];
        // filterData[category] = [];
        filterData.push({
          name: category_kr,
          eng_name: response.data[i].eng_name,
          options: [],
          id: response.data[i].id
        });
        let idx = 0;

        option_category.forEach((option: any) => {
          if (option.type > 1) {
            filterData[i].options.push({
              name: option.name,
              eng_name: option.eng_name,
              id: option.id,
              selects: [],
            });
            if (option.name === "연도") {
              filterData[i].options[idx].selects.push({
                maxVal: option.max_value,
                minVal: option.min_value,
                pivotVal: option.pivot_value,
              });
            }
            option["select_option"].forEach((select: any) => {
              filterData[i].options[idx].selects.push({
                name: select.name,
                eng_name: select.eng_name,
              });
            });
            idx += 1;
          }
        });
      }
    }

    const getFilterDataRes = {
      data: filterData,
      error: '',
    }
    return getFilterDataRes
  } catch(e: any) {
    console.error(e);
    const getCateRes = {
      data: null,
      error: '',
    }
    return getCateRes;
  }
}

export const getItemList = async ({
  selling_type,
  page_size,
  page,
  endDate,
  category,
  value_product,
  search,
  sort,
  tag,
  isDeleted,
}: GetItemListParams) => {
  try {
    const url = API_ENDPOINT +`/service/product/?selling_type=${selling_type || ""}&page_size=${page_size}&page=${page}&start_date__lte=${endDate || ""}&category=${category || ""}${value_product ? "&value_product=" + value_product : ""}${search? "&search=" + encodeURIComponent(search) : ""}${sort ? "&ordering=" + sort : ""}${tag ? "&tags=" + tag : ""}${"&is_deleted=" + encodeURIComponent(false)}`;
    const response = await axios.get(url);
    const res: GetItemListResType = {
      items: response.data,
    };
    return res;
  } catch (e) {
    const res: GetItemListResType = {
      items: [],
      error: e,
    };
    return res;
  }
};

export const getItemDetail = async (id:string, user?:any) : Promise<GetItemDetailResType> => {
  try{
    const verifyRes = await isNotLogin(user);
    let headers;
    if(verifyRes){
      headers = undefined;
    } else {
      headers = {
        headers: {
          'Authorization': `Bearer ${user.access}`
        }
      }
    }
    const response = await axios.get(API_ENDPOINT +`/service/product/${id}/`, headers);
    const res: GetItemDetailResType = {
      data:response.data
    }
    return res

  } catch (e){
    const res: GetItemDetailResType = {
      error:e,
    }
    return res;
  }
}


//상품등록 카테고리
export const getCategoryData = async() => {
  try{
    const response = await axios.get(API_ENDPOINT + '/service/category/');
    const categorys : CategoryData[] = [];
    response.data.forEach((item : any) => {
      const options_category : CategoryOptionData[] = [];

      item.option_category.forEach((item : any) => {
        if(item.name === "연도") {
          options_category.push({
            eng_name: item.eng_name,
            id: item.id,
            name: item.name,
            selects: [{
              max_value: item.max_value,
              min_value: item.min_value,
              pivot_value: item.pivot_value,
            }]
          })
        } else {
          options_category.push({
            eng_name: item.eng_name,
            name: item.name,
            id: item.id,
            selects: item.select_option
          })
        }
      })

      const category : CategoryData = {
        eng_name: item.eng_name,
        name: item.name,
        options: options_category
      }

      categorys.push(category);
    })
    return categorys;
  } catch(error: any) {
    return error;
  }
}

export const getExchangeRate = async () => {
  try{
    const response = await axios.get('https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD');
    const res: GetExchangeRateType = {
      data:response.data[0]
    }
    return res

  } catch (e){
    const res: GetExchangeRateType = {
      error:e,
    }
    return res;
  }
}

export const requestAcceptPayment = async (
  user: any,
  order_id: string,
  amount: number,
  purchases:any,
  paymentKey: string,
) => {
  if (await isNotLogin(user)) {
    return { status: 403, error: "Unathourization Error" };
  }
  try {
    if(purchases.length > 0){
      const response = await axios.post(API_ENDPOINT + "/accounts/v2/purchase-group/", {
        payment_key: paymentKey,
        order_id: order_id,
        payment_type: "NORMAL",
        amount: amount,
        user: user.user.id,
        purchases
      }, {
        headers:{
          'Authorization': `Bearer ${user.access}`,
        }
      });

      const res: GetExchangeRateType = {
        data: response.data[0],
      };
      return res;
    } else {
      return { status: 400, error: "Bad Request. There are not any product in List" };
    }
  } catch (e) {
    console.error(e)
    const res: GetExchangeRateType = {
      error: e,
    };
    return res;
  }
};

export const createCartItem = async (user: any, productId: number) => {
  try {
    const response = await axios.post(
      API_ENDPOINT + "/accounts/v2/cart/",
      {
        user: user.user.id,
        product: productId,
      },
      {
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
      }
    );
    const createCartItemRes : CreateCartResType = {
      status:200,
    }
    return createCartItemRes;
  } catch (error:any) {
    const createCartItemRes : CreateCartResType = {
      status:400,
      error:error.response.data,
    }
    return createCartItemRes;
  }
}

export const getBannerImages =  () => {
  try{
    const response = axios.get(API_ENDPOINT + "/service/banner/");
    return response

  } catch (e){
    const res: GetExchangeRateType = {
      error:e,
    }
    return res;
  }
}

export const getPresetData = async() : Promise<PresetData[]> => {
  try {
    const response = await axios.get(API_ENDPOINT + "/service/preset");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createBid = async (user:any, item?:VerticalCardType, bidPrice?: number, bidPriceAboutDollar?: number, bidCause?: string) => {
  try {
    const response = await axios.post(
      API_ENDPOINT + "/accounts/v2/bidding/",
      {
        user: user.user.id,
        product: item?.id,
        cause: bidCause,
        price: bidPrice,
        dollar_price: bidPriceAboutDollar
      },
      {
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
      }
    );
    return {
      data:response.data,
      error:''
    };
  } catch (error:any) {
    const createCartItemRes : CreateCartResType = {
      status:400,
      error:error.response.data,
    }
    return createCartItemRes;
  }
}

export const updateBid = async (user:any, item?:VerticalCardType, bidPrice?: number, bidPriceAboutDollar?: number, bidId?: number, bidCause?: string) => {
  try {
    const response = await axios.put(
      API_ENDPOINT + `/accounts/v2/bidding/${bidId}/`,
      {
        user: user.user.id,
        product: item?.id,
        price: bidPrice,
        cause: bidCause,
        dollar_price: bidPriceAboutDollar
      },
      {
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
      }
    );
    return {
      data:response.data,
      error:''
    };
  } catch (error:any) {
    const createCartItemRes : CreateCartResType = {
      status:400,
      error:error.response.data,
    }
    return createCartItemRes;
  }
}

export const getNoticeData =async (access: string) : Promise<NoticeData[]> => {
  try {
    const response = await axios.get(API_ENDPOINT + "/service/notice/", {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    })
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getOneNoticeData =async (access: string, id: number) : Promise<NoticeData> => {
  try {
    const response = await axios.get(API_ENDPOINT + `/service/notice/${id}`)
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getChart = async (product: any) => {
  const filterProduct = product.filter((e: any) => e.option.name === '스포츠' || e.option.name === '선수명')
  const array = filterProduct.map((e: any) => `product__value_product__value=${e.value}`);
  const finalURL = `/accounts/v2/purchase/?${array.join('&')}`;
  try {
    const response = await axios.get(API_ENDPOINT + finalURL);
    return response.data;
  } catch (error: any) {
    console.log(error)
  }
}

export const getTags = async () => {
  try {
    const response = await axios.get(API_ENDPOINT + '/service/tag');
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error: any) {
    console.log(error);
  }
}

export const getRecommendKeyword = async () => {
  try {
    const response = await axios.get(API_ENDPOINT + '/service/recommand-keyword');
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error: any) {
    console.log(error);
  }
}

export const getRecommendItemList = async () => {
  try {
    const response = await axios.get(API_ENDPOINT + '/service/recommand-product');
    if (response.status === 200) {
      return {
        items: response.data
      };
    } else {
      return {
        items: []
      };
    }
  } catch (error: any) {
    console.log(error);
  }
}

export const editProduct = async (id: any, user: any, formData: any) => {
  try {

    const response = await axios.patch(API_ENDPOINT + `/service/product/${id}/`, formData, {headers:{
        'Authorization': `Bearer ${user.access}`,
      }});
  } catch (error: any) {
    console.log(error);
  }
}

export const deleteProduct = async (id: any, user: any) => {
  try {
    return await axios.patch(API_ENDPOINT + `/service/product/${id}/`, {is_deleted: true}, {
      headers: {
        'Authorization': `Bearer ${user.access}`,
      }
    });
  } catch (error: any) {
    console.log(error);
  }
}

export const editCategory = async (id: any, user: any,  value: any, eng_value: any) => {
  try {
    return await axios.patch(API_ENDPOINT + `/service/category/value/${id}/`,
        {value: value, eng_value: eng_value},
      {
      headers: {
        'Authorization': `Bearer ${user.access}`,
      }
    });
  } catch (error: any) {
    console.log(error);
  }
}

export const editImage1 = async (id: any, user: any,  value: any) => {
  try {
    return await axios.patch(API_ENDPOINT + `/service/product/${id}/image1/`,
        { image1: value },
        {
          headers: {
            'Authorization': `Bearer ${user.access}`,
          }
        });
  } catch (error: any) {
    console.log(error);
  }
}
export const editImage2 = async (id: any, user: any,  value: any) => {
  try {
    return await axios.patch(API_ENDPOINT + `/service/product/${id}/image2/`,
        { image2: value },
        {
          headers: {
            'Authorization': `Bearer ${user.access}`,
          }
        });
  } catch (error: any) {
    console.log(error);
  }
}
export const editImage3 = async (id: any, user: any,  value: any) => {
  try {
    return await axios.patch(API_ENDPOINT + `/service/product/${id}/image3/`,
        { image3: value },
        {
          headers: {
            'Authorization': `Bearer ${user.access}`,
          }
        });
  } catch (error: any) {
    console.log(error);
  }
}