import { styled } from "styled-components";
import { colors } from "styled/colors";
import timer from "assets/timer.svg";
import timer_end from "assets/timer_end.svg";
import {Font_lg, Font_md} from 'styled/fonts';
import { useEffect, useState } from "react";
import { differenceInDays, differenceInMinutes, differenceInSeconds, differenceInHours } from "date-fns";
import be from "date-fns/esm/locale/be/index.js";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
interface ProgressIProps {
  step: string;
  candidatesCnt: number;
  endDate?: string;
  startDate?: string;
  is_pending?: boolean;
  is_selled?: boolean;
}

const AuctionStatusComponent = (props: ProgressIProps) => {

  const [timeStr, setTimeStr] = useState('00:00:00');
  const [percent, setPercent] = useState('0%');
  const language = useRecoilValue(langState);
  
  if(!(props.is_pending || props.is_selled)){
    const intervalId = setInterval(() => {
      if (props.endDate) {
        const endSecs = new Date(props.endDate || "");
        const nowSecs = new Date(Date.now());

        // 일, 시간, 분, 초 계산
        const days = differenceInDays(endSecs, nowSecs);
        const hours = String(differenceInHours(endSecs, nowSecs) % 24).padStart(
          2,
          "0"
        );
        const minutes = String(
          differenceInMinutes(endSecs, nowSecs) % 60
        ).padStart(2, "0");
        const seconds = String(
          differenceInSeconds(endSecs, nowSecs) % 60
        ).padStart(2, "0");
        if (Number(seconds) < 0) {
          setTimeStr("00:00:00");
        } else {

          setTimeStr(`${days}${language === 'ko-KR' ? '일' : 'Day'} ${hours}:${minutes}:${seconds}`);
        }

        // props에서 받은 startDate, endDate를 Date 객체로 변환합니다.
        const endDate = new Date(props.endDate);
        const startDate = new Date(props.startDate || "");
        if (startDate) {
          // 현재 날짜(nowDate)부터 종료 날짜(endDate)까지의 시간 차이 (초)
          const secondsToEndTime = differenceInSeconds(endDate, nowSecs);
          const betweenStartAndEnd = differenceInSeconds(endDate, startDate);

          const step = Math.round(
            (secondsToEndTime / betweenStartAndEnd) * 100
          );
          setPercent(step.toString() + "%");
        }
      }
    }, 1000);
  }
  
  return (
    <AuctionStatus>
      <ProgressBar percent={percent}/>
      <TextSection>
        <Timer>
          <img src={timeStr === "00:00:00" ? timer_end : timer} />
          &nbsp; <Font_lg color={timeStr === "00:00:00" ? "#A0AEC0" : "black"} isBold>{timeStr}</Font_lg>
        </Timer>
        <Font_md isBold color='#A0AEC0'>{props.candidatesCnt}{language === 'ko-KR' ? " 명 제안" : " bids"}</Font_md>
      </TextSection>
    </AuctionStatus>
  );
};

const ProgressBar = styled.div<{percent: string}>`
  margin-bottom: 0.5rem;
  width:100%;
  height: 4px;
  background: linear-gradient(to right, ${colors.GREEN[500]} ${props => props.percent}, ${colors.GREY[100]} ${props => props.percent});
`

const AuctionStatus = styled.div`
  margin-top: -6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2rem);
`

const Timer = styled.div`
  display: flex;
`


export default AuctionStatusComponent;