import styled from "styled-components";
import OutlineButtonComponent from "components/common/OutlineButtonComponent";
import { Size } from "types/types";
import SuccessContent from "components/common/SuccessContent";
import { useNavigate } from "react-router-dom";
import ButtonComponentGreen from "components/common/buttons/ButtonComponentGreen";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";

const SuccessPage = () => {
  const navigate = useNavigate();
  const handleButton = () => {
    navigate('/mypage');
  }

  const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

  return (
    <Success>
      <SuccessContent
        mainText={t("판매자 신청이 완료되었습니다!")}
        subText={t("신청 결과는 48시간 이내에 검토 후 \n 이메일로 안내될 예정입니다.")}/>
      <ButtonComponentGreen
      text={t("확인")}
      size={Size.LARGE}
      onClick={handleButton}
      />
    </Success>
  );
};

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  padding-bottom: 16px;
`

export default SuccessPage;