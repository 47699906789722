import {useEffect, useState} from "react";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import SignupBtnComponent from "components/common/buttons/SignupBtnComponent";
import login_logo from "assets/login_logo.svg";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import {Input, useDisclosure} from "@chakra-ui/react";
import styled from "styled-components";
import {Size} from "types/types";
import {colors} from "styled/colors";
import {Font_sm} from "styled/fonts";
import {useLocation, useNavigate} from "react-router-dom";
import {loginState, langState} from 'recoil/loginRecoil';
import {useSetRecoilState, useRecoilValue} from "recoil";
import {login} from "api/UserApi";
import ModalComponent from "components/common/ModalComponent";
import {useTranslation} from "react-i18next";
import Loading from "components/Loading";

const LoginPage = () => {

    const {isOpen, onOpen, onClose} = useDisclosure();
    const {t, i18n} = useTranslation("main");
    const language = useRecoilValue(langState)

    const location = useLocation();
    const navigate = useNavigate();
    const setLogin = useSetRecoilState(loginState);
    const [loading, setLoading] = useState(false);
    const GOOGLE_AUTH: any = process.env.REACT_APP_GOOGLE_AUTH;
    const APPLE_AUTH: any = process.env.REACT_APP_APPLE_AUTH;

    // value ------------------------------
    const [email, setEmail] = useState('');
    const handleEmailChange = (event: any) => setEmail(event.target.value)

    const [password, setPassword] = useState('');
    const handlePasswordChange = (event: any) => setPassword(event.target.value)

    const [isValid, setIsValid] = useState(false);
    // --------------------------------------
    // handle login function
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleLoginBtn = async () => {
        const loginRes: any = await login({email, password})
        if (loginRes.error !== '') {
            console.error("Login error:", loginRes.error);
            setIsError(true);
            setErrorMsg("아이디 혹은 비밀번호가 틀렸습니다.");
        } else {
            const {access, user} = loginRes;
            const loginData = {access, user};
            await setLogin(loginData);
            navigate('/')
        }
    }
    const checkMobile = () => {

        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.indexOf('android') > -1) {
            //안드로이드
            return "android";
        } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            //IOS
            return "ios";
        } else {
            //아이폰, 안드로이드 외
            return "other";
        }

    }

    const OauthHandler = async (type: string) => {
        await setLoading(true);
        if (!loading) {
            if (type === "google") {
                var mobile = /iphone|ipad|ipod|android/i.test(
                    navigator.userAgent.toLowerCase()
                );
                if (mobile) {
                    //모바일 처리
                    if (checkMobile() == "ios" && window.webkit != undefined) {
                        window.webkit.messageHandlers.oauth.postMessage(type);
                    }
                    // else if (
                    //     checkMobile() == "android" &&
                    //     window.Android != undefined
                    // ) {
                    //     window.Android.oauth(type);
                    // }
                    else {
                        window.location.href = GOOGLE_AUTH;
                    }
                } else {
                    //비 모바일 처리
                    window.location.href = GOOGLE_AUTH;
                }
            } else if (type == "apple") {
                var mobile = /iphone|ipad|ipod|android/i.test(
                    navigator.userAgent.toLowerCase()
                );

                if (mobile) {
                    //모바일 처리
                    if (checkMobile() == "ios") {
                        window.webkit.messageHandlers.oauth.postMessage(type);
                    } else if (checkMobile() == "android") {
                        window.location.href = APPLE_AUTH;
                    }
                } else {
                    //비 모바일 처리
                    window.location.href = APPLE_AUTH;
                }
            }
        }
    }

    useEffect(() => {
        setIsValid(Boolean(email && password));
    }, [email, password])

    useEffect(() => {
        i18n.changeLanguage(language);
        const sch = location.search;
        const params = new URLSearchParams(sch);
        const param: string = params.get('dupSignup') || '';

        if (param === 'true')
            onOpen();
    }, [])

    return (
        <Login>
            <ProcessHeader isSearchForm={false}/>
            <MiddleWrapper>
                <ImageWrapper>
                    <img src={login_logo}/>
                </ImageWrapper>
                <InputWrapper>
                    <Input
                        size="lg"
                        width="calc(100% - 2rem)"
                        placeholder={t("이메일(아이디)")}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <Input
                        size="lg"
                        width="calc(100% - 2rem)"
                        placeholder={t("비밀번호")}
                        value={password}
                        type="password"
                        onChange={handlePasswordChange}
                    />
                </InputWrapper>
                {isError && (
                    <ErrMsg>
                        <Font_sm color="#E53E3E">{t(errorMsg)}</Font_sm>
                    </ErrMsg>
                )}
                <ButtonWrapper>
                    <ButtonComponent
                        text={t("로그인")}
                        isValid={isValid}
                        size={Size.LARGE}
                        onClick={handleLoginBtn}
                    />
                    <StrForgotPwd onClick={() => navigate("/findpwd/email")}>
                        {t("비밀번호를 잊으셨나요?")}
                    </StrForgotPwd>
                </ButtonWrapper>
            </MiddleWrapper>
            <OauthWrapper>
                <SignupBtnComponent
                    onClick={() => OauthHandler("google")}
                    type={"google"}
                    from="login"
                    isDisabled={loading}
                />
                <SignupBtnComponent
                    onClick={() => OauthHandler("apple")}
                    type={"apple"}
                    from="login"
                    isDisabled={loading}
                />
            </OauthWrapper>
            <ModalComponent
                isOpen={isOpen}
                onClose={onClose}
                content={t(`이미 가입된 계정입니다.`)}
                bottomContent={t("이메일로 로그인해주세요!")}
                leftBtnString={"취소"}
                rightBtnString={"승인"}
                onClickSubmit={() => {
                }}
            />
            {/* {loading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )} */}
        </Login>
    );
};

const ImageWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const MiddleWrapper = styled.div`
  flex: 1;
`

const InputWrapper = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const ButtonWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StrForgotPwd = styled.p`
  margin-top: 24px;
  color: ${colors.GREY[600]};
  font-size: 14px;
`

const OauthWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 58px;
`

const Login = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`
const ErrMsg = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  padding-left: 1rem;
`

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
`
export default LoginPage;