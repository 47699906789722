import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import ForSaleHistory from "../../components/sellerHistory/ForSaleHistory";
import SoldHistory from "../../components/sellerHistory/SoldHistory";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { colors } from "styled/colors";
import {ToastContainer} from "react-toastify";
import notify from "../../components/common/toast/ToastComponent";
import {deleteProduct} from "../../api/api";
import {deleteProductState} from "../../recoil/sellerRecoil";

const SellerHistoryPage = () => {

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);
    const [isDelete, setIsDelete] = useRecoilState(deleteProductState);
    const navigate = useNavigate();
    
    const [tabIndex, setTabIndex] = useState(0);
    const { id } = useParams();
  
    useEffect(()=> {
        i18n.changeLanguage(language);
        if (isDelete) {
            notify(t('삭제가 완료되었습니다.'));
            setIsDelete(false);
        }
    },[])

    useEffect(()=> {
        setTabIndex(Number(id));
    },[id]);

    return (
      <SellerHistoryWrapper>
          <ToastContainer />
          <ProcessHeaderComponent
          title={t("일반 판매 내역")}
          isSearchForm={false}
          path="/mypage"
        />
        <Tabs
          variant="line"
          size="md"
          isFitted
          colorScheme="gray"
          index={tabIndex}
        >
          <TabList>
            <TabItem
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => navigate("/seller-history/0")}
            >
              {t("판매중")}
            </TabItem>
            <TabItem
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => navigate("/seller-history/1")}
            >
              {t("판매완료")}
            </TabItem>
          </TabList>
          <TabPanels>
            <TabPanel padding={0}>
              <ForSaleHistory />
            </TabPanel>
            <TabPanel padding={0}>
              <SoldHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </SellerHistoryWrapper>
    );
};

const SellerHistoryWrapper = styled.div`
`
const TabItem = styled(Tab)`
  font-weight: 400;
`

export default SellerHistoryPage;