import styled from "styled-components";
import success from 'assets/success.svg';
import { Font_lg, Font_md } from "styled/fonts";

interface contentTypes {
    mainText: string;
    subText: string;
}

const SuccessContent = (props: contentTypes) => {

    const formattedText = props.subText.split("\n").map((line, index) => (
        <div key={index}>
          {line}
          <br />
        </div>
      ));
    return (
        <Contents>
        <img src={success} />
        <Font_lg isBold>{props.mainText}</Font_lg>
        <Subtext>{formattedText}</Subtext>
      </Contents>
    )
};

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: calc(100% - 2rem);
`

const Subtext = styled(Font_md)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`


export default SuccessContent;