import { useEffect, useState } from "react";
import styled from "styled-components";
import filter from "assets/filter.svg";
import filter_green from "assets/filter_green.svg";
import expand_more from "assets/expand_more.svg";
import { Font_sm } from "styled/fonts";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { filterDataState, selectedFilterDataState, selectedYear } from "recoil/homeRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";

interface FilterIProps {
  category: string;
  isAuction: string;
  isBuy: string;
}

const FilterComponent = (props: FilterIProps) => {
  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState)
  
  const filterData = useRecoilValue(filterDataState);
  const isKor = language === "ko-KR";

  const selectedFilterData: Array<string> = useRecoilValue(selectedFilterDataState);
  let cateIdx = props.category === "card" ? 0 : 1;
  const curYear = useRecoilValue(selectedYear);

  const navigate = useNavigate();
  
  const generateFilterAttr = (targetOption: string) => {
    if((targetOption === "연도" || targetOption === "Year") && selectedFilterData.includes(targetOption)){
      return (
        <FilterAttr
          isSelected={true}
          onClick={() => navigate(`/filter?category=${props.category === "card" ? 1 : 2}&isAuction=${props.isAuction}&isBuy=${props.isBuy}`)}
        >
          <Font_sm isBold color={"#0AB88C"}>
            {curYear[0]} ~ {curYear[1]}년
          </Font_sm>
          <img src={expand_more} alt="expand_more" />
        </FilterAttr>
      );
    }
    let selects: any[] = [];
    cateIdx = props.category === "card" ? 0 : 1;
    filterData[cateIdx].options.forEach((option: any) => {
      if (option.name === targetOption || option.eng_name === targetOption) {
        option.selects.forEach((select: any) => {
          if (selectedFilterData.includes(isKor ? option.id + "_" + select.name : option.id + "_" + select.eng_name)){
            selects.push(isKor ? select.name : select.eng_name);
          }
        });
      }
    });
    const selectCnt = selects.length;
    let text = targetOption;
    if (selectCnt > 1) text = targetOption + " " + selects.length.toString();
    else if (selectCnt == 1) text = selects[0];
    else text = targetOption;
    if (targetOption === '그레이딩' || targetOption === '제조사' || targetOption === 'Grading' || targetOption === 'Manufacturer') {
      return (<></>);
    }
    if ((targetOption === '연도' || targetOption === 'Year') && props.category === "collection") {
      return (<></>);
    }
    return (
      <FilterAttr
        isSelected={selectCnt > 0}
        onClick={() => navigate(`/filter?category=${props.category === "card" ? 1 : 2}&isAuction=${props.isAuction}&isBuy=${props.isBuy}`)}
      >
        <Font_sm isBold color={selectCnt >= 1 ? "#0AB88C" : ""}>
          {text}
        </Font_sm>
        <img src={expand_more} alt="expand_more" />
      </FilterAttr>
    );
  };

  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])

  return (
    <Filter className="FilterComponent">
      <FilterIcon 
        onClick={() => navigate(`/filter?category=${props.category === "card" ? 1 : 2}&isAuction=${props.isAuction}&isBuy=${props.isBuy}`)}
        isSelected={selectedFilterData.length > 0}>
        <img
          src={selectedFilterData.length > 0 ? filter_green : filter}
          alt="filter"
        />
      </FilterIcon>

      <FilterList>
        {filterData && Object.keys(filterData).length !== 0 &&
          filterData[cateIdx].options.map((option: any) =>
            generateFilterAttr(language === "ko-KR" ? option.name : option.eng_name)
          )}
      </FilterList>
    </Filter>
  );
};

const Filter = styled.div`
  display: flex;
  gap: 0.5rem;
  border-bottom: 8px solid #f7fafc;
  padding: 0 1rem 1.5rem 1rem;
  width: 100%;
  overflow-x: auto; /* 가로 스크롤을 활성화 */
`;

const FilterIcon = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border: 1px solid ${(props) => (props.isSelected ? "#0AB88C" : "#E2E8F0")};
  min-width: 32px;
  height: 32px;
`;

const FilterList = styled.div`
  display: flex;
  flex-wrap: nowrap; /* 자식 요소가 줄 바꿈되지 않도록 설정 */
  gap: 0.5rem;
`;

const FilterAttr = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 9999px;
  border: 1px solid ${(props) => (props.isSelected ? "#0AB88C" : "#E2E8F0")};
  white-space: nowrap; /* 텍스트가 길어도 줄 바꿈되지 않도록 설정 */
  width: auto;
  word-break:break-all;
  height: 32px;
`;

export default FilterComponent;
