import { styled } from "styled-components";
import { Font_md, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import next_img from "assets/chevron_right.svg";
import { checkAndConvertCurrency, fixedExchangePrice, formatCurrency } from 'api/StringUtils';
import { useNavigate } from "react-router-dom";
import { loginState } from "recoil/loginRecoil";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { exchangeRateState } from "recoil/homeRecoil";

interface CardType {
    date: string;
    name: string;
    price: number;
    productPrice?: number;
    auctionStatus: string;
    endDate?: string;
    image: string;
    onClick?: Function;
    currency: string;
    dollarPrice?: number;
}

const BiddingCard = (props: CardType) => {
    const tmpFunction = () => {};
    const buttonHandler = props.onClick || tmpFunction;
    const navigate = useNavigate();
    const cutText = (text : string) => {
        if(text.length > 30) {
            return text.substring(0,30) + "...";
        }
        return text;
    }

    const exchangeRate = useRecoilValue(exchangeRateState);

    const chooseAuctionStatusColor = (status : string) => {
        if(status === "선정") {
            return colors.GREEN[500];
        } else if(status === "제안종료") {
            return colors.GREY[400];
        }
        return colors.GREY[700];
    }

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])



    return (
        <BiddingCardWrapper onClick={() => {buttonHandler()}}>
            <ListTitle>
                <Font_sm>{props.date} {t("제안")}</Font_sm>
                <Arrow src={next_img}/>
            </ListTitle>
            <ListMain>
                <ProductImg src={props.image}/>
                <ListInfo>
                    <ProductName>{cutText(props.name)}</ProductName>
                    {props.currency === 'USD' ?
                    <Font_sm isBold>{checkAndConvertCurrency(language, (props.productPrice || 0) / fixedExchangePrice, props.currency)}</Font_sm> :
                    <Font_sm isBold>{checkAndConvertCurrency(language, (props.productPrice || 0), props.currency)}</Font_sm>}
                    {/* {props.currency ?
                    <Font_sm isBold>{checkAndConvertCurrency(language, props.price, props.currency, exchangeRate?.basePrice)}</Font_sm> :
                    <Font_sm isBold>{language !== 'ko-KR' ? checkAndConvertCurrency(language, props.dollarPrice ? props.dollarPrice : 0, 'USD') : checkAndConvertCurrency(language, props.price, 'KRW')}</Font_sm>} */}
                    <EndDateWrapper>
                        <Font_sm isBold color={chooseAuctionStatusColor(props.auctionStatus)}>{t(props.auctionStatus)}</Font_sm>
                        {props.endDate && <Font_sm isBold>{props.endDate}</Font_sm>}
                    </EndDateWrapper> 
                </ListInfo>
            </ListMain>
        </BiddingCardWrapper>
    )
};

const BiddingCardWrapper = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const ListTitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const Arrow = styled.img`
`

const ListMain = styled.div`
    display: flex;
    gap: 1rem;
`

const ProductImg = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    object-fit: cover;
`

const ListInfo = styled.div`
    flex: 1;

`

const ProductName = styled(Font_md)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 
`

const EndDateWrapper = styled.div`
    display: flex;
    gap: 4px;
`



export default BiddingCard;