import styled from "styled-components";
import PasswordValidComponent from "components/common/PasswordValidComponent";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEqualPasswordState, isValidPasswordState, passwordState } from "recoil/signupRecoil";
import { isChangeSuccessState } from "recoil/mypageRecoil";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Font_sm } from "styled/fonts";
import axios from "axios";
import { loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";

const isShowBtnStyle = {
  right:"12px",
}

const ChangePwdPage = () => {
  
  const isEqualPassword = useRecoilValue(isEqualPasswordState);
  const isValidPassword = useRecoilValue(isValidPasswordState);
  const newPassword = useRecoilValue(passwordState);
  const setIsChangeSuccess = useSetRecoilState(isChangeSuccessState);
  const navigate = useNavigate();
  const [isShowPwd, setIsShowPwd] = useState(false);
  const [curPwd, setCurPwd] = useState('');
  const [curPwdErrMsg, setCurPwdErrMsg] = useState('');
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const user :any = useRecoilValue(loginState);

  const { t, i18n } = useTranslation("mypage");
  const language = useRecoilValue(langState);

  useEffect(()=> {
    i18n.changeLanguage(language);
  },[])
  useEffect(()=> {
    setCurPwdErrMsg('');
  },[curPwd])
  const HandleClick = async () => {
    try {
      await axios.post(API_ENDPOINT + '/accounts/v2/password/change/', {
        current_password:curPwd,
        password1:newPassword,
        password2:newPassword,
      }, {
        headers: {Authorization: `Bearer ${user.access}`},
      });
      navigate('/mypage/personal');
      setIsChangeSuccess(1);
    } catch (error: any) {
      console.error("Change Pwd Error:", error);
      if(error.response.data.current_password)
        setCurPwdErrMsg(t("비밀번호가 틀렸습니다."))
      // console.log(error.response.data);
    }
  };
  const handleChangePwd = async (event: any) => setCurPwd(event.target.value);
  return (
    <ChangePwd>
      <ProcessHeader title={t("비밀번호 변경")} isSearchForm={false} />
      <InputWrapper isError={curPwdErrMsg ? true : false}>
        <InputGroup className='origin' size="lg">
          <Input
            size="lg"
            placeholder={t("현재 비밀번호")}
            type={isShowPwd ? "text" : "password"}
            value={curPwd}
            onChange={handleChangePwd}
            // isInvalid={(!isValid && !(nickname.length === 0))}
          />
          <InputRightElement>
            <Button
              style={isShowBtnStyle}
              size="xs"
              colorScheme="gray"
              onClick={() => setIsShowPwd(!isShowPwd)}
              variant="outline"
            >
              {t(isShowPwd ? "숨기기" : "보기")}
            </Button>
          </InputRightElement>
        </InputGroup>
        {curPwdErrMsg !== '' && (
          <ErrMsg>
            <Font_sm color="#E53E3E">
              {curPwdErrMsg}
            </Font_sm>
          </ErrMsg>
        )}
        <PasswordValidComponent isChange={true} />
      </InputWrapper>
      <ButtonWrapper>
        <ButtonComponent
          onClick={HandleClick}
          isValid={isEqualPassword && isValidPassword}
          text={t("비밀번호 변경")}
          size={Size.LARGE}
        />
      </ButtonWrapper>
    </ChangePwd>
  );
};

const ChangePwd = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div<{isError:boolean}>`
  margin:32px 0px 32px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  .origin{
    width: calc(100% - 2rem);
    margin-bottom:${props => props.isError ? "":"1rem"};
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
`

const ErrMsg = styled.div`
  width: 100%;
  padding: 0.5rem 0 0 1rem;
  margin-bottom:1rem;
`;

export default ChangePwdPage;