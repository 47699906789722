import { useEffect, useState } from "react";
import styled from "styled-components";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import EmailInputComponent from "components/common/EmailValidComponent";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import SecretKeyComponent from "components/common/SecretKeyComponent";
import { useRecoilValue, useRecoilState } from "recoil";
import { countdownState, emailState, secretState } from "recoil/signupRecoil";
import { Size } from "types/types";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { Font_lg } from "styled/fonts";
import { sendEmailForValid } from "api/UserApi";

const EmailPage = () => {

  const { t, i18n } = useTranslation("signup");
  const language = useRecoilValue(langState)

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const [countdown, setCountdown] = useRecoilState(countdownState);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidSecret, setIsValidSecret] =  useState(true);

  const [emailErrMsg, setEmailErrMsg] = useState('이미 가입된 이메일입니다.');
  const [secretErrMsg, setSecretErrMsg] = useState('유효하지 않은 인증번호 입니다.');

  const [email, setEmail] = useRecoilState(emailState);
  const [secret, setSecret] = useRecoilState(secretState);
  const [isSend, setIsSend] = useState(false);

  useEffect(()=>{
    setSecret('');
    setEmail('');
    setCountdown('10:00');
    i18n.changeLanguage(language);
  }, [])

  useEffect(()=> {
    if(secret.length == 6)
      setIsValidSecret(true);
    setIsValidEmail(true);
  },[email, secret])

  useEffect(()=>{
    if(countdown == '00:00'){
      setIsValidSecret(false);
      setSecretErrMsg('인증 시간이 지났습니다. 인증 메일을 재발송해주세요.');
    }
    else if(countdown == '10:00'){
      setIsValidSecret(true);
      setSecretErrMsg('유효하지 않은 인증번호 입니다.');
    }
  }, [countdown])

  const navigate = useNavigate();

  const onClickResendBtn = () => {
    HandleSendBtn();
  }

  const HandleConfirmBtn= () => {
    // call authorization
    navigate('/signup/password');
  };
  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  const HandleSendBtn = async () => {
    const response = await sendEmailForValid(email, true,language.indexOf('ko') === -1);
    if(response.error){
      setEmailErrMsg(response.error);
      setIsValidEmail(false);
    } else {
      await setIsSend(true);
    }
  };

  const HandleClick = async () => {
    if(isSend){
      try{
        const res = await axios.put(apiEndpoint + `/accounts/email-auth/email/${secret}/`, {
          email:email,
          key:secret,
          is_signup: true
        });
        HandleConfirmBtn();
      } catch(e:any) {
        setSecretErrMsg(e.response.data.email[0])
        setIsValidSecret(false);
      }
    }
    else{
      HandleSendBtn();
    }
  }

  return (
    <Email>
      <ProcessHeader isSearchForm={false} title={t("회원가입")} stepPercent={50} />
      <StringWrapper>
        <Contents>
          {i18n.language === "ko-KR" ? (
            <>
              <span>가입할 </span>
              <span className="highlight">이메일 </span>
              <span>계정을</span>
              <p>인증해주세요.</p>
            </>
          ) : (
            <Font_lg isBold>Please verify your email address</Font_lg>
          )}
        </Contents>
      </StringWrapper>
      <InputWrapper>
        <EmailInputComponent
          isSend={isSend}
          errMsg={t(emailErrMsg)}
          isValidEmail={isValidEmail}
        />
        {isSend && (
          <SecretKeyComponent
            isValidSecret={isValidSecret}
            errMsg={t(secretErrMsg)}
            isSend={false}
            sendEmailAgain={() => onClickResendBtn()}
          />
        )}
      </InputWrapper>
      <ButtonWrapper>
        <ButtonComponent
          text={t(isSend ? '인증번호 확인' : '인증 메일 발송')}
          isValid={Boolean(email) && isValidEmail && isValidSecret}
          size={Size.LARGE}
          onClick={HandleClick}
        />
      </ButtonWrapper>
    </Email>
  );
};

const Email = styled.div`

`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 35px;
  width:100%;
  margin:0px 16px 24px 0px;
`

const ButtonWrapper = styled.div`
  display:flex;
  justify-content: center;
`

const StringWrapper = styled.div`
  margin: 25px 1rem 25px 1rem;
  font-size: 20px;
  font-weight: 400;
  .highlight {
    font-weight: 600;
  }
`;

const Contents = styled.div`
  width: 328px;
`

export default EmailPage;