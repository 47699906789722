const SearchSvgComponent = ({ isClicked }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4766 11.4716H12.6866L12.4066 11.2016C13.6066 9.80157 14.2266 7.89157 13.8866 5.86157C13.4166 3.08157 11.0966 0.861573 8.29658 0.521573C4.06658 0.00157288 0.506578 3.56157 1.02658 7.79157C1.36658 10.5916 3.58658 12.9116 6.36658 13.3816C8.39658 13.7216 10.3066 13.1016 11.7066 11.9016L11.9766 12.1816V12.9716L16.2266 17.2216C16.6366 17.6316 17.3066 17.6316 17.7166 17.2216C18.1266 16.8116 18.1266 16.1416 17.7166 15.7316L13.4766 11.4716ZM7.47658 11.4716C4.98658 11.4716 2.97658 9.46157 2.97658 6.97157C2.97658 4.48157 4.98658 2.47157 7.47658 2.47157C9.96658 2.47157 11.9766 4.48157 11.9766 6.97157C11.9766 9.46157 9.96658 11.4716 7.47658 11.4716Z"
      fill={ isClicked ? '#0AB88C' : 'black' }
    />
  </svg>
);

export default SearchSvgComponent;
