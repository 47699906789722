import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import RadioButtons from "components/common/RadioButtons";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import styled from "styled-components";
import { Font_md, Font_sm, Font_xl } from "styled/fonts";
import { useState } from "react";
import { Size } from "types/types";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { categoryState, sellingTypeState } from "recoil/addProductRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";

const ChooseCategory = () => {
    const [sellingType, setSellingType] = useRecoilState(sellingTypeState);
    const [category, setCategory] = useRecoilState(categoryState);

    const navigate = useNavigate();
    const input = [sellingType, category];
    const isValid = input.every(input => input != undefined);

    const { t, i18n } = useTranslation("addProduct");
    const language = useRecoilValue(langState);

    useEffect(()=> {
        i18n.changeLanguage(language);
        setCategory(undefined);
        setSellingType(undefined);
    },[])

    return (
        <ChooseCategoryWrapper>
            <ProcessHeaderComponent
            title={t("상품등록")}
            isSearchForm={false}
            path="/mypage"
            />
            <MainWrapper>
                <Title>
                    <Font_xl>{t("판매 방식과 상품 카테고리를")}</Font_xl>
                    <Font_xl>{t("선택해주세요.")}</Font_xl>
                </Title>
                <RadioButtons
                title={t("카테고리")}
                firstButton={t("스포츠 카드")}
                secondButton={t("스포츠 컬렉션")}
                item={category}
                setItem={setCategory}/>
                <RadioButtons
                title={t("판매 방식")}
                firstButton={t("즉시 판매하기")}
                secondButton={t("가격 제안받기")}
                item={sellingType}
                setItem={setSellingType}/>
                {sellingType == 1 &&
                <InformationWrapper>
                    <Font_md isBold>{t("가격 제안은 어떻게 이뤄지나요?")}</Font_md>
                    <InformationTable>
                        <Information>  • {t("구매를 원하는 사람들에게 가격을 제안 받을 수 있습니다.")}</Information>
                        <Information>  • {t("제안이 마감된 후에 가격 제안을 확인할 수 있습니다.")}</Information>
                        <Information>  • {t("마음에 드는 가격 제안을 선택하여 판매할 수 있습니다.")}</Information>
                </InformationTable>
                </InformationWrapper>}
            </MainWrapper>
            <ButtonWrapper>
                <ButtonComponent
                    isValid={isValid}
                    text={t("다음")}
                    size={Size.LARGE}
                    onClick={()=> {navigate('/add-product/info')}}
                    />
            </ButtonWrapper>
        </ChooseCategoryWrapper>
    )
}

const ChooseCategoryWrapper = styled.div`
`

const MainWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
`

const Title = styled.div`
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const InformationTable = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`

const Information = styled(Font_sm)`
    white-space: pre-wrap
`



export default ChooseCategory;