import { Routes, Route } from "react-router-dom";
import AuctionHistoryPage from "./AuctionHistoryPage";
import BiddingDetailPage from "./BiddingDetailPage";
import BidDetailPage from "./BidDetailPage";

const AuctionHistoryRouter = () => {
    return (
        <Routes>
            <Route path="/auction-history/:id" element={<AuctionHistoryPage/>}/>
            <Route path="/bidding-detail/:id" element={<BiddingDetailPage/>}/>
            <Route path="/bid-detail/:id" element={<BidDetailPage/>}/>
        </Routes>
    );
};

export default AuctionHistoryRouter;