import { styled } from "styled-components";
import { Input } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from "recoil";
import { emailState } from "recoil/signupRecoil";
import { Font_sm } from "styled/fonts";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";

interface EmailInputIProps {
  isValidEmail:boolean,
  errMsg?:string,
  isSend:boolean,
}

const EmailInputComponent = (props: EmailInputIProps) => {

  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState);
  
  const [email, setEmail] = useRecoilState(emailState);
  const handleEmailInput = (event: any) => setEmail(event.target.value);
  console.log(props.errMsg);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);
  
  return (
    <EmailInput>
      <Input
        size="lg"
        style={{width: 'calc(100% - 2rem)'}}
        placeholder={t("이메일")}
        value={email}
        onChange={handleEmailInput}
        isInvalid={!props.isValidEmail}
        variant={props.isSend ? "filled" : 'outline'}
        disabled={props.isSend}
      />
      {!props.isValidEmail ? <ErrMsg><Font_sm color='#E53E3E'>{props.errMsg}</Font_sm></ErrMsg> : <></>}
    </EmailInput>
  );
};

const EmailInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`

const ErrMsg = styled.div`
  width:100%;
  padding-left: 1rem;
`

export default EmailInputComponent;
