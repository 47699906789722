import axios from "axios";
import {access} from "fs";
import {LoginType, LoginResType, CartData, AddressData} from "types/types";
import {passwordState} from "recoil/signupRecoil";
import {UpdateUserType} from "types/types";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const login = async (props: LoginType) => {
    try {
        const response = await axios.post(API_ENDPOINT + '/accounts/login/', {
            email: props.email,
            password: props.password,
        });

        const addrResPromise = getUserAddress(response.data.access); // 유저 주소를 가져오는 Promise

        const loginRes: LoginResType = {
            access: response.data.access,
            user: {
                ...response.data.user,
                addresses: await addrResPromise, // Promise가 완료될 때까지 기다립니다.
            },
            error: '',
        };

        return loginRes;
    } catch (error: any) {
        const loginRes: LoginResType = {
            access: '',
            user: '',
            error: error.response.data,
        };
        return loginRes;
    }
};


export const authAccessToken = async (token: string) => {
    try {
        if (!token)
            return {data: false};
        await axios.post(API_ENDPOINT + '/accounts/token/verify/', {token});
        return {data: true};
    } catch (e) {
        console.error(e);
        return {
            data: false,
            error: e
        }
    }
}

export const getUserInfo = async (access?: string) => {
    try {
        const response = await axios.get(API_ENDPOINT + '/accounts/user/', {
            headers: {
                'Authorization': `Bearer ${access}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        const addresses = await getUserAddress(access || "");
        if (addresses.error) {
            return {
                data: {...response.data, addresses: []},
                error: undefined
            };
        } else {
            return {
                data: {...response.data, addresses},
                error: undefined
            };
        }

    } catch (error: any) {
        return {
            data: undefined,
            error: error
        };
    }
}

export const updateUserInfo = async (props: UpdateUserType) => {
    try {
        const response = await axios.patch(API_ENDPOINT + '/accounts/user/', props.formData, {
            headers: {
                'Authorization': `Bearer ${props.access}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        const addresses = await getUserAddress(props.access || "");
        if (addresses.error) {
            return {
                data: {...response.data, addresses: []},
                error: undefined
            };
        } else {
            return {
                data: {...response.data, addresses},
                error: undefined
            };
        }
    } catch (error: any) {
        return error;
    }
}

export const googleLogin = async (code: string) => {
    try {
        const data = await axios.post(API_ENDPOINT + '/accounts/social/google/login/', {
            code,
        })
        return data;
    } catch (error: any) {
        console.error(error);
    }
}

export const logout = async (access: string) => {
    try {
        const data = await axios.post(API_ENDPOINT + '/accounts/logout/', {
            headers: {
                'Authorization': `Bearer ${access}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return data;
    } catch (error: any) {
        return error;
    }
}

export const getCartData = async (access: string): Promise<CartData[]> => {
    try {
        const response = await axios.get<CartData[]>(API_ENDPOINT + '/accounts/v2/cart/', {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        })
        return response.data;
    } catch (error: any) {
        return error;
    }
}

export const deleteCartData = async (access: string, id: number) => {
    try {
        await axios.delete(API_ENDPOINT + `/accounts/v2/cart/${id}`, {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        })
    } catch (error: any) {
    }
}

export const getAddressData = async (access: string): Promise<AddressData> => {
    try {
        const response = await axios.get(API_ENDPOINT + '/accounts/v2/address/', {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        })
        return response.data[0];
    } catch (error: any) {
        throw error;
    }
}

export const createAddressData = async (access: string, props: AddressData): Promise<AddressData> => {
    const {address, detail_address, name, phone, user, last_name} = props;
    try {
        const response = await axios.post(API_ENDPOINT + '/accounts/v2/address/',
            {
                address,
                detail_address,
                name,
                phone,
                user,
                last_name: last_name
            },
            {
                headers: {
                    'Authorization': `Bearer ${access}`
                }
            })
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const updateAddressData = async (access: string, props: AddressData): Promise<AddressData> => {
    const {id, address, detail_address, name, phone, user, last_name} = props;
    try {
        const response = await axios.patch(API_ENDPOINT + `/accounts/v2/address/${id}/`,
            {
                address,
                detail_address,
                name,
                phone,
                user,
                last_name: last_name
            },
            {
                headers: {
                    'Authorization': `Bearer ${access}`
                }
            })
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const sendEmailForValid = async (email: string, isSignup: boolean, isEn: boolean = false) => {
    try {
        // POST 요청은 body에 실어 보냄
        await axios.post(API_ENDPOINT + "/accounts/email-auth/email/?is_en=" + isEn, {
            email: email,
            is_signup: isSignup,
        });
        return {
            data: 200,
            error: null,
        }
    } catch (e: any) {
        return {
            data: null,
            error: e.response.data.email[0]
        }
    }
}

export const validSecretForReset = async (email: string, secret: string, isEn: boolean = false) => {
    try {
        const res = await axios.put(
            API_ENDPOINT + `/accounts/email-auth/email/${secret}/`,
            {
                email: email,
                key: secret,
            }
        );
        return {
            data: 200,
            error: null,
        };
    } catch (e: any) {
        return {
            data: null,
            error: e.response.data.email[0],
        };
    }
};

export const setNewPassword = async (
    password: string,
    passwordForValid: string,
    secret: string
) => {
    //http://api.list-it.xyz/api/accounts/email-auth/password/reset/
    try {
        const res = await axios.post(
            API_ENDPOINT + `/accounts/email-auth/password/reset/`,
            {
                new_password1: password,
                new_password2: passwordForValid,
            },
            {
                headers: {Authorization: `Email ${secret}`},
            }
        );
        return {
            data: 200,
            error: null,
        };
    } catch (e: any) {
        return {
            data: null,
            error: e.response.data.email[0],
        };
    }
};

export const getUserAddress = async (access: string) => {
    try {
        const response = await axios.get(API_ENDPOINT + `/accounts/v2/address/`, {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        });
        return response.data;
    } catch (error: any) {
        return {error};
    }
}

export const inactiveUserAccount = async (access: string, userId: string) => {
    try {
        const response = await axios.delete(API_ENDPOINT + `/accounts/v2/withdraw/${userId}/`,
            {
                headers: {
                    'Authorization': `Bearer ${access}`
                }
            })
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const appleLogin = async (idToken: string, code: string | null) => {
    let body: any = {
        access_token: idToken,
        code: code,
        id_token: idToken
    };
    if (code === null || code === "") {
        body = {
            access_token: idToken,
            id_token: idToken
        }
    }
    try {
        const response = await axios.post(API_ENDPOINT + '/accounts/social/apple/login/', body)

        const loginRes: LoginResType = {
            access: response.data.access,
            user: response.data.user,
            error: '',
        }
        return loginRes;
    } catch (error: any) {
        const loginRes: LoginResType = {
            access: '',
            user: '',
            error: error.response.data['non_field_errors'][0],
        }
        return loginRes;
    }
}