// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
  width: calc(100% - 2rem);
  background-color: #EDF2F7;
  height: 3rem;
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 16px;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    /* margin-bottom: 1rem; */
    margin : auto;
    margin-bottom: 38px;
  }
}
@media only screen and (min-width: 481px) {
  .Toastify__toast {
    width: 368px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/toast/custom.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;EACrB;AACF;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".Toastify__toast {\n  width: calc(100% - 2rem);\n  background-color: #EDF2F7;\n  height: 3rem;\n  padding: 12px 16px;\n  border-radius: 6px;\n  font-size: 16px;\n}\n\n@media only screen and (max-width : 480px) {\n  .Toastify__toast {\n    /* margin-bottom: 1rem; */\n    margin : auto;\n    margin-bottom: 38px;\n  }\n}\n@media only screen and (min-width: 481px) {\n  .Toastify__toast {\n    width: 368px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
