import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './custom.css';

const notify = (msg:string) => {
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_CENTER, 
    autoClose: 2000,
    hideProgressBar: true,
  });
};

export default notify;