import styled from "styled-components";
import { colors } from "styled/colors";

const basicColor = colors.GREY[800]

interface fontTypes {
    isBold?: boolean;
    color?: string;
}

export const Font_3xl = styled.p<fontTypes>`
    font-size: 30px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`

export const Font_2xl = styled.p<fontTypes>`
    font-size: 24px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`

export const Font_xl = styled.p<fontTypes>`
    font-size: 20px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`

export const Font_lg = styled.p<fontTypes>`
    font-size: 18px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`

export const Font_md = styled.p<fontTypes>`
    font-size: 16px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`

export const Font_sm = styled.p<fontTypes>`
    font-size: 14px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`

export const Font_xs = styled.p<fontTypes>`
    font-size: 12px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`

export const Font_2xs = styled.p<fontTypes>`
    font-size: 11px;
    font-weight: ${(props) => (props.isBold !== undefined ? '600' : '400')};
    color: ${(props) => props.color || basicColor };
`