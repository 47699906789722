import styled from "styled-components";
import {useTranslation} from "react-i18next";

interface PropsType {
    sellingType: number;
}

const SellingType = (props: PropsType) => {
    const { sellingType } = props;
    const {t, i18n} = useTranslation("main");

    return (
        <ButtonContainer sellingType={sellingType}>
            { sellingType === 1 ? t('즉시구매') : t('가격제안')}
        </ButtonContainer>
    )
}

const ButtonContainer = styled.div<{ sellingType: number }>`   
  display: flex;
  width: fit-content;
  padding: 0 4px;
  height: 20px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  background-color: ${(props) => props.sellingType === 1 ? 'rgba(243, 252, 250, 1)' : 'rgba(255, 250, 240, 1)'} ;
  color: ${(props) => props.sellingType === 1 ? 'rgba(10, 184, 140, 1)' : 'rgba(238, 120, 43, 1)'};
`

export default SellingType;