import styled from "styled-components";
import MenuItemComponent from "components/common/MenuItemComponent";
import { colors } from "styled/colors";
import { Font_md, Font_sm } from "styled/fonts";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { getSellerInfo } from "api/SellerApi";
import { useEffect } from "react";
import { useState } from "react";
import { SellerType } from "types/types";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import Loading from "components/Loading";
import { exchangeRateState } from "recoil/homeRecoil";
import { checkAndConvertCurrency } from "api/StringUtils";


const SaleInfo = () => {
    const navigate = useNavigate();
    const user : any = useRecoilValue(loginState);
    const [seller, setSeller] = useState<SellerType>();
    const [loading, setLoading] = useState(false);
    console.log(user);
    const fetchSellerData = async() => {
        setLoading(true);
        const response = await getSellerInfo(user.access);
        setLoading(false);
        if(response instanceof Error) {
            console.error('API 호출 중 에러 발생:', response.message);
        } else {
            setSeller(response.data[0]);
        }
    };

    const PriceWithComma = (price: number | undefined) : string => {
        if(price) {
            const priceWithComma : string = price.toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        return priceWithComma;
        } else {
            return '0';
        }
    };

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);
    const exchangeRate = useRecoilValue(exchangeRateState);

    useEffect(()=> {
        i18n.changeLanguage(language);
        fetchSellerData();
    },[]);

    return (
        <SaleInfoWrapper>
            {loading ?
            <Loading/> :
            <>
            <MenuItemComponent name={t("일반 판매 내역")} isBold onClick={()=> {navigate('/seller-history/0')}}/>
            <SaleButton>
                <SaleText onClick={()=> {navigate('/seller-history/0')}}>
                    <Font_md isBold>{user.user.sell_total}</Font_md>
                    <Font_sm>{t("전체")}</Font_sm>
                </SaleText>
                <SaleText onClick={()=> {navigate('/seller-history/0')}}>
                    <Font_md isBold color={user.user.sell_progress !== 0 ? `${colors.GREEN[500]}` : ''}>{user.user.sell_progress}</Font_md>
                    <Font_sm>{t("판매중")}</Font_sm>
                </SaleText>
                <SaleText onClick={()=> {navigate('/seller-history/1')}}>
                    <Font_md isBold color={user.user.sell_done !== 0 ? '#E53E3E' : ''}>{user.user.sell_done}</Font_md>
                    <Font_sm>{t("판매완료")}</Font_sm>
                </SaleText>
            </SaleButton>
            <MenuItemComponent name={t("제안 판매 내역")} isBold onClick={()=> {navigate('/seller-auction-history/0')}}/>
            <SaleButton>
                <SaleText onClick={()=> {navigate('/seller-auction-history/0')}}>
                    <Font_md isBold>{user.user.auction_total}</Font_md>
                    <Font_sm>{t("전체")}</Font_sm>
                </SaleText>
                <SaleText onClick={()=> {navigate('/seller-auction-history/0')}}>
                    <Font_md isBold color={user.user.auction_progress !== 0 ? `${colors.GREEN[500]}` : ''}>{user.user.auction_progress}</Font_md>
                    <Font_sm>{t("진행중")}</Font_sm>
                </SaleText>
                <SaleText onClick={()=> {navigate('/seller-auction-history/1')}}>
                    <Font_md isBold color={user.user.auction_progress !== 0 ? '#E53E3E' : ''}>{user.user.auction_success}</Font_md>
                    <Font_sm>{t("마감")}</Font_sm>
                </SaleText>
                <SaleText onClick={()=> {navigate('/seller-auction-history/2')}}>
                    <Font_md isBold>{user.user.auction_done}</Font_md>
                    <Font_sm>{t("종료")}</Font_sm>
                </SaleText>
            </SaleButton>
            <MenuItemComponent name={t("정산내역")} isBold onClick={()=> {navigate("/mypage/calculate")}}/>
            <CalcText>
                <Font_sm>{t("정산계좌")}</Font_sm>
                {seller && <Font_sm>{seller.account_owner}  | {seller.account_bank} {seller.account_number}</Font_sm>}
            </CalcText>
            <CalcText>
                <Font_sm>{t("총 정산금액")}</Font_sm>
                <Font_sm isBold>{seller?.swift_code ? checkAndConvertCurrency(language, user.user.calculated_total ? user.user.calculated_total : 0 , 'USD', exchangeRate?.basePrice) : checkAndConvertCurrency(language, user.user.calculated_total ? user.user.calculated_total : 0 , 'KRW', exchangeRate?.basePrice)}</Font_sm>
            </CalcText>
            </>
            }
        </SaleInfoWrapper>
    )
}

const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const SaleInfoWrapper = styled.div`
    flex:1;
    margin-bottom: 40px;
`

const SaleButton = styled.div`
    display: flex;
    background-color: ${colors.GREY[50]};
    padding: 0.5rem 0;
    margin: 0.5rem 0;
    justify-content: space-around;
    border-radius: 0.5rem;
`

const SaleText = styled.div`
    text-align: center;
    cursor: pointer;
`

const CalcText = styled.div`
    display: flex;
    justify-content: space-between;
`

export default SaleInfo;