import React, {useState, useEffect} from "react";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import SignupBtnComponent from "components/common/buttons/SignupBtnComponent";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import ImagesSlide from "components/ImagesSlideHome";
import styled from "styled-components";
import {Size} from "types/types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {langState} from "recoil/loginRecoil";

const OnboardingPage = () => {
    const {t, i18n} = useTranslation("main");
    const language = useRecoilValue(langState)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const GOOGLE_AUTH: any = process.env.REACT_APP_GOOGLE_AUTH;
    const APPLE_AUTH: any = process.env.REACT_APP_APPLE_AUTH;

    const HandleClickLogin = () => {
        navigate('/login');
    };
    const HandleClickEmail = () => {
        navigate('/signup/terms');
    };
    const checkMobile = () => {

        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.indexOf('android') > -1) {
            //안드로이드
            return "android";
        } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            //IOS
            return "ios";
        } else {
            //아이폰, 안드로이드 외
            return "other";
        }

    }
    const OauthHandler = async (type: string) => {
        await setLoading(true);
        if (type === 'google') {
            var mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

            if (mobile) {
                //모바일 처리
                if(checkMobile() == 'ios' && window.webkit != undefined){
                    window.webkit.messageHandlers.oauth.postMessage(type);
                }
                // else if(checkMobile() == 'android' && window.Android != undefined){
                //     window.Android.oauth(type);
                // }
                else{
                    window.location.href = GOOGLE_AUTH;
                }
            } else {
                //비 모바일 처리
                window.location.href = GOOGLE_AUTH;

            }
        } else if (type == 'apple') {
            var mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

            if (mobile) {
                //모바일 처리
                if (checkMobile() == 'ios') {
                    window.webkit.messageHandlers.oauth.postMessage(type);
                } else if (checkMobile() == 'android') {
                    window.location.href = APPLE_AUTH;
                }
            } else {
                //비 모바일 처리
                window.location.href = APPLE_AUTH;
            }
        }
        // await setLoading(false);
    }
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [])

    return (
        <Onboarding>
            <ProcessHeader isSearchForm={false}/>
            <MiddleWrapper>
                <ImagesSlide/>
            </MiddleWrapper>
            <ButtonWrapper>
                <SignupBtnComponent
                    onClick={() => OauthHandler("google")}
                    type={"google"}
                    from="signup"
                    isDisabled={loading}/>
                <SignupBtnComponent
                    onClick={() => OauthHandler("apple")}
                    type={"apple"}
                    from="signup"
                    isDisabled={loading}/>
                <SignupBtnComponent
                    onClick={HandleClickEmail}
                    type={"email"}
                    from="signup"
                    isDisabled={loading}/>
                <ButtonComponent
                    onClick={HandleClickLogin}
                    isValid={true}
                    size={Size.LARGE}
                    text={t("로그인")}
                ></ButtonComponent>
            </ButtonWrapper>
        </Onboarding>
    );
};

const ButtonWrapper = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MiddleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Onboarding = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`

export default OnboardingPage;