import { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import HomeSvgComponent from "assets/Footer/home_img";
import SearchSvgComponent from "assets/Footer/search_img";
import LoginReqImg from "assets/login_req.svg"
import MySvgComponent from "assets/Footer/my_img";
import { Font_2xs } from "styled/fonts";
import { colors } from "styled/colors";
import ModalComponent from "components/common/ModalComponent";
import { useDisclosure } from "@chakra-ui/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";

interface FooterIProps {
	isClick: number;
}
const FooterComponent = (props: FooterIProps) => {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const loginValue = useRecoilValue(loginState)
	const set = useSetRecoilState(loginState)
	
	const handleMypageTab = () => {
		if(!loginValue.access || Object.keys(loginValue.user).length === 0  || loginValue.user === undefined){
			onOpen();
		}
		else{
			handleClickItem('mypage')
		}
	}
	const handleClickItem = (path: string) => window.location.replace('/' + path);

	const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState);

	useEffect(()=> {
		i18n.changeLanguage(language);
	},[])

  return (
    <Footer className="footer">
			<FooterItem onClick={()=>handleClickItem('')}>
				<HomeSvgComponent isClicked={props.isClick === 0}/>
				<FooterText isClick={props.isClick === 0}>{t("홈")}</FooterText>
			</FooterItem>
			<FooterItem onClick={()=>handleClickItem('explore?category=3')}>
				<SearchSvgComponent isClicked={props.isClick === 1}/>
				<FooterText isClick={props.isClick === 1}>{t("탐색")}</FooterText>
			</FooterItem>
			<FooterItem onClick={()=>handleMypageTab()}>
				<MySvgComponent isClicked={props.isClick === 2}/>
				<FooterText isClick={props.isClick === 2}>{t("마이페이지")}</FooterText>
			</FooterItem>
			<ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        content={language === 'ko-KR' ? `로그인하고 더 많은 기능을` : `Sign up today!`}
				bottomContent={language === 'ko-KR' ? `이용해보세요!` : ''}
        leftBtnString={t("돌아가기")}
        rightBtnString={t("로그인")}
        onClickSubmit={()=>navigate('/onboarding')}
				imageUrl={LoginReqImg}
      />
    </Footer>
  );
};

const Footer = styled.div`
	position: fixed;
	bottom:0;
	display: flex;
	height: 64px;
	background-color: white;
	border-top:1px solid ${colors.GREY[100]};
	z-index: 20;
`

const FooterItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100% / 3);
	gap: 2px;
`

const FooterText = styled(Font_2xs)<{isClick:boolean}>`
	color: ${(props) => props.isClick ? colors.GREEN[500] : 'black'}
`

export default FooterComponent;
