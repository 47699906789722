import { Routes, Route } from "react-router-dom";
import Mypage from "./Mypage";
import CalculatePage from "./calculatepage/CalculatePage";
import SettingProfilePage from "./SettingProfilePage";
import PersonalHomePage from "./setPersonalPage/PersonalHomePage";
import ChangePwdPage from "./setPersonalPage/ChangePwdPage";
import ChangeNumPage from "./setPersonalPage/ChangeNumPage";
import WithDrawPage from "./setPersonalPage/WithDrawPage";
import HomeAddrPage from "./homeAddrPage/HomeAddrPage";
import ChangeAddrPage from "./homeAddrPage/ChangeAddrPage";
import SellerApplicationPage from "./sellerapplicationpage/SellerApplicationPage";
import SuccessPage from "./sellerapplicationpage/SuccessPage";
import AskPage from "./AskPage";
import SetLanguagePage from "./SetLanguagePage";
import NoticePage from "./noticepage/NoticePage";
import NoticeDetailPage from "./noticepage/NoticeDetailPage";
import TermsofUsePage from "./ TermsofUsePage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import SecessionPage from "./secessionpage/SecessionPage";
import SecessionSuccessPage from "./secessionpage/SuccessPage";
import TermsofUseForSeller from "./TermsofUseForSellerPage";

const MypageRouter = () => {

  return (
    <Routes>
      <Route path='/mypage' element={<Mypage />}/>
      <Route path='/mypage/calculate' element={<CalculatePage/>}/>
      <Route path="/mypage/setting-profile" element={<SettingProfilePage/>}/>
      <Route path='/mypage/personal' element={<PersonalHomePage />}/>
      <Route path='/mypage/secession' element={<SecessionPage/>}/>
      <Route path="/mypage/secession/success" element={<SecessionSuccessPage/>}/>
      <Route path='/mypage/change-pwd' element={<ChangePwdPage />}/>
      <Route path='/mypage/change-num' element={<ChangeNumPage />}/>
      <Route path='/mypage/withdraw' element={<WithDrawPage />}/>
      <Route path='/mypage/home-addr' element={<HomeAddrPage />}/>
      <Route path='/mypage/change-addr' element={<ChangeAddrPage />}/>
      <Route path='/mypage/seller-application' element={<SellerApplicationPage/>}/>
      <Route path="/mypage/seller-application/success" element={<SuccessPage/>}/>
      <Route path="/mypage/ask" element={<AskPage/>}/>
      <Route path="/mypage/lang" element={<SetLanguagePage/>}/>
      <Route path="/mypage/notice" element={<NoticePage/>}/>
      <Route path="/mypage/notice/:id" element={<NoticeDetailPage/>}/>
      <Route path="/mypage/terms-of-service" element={<TermsofUsePage/>}/>
      <Route path="/mypage/privacy-policy" element={<PrivacyPolicyPage/>}/>
      {/* <Route path="/mypage/terms-of-service-for-seller" element={<TermsofUseForSeller/>}/> */}
    </Routes>
  );
};

export default MypageRouter;