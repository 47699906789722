import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_lg, Font_md, Font_sm } from "styled/fonts";

interface RadioButtonsType {
    title: string;
    description?: string;
    firstButton: string;
    secondButton: string;
    item: number | undefined;
    setItem: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const RadioButtons = (props: RadioButtonsType) => {

    const { item, setItem } = props;

    const isButtonValid = (thisItem : number) : boolean => {
        if(item == thisItem) {
            return true;
        } else {
            return false;
        }
    }
    
    return (
        <RadioButtonsWrapper>
            <Font_md isBold>{props.title}</Font_md>
            <Buttons>
                <Button 
                onClick={()=> {setItem(0)}}
                isValid={isButtonValid(0)}>
                    <Font_lg isBold color={isButtonValid(0) ? colors.GREEN[500] : colors.GREY[800]}>{props.firstButton}</Font_lg>
                </Button>
                <Button 
                onClick={()=> {setItem(1)}}
                isValid={isButtonValid(1)}>
                    <Font_lg isBold color={isButtonValid(1) ? colors.GREEN[500] : colors.GREY[800]}>{props.secondButton}</Font_lg>
                </Button>
            </Buttons>
            {props.description && <Font_sm isBold>{props.description}</Font_sm>}
        </RadioButtonsWrapper>
    )
}

const RadioButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const Buttons = styled.div`
    display: flex;
    gap: 0.5rem;
`

const Button = styled.div<{isValid: boolean;}>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => (props.isValid ? colors.GREEN[500] : colors.GREY[200])};
    background-color: ${(props) => (props.isValid ? colors.GREEN[50] : 'white')};
    border-radius: 6px;
    padding: 11px 0;
    width: 100%;
`


export default RadioButtons;