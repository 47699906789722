import ProcessHeader from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import CardListComponent from "components/puchaceHist/CardListComponent";
import { DeliverStatus, PurchaseListData } from "types/types";
import { Font_md, Font_sm } from "styled/fonts";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import notify from "components/common/toast/ToastComponent";
import { formatCurrency } from "api/StringUtils";
import { loginState } from "recoil/loginRecoil";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { colors } from "styled/colors";
import { getOnePurchaseData } from "api/UserHistoryApi";
import { isNotLogin } from "api/UserUtil";
import { getPresetData } from "api/api";
import { checkAndConvertCurrency } from "api/StringUtils";
import { exchangeRateState } from "recoil/homeRecoil";

const deleverInfo: any = {
  reciever: "홍길동",
  contact: "01012341234",
  address: "서울시 강남구 학동로 1길 8, 302호 (22222)",
  memo: "문 앞에 놔주세요",
};

const keyHangul: any = {
  reciever: "수령인",
  contact: "연락처",
  address: "주소",
  memo: "배송메모",
  paymentMethod: "결제수단",
  itemPrice: "상품금액",
  deliverPrice: "배송비",
  totalPrice: "총 결제금액",
};

const payInfo: any = {
  paymentMethod: "신용・체크카드(신한)",
  itemPrice: 800000,
  deliverPrice: 250000,
  totalPrice: 1050000,
};

const PurchaceDetailPage = () => {
  const { id } = useParams();

  const [purchaseData, setPurchaseData] = useState<PurchaseListData[]>();
  const [totalProductPrice, setTotalProductPrice] = useState<number>(0);

  const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(
    isCompletePurchaceState
  );
  const [deliveryPrice, setDeliverPrice] = useState(0);

  const exchangeRate = useRecoilValue(exchangeRateState);

  // const fetchPresetData = async() => {
  //   const data = await getPresetData();
  //   const findDeliveryPrice = data.filter((item)=> item.key == "delivery");
  //   setDeliverPrice(Number(findDeliveryPrice[0].value));
  // }

  const fetchPurchaseData = async () => {
    try {
      const dataRes = await getOnePurchaseData(user.access, Number(id));
      setPurchaseData([dataRes]);
      console.log(dataRes);
    } catch(error) {
      console.log(error);
    }
  }
  
  const user : any = useRecoilValue(loginState);
  useEffect(() => {
    if (isCompletePurchace) {
      fetchPurchaseData();
      notify(t("구매확정이 완료되었습니다."));
      setIsCompletePurchace(false);
    }
  }, [isCompletePurchace]);

  useEffect(()=> {
    if(purchaseData && totalProductPrice == 0) {
      purchaseData[0].purchases.map((list)=> setTotalProductPrice((prevData) => prevData + list.product.price))
    }
  }, [purchaseData]);

  const { t, i18n } = useTranslation("history");
  const language = useRecoilValue(langState);

  
  useEffect(()=>{
    i18n.changeLanguage(language);
    fetchPurchaseData();
    isNotLogin(user).then((data) => {
      if(data)
        window.location.replace("/");
    });
  }, [])

  return (
    <PurchaceHist>
      <ToastContainer />
      <ProcessHeader title={t("구매 상세 내역")} isSearchForm={false} />
      {purchaseData &&
      <>
      <CardListComponent isDetail={true} cardList={purchaseData} />
      <AuctionInfoWrapper>
            <Font_md isBold>{t("배송 정보")}</Font_md>
            <InfoList>
                <InfoTitle>
                    <Font_sm>{t("수령인")}</Font_sm>
                    <Font_sm>{t("연락처")}</Font_sm>
                    <Font_sm>{t("주소")}</Font_sm>
                    <Font_sm>{t("배송메모")}</Font_sm>
                </InfoTitle>
                <InfoContent>
                    <Font_sm>{purchaseData[0].purchases[0].name}</Font_sm>
                    <Font_sm>{purchaseData[0].purchases[0].phone}</Font_sm>
                    <Font_sm>{purchaseData[0].purchases[0].address} {purchaseData[0].purchases[0].detail_address}</Font_sm>
                    <Font_sm>{purchaseData[0].purchases[0].memo}</Font_sm>
                </InfoContent>
            </InfoList>
        </AuctionInfoWrapper>
            <PayInfoWrapper>
                <PayContentWrapper>
                    <Font_md isBold>{t("결제 정보")}</Font_md>
                    <InfoList>
                        <InfoTitle>
                            <Font_sm>{t("상품금액")}</Font_sm>
                            <Font_sm>{t("배송비")}</Font_sm>
                            <Font_sm isBold>{t("총 결제금액")}</Font_sm>
                        </InfoTitle>
                        {totalProductPrice &&
                        <PayInfoContent>
                        <Font_sm>{checkAndConvertCurrency(language, totalProductPrice, purchaseData[0].purchases[0].currency, exchangeRate?.basePrice)}</Font_sm>
                        <Font_sm>{checkAndConvertCurrency(language, purchaseData[0].amount - totalProductPrice, purchaseData[0].purchases[0].currency, exchangeRate?.basePrice)}</Font_sm>
                        <Font_sm isBold>{checkAndConvertCurrency(language, purchaseData[0].amount, purchaseData[0].purchases[0].currency, exchangeRate?.basePrice)}</Font_sm>
                    </PayInfoContent>}
                    </InfoList>
                </PayContentWrapper>
            </PayInfoWrapper>
      </>}
    </PurchaceHist>
  );
};

const PurchaceHist = styled.div``

const AuctionInfoWrapper = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const InfoList = styled.div`
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
`

const InfoTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
`

const PayInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    align-items: flex-end;
`

const PayInfoWrapper = styled.div`
    border-bottom: 1px solid ${colors.GREY[100]};
`

const PayContentWrapper = styled.div`
    padding: 1rem;
`




export default PurchaceDetailPage;
