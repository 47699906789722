import styled from "styled-components";
import cartEmpty from "assets/cartEmpty.svg";
import SmallButtonComponent from "components/common/buttons/SmallButtonComponent";
import { Font_lg, Font_md } from "styled/fonts";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import empty_cart from "assets/emptyCart.svg";
import { colors } from "styled/colors";
import { useNavigate } from "react-router-dom";

const EmptyComponent = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState);

  useEffect(()=> {
    i18n.changeLanguage(language);
  },[])

  return (
    <Empty>
      <EmptyIcon>
        <CartImg src={empty_cart}/>
        <Font_md color={colors.GREY[400]}>{t("장바구니가 비어있습니다.")}</Font_md>
      </EmptyIcon>
      <SmallButtonComponent onClick={()=>navigate('/explore')} text={t("상품 둘러보기")} cnt={3} isReverse={false} />
    </Empty>
  );
};

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  height: calc(100% - 57px);
`;

const EmptyIcon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CartImg = styled.img`
  height: 5rem;
`


export default EmptyComponent;
