export const colors = {
  'GREEN':{
    '10':"#F7FDFB",
    '50':"#F3FCFA",
    '100':"#CCF0E7",
    '200':"#A0E3D3",
    '300':"#69D3B9",
    '400':"#3DC7A4",
    '500':"#0AB88C",
    '600':"#09A57D",
    '700':"#078363",
    '800':"#06654D",
    '900':"#044D3B",
  },
  'GREY':{
    '50':"#F7FAFC",
    '100':"#EDF2F7",
    '200':"#E2E8F0",
    '300':"#CBD5E0",
    '400':"#A0AEC0",
    '500':"#718096",
    '600':"#4A5568",
    '700':"#2D3748",
    '800':"#1A202C",
    '900':"#171923",
  }
}