import styled from "styled-components";
import {
  ReferenceLine,
  LabelList,
  LineChart,
  Line,
  ResponsiveContainer,YAxis
} from "recharts";
import {changeFixedUsdToKrw, changeKrwToUsd, changeUsdToKrw} from "../../api/StringUtils";
import {ExchangeRateType} from "../../types/types";
import {fi} from "date-fns/locale";
import {useState} from "react";

export interface ChartProps {
  price: number;
  created: string;
  product: {
    value_product: any;
  };
  currency: string;
}


interface LabelProps {
  x: string | number | undefined;
  y: string | number | undefined;
  value: number;
  type: string;
  language: string;
  currency: any;
}

const CustomLabel = ({ x, y, value, type, language, currency }: LabelProps) => {
  return (
    <g>
      {type === "max" ? (
        <>
          <circle cx={Number(x)} cy={Number(y) - 5} r={2} fill="#E53E3E" />
          <text
            textAnchor={(Number(x) > 200 ? 'end' : 'start')}
            fontSize={12}
            x={Number(x) + (Number(x) > 200 ? -7 : 7)}
            y={Number(y) - 1}
            fill="#E53E3E"
          >
            { language === 'ko-KR' ? (currency === 'USD' ? `최고 약 ${changeFixedUsdToKrw(value).toLocaleString()}원` : `최고 ${value.toLocaleString()}원`) : (currency === 'USD' ? `Max $${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : `Max Approx. $${changeKrwToUsd(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`)}
            {/*{ currency === 'USD' ? language === 'ko-KR' ? `최고 US $${value.toLocaleString()}` : `Max $${value.toLocaleString()}` : language === 'ko-KR' ? `최고 ${value.toLocaleString()}원` : `Max KRW ${value.toLocaleString()}`}*/}
          </text>
        </>
      ) : (
        <>
          <circle cx={x} cy={Number(y) + 7} r={2} fill="#3F5BE4" />
          <text
            textAnchor={(Number(x) > 200 ? 'end' : 'start')}
            fontSize={12}
            x={Number(x) + (Number(x) > 200 ? -7 : 7)}
            y={Number(y) + 11}
            fill="#3F5BE4"
          >
            { language === 'ko-KR' ? (currency === 'USD' ? `최저 약 ${changeFixedUsdToKrw(value).toLocaleString()}원` : `최저 ${value.toLocaleString()}원`) : (currency === 'USD' ? `Min $${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : `Min Approx. $${changeKrwToUsd(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`)}
            {/*{ currency === 'USD' ? language === 'ko-KR' ? `최저 US $${value.toLocaleString()}` : `Min $${value.toLocaleString()}` : language === 'ko-KR' ? `최저 ${value.toLocaleString()}원` : `Min KRW ${value.toLocaleString()}`}*/}
          </text>
        </>
      )}
    </g>
  );
};

function addConvertProperty(dataArray: any, exchangeRate: any) {
  for (let i = 0; i < dataArray.length; i++) {
    const currency = dataArray[i].currency;
    const price = dataArray[i].price;

    if (currency === 'USD') {
      dataArray[i].convert = changeFixedUsdToKrw(price);
    } else {
      dataArray[i].convert = price;
    }
  }
  return dataArray;
}

const ChartComponent = (props: { chartData: ChartProps[], exchangeRate: ExchangeRateType, language: string }) => {
  const { chartData, exchangeRate, language } = props;
  const [maxLabelIndex, setMaxLabelIndex] = useState<number | null>(null);
  const [minLabelIndex, setMinLabelIndex] = useState<number | null>(null);
  const sortArray = chartData.sort(
    (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
  );
  const convertedValues = chartData.map((e) => {
    if (e.currency === 'USD') {
      return { value: changeFixedUsdToKrw(e.price), original: e.price };
    } else {
      return { value: e.price, original: e.price };
    }
  });
  const recentPrice = convertedValues[convertedValues.length - 1].value;
  const maxObject = convertedValues.reduce((max, current) => (current.value > max.value) ? current : max);
  const minObject = convertedValues.reduce((min, current) => (current.value < min.value) ? current : min);
  const maxValue = maxObject.original;
  const minValue = minObject.original;
  const finalArray = addConvertProperty(sortArray, exchangeRate);
  const finallArray = finalArray.map((e: any) => e.convert);
  const array = [{ price: maxValue, convert: maxObject.value }, { price: maxValue, convert: maxObject.value }];
  const getPrice = (x: any)=>{return x.price;}
  const getConvert = (x: any)=>{return x.convert;}
  return (
    <Container>
      {chartData.length === 1 ? (
        <ResponsiveContainer width="100%" height={120}>
          <LineChart data={array}>
            <Line
              type="linear"
              dot={false}
              dataKey={getConvert}
              stroke="#E53E3E"
              strokeWidth={2}
            >
              <LabelList
                dataKey={getPrice}
                content={(props) => {
                  // @ts-ignore
                  if (props.value === maxValue && props.index === 0) {
                    return (
                      <CustomLabel
                        currency={chartData[0].currency}
                        x={Number(props.x) + 10}
                        y={Number(props.y) + 20}
                        value={props.value}
                        type="max"
                        language={language}
                      />
                    );
                  }
                  return null;
                }}
              />
            </Line>
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            data={finalArray}
            height={160}
            margin={{ left: 20, right: 20, top: 20, bottom: 20 }}
          >
            <YAxis
                domain={[]}
                axisLine={false}
                tickLine={false}
                tick={false}
                width={0}
            />
            <Line
              type="linear"
              dot={false}
              dataKey={getConvert}
              stroke="#E53E3E"
              strokeWidth={2}
            >
              <LabelList
                dataKey={getPrice}
                content={(props: any) => {
                  if (props.value === maxValue && props.index) {
                    setMaxLabelIndex(props.index);
                    if (props.index === maxLabelIndex) {
                      return (
                          <CustomLabel
                              currency={finalArray[props.index].currency}
                              x={props.x}
                              y={props.y}
                              value={props.value}
                              type="max"
                              language={language}
                          />
                      );
                    }
                  }
                  return null;
                }}
              />
              <LabelList
                dataKey={getPrice}
                content={(props: any) => {
                  if (props.value === minValue) {
                    setMinLabelIndex(props.index);
                    if (props.index === minLabelIndex) {
                      return (
                          <CustomLabel
                              currency={finalArray[props.index].currency}
                              x={props.x}
                              y={props.y}
                              value={props.value}
                              type="min"
                              language={language}
                          />
                      );
                    }
                  }
                  return null;
                }}
              />
            </Line>
            <ReferenceLine
              style={{ opacity: 0.2 }}
              y={Number(recentPrice)}
              stroke="#E53E3E"
              strokeDasharray="3 3"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
`;

export default ChartComponent;
