import SaleCard from "components/sellerHistory/SaleCard";
import { styled } from "styled-components";
import card_img from "assets/card_img.svg";
import { UploadData } from "types/types";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { getProducts } from "api/SellerApi";
import { isoStringToDate } from "api/StringUtils";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import EmptyMessage from "components/common/EmptyMessage";

const SellerBiddingHistory = () => {
    const [sellerBiddingData, setSellerBiddingData] = useState<UploadData[]>([]);
    const [loading, setLoading] = useState(false);

    const user: any = useRecoilValue(loginState);

    const navigate = useNavigate();

    const fetchBiddingData = async () => {
      try {
        setLoading(true);
        const dataRes = await getProducts(user.access);
        const filterData = dataRes.filter((item) => item.product.selling_type == 2 && !item.product.is_pending && !item.product.is_selled).reverse();
        setSellerBiddingData(filterData);
        setLoading(false);
      } catch(error) {
        console.log(error);
      }
    }

    const identifyBiddingStatus = (list: UploadData) : string => {
      const today = new Date();
      const startDate = new Date(list.product.start_date);

      if(today < startDate) return "오픈예정";
      else return "제안마감"
    }


    useEffect(()=> {
      fetchBiddingData();
    },[])

    return (
        <BiddingHistoryWrapper>
          {loading ?
          <Loading/>
          :
          <>
          {sellerBiddingData.length !== 0 ?
            sellerBiddingData.map((list)=> 
            <SaleCard
            name={list.product.name}
            price={list.product.price}
            image={list.product.image1}
            bidStatus={identifyBiddingStatus(list)}
            bidDate={identifyBiddingStatus(list) == "오픈예정" ? isoStringToDate(list.product.start_date, true, false) : isoStringToDate(list.product.end_date, true, false)}
            onClick={() => {navigate(`/item-detail-bid?id=${list.product.id}`)}}
            currency={list.product.author.swift_code ? 'USD' : 'KRW'}
            />) : 
            <EmptyMessage/>}
          </>
          }
        </BiddingHistoryWrapper>
    )
};

const BiddingHistoryWrapper = styled.div`

`

export default SellerBiddingHistory;