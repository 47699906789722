import { styled } from "styled-components";
import card_img from "assets/card_img.svg";
import { colors } from "styled/colors";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect } from "react";
import {changeKrwToUsd, formatCurrency, formatCurrencyUsd} from "api/StringUtils";
import { exchangeRateState } from "recoil/homeRecoil";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";

interface CardIProps {
  title: string;
  itemPrice: number;
  deliverPrice: number;
  image:string;
  isPayPage?: boolean;
}

const HorizontalCard_Pay = (props: CardIProps) => {
  const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(
    isCompletePurchaceState
  );
  const { t } = useTranslation("main");
  const language = useRecoilValue(langState)
  const exchangeRate = useRecoilValue(exchangeRateState);

  useEffect(() => {
    setIsCompletePurchace(false);
  }, []);

  return (
    <HorizontalCard>
      <CardSection>
        <ImageSection image={props.image}/>
        <TextSection>
          <Text className={"title"}>{props.title}</Text>
          <Text className="itemPrice">
            {language === "ko-KR"
              ? formatCurrency(props.itemPrice) + "원"
              : "$" +
                formatCurrencyUsd(
                  props.isPayPage ?
                  props.itemPrice :
                  changeKrwToUsd(props.itemPrice, exchangeRate?.basePrice)
                )}
          </Text>
          <Text>
            {t("배송비") + " "}
            {language === "ko-KR"
              ? formatCurrency(props.deliverPrice) + "원"
              : "$" +
                formatCurrencyUsd(
                  changeKrwToUsd(props.deliverPrice, exchangeRate?.basePrice)
                )}
          </Text>
        </TextSection>
      </CardSection>
    </HorizontalCard>
  );
};

const HorizontalCard = styled.div`
  width: 100%;
`;

const CardSection = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 1rem 0;
`;

const ImageSection = styled.div<{image:string}>`
  min-width: 64px;
  height: 64px;
  border-radius: 8px;
  background-image: ${props => `url(${props.image}` });
  background-repeat: no-repeat;
  background-size: cover;
`;

const TextSection = styled.div`
  width: calc(100%-100px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Text = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.GREY[700]};
  font-size: 14px;
  font-weight: 400;
  &.title {
    font-size: 16px;
  }
  &.itemPrice {
    font-weight: 600;
    color: ${colors.GREY[900]};
  }
`;

export default HorizontalCard_Pay;
