import GreenBackgroundButton from "components/GreenBackgroundButton";
import ButtonComponentMd from "components/common/buttons/ButtonComponentMd";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import SaleInfo from "components/mypage/SaleInfo";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { sellerApplicationStatus } from "recoil/sellerRecoil";
import styled from "styled-components";
import { Font_lg, Font_md, Font_sm } from "styled/fonts";
import { SellerApplicationStatus, Size } from "types/types";
import { useNavigate } from "react-router-dom";
import { getSellerInfo } from "api/SellerApi";
import { loginState } from "recoil/loginRecoil";
import { getUserAddress, getUserInfo } from "api/UserApi";
import SmallButtonComponent from "components/common/buttons/MideumButtonComponent";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";

const SaleComponent = () => {
    const [user, setUser] = useRecoilState(loginState);

    const [applicationStatus, setApplicationStatus] = useRecoilState(sellerApplicationStatus);

    const navigate = useNavigate();
    const resistSellerHandler = () => {
        // setApplicationStatus(SellerApplicationStatus.RESISTERED)
        //임시 데이터 저장
        navigate("/mypage/seller-application")
    };

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    const fetchSellerData = async() => {
        if (user) {
            try {
                const sellerInfo = await getSellerInfo(user.access);
                if (sellerInfo.data.length === 0) {
                    return;
                }
                setApplicationStatus(SellerApplicationStatus.PENDING);
            } catch (error) {
                console.error("에러 발생:", error);
            }
        }
    };

    const fetchUserData = async() => {
        const userRes = await getUserInfo(user.access);
        if(userRes.error){
            setUser({user:{}, access:""})
            navigate('/');
        }
        setUser((prevUser: any)=> ({
            ...prevUser,
            user: {...userRes.data},
        }));
    }

    useEffect(() => {
      i18n.changeLanguage(language);
      fetchSellerData();
      fetchUserData();
    //   if (!user.access) {
    //     window.location.replace("/");
    //   }
    }, []);

    return (
        <SalePageWrapper>
            {user && 
            <>
            {user.user.is_seller ? (
            <SaleForSeller>
                <SaleInfo/>
                <AccountResistButton>
                    <ButtonComponent isValid text={t("상품 등록하기")} size={Size.LARGE} onClick={()=> {navigate('/add-product/category')}}/>
                </AccountResistButton>
            </SaleForSeller>
            ) :
            (applicationStatus == "PENDING" ? (
                <Sale>
                    <SaleText>
                        <Font_lg isBold>{t("판매자 신청 완료")}</Font_lg>
                        <Font_md>{t("신청 결과는 이메일로 안내될 예정입니다.")}</Font_md>
                    </SaleText>
                    <SmallButtonComponent onClick={()=> navigate('/mypage/ask')} text={t("1:1문의 남기기")} isReverse cnt={3}/>
                </Sale>
            ) : 
            (<Sale>
                <SaleText>
                    <Font_md>{t("판매자로 등록되지 않은 계정입니다.")}</Font_md>
                    <Font_md>{t("판매자 신청 후, 상품을 판매해보세요!")}</Font_md>
                </SaleText>
                <SmallButtonComponent text={t("판매자 신청하기")} onClick={resistSellerHandler} cnt={3} isReverse={false}/>
            </Sale>))}
            </>}
        </SalePageWrapper>
    )
}

const SalePageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Sale = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 284px);
`
const SaleForSeller = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 284px);
`

const SaleText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    text-align: center;
`

const AccountResistButton = styled.div`
    display: flex;
    justify-content: center;
`



export default SaleComponent;