import { useEffect, useRef } from "react";
import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_sm } from "styled/fonts";

interface InfoBubbleProps {
    isShow: boolean;
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
    text: string;
}

const InfoBubbleComponent = ({isShow, text, setIsShow} : InfoBubbleProps) => {
    const bubbleRef = useRef<HTMLDivElement>(null);
    const fadeInOut = () => {
        if(bubbleRef.current) {
            bubbleRef.current.style.transition = `opacity 1s 0s`;
            bubbleRef.current.style.opacity = '1';
            setTimeout(()=> {
                if(bubbleRef.current) {
                    bubbleRef.current.style.opacity = '0';
                }
            }, 3000)
        }
    }

    useEffect(()=> {
        if(isShow) {
            fadeInOut();
        }
    },[isShow])

    return (
        <InfoBubbleWrapper ref={bubbleRef}>
            <Font_sm color="#ffffff">{text}</Font_sm>
        </InfoBubbleWrapper>
    )
};

const InfoBubbleWrapper = styled.div`
    position: absolute;
    width: 250px;
    display: flex;
    align-items: center;
    min-height: 1.5rem;
    padding: 0 0.5rem;
    background: ${colors.GREY[700]};
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-left: 8px;
    opacity: 0;
    top: 0;
    left: 20px;
    z-index: 10;
    &::after
    {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 12px 13px 12px 0;
    border-color: transparent ${colors.GREY[700]};
    top: 0;
    left: -8px;
    z-index: 10;
    }
`

export default InfoBubbleComponent;