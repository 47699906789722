import styled from "styled-components";
import { Font_md, Font_lg, Font_sm, Font_xs } from "styled/fonts";
import { colors } from "styled/colors";
import deliveryBox from "assets/delivery-box.svg";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

const DeliveryMessage = () => {
    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <DeliveryMessageWrapper>
            <DeliveryBox src={deliveryBox}/>
                <Font_lg isBold>{t("상품이 판매되었습니다!")}</Font_lg>
                <SubText>
                    <Font_md>{t("상품 배송을 위해 리스트잇으로")}</Font_md>
                    <Font_md>{t("판매된 상품을 보내주세요.")}</Font_md>
                </SubText>
                <AddressWrapper>
                    <Font_xs>{t("리스트잇 주소")}</Font_xs>
                    <Font_sm>서울시 강남구 테헤란로27길 8-14 1003호 (06141)</Font_sm>
                </AddressWrapper>
        </DeliveryMessageWrapper>
    )
};

const DeliveryMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
`

const DeliveryBox = styled.img`
`

const SubText = styled.div`
    text-align: center;
`

const AddressWrapper = styled.div`
    border: 1px solid ${colors.GREY[100]};
    background-color: ${colors.GREY[50]};
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
`



export default DeliveryMessage;