import SuccessContent from "components/common/SuccessContent";
import ButtonComponentGreen from "components/common/buttons/ButtonComponentGreen";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import { styled } from "styled-components";
import { Size } from "types/types";

const SuccessProposalPage = () => {
    const navigate = useNavigate();
    const handleButton = () => {
      navigate('/seller-auction-history/1');
    }
  
    const { t, i18n } = useTranslation("history");
      const language = useRecoilValue(langState);
  
      useEffect(()=> {
          i18n.changeLanguage(language);
      },[])
      
    return(
    <SuccessProposalWrapper>
      <SuccessContent
        mainText={t("선정이 완료되었습니다!")}
        subText={t("구매자 결제 후 빠른 배송을 위해 \n 상품을 미리 준비해주세요.")}/>
      <ButtonComponentGreen
      text={t("확인")}
      size={Size.LARGE}
      onClick={handleButton}
      />
    </SuccessProposalWrapper>
    );
};

const SuccessProposalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  padding-bottom: 16px;
`

export default SuccessProposalPage;