import ProcessHeader from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import CardListComponent from "components/puchaceHist/CardListComponent";
import { DeliverStatus, PurchaseListData} from 'types/types';
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { getPurchaseData } from "api/UserHistoryApi";
import { useState } from "react";
import { isNotLogin } from "api/UserUtil";
import { useRecoilState } from "recoil";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import EmptyMessage from "components/common/EmptyMessage";
import Loading from "components/Loading";

const PurchaceHistPage = () => {
  const navigate = useNavigate();
  const user : any = useRecoilValue(loginState);
  const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(isCompletePurchaceState);
  const [loading, setLoading] = useState(false);

  const [purchaseData, setPurchaseData] = useState<PurchaseListData[]>([]);

  const { t, i18n } = useTranslation("history");
  const language = useRecoilValue(langState);

  const fetchPurchaseData = async() => {
    try {
      setLoading(true);
      const purchaseRes : PurchaseListData[] = await getPurchaseData(user.access);
      const filterData = purchaseRes.filter((list) => list.purchases && list.purchases[0].product.selling_type === 1);
      if(purchaseRes) setPurchaseData(filterData);
      setLoading(false);
    } catch(error) {
      console.log(error);
    }
  }; 

  useEffect(()=>{
    i18n.changeLanguage(language);
    fetchPurchaseData();

    isNotLogin(user).then((data) => {
      if(data)
        window.location.replace("/");
    });
  }, [])

  useEffect(()=> {
    fetchPurchaseData();
  },[isCompletePurchace])

  return (
    <PurchaceHist>
      <ProcessHeader title={t("구매 내역")} isSearchForm={false}/>
      {!loading ? 
      purchaseData.length !== 0 ? <CardListComponent cardList={purchaseData} isDetail={false}/> : <EmptyMessage/>
      : <Loading/>}
    </PurchaceHist>
  );
};

const PurchaceHist = styled.div`

`

export default PurchaceHistPage;