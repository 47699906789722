import { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Input, Button, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  passwordState,
  isValidPasswordState,
  isEqualPasswordState,
} from "recoil/signupRecoil";
import { Font_sm } from "styled/fonts";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";

interface PwdValidIProps {
  isChange: boolean;
}

const PasswordValidComponent = (props: PwdValidIProps) => {

  const { t, i18n } = useTranslation("signup");
  const language = useRecoilValue(langState);

  // valid valid_pwd == pwd
  const [isEqualPassword, setIsEqualPwd] = useRecoilState(isEqualPasswordState);

  // input handler
  const [validPassword, setValidPassword] = useState("");
  const [password, setPassword] = useRecoilState<string>(passwordState);
  const handleChangePwd = (event: any) => setPassword(event.target.value);
  const handleChangeValid = (event: any) => setValidPassword(event.target.value);

  // 입력된 비밀번호가 모든 조건을 만족했는지 판단
  const [isValidPassword, setIsValidPassword] =
    useRecoilState<boolean>(isValidPasswordState);

  // show button handler
  const [isShowPwd, setIsShowPwd] = useState(false);
  const [isShowValidPwd, setIsShowValidPwd] = useState(false);
  const handleShowPwdBtn = (isPassword: boolean) => {
    if (isPassword) {
      setIsShowPwd(!isShowPwd);
    } else {
      setIsShowValidPwd(!isShowValidPwd);
    }
  };

  const isShowBtnStyle = {
    right: "12px",
  };

  useEffect(()=>{
    setPassword("");
    i18n.changeLanguage(language);
  }, [])

  useEffect(() => {
    const checkPassword = async () => {
      // Password contains at least one letter
      const isEng = /[a-zA-Z]/.test(password);

      // Password has at least 8 characters
      const isEight = password.length >= 8;

      // Password contains at least one digit
      const isNum = /\d/.test(password);

      const isEqual = validPassword === password && password !== "";
      await setIsEqualPwd(isEqual);

      // Check if all conditions are met
      await setIsValidPassword(
        (isEng && isEight && isNum) || password.length == 0
      );
    };
    checkPassword();
  }, [password, validPassword]);

  return (
    <PasswordValid>
      <InputSection>
        <InputGroup style={{ width: "calc(100% - 2rem)" }} size="lg">
          <Input
            placeholder={t(props.isChange ? "새 비밀번호" : "비밀번호")}
            value={password}
            onChange={handleChangePwd}
            type={isShowPwd ? "text" : "password"}
            isInvalid={!isValidPassword}
          />
          <InputRightElement>
            <Button
              style={isShowBtnStyle}
              size="xs"
              onClick={() => handleShowPwdBtn(true)}
              colorScheme="gray"
              variant="outline"
            >
              {t(isShowPwd ? "숨기기" : "보기")}
            </Button>
          </InputRightElement>
        </InputGroup>
        {!isValidPassword && (
          <ErrMsg>
            <Font_sm color="#E53E3E">
              {t("영문, 숫자를 포함하여 8자 이상 입력해주세요.")}
            </Font_sm>
          </ErrMsg>
        )}
      </InputSection>
      <InputSection>
        <InputGroup style={{ width: "calc(100% - 2rem)" }} size="lg">
          <Input
            placeholder={t(props.isChange ? "새 비밀번호 확인" : "비밀번호 확인")}
            value={validPassword}
            onChange={handleChangeValid}
            type={isShowValidPwd ? "text" : "password"}
            isInvalid={!isEqualPassword && !(validPassword.length === 0)}
          />
          <InputRightElement>
            <Button
              style={isShowBtnStyle}
              size="xs"
              onClick={() => handleShowPwdBtn(false)}
              colorScheme="gray"
              variant="outline"
            >
              {t(isShowValidPwd ? "숨기기" : "보기")}
            </Button>
          </InputRightElement>
        </InputGroup>

        {!isEqualPassword && !(validPassword.length === 0) && (
          <ErrMsg>
            <Font_sm color="#E53E3E">{t("비밀번호가 일치하지 않습니다.")}</Font_sm>
          </ErrMsg>
        )}
      </InputSection>
    </PasswordValid>
  );
};

const PasswordValid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

const ErrMsg = styled.div`
  width: 100%;
  padding: 0.5rem 0 0 1rem;
`;

const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default PasswordValidComponent;
