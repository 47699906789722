import { styled } from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import DetailCard from "components/auctionHistory/DetailCard";
import { Size } from "types/types";
import card_img from "assets/card_img.svg";
import { Font_lg, Font_md, Font_sm } from "styled/fonts";
import { checkAndConvertCurrency, formatCurrency, isoStringToDate } from 'api/StringUtils';
import { colors } from "styled/colors";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import SaleCard from "components/sellerHistory/SaleCard";
import confetti from "assets/confetti.svg";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { getOneBiddingData } from "api/UserHistoryApi";
import { loginState } from "recoil/loginRecoil";
import { BiddingData } from "types/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { exchangeRateState } from "recoil/homeRecoil";

const BiddingDetailPage = () => {
    const { id } = useParams(); 
    const user : any = useRecoilValue(loginState);

    const [biddingData, setBiddingData] = useState<BiddingData>()

    const exchangeRate = useRecoilValue(exchangeRateState);

    const fetchBiddingData = async () => {
        if(id) {
            try {
                const biddingRes = await getOneBiddingData(user.access, Number(id));
                setBiddingData(biddingRes);

            } catch(error) {
                console.log(error);
            }
        }
    }

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const navigate = useNavigate();

  
    useEffect(()=> {
        i18n.changeLanguage(language);
        fetchBiddingData();
    },[])


    return (
        <DetailWrapper>
            <ProcessHeaderComponent
            title={t("제안 상세 내역")}
            isSearchForm={false}/>
            {biddingData &&
            <>
            <Title>
                <Font_sm>{isoStringToDate(biddingData.created, false, false)} {t("제안")}</Font_sm>
            </Title>
            <SaleCard
            name={biddingData.product.name}
            price={biddingData.product.price}
            image={biddingData.product.image1}
            />
            <AuctionInfoWrapper>
                <Font_md isBold>{t("제안 정보")}</Font_md>
                <InfoList>
                    <InfoTitle>
                        <Font_sm>{t("제안일")}</Font_sm>
                        <Font_sm>{t("제안가")}</Font_sm>
                        <Font_sm>{t("구매 이유")}</Font_sm>
                        <Font_sm>{t("제안 마감")}</Font_sm>
                    </InfoTitle>
                    <PayInfoContent>
                        <Font_sm>{isoStringToDate(biddingData.created, false, true)}</Font_sm>
                        {biddingData.purchase ?
                        <Font_sm>{checkAndConvertCurrency(language, biddingData.price, biddingData.purchase.currency, exchangeRate?.basePrice)}</Font_sm>  :
                        <Font_sm>{language !== 'ko-KR' ? checkAndConvertCurrency(language, biddingData.dollar_price ? biddingData.dollar_price : 0, 'USD') : checkAndConvertCurrency(language, biddingData.price, 'KRW')}</Font_sm>}
                        <Font_sm>{biddingData.cause}</Font_sm>
                        <Font_sm>{isoStringToDate(biddingData.product.end_date, true, true)}</Font_sm>
                    </PayInfoContent>
                </InfoList>
            </AuctionInfoWrapper>
                {biddingData.is_success && 
                <AuctionInfoWrapper>
                    <MessageWrapper>
                    <CelebrateImg src={confetti}/>
                    <Font_lg isBold>{t("선정을 축하드립니다!")}</Font_lg>
                    <Font_md>{t("선정 내역을 확인해주세요.")}</Font_md>
                    </MessageWrapper>
                    <ButtonWrapper>
                        <ButtonComponent
                        isValid={true}
                        text={t("선정 내역 보기")}
                        onClick={()=> navigate(`/bid-detail/${biddingData.id}`)}
                        size={Size.LARGE}
                        />
                    </ButtonWrapper>
                </AuctionInfoWrapper>}
                {biddingData.product.status == 8 &&
                <AuctionInfoWrapper>
                    <MessageWrapper>
                        <Font_lg isBold>{t("제안이 마감되었습니다!")}</Font_lg>
                        <Font_md>{t("선정 결과를 기다려주세요.")}</Font_md>
                    </MessageWrapper>
                </AuctionInfoWrapper>}
                {(biddingData.product.is_pending && biddingData.product.success_price && !biddingData.is_success) &&
                <AuctionInfoWrapper>
                    <MessageWrapper>
                        <Font_sm isBold color={colors.GREY[400]}>{t("미선정")}</Font_sm>
                    </MessageWrapper>
                </AuctionInfoWrapper>}
            </>}
        </DetailWrapper>
    )
}

//todo: 제안이유 넣기

const DetailWrapper = styled.div`
`

const AuctionInfoWrapper = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const PriceInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
`

const InfoList = styled.div`
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
`

const InfoTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const PayInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    align-items: flex-end;
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`

const CelebrateImg = styled.img`
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
`



export default BiddingDetailPage;