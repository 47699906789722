import { useState } from "react";
import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_sm } from "styled/fonts";

interface ChipTypes {
    name: string;
    onClick?: Function;
}

const ChipComponent = (props: ChipTypes) => {
    const [isValid, setIsValid] = useState(false);
    const buttonHandler = () => {
        setIsValid(isValid => !isValid);
        if(props.onClick) props.onClick();
    }
    return (
        <Chip isBold color={ isValid ? colors.GREEN[500] : colors.GREY[700]} onClick={buttonHandler} isValid={isValid}>
            {props.name}
        </Chip>
    )
}

const Chip = styled(Font_sm)<{isValid: boolean;}>`
    height: 2rem;
    border: 1px solid ${(props) => props.isValid ? colors.GREEN[500] : colors.GREY[200]};
    border-radius: 9999px;
    padding: 6px 12px;
    cursor: pointer;
`

export default ChipComponent;