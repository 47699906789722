import styled from "styled-components";
import OutlineButtonComponent from "components/common/OutlineButtonComponent";
import GreenBackgroundButton from "components/GreenBackgroundButton";
import { Size } from "types/types";
import SuccessContent from "components/common/SuccessContent";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const { t, i18n } = useTranslation("findpwd");
  const language = useRecoilValue(langState);
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);
  
  return (
    <Success>
      <SuccessContent
        mainText={t("비밀번호가 재설정되었습니다!")}
        subText={t("새 비밀번호로 로그인해주세요.")}/>
      <OutlineButtonComponent
      text={t("로그인")}
      size={Size.LARGE}
      onClick={()=>navigate('/login')}
      />
    </Success>
  );
};

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  padding-bottom: 16px;
`

export default SuccessPage;