import { Size } from "types/types";
import { styled } from "styled-components";
import {colors} from "../../../styled/colors";

interface ButtonTypes {
  isValid:boolean;
  text:string;
  size:Size;
  onClick?:Function;
  color?: string;
}

const ButtonComponent = (props:ButtonTypes) => {
  const tmpFunction = () => {}
  const buttonHandler = props.onClick || tmpFunction;
  return (
    <Button 
      isValid={props.isValid} 
      size={props.size}
      onClick={() => {
        if(props.isValid)
          buttonHandler();
      }}
      color={props.color || ""}
      text={props.text}>
      {props.text}
    </Button>
  );
};

const Button = styled.div<{ isValid: boolean; size: string; color: string, text: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.color !== "" ? props.color : props.isValid ? colors.GREY[700] : colors.GREY[50])};
  color: ${(props) => (props.isValid ? 'white' : colors.GREY[400])};
  width: ${(props) => (props.size === 'LARGE' ? 'calc(100% - 2rem)' : '50%')};
  font-weight: 600;
  height: 48px;
  margin-top: 5px;
  padding: 0px 24px;
  border-radius: 6px;
  font-size: ${(props) => props.text === '로그인' || props.text === 'Login' ? "18px" : ""};
  cursor: pointer;
`;

export default ButtonComponent;
