import styled from "styled-components";
import PasswordValidComponent from "components/common/PasswordValidComponent";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useRecoilValue } from 'recoil';
import { isEqualPasswordState, isValidPasswordState, passwordState, secretState } from "recoil/signupRecoil";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";
import { setNewPassword } from "api/UserApi";

const ResetpwdPage = () => {

  const { t, i18n } = useTranslation("findpwd");
  const language = useRecoilValue(langState);
  
  const isEqualPassword = useRecoilValue(isEqualPasswordState);
  const isValidPassword = useRecoilValue(isValidPasswordState);
  const secret = useRecoilValue(secretState);
  const password = useRecoilValue<string>(passwordState);

  const navigate = useNavigate();
  const HandleClick = () => {
    // something to do
    if(isEqualPassword && isValidPassword){
      setNewPassword(password, password, secret);
      navigate('/reset/success'); 
    }
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);
  
  return (
    <Resetpwd>
      <ProcessHeader isSearchForm={false} title={language === 'ko-KR' ? "비밀번호 재설정" : "Reset password"} />
      <InputWrapper>
        <PasswordValidComponent isChange={true} />
      </InputWrapper>
      <ButtonWrapper>
        <ButtonComponent
          onClick={HandleClick}
          isValid={isEqualPassword && isValidPassword}
          text={t("비밀번호 재설정")}
          size={Size.LARGE}
        />
      </ButtonWrapper>
    </Resetpwd>
  );
};

const Resetpwd = styled.div`

`;

const InputWrapper = styled.div`
  margin:32px 0px 32px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
`

export default ResetpwdPage;