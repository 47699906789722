import React, {useState} from "react";
import { styled } from "styled-components";
import backArrow from "../../../assets/backArrow.svg";
import more from "../../../assets/more_button.svg";
import { useNavigate } from "react-router-dom";
import ProgressBarComponent from "components/ProgressBarComponent";
import { Font_md } from "styled/fonts";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {itemListState} from "../../../recoil/homeRecoil";
import ModalComponent from "../ModalComponent";
import {useDisclosure} from "@chakra-ui/react";
import {deleteProduct} from "api/api";
import {loginState} from "../../../recoil/loginRecoil";
import {deleteProductState} from "../../../recoil/sellerRecoil";
import {useTranslation} from "react-i18next";
import {filterPageState} from "../../../recoil/filterRecoil";


export interface ITodoTypes {
  title?: string;
  isSearchForm: boolean;
  stepPercent?: number;
  path?: string;
  isOwner?: boolean;
  productId?: string;
  isReset?: boolean;
}

const ProcessHeaderComponent = (props: ITodoTypes) => {
  const { t, i18n } = useTranslation("main");
  const navigate = useNavigate();
  const setItemList = useSetRecoilState(itemListState);
  const setPage = useSetRecoilState(filterPageState);
  const [isModal, setIsModal] = useState<boolean>(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const user: any = useRecoilValue(loginState);
  const setDeleteState = useSetRecoilState(deleteProductState);
  const HandleClick = () => {
    if (props.isReset) {
      setItemList([]);
      setPage(1);
    }
    if (props.path) {
      navigate(props.path);
    }
    else {
      navigate(-1);
    }
  };

  const moreClick = () => {
    setIsModal(!isModal);
  };

  const onClickEdit = (id: string) => {
    navigate(`/edit-product?id=${id}`);
  }

  const onClickDelete = async (id: string) => {
    const response = await deleteProduct(id, user);
    if (response?.status === 200) {
      setDeleteState(true);
      navigate('/seller-history/0');
    }
  }
  return (
    <>
      <Header>
        <ArrowBtnWrapper>
          <ArrowBtn onClick={HandleClick} src={backArrow}></ArrowBtn>
        </ArrowBtnWrapper>
        <TextWrapper>
          <Font_md isBold>{props.title}</Font_md>
        </TextWrapper>
        {/*<EmptyIcon/>*/}
        { props.isOwner ? <img onClick={() => moreClick()} src={more} alt="" /> : <EmptyIcon/>}
        { isModal && <EditModal>
          <ModalText onClick={() => onClickEdit(props.productId || '')} isDelete={false}>{t('수정하기')}</ModalText>
          <ModalText onClick={() => onOpen()} isDelete={true}>{t('삭제하기')}</ModalText>
        </EditModal>}
      </Header>
      {
        props.stepPercent && <ProgressBarComponent stepPercent={props.stepPercent || 0} />
      }
      <ModalComponent
          isOpen={isOpen}
          onClose={onClose}
          content={
            t('상품을 삭제하시겠어요?')
          }
          leftBtnString={t('취소')}
          rightBtnString={t('삭제')}
          onClickSubmit={() => onClickDelete(props.productId || '')}
      />
    </>
  );
};

const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  z-index: 11;
`;

const ArrowBtn = styled.img`

`;

const ArrowBtnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyIcon = styled.div`
  width: 40px;
`
const EditModal = styled.div`
  position: absolute;
  right: 4px;
  width: 160px;
  height: 92px;
  border-radius: 8px;
  border: 1px solid rgba(237, 242, 247, 1);
  top: 56px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  box-sizing: border-box;
  justify-content: space-between;
`;

const ModalText = styled.div<{ isDelete: boolean }>`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => !props.isDelete ? 'rgba(23, 25, 35, 1)' : 'rgba(229, 62, 62, 1)'};
`;
export default ProcessHeaderComponent;
