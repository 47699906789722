import { useEffect, useState } from "react";
import SearchHeaderComponent from "components/common/Headers/SearchHeader";
import styled from "styled-components";
import VerticalCardListComponent from "components/common/VerticalCardListComponent";
import {itemListState, scrollRecoilState} from "recoil/homeRecoil";
import { useRecoilState } from "recoil";
import cantSearchImage from "assets/cant_search.svg";
import { Font_md } from "styled/fonts";
import { colors } from "styled/colors";
import { useTranslation } from "react-i18next";
import {getItemList, getRecommendKeyword} from "../../api/api";
import RecommendKeyword from "../../components/search/RecommendKeyword";
import {useInView} from "react-intersection-observer";
import {searchState} from "../../recoil/searchRecoil";
import {useNavigate} from "react-router-dom";


const SearchPage = () => {
  const [itemList, setItemList] = useRecoilState(itemListState);
  const [keyword, setKeyword] = useState([]);
  const [cnt, setCnt] = useState(0);
  const { t } = useTranslation("main");
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [scrollValue, setScrollValue] = useRecoilState(scrollRecoilState);
  const [maxPage, setMaxPage] = useState(0);
  useEffect(()=> {
    (async ()=>{
      const response = await getRecommendKeyword();
      setKeyword(response);
    })();
  },[])

  const [ref, inView] = useInView();

  useEffect(()=>{
    if (!inView){
      return;
    } else{
      if (isFirst){
        setIsFirst(false);
      } else{
        (async () => {
          if (maxPage !== page) {
            const response = await getItemList({
              search: searchVal,
              page_size: "10",
              page: String(page),
            });
            if (!response.error) {
              await setItemList([...itemList, ...response.items.results]);
              setPage(page + 1)
            }
          }
        })();
      }
    }
  }, [inView])
  const handleNavigate = (type: number, id: string) => {
    const scroll = document.getElementById("application")?.scrollTop;
    if (scroll) {
      setScrollValue(scroll);
    }
    navigate(`/item-detail${type === 2 ? "-bid" : ""}?id=${id}`);
  };

  const [searchVal, setSearchVal] = useRecoilState(searchState);
  const handleInput = async (e: any) => {
    setSearchVal(e.target.value);
  };
  const HandleSearchClick = (value?: string) => {
    (async () => {
      setItemList([]);
      setPage(1);
      setCnt(cnt + 1);
      setIsFirst(true);
      const response = await getItemList({
        search: value !== '' ? value : searchVal,
        page_size: "10",
        page: "1",
      });
      if (response.items.count < 10) {
        setMaxPage(1);
      } else {
        setMaxPage(Math.floor(response.items.count / 10));
      }
      setItemList(response.items.results);
    })();
  };
  const setScroll = () => {
    if (scrollValue) {
      document.getElementById("application")?.scroll(0, scrollValue);
    }
  }
  useEffect(() => {
    if (searchVal && itemList.length === 0) {
      HandleSearchClick();
    }
    setScroll();
  }, [])

  return (
    <Search>
      <SearchHeaderComponent handleInput={handleInput} HandleSearchClick={HandleSearchClick} searchVal={searchVal} />
      <RecommendKeyword keyword={keyword} HandleSearchClick={HandleSearchClick} />
      {itemList.length > 0 ? (
        <div style={{marginTop:'1rem'}}>
          <VerticalCardListComponent onClickHandler={handleNavigate}/>
          <div style={{ height: "1px" }} ref={ref} />
        </div>
        
      ) : (
        <NoSearchSection>
          {cnt !== 0 && (
            <>
              <img src={cantSearchImage} />
              <Font_md color={colors.GREY[400]}>{t("검색 결과가 없습니다.")}</Font_md>
            </>
          )}
        </NoSearchSection>
      )}
    </Search>
  );
};

const Search = styled.div`
  width: 100%;
  height: 100%;
`

const NoSearchSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
`

export default SearchPage;