import { styled } from "styled-components";
import chevron_right from "../../assets/chevron_right.svg"
import {colors} from "../../styled/colors";

interface MenuItemTypes {
    name: string;
    isBold?: boolean;
    onClick?: Function;
}

const MenuItemComponent = (props: MenuItemTypes) => {
    const tmpFunction = () => {}
    const buttonHandler = props.onClick || tmpFunction;
    return (
        <MenuItemWrapper onClick={() => buttonHandler()}>
            <MenuItem isBold={props.isBold}>{props.name}</MenuItem>
            <img src={chevron_right}/>
        </MenuItemWrapper>
    )
}

const MenuItemWrapper = styled.div`
    padding: 17px 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const MenuItem = styled.p<{isBold?: boolean;}>`
    font-size: 16px;
    color: ${colors.GREY[700]};
    font-weight: ${(props) => (props.isBold == undefined ? '400' : '600')};
`;
export default MenuItemComponent;