export enum Size{
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
}

export enum HeightSize{
  LARGE = "40px",
  MIDDLE = "32px",
  SMALL = "24px",
}

export enum SellerApplicationStatus{
  NORESISTERED = "NORESISTERED",
  PENDING = "PENDING",
  RESISTERED = "RESISTERED",
}

export type LoginType = {
  email:string;
  password: string;
}

export type LoginResType = {
  access:string;
  user:any;
  error:any;
}

export type SellerType = {
  name: string;
  birthday: string;
  phone_number: string;
  introduce: string;
  account_owner: string;
  account_bank: string;
  account_number: string;
  user: number;
  category: number;
  image1?: string;
  image2?: string;
  image3?: string;
  error?:any;
  formData?: any;
  swift_code?: string;
  business_number?: string;
  mailordersales_number: string;
}

export type UpdateUserType = {
  access?: string;
  formData : FormData;
}

export enum DeliverStatus{
  지정되지않음,
  결제대기,
  배송준비,
  검수중,
  배송중,
  배송완료,
  선정취소
}

export type VerticalCardType = {
  id: string,
  author: any,
  isAuction: boolean,
  name: string,
  price: number,
  created: string,
  is_selled:boolean,
  image_product: Array<any>,
  value_product: Array<any>,
  content: string,
  end_date: string,
  selling_type: number,
  start_date: string,
  image1: string,
  image2?: string,
  image3?: string,
  is_bidded: boolean,
  is_pending: boolean,
  bidder: number,
  cause?: string,
  dollar_price?: number,
  category?: number,
}

export enum AuctionType{
  입찰 = "입찰",
  선정 = "선정",
}

export type CategoryOptionType = {
  access?: string;
  value: string;
  product: number;
  option: number;
}

export type CreateProductType = {
  access?: string;
  formData: FormData;
}

export type GetItemListResType = {
  items?:any;
  error?:any;
  count?: number;
}

export type GetItemDetailResType = {
  data?:VerticalCardType;
  error?:any;
}

export type GetExchangeRateType= {
  data?:ExchangeRateType;
  error?:any;
}

export type ExchangeRateType = {
  basePrice: number;
  currencyCode: string;
  provider: string;
  timestamp: number;
};

export type GetItemListParams = {
  selling_type?: string,
  page_size: string,
  page: string,
  endDate?: string,
  category?: number,
  value_product?: string,
  search?: string,
  sort?: string,
  tag?: number,
  isDeleted?: boolean,
}

export enum SaleHistoryStatus {
  결제예정 = "결제예정",
  발송요청 = "발송요청",
  정산예정 = "정산예정",
  정산완료 = "정산완료",
  선정취소 = "선정취소",
  제안종료 = "제안종료",
  제안없음 = "제안없음",
}

export type CartData = {
  created: string;
  id: number;
  modified: string;
  product: {
    id: number;
    name: string;
    image1: string;
    is_selled: boolean;
    price: number;
  }
  dollar_price?: number;
}

export type OptionState = {
  id: number;
  eng_value: string;
  value: string;
}

export type CategoryOptionData = {
  eng_name: string;
  id: number;
  name: string;
  selects: object[];
}

export type CategoryData = {
  eng_name: string;
  name: string;
  options: CategoryOptionData[];
}

export type AddressData = {
  id?: number;
  address: string;
  detail_address: string;
  name: string;
  phone: string;
  user: number;
  last_name: string;
}

type ValueData = {
  id: number;
  option: {
    name: string;
    eng_name: string;
  };
  value: string;
  eng_value: string;
}

type BiddingsData = {
  created: string;
  id: number;
  cause: string;
  is_success: boolean;
  price: number;
  dollar_price: number;
  product: number;
  user: BiddingUserData;
}

export type ProductData = {
  id: number;
  author: any;
  value_product: ValueData[];
  biddings: BiddingsData[];
  is_selled: boolean;
  is_pending: boolean;
  is_bidded: boolean;
  success_price: number;
  created: string;
  selling_type: number;
  name: string;
  content: string;
  image1: string;
  image2?: string;
  image3?: string;
  price: number;
  start_date: string;
  end_date: string;
  status: number;
  track_id: string,
  calculate_status: number,
  calculated_date: string,
  carrier_id: string,
  carried_date: string,
  category: number;
}

export interface PurchaseData {
  id: number,
  created: string,
  price: number,
  currency: string,
  product: ProductData,
  address: string,
  detail_address: string,
  name: string,
  phone:string,
  memo: string,
  is_done: boolean
}

export interface PurchaseListData {
  amount: number,
  created: string,
  id: number,
  order_id: string,
  payment_type: string,
  purchases: PurchaseData[],
  user: number
}

export interface PurchaseExceptProductData {
  id: number,
  order_id: string,
  created: string,
  price: number,
  currency: string,
  address: string,
  detail_address: string,
  name: string,
  phone:string,
  memo: string,
  is_done: boolean,
  product: number,
}

export type BiddingUserData = {
  id: number,
  thumbnail: string,
}

export type BiddingData = {
  id: number,
  product: ProductData,
  purchase: PurchaseExceptProductData,
  created: string,
  price: number,
  is_success: false,
  cause: string,
  user: number,
  dollar_price: number;
}

export type UploadPurchaseData = {
  id: number,
  order_id: string,
  created: string,
  price: number,
  currency: string,
  address: string,
  detail_address: string,
  name: string,
  phone: string,
  memo: string,
  is_done: boolean,
  product: number
}

export type UploadData = {
  id: number,
  product: ProductData,
  purchase: UploadPurchaseData,
  created: string,
  user: number
}

export enum CalculateStatus {
  지정되지않음,
  정산예정,
  정산완료,
}

export type PayResponseType= {
  status: number;
  error?:any;
}

export type CreateCartResType = {
  status: number;
  error?:any;
}

export type PresetData = {
  key: string;
  value: string;
}

export type Categories = {
  id: number;
  name: string;
  eng_name: string;
  selects: any[]; // selects 배열의 데이터 타입에 따라 수정
}

export type Category = {
  eng_name: string;
  name: string;
  selects: object[];
}

export type ReUploadData = {
  product: {
    id: number;
    price: number;
    start_date: string;
    end_date: string;
  },
  user: number;
}

export type NoticeData = {
  id: number;
  created: string;
  title: string;
  content: string;
}