import { styled } from "styled-components";
import { Size } from "types/types";
import {colors} from "../../styled/colors";

interface ButtonTypes {
    text:string;
    size:Size;
    onClick?:Function;
  }

const OutlineButtonComponent = (props: ButtonTypes) => {
    const tmpFunction = () => {}
    const buttonHandler = props.onClick || tmpFunction;
    return (
            <Button
            size={Size.LARGE}
            onClick={()=> {
                buttonHandler();
            }}>
            {props.text}
            </Button>
    )
}

const Button = styled.div<{ size: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.GREY[200]};
  color: ${colors.GREY[700]};
  width: ${(props) => (props.size === 'LARGE' ? 'calc(100% - 2rem)' : '50%')};
  font-weight: 600;
  height: 48px;
  margin-top: 5px;
  padding: 0px 24px;
  border-radius: 6px;
`;

export default OutlineButtonComponent;