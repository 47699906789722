import { styled } from "styled-components";
import { Font_md, Font_sm } from "styled/fonts";
import { checkAndConvertCurrency, formatCurrency } from 'api/StringUtils';
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getPresetData } from "api/api";
import { exchangeRateState } from "recoil/homeRecoil";

interface calculateInfoType {
    price: number;
    currency: string;
}

const CalculateInfo = (props: calculateInfoType) => {

    const [charge, setCharge] = useState(0);
    const exchangeRate = useRecoilValue(exchangeRateState);

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);


    const fetchPresetData = async() => {
        const data = await getPresetData();
        const findCharge = data.filter((item)=> item.key == "fee");
        setCharge(Number(findCharge[0].value));
      }

    const roundToCharge = (price: number) : number=> {
        return Math.round(price * (charge/100));
    }

  
    useEffect(()=> {
        fetchPresetData();
        i18n.changeLanguage(language);
    },[])

    return (
        <CalculateInfoWrapper>
            <Font_md isBold>{t("정산 정보")}</Font_md>
            {props.price &&
            <InfoList>
            <Info>
                <Font_sm>{t("상품금액")}</Font_sm>
                <Font_sm>{checkAndConvertCurrency(language, props.price, props.currency, exchangeRate?.basePrice)}</Font_sm>
            </Info>
            <Info>
                <Font_sm>{t("리스트잇 수수료")} ({charge}%)</Font_sm>
                <Font_sm>{checkAndConvertCurrency(language, roundToCharge(props.price), props.currency, exchangeRate?.basePrice)}</Font_sm>
            </Info>
            <Info>
                <Font_sm isBold>{t("총 정산금액")}</Font_sm>
                <Font_sm isBold>{checkAndConvertCurrency(language, props.price - roundToCharge(props.price), props.currency, exchangeRate?.basePrice)}</Font_sm>
            </Info>
        </InfoList>}
        </CalculateInfoWrapper>
    )
}

const CalculateInfoWrapper = styled.div`
`

const InfoList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1rem;
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
`

export default CalculateInfo;