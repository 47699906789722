import {atom} from "recoil";

export const sortDataState = atom<string>({
    key:'sortDataState',
    default: '-id',
})

export const typeDataState = atom<string>({
    key:'typeDataState',
    default: '1',
})

export const checkItemsState = atom<boolean[]>({
    key:'checkItemsState',
    default: [false, false],
})

export const isFirstState = atom<boolean>({
    key:'isFirstState',
    default: true,
})

export const filterPageState = atom<number>({
    key:'filterPageState',
    default: 1,
})
