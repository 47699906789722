import {useEffect, useState} from "react";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import SignupTextWrapper from "components/SignupTextComponent";
import { Checkbox, VStack, Divider, Text} from "@chakra-ui/react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { checkItemsState } from "recoil/signupRecoil";
import next_img from "assets/chevron_right.svg";
//       <Route path="/mypage/terms-of-service" element={<TermsofUsePage/>}/>
{/* <Route path="/mypage/privacy-policy" element={<PrivacyPolicyPage/>}/> */}
const TermsPage = () => {
  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState)
  
  const location = useLocation();
  const navigate = useNavigate();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const type = params.get('type');
  const email = params.get('email');

  const HandleClick = () => {
    if(type === 'social')
      navigate('/signup/personal?type=social&email=' + email);
    else
      navigate('/signup/email');
  };

  const isCheckEssential = () => {
    return checkedItems[0] && checkedItems[1] && checkedItems[2];
  }

  const [checkedItems, setCheckedItems] = useRecoilState(checkItemsState)
  const allChecked = isCheckEssential();

  const checkboxWrapperStyle = {
    margin:'0px 16px 24px 16px',
    fontSize:'16px'
  }

  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])

  return (
    <Terms>
      <ProcessHeader isSearchForm={false} title={t("회원가입")} stepPercent={25} />
      <SignupTextWrapper 
        topString="리스트잇 시작을 위해"
        highlight="이용약관"
        bottomString="에 동의해주세요"/>
      <VStack 
        align='left'
        spacing='1rem'
        style={checkboxWrapperStyle}>
        <Checkbox 
          isChecked={checkedItems.every(Boolean)}
          onChange={(e) => setCheckedItems([e.target.checked, e.target.checked, e.target.checked, e.target.checked])}
          size='lg' 
          colorScheme='green'>
          <Text fontSize='md' as='b'>{t("리스트잇 이용약관에 모두 동의합니다.")}</Text>
        </Checkbox>
        <Divider />
        <Checkbox 
          isChecked={checkedItems[0]}
          onChange={(e) => setCheckedItems([e.target.checked, checkedItems[1], checkedItems[2], checkedItems[3]])}
          size='lg' 
          colorScheme='green' >
          <Text fontSize='md'>{t("[필수] 만 14세 이상입니다.")}</Text>
        </Checkbox>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <Checkbox 
            isChecked={checkedItems[1]}
            onChange={(e) => setCheckedItems([checkedItems[0], e.target.checked, checkedItems[2], checkedItems[3]])}
            size='lg' 
            colorScheme='green' >
            <Text fontSize='md'>{t("[필수] 서비스 이용약관에 동의합니다.")}</Text>
          </Checkbox>
          <BtnForDetail onClick={() => navigate("/mypage/terms-of-service")} src={next_img}/>
        </div>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <Checkbox 
            isChecked={checkedItems[2]}
            onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], e.target.checked, checkedItems[3]])}
            size='lg' 
            colorScheme='green' >
            <Text fontSize='md'>{t("[필수] 개인정보처리방침에 동의합니다.")}</Text>
          </Checkbox>
          <BtnForDetail onClick={() => navigate("/mypage/privacy-policy")} src={next_img}/>
        </div>
        <Checkbox 
          isChecked={checkedItems[3]}
          onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], e.target.checked])}
          size='lg' 
          colorScheme='green' >
          <Text fontSize='md'>{t("[선택] 마케팅 정보 수신에 동의합니다.")}</Text>
        </Checkbox>
      </VStack>
      <ButtonWrapper>
        <ButtonComponent 
          isValid={allChecked} 
          text={t("다음")}
          size={Size.LARGE}
          onClick={() => HandleClick()}/>
      </ButtonWrapper>
    </Terms>
  );
};

const Terms = styled.div`

`

const ButtonWrapper = styled.div`
  display:flex;
  justify-content: center;
`

const BtnForDetail = styled.img`

`
export default TermsPage;