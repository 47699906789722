import { styled } from "styled-components";
import card_img from "assets/card_img.svg";
import { SaleHistoryStatus, Size } from "types/types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { langState, loginState } from "recoil/loginRecoil";
import { UploadData } from "types/types";
import { getProducts } from "api/SellerApi";
import { isoStringToDate } from "api/StringUtils";
import Loading from "components/Loading";
import SaleCard from "./SaleCard";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import ButtonComponentMd from "components/common/buttons/ButtonComponentMd";
import { colors } from "styled/colors";
import EmptyMessage from "components/common/EmptyMessage";
import { useTranslation } from "react-i18next";

const SellerBidHistory = () => {
    const navigate = useNavigate();

    const [sellerBidData, setSellerBidData] = useState<UploadData[]>([]);
    const [loading, setLoading] = useState(false);
    const user: any = useRecoilValue(loginState);

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const fetchBidData = async () => {
      try {
        setLoading(true);
        const dataRes = await getProducts(user.access);
        console.log(dataRes);
        const filterData = dataRes.filter((item) => item.product.selling_type == 2 && item.product.is_pending && item.product.status == 8).reverse();
        console.log(filterData);

        setSellerBidData(filterData);
        setLoading(false);
      } catch(error) {
        console.log(error);
      }
    }

    useEffect(()=> {
        i18n.changeLanguage(language);
        fetchBidData();
    },[])


    return (
        <BidHistoryWrapper>
          {(sellerBidData && !loading) ?
          <>
          {sellerBidData.length !== 0 ?
          sellerBidData.map((list)=> 
          <BidList>
          <SaleCard key={list.id}
          name={list.product.name}
          price={list.product.price}
          image={list.product.image1}
          bidStatus={t("제안마감")}
          isEndPage
          currency={list.product.author.swift_code ? 'USD' : 'KRW'}
          />
          <ButtonWrapper>
            <ButtonComponentMd
            isValid={true}
            text={t("제안 확인하기")}
            onClick={()=> {navigate(`/seller-auction-history/detail/${list.id}`)}}
            size={Size.LARGE}/>
          </ButtonWrapper>
          </BidList>
          ): 
          <EmptyMessage/>}
          </> 
          : <Loading/>}
        </BidHistoryWrapper>
    )
};

const BidHistoryWrapper = styled.div`
`

const BidList = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${colors.GREY[100]}
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default SellerBidHistory;