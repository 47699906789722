import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { styled } from "styled-components";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import BiddingHistory from "components/auctionHistory/BiddingHistory";
import BidHistory from "../../components/auctionHistory/BidHistory";
import { loginState } from "recoil/loginRecoil";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "styled/colors";

const AuctionHistoryPage = () => {

    const navigate = useNavigate();

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const [tabIndex, setTabIndex] = useState(0);
    const { id } = useParams();

    useEffect(()=>{
        i18n.changeLanguage(language);
      }, [])

    useEffect(()=> {
        setTabIndex(Number(id));
    }, [id])

    return (
      <BiddingHistoryWrapper>
        <ProcessHeaderComponent
          title={t("제안 내역")}
          isSearchForm={false}
          path="/mypage"
        />
        <Tabs
          variant="line"
          size="md"
          isFitted
          colorScheme="gray"
          index={tabIndex}
        >
          <TabList>
            <TabItem
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => navigate("/auction-history/0")}
            >
              {t("제안")}
            </TabItem>
            <TabItem
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => navigate("/auction-history/1")}
            >
              {t("선정")}
            </TabItem>
          </TabList>
          <TabPanels>
            <TabPanel padding={0}>
              <BiddingHistory />
            </TabPanel>
            <TabPanel padding={0}>
              <BidHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </BiddingHistoryWrapper>
    );
};

const BiddingHistoryWrapper = styled.div`
`

const TabItem = styled(Tab)`
  font-weight: 400;
`
export default AuctionHistoryPage;