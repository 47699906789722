import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import ImagesSlide from "components/common/ImageSlide";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { Font_2xl, Font_md, Font_sm, Font_xs } from "styled/fonts";
import {
    changeKrwToUsd, changeUsdToKrw, fixedExchangePrice,
    formatCurrency,
    formatDateStringToEnglish,
    formatDateToKorean,
} from "api/StringUtils";
import { Divider, useDisclosure } from "@chakra-ui/react";
import { Tabs, TabList, Tab, Button } from "@chakra-ui/react";
import profile_default_img from "../../assets/profile_default_img.svg";
import LoginReqImg from "assets/login_req.svg";
import more_horiz from "assets/more_horiz.svg";
import ProfileComponent from "components/common/ProfileComponent";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import MainFooterComponent from "components/main/MainFooter";
import AuctionStatusComponent from "components/item/AuctionStatusComponent";
import { Size } from "types/types";
import { colors } from "styled/colors";
import { getItemDetail, getPresetData, getChart } from "api/api";
import { VerticalCardType } from "types/types";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { langState, loginState } from "recoil/loginRecoil";
import SellerInfoComponent from "components/item/SellerInfoComponent";
import ModalComponent from "components/common/ModalComponent";
import { isNotLogin } from "api/UserUtil";
import { getBiddingDataUser } from "api/UserHistoryApi";
import { exchangeRateState } from "recoil/homeRecoil";
import help_img from "assets/help_outline.svg";
import ChartComponent from "../../components/item/ChartComponent";
import TradingHistory, {ChartProps} from "../../components/item/TradingHistory";
import InfoBubbleComponent from "components/item/InfoBubbleComponent";
import Inspection from "../../assets/free_inspection.svg";
import Shipping from "../../assets/free_shipping.svg";
import {
    DeliverInfoContainer,
    ImgContainer,
    InfoContainer,
    InfoContent,
    InfoTitle,
    TextContainer
} from "./ItemDetailPage";

const ItemDetailPage_Bid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const cardId: string = params.get("id") || "1";
  const originPath: string = params.get("origin-path") || "";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [priceStr, setPriceStr] = useState("");
  const [myBidPrice, setMyBidPrice] = useState(0);
  const [myBidPriceAboutDollar, setMyBidPriceAboutDollar] = useState(0);

  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState);

  const [itemInfo, setItemInfo] = useState<VerticalCardType>();
  const [images, setImages] = useState<any>([]);
  const user: any = useRecoilValue(loginState);

  const [deliveryPrice, setDeliverPrice] = useState(0);
  const exchangeRate = useRecoilValue(exchangeRateState);
  const [isMyProduct, setIsMyProduct] = useState(false);

  // true : do not yet bid, false : already did bid
  const bidItemStatus: boolean = !itemInfo?.is_bidded;
  // true : bid end
  let isBidEnd: boolean =
    itemInfo?.is_selled === undefined ? false : itemInfo?.is_selled;
  if (itemInfo?.is_pending !== undefined && itemInfo.is_pending === true)
    isBidEnd = true;

  const [chartData, setChartData] = useState<ChartProps[]>([]);
  const [grading, setGrading] = useState<string | undefined>('');
  const [filterData, setFilterData] = useState<ChartProps[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isBuubleShow, setIsBubbleShow] = useState<boolean>(false);
  const bubbleRef = useRef<HTMLImageElement>(null);

  const onClickHelpBtn = () => {
    setIsBubbleShow(true);
    setTimeout(()=> setIsBubbleShow(false), 3000);
  }

  const onClickBidBtn = async () => {
    if (await isNotLogin(user)) {
      onOpen();
    } else {
      navigate("/bid?id=" + cardId);
    }
  };
  const candidates: number = itemInfo?.bidder || 0;
  const isActiveAuctionButton = (): boolean => {
    if (itemInfo) {
      const today = new Date();
      const startDate = new Date(itemInfo.start_date);

      if (today < startDate) return false;
      return true;
    }
    return false;
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    (async () => {
      const data = await isNotLogin(user);
      let response;
      if (data) response = await getItemDetail(cardId);
      else response = await getItemDetail(cardId, user);
      if(!data && user.user.id === response.data?.author.user.id){
        setIsMyProduct(true);
      }
      if (response.error) {
        console.error(response.error);
      } else {
        const preset = await getPresetData();
        const findDeliveryPrice = preset.filter(
          (item) => item.key == "delivery"
        );
        setDeliverPrice(Number(findDeliveryPrice[0].value));

        if (response.data?.is_bidded) {
          const biddedData = await getBiddingDataUser(
            user.access,
            response.data?.id || "1"
          );
          setMyBidPrice(biddedData[0].price);
          setMyBidPriceAboutDollar(biddedData[0].dollar_price);
        }
        setItemInfo(response.data);
        let list = [];
        list.push(response.data?.image1);
        if (response.data?.image2) list.push(response.data?.image2);
        if (response.data?.image3) list.push(response.data?.image3);
        setImages(list || []);
        setPriceStr(
          language === "ko-KR"
            ? formatCurrency(response.data?.price || 0) + "원"
            : "KRW " + formatCurrency(response.data?.price || 0)
        );
        const currentDate = new Date();
        const threeMonthsAgo = new Date(currentDate);
        threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
        const res = await getChart(response.data?.value_product);
        const filterData = res.filter((item: any) => new Date(item.created) >= threeMonthsAgo);
        const gradingOption = response.data?.value_product.find(
          (item) => item.option.name === "그레이딩"
        );
        const gradingValue = gradingOption ? gradingOption.value : undefined;
        setGrading(gradingValue);
        setChartData(res);
        setFilterData(filterData);
      }
    })();
  }, []);
  useEffect(() => {
    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate);
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    if (tabIndex === 0) {
        const filteredData = chartData.filter(item => new Date(item.created) >= threeMonthsAgo);
        setFilterData(filteredData);
    } else if (tabIndex === 1) {
        const filteredData = chartData.filter(item => new Date(item.created) >= sixMonthsAgo);
        setFilterData(filteredData);
    } else if (tabIndex === 2) {
        const filteredData = chartData.filter(item => new Date(item.created) >= oneYearAgo);
        setFilterData(filteredData);
    } else if (tabIndex === 3) {
        setFilterData(chartData);
    }
}, [tabIndex]);
  return (
    <ItemDetail>
      <ProcessHeaderComponent
        path={originPath !== "" ? originPath : ""}
        title=""
        isSearchForm={false}
        productId={cardId}
      />
      <ImagesSlide images={images} molecule={4} denominator={5} arrow={true}/>
      <AuctionStatusComponent
        startDate={itemInfo?.start_date}
        endDate={itemInfo?.end_date}
        candidatesCnt={candidates}
        step={"40%"}
        is_pending={itemInfo?.is_pending}
        is_selled={itemInfo?.is_selled}
      />
      <TitleSection>{itemInfo?.name}</TitleSection>
      <PriceSection>
        <Font_sm color="#2D3748">{t("판매 희망가")}</Font_sm>
        <Font_2xl isBold>
        {language === "ko-KR"
            ? (itemInfo?.author?.swift_code ?
              "US $" + changeKrwToUsd(itemInfo?.price, exchangeRate?.basePrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              : formatCurrency(itemInfo?.price || 0) + "원")
            : (itemInfo?.author?.swift_code ?
              "$" + changeKrwToUsd(itemInfo?.price, exchangeRate?.basePrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              : "KRW " + formatCurrency(itemInfo?.price || 0) 
              )}
        </Font_2xl>
        {language === "ko-KR" ? (
          (itemInfo?.author?.swift_code &&
            <ExchangeInfo>
              <Font_sm>
                약 {changeUsdToKrw(itemInfo?.price / fixedExchangePrice, exchangeRate?.basePrice).toLocaleString()} 원
              </Font_sm>
              <div style={{ position: "relative" }}>
                    <HelpImg src={help_img} onClick={onClickHelpBtn}/>
                    <InfoBubbleComponent isShow={isBuubleShow} setIsShow={setIsBubbleShow} text={t("환율에 따라 금액이 변동될 수 있으며, 선정될 경우, 가격 제안 제출 시 환율로 결제됩니다.")}/>
                </div>
            </ExchangeInfo>)
        ):
        (itemInfo?.author?.swift_code ?
          ''
          :
          <>
            <Font_sm>Approximately</Font_sm>
            <ExchangeInfo>
              <Font_sm>
                US $
                  {formatCurrency(
                      changeKrwToUsd(itemInfo?.price, exchangeRate?.basePrice, true)
                  )}
              </Font_sm>
                <div style={{ position: "relative" }}>
                    <HelpImg src={help_img} onClick={onClickHelpBtn}/>
                    <InfoBubbleComponent isShow={isBuubleShow} setIsShow={setIsBubbleShow} text={t("환율에 따라 금액이 변동될 수 있으며, 선정될 경우, 가격 제안 제출 시 환율로 결제됩니다.")}/>
                </div>
            </ExchangeInfo>
          </>
        )}
      </PriceSection>
      <Divider style={{ marginTop: "1rem" }} />

      <OptionSection>
        {itemInfo?.value_product.map((item: any) => (
          <tr>
            <td className="title">
              {language === "ko-KR" ? item.option.name : item.option.eng_name}
            </td>
            <td className="content">
              {language === "ko-KR"
                ? item.value !== ""
                  ? item.value
                  : item.eng_value
                : item.eng_value !== ""
                ? item.eng_value
                : item.value}
            </td>
          </tr>
        ))}
      </OptionSection>
      <Divider style={{ marginTop: "1rem" }} />
      <DeliverInfoSection>
        <tr>
          <td className="title">{t("판매 희망가")}</td>
          <td className="content">{priceStr}</td>
        </tr>
        <tr>
          <td className="title">{t("제안 마감 ")}</td>
          <td className="content">
            {language === "ko-KR"
              ? formatDateToKorean(itemInfo?.end_date)
              : formatDateStringToEnglish(itemInfo?.end_date)}
          </td>
        </tr>
      </DeliverInfoSection>

      <Divider className="thick" style={{ marginTop: "1rem" }} />
        <DeliverInfoContainer>
            <span style={{ fontSize: '16px', fontWeight: 600 }}>{t('배송 정보')}</span>
            <InfoContainer>
                <ImgContainer><img src={Inspection}/></ImgContainer>
                <TextContainer>
                    <InfoTitle>{t('무료 검수')}</InfoTitle>
                    <InfoContent>{t('무료 검수 내용')}</InfoContent>
                </TextContainer>
            </InfoContainer>
            <InfoContainer>
                <ImgContainer><img src={Shipping}/></ImgContainer>
                <TextContainer>
                    <InfoTitle>{t('무료 배송')}</InfoTitle>
                    <InfoContent>{t('무료 배송 내용')}</InfoContent>
                </TextContainer>
            </InfoContainer>
        </DeliverInfoContainer>
      {/*<DeliverInfoSection>*/}
      {/*  <tr>*/}
      {/*    <td className="title">{t("배송비")}</td>*/}
      {/*    <td className="content">*/}
      {/*      {language === "ko-KR"*/}
      {/*        ? formatCurrency(deliveryPrice) + "원"*/}
      {/*        : "$" +*/}
      {/*          formatCurrency(*/}
      {/*            changeKrwToUsd(deliveryPrice, exchangeRate?.basePrice)*/}
      {/*          )}*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*</DeliverInfoSection>*/}
      <Divider className="thick" style={{ marginTop: "1rem" }} />
      <ItemDescSection>
        <Font_md isBold>{t("상품 정보")}</Font_md>
        <Font_md>{itemInfo?.content}</Font_md>
      </ItemDescSection>
      <Divider className="thick" style={{ marginTop: "1rem" }} />
      {/*filterData.length && exchangeRate ? (
        <>
          <div style={{ width: "calc(100% - 2rem)", marginTop: "1rem" }}>
            <Font_md isBold>{t("시세 차트")}</Font_md>
          </div>
          <div style={{ width: "100%", padding: "0 16px 0 16px" }}>
            <Tabs
              variant="line"
              size="md"
              isFitted
              colorScheme="black"
              width={"100%"}
              style={{ marginTop: "1rem" }}
            >
              <TabList>
                <ChartTab
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(0)}
                >
                  {t("3개월")}
                </ChartTab>
                <ChartTab
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(1)}
                >
                  {t("6개월")}
                </ChartTab>
                <ChartTab
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(2)}
                >
                  {t("1년")}
                </ChartTab>
                <ChartTab
                  _selected={{
                    fontWeight: 600,
                    borderBottom: `2px solid ${colors.GREY[700]}`,
                  }}
                  onClick={() => setTabIndex(3)}
                >
                  {t("전체")}
                </ChartTab>
              </TabList>
            </Tabs>
          </div>
          <ChartComponent chartData={filterData} exchangeRate={exchangeRate} language={language} />
          <TradingHistory
            chartData={filterData.sort(
              (a, b) =>
                new Date(b.created).getTime() - new Date(a.created).getTime()
            )}
            summary
            language={language}
            exchangeRate={exchangeRate}
            sellingType={itemInfo?.selling_type}
          />
          <Button
            onClick={() => navigate(`/trading-history?id=${cardId}`)}
            width="calc(100% - 2rem)"
            variant="outline"
            colorScheme="gray"
            style={{ marginTop: "1rem" }}
          >
            {t("거래 내역 더보기")}
          </Button>
          <Divider className="thick" style={{ marginTop: "1rem" }} />
        </>
      ) : (
        <></>
      )*/}
      <div style={{ width: "100%" }}>
        <ProfileWrapper>
          {itemInfo?.author ? (
            <ProfileComponent
              photo={itemInfo?.author.user.thumbnail || profile_default_img}
              nickname={itemInfo?.author.user.name || ""}
              code={itemInfo?.author.user.code}
            />
          ) : (
            <ProfileComponent
              photo={profile_default_img}
              nickname={""}
              code={0}
            />
          )}
          {/* <img src={more_horiz} /> */}
        </ProfileWrapper>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IntroductionSection>
            <Font_md>
              {itemInfo?.author ? itemInfo?.author.summary : ""}
            </Font_md>
          </IntroductionSection>
        </div>
        <SellerInfoComponent author={itemInfo?.author} />
        <CautionWrapper>
          <CautionSection>
            <Font_md isBold>{t("주의사항")}</Font_md>
            <Font_xs>
              {t(
                "소비자의 귀책사유로 인한 상품의 멸실, 훼손된 경우, 단순변심으로 인한 환불 및 교환은 불가능하며, 이외 다른 사유로 환불 및 교환을 희망하시는 경우에는 contact@list-it.xyz로 문의 부탁 드립니다."
              )}
            </Font_xs>
          </CautionSection>
        </CautionWrapper>
      </div>
      <div style={{ width: "100%", marginTop: "1rem" }}>
        <MainFooterComponent />
      </div>

      <ButtonWrapper_Auction>
        {isBidEnd ? (
          <ButtonComponent
            isValid={false}
            text={t("제안 마감")}
            size={Size.LARGE}
          />
        ) : (
          <>
            {isMyProduct ? (
              <Font_md isBold color={colors.GREEN[500]}>
                {candidates}
                {t("명 제안중")}
              </Font_md>
            ) : bidItemStatus ? (
              <>
                <Font_md isBold color={colors.GREEN[500]}>
                  {candidates}
                  {t("명 제안중")}
                </Font_md>
                <ButtonComponent
                  onClick={() => onClickBidBtn()}
                  isValid={isActiveAuctionButton()}
                  text={t("가격 제안하기")}
                  size={Size.LARGE}
                />
              </>
            ) : (
              <>
                <Font_md isBold color={colors.GREY[400]}>
                  {language === "ko-KR"
                    ? formatCurrency(myBidPrice) + "원"
                    : "$" +
                      formatCurrency(
                        myBidPriceAboutDollar
                      )}
                  &nbsp; {t("제안 완료")}
                </Font_md>
                <ButtonComponent
                  isValid={true}
                  text={t("다시 제안하기")}
                  size={Size.LARGE}
                  onClick={() => navigate(`/bid?id=${cardId}&isReBid=true`)}
                />
              </>
            )}
          </>
        )}
      </ButtonWrapper_Auction>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        content={
          language === "ko-KR" ? `로그인하고 더 많은 기능을` : `Sign up today!`
        }
        bottomContent={language === "ko-KR" ? `이용해보세요!` : ""}
        leftBtnString={t("돌아가기")}
        rightBtnString={t("로그인")}
        onClickSubmit={() => navigate("/onboarding")}
        imageUrl={LoginReqImg}
      />
    </ItemDetail>
  );
};

const ItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleSection = styled.div`
  margin-top: 1rem;
  width: calc(100% - 2rem);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.02em;
`;

const PriceSection = styled.div`
  margin-top: 1rem;
  width: calc(100% - 2rem);
`;

const ItemDescSection = styled.div`
  margin-top: 1rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OptionSection = styled.table`
  border-spacing; 8px;
  margin-top: 1rem;
  width: calc(100% - 2rem);
  border-spacing;
  8px;
  tr {
    td.title {
      width: 35%;
      font-weight: 600;
    }

    td.content {
      width: calc(65% - 10px);
    }
  }
`;

const DeliverInfoSection = styled.table`
  margin-top: 1rem;
  width: calc(100% - 2rem);
  tr {
    td.title {
      width: 35%;
      font-weight: 600;
    }

    td.content {
      width: calc(65% - 10px);
    }
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  padding: 19px 16px;
  justify-content: space-between;
`;

const IntroductionSection = styled.div`
  width: calc(100% - 2rem);
`;

const ButtonWrapper_Auction = styled.div`
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-top: 1px solid ${colors.GREY[100]};
`;

const CautionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CautionSection = styled.div`
  width: calc(100% - 2rem);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ChartTab = styled(Tab)`
  font-weight: 400;
`

const ExchangeInfo = styled.div`
  display: flex;
  gap: 2px;
  align-items: flex-start;
`

const HelpImg = styled.img`
  margin-top: 4px;
`
export default ItemDetailPage_Bid;
