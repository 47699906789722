import axios from "axios";
import { BiddingData, PurchaseData, PurchaseExceptProductData, PurchaseListData } from "types/types";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getBiddingData = async(access: string) : Promise<BiddingData[]> => {
    try{
        const response = await axios.get(API_ENDPOINT + '/accounts/v2/bidding', {
            headers: {
                'Authorization': `Bearer ${access}`
              }
        })
        return response.data;
    } catch(error: any) {
        throw error;
    }
}

export const getPurchaseData = async(access: string) : Promise<PurchaseListData[]>=> {
    try{
        const response = await axios.get(API_ENDPOINT + '/accounts/v2/purchase-group/', {
            headers: {
                'Authorization': `Bearer ${access}`
              }
        })
        return response.data;
    } catch(error: any) {
        throw error;
    }
}

export const getOnePurchaseData =async (access: string, id: number) => {
    try{
        const response = await axios.get(API_ENDPOINT + `/accounts/v2/purchase-group/${id}/`, {
            headers: {
                'Authorization': `Bearer ${access}`
              }
        })
        return response.data;
    } catch(error: any) {
        throw error;
    }
}

export const getOneBiddingData = async(access: string, bidding_id: number) : Promise<BiddingData> => {
    try{
        const response = await axios.get(API_ENDPOINT + `/accounts/v2/bidding/${bidding_id}/`, {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        })
        return response.data;
    } catch(error: any) {
        throw error;
    }
}

export const MakeIsDoneDataTrueForBidding = async (access: string, list: PurchaseExceptProductData | PurchaseData) => {
    try {
        const response = await axios.patch(API_ENDPOINT + `/accounts/v2/purchase/${list.id}/`, 
        {
            is_done: true
        },{
            headers: {
                'Authorization': `Bearer ${access}`
            }
        })
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const getBiddingDataUser = async(access: string, productId: string) => {
    try{
        const response = await axios.get(API_ENDPOINT + `/accounts/v2/bidding/?product_id=${productId}`, {
            headers: {
                'Authorization': `Bearer ${access}`
              }
        })
        return response.data;
    } catch(error: any) {
        console.log(error);
    }
}