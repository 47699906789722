import styled from "styled-components";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import logo from "assets/main_logo.svg";
import { colors } from "styled/colors";

const Loading = () => {
    return (
        <LoadingWrapper>
            <ProgressWrapper>
                <CircularProgress isIndeterminate capIsRound color={colors.GREEN[500]} trackColor={"#daf5ee"}/>
            </ProgressWrapper>
            <Logo src={logo}/>
        </LoadingWrapper>
    );
};

const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 98px);
    justify-content: center;
`

const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.img`
    position
    width: 100px;
    height: 20px;
    margin-top: 1rem;
`



export default Loading;