import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_md, Font_sm } from "styled/fonts";
import { langState, loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { NoticeData } from "types/types";
import { getNoticeData } from "api/api";
import { isoStringToDate } from "api/StringUtils";

const NoticePage = () => {
    const navigate = useNavigate();

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    const user: any = useRecoilValue(loginState);

    const [noticeData, setNoticeData] = useState<NoticeData[]>();

    const fetchNoticeData = async () => {
        try {
            const dataRes = await getNoticeData(user.access);
            setNoticeData(dataRes);
        } catch (error) {
            console.log(error);
        }

    }

  
    useEffect(()=> {
        i18n.changeLanguage(language);
        fetchNoticeData();
    },[])

    return (
        <NoticeWrapper>
            <ProcessHeaderComponent
            title={t("공지사항・이벤트")}
            isSearchForm={false}/>
            {noticeData && noticeData.map((item)=> (
                <List onClick={()=> {navigate(`/mypage/notice/${item.id}`)}}>
                    <Font_md>{item.title}</Font_md>
                    <Font_sm color={colors.GREY[400]}>{isoStringToDate(item.created, false, true)}</Font_sm>
                </List>
            ))}
        </NoticeWrapper>
    )
};

const NoticeWrapper = styled.div`
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

export default NoticePage;