import styled from "styled-components";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { Font_2xl, Font_md, Font_sm } from "styled/fonts";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

const PrivacyPolicyPage = () => {
    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <PrivacyPolicyWrapper>
            <ProcessHeaderComponent
            title={t("개인정보처리방침")}
            isSearchForm={false}/>
        {language == "ko-KR" ?
                    <Content>
                    <Font_2xl isBold>개인정보처리방침</Font_2xl>
                    <ContentItem>
                        <Font_md isBold>제1조(개인정보의 처리 목적)</Font_md>
                        <Font_sm>회사는 다음의 목적을 위하여 개인정보를 처리합니다.</Font_sm>
                        <Font_sm>1. 회원 가입 및 관리: 회원가입의사 확인, 본인 식별인증, 만14세 이상 여부 확인, 회원자격 유지 및 관리, 서비스 부정이용 방지, 각종 고지·통지 등</Font_sm>
                        <Font_sm>2. 서비스 제공: 기본/맞춤형 서비스 제공, 계약서·청구서 발송, 본인인증, 연령인증, 요금결제 및 정산, 채권추심 등</Font_sm>
                        <Font_sm>3. 고충처리: 이용자의 신원 확인, 고충사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등</Font_sm>
                        <Font_sm>4. 마케팅 및 광고에의 활용: 맞춤형 광고 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 등</Font_sm>
                        <Font_sm>5. 서비스 개선 및 개발: 기존 서비스 개선 및 신규 서비스, 맞춤형 서비스 개발 등</Font_sm>
                        <Font_sm>6. 가명정보의 활용: 통계작성, 과학적 연구, 공익적 기록보존 등을 위한 가명처리 및 가명정보의 활용</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제2조(처리하는 개인정보 항목)</Font_md>
                        <Font_sm>① 회사는 서비스 이용자에 대하여 다음의 개인정보항목을 수집하여 처리하고 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 회원 가입 시 기본수집사항(필수항목): ID, 비밀번호, 전화번호(3호에 해당하지 아니하는 경우에 한함), 생년월일, 이메일 주소</Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 회원 가입 시 기본수집사항(선택항목): 이름, 주소, 사업자 여부, 사업자명, 사업자등록번호, 통신판매업 신고번호</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 회원가입 선택 수단에 따라 회원 가입 승인 목적에서 제휴사로부터 추가로 제공받는 사항</Font_sm>
                        <Table>
                            <thead>
                                    <tr>
                                        <Td rowSpan={2}>회원 가입 시 이용자가 선택한 제휴사/서비스</Td>
                                        <Td colSpan={2}>제휴사 제공 항목</Td>
                                    </tr>
                                    <tr>
                                        <Td>필수</Td>
                                        <Td>선택</Td>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Td>Google ID로 회원가입(제휴사: ***)</Td>
                                    <Td>이메일, 이름, 프로필 이미지, 로케일(사용지역 및 언어)</Td>
                                    <Td></Td>
                                </tr>
                                <tr>
                                    <Td>Apple ID로 회원가입(제휴사: ***)</Td>
                                    <Td>이메일</Td>
                                    <Td></Td>
                                </tr>
                            </tbody>
                        </Table>
                        <Font_sm>&nbsp;&nbsp;4. 서비스 이용과정에서 수집: 판매를 위한 판매자 정보(이름, 반품주소, 전화번호, 사업자 여부, 사업자명, 사업자등록번호, 통신판매업 신고번호), 판매게시내역, 입찰 및 낙찰, 구매내역</Font_sm>
                        <Font_sm>&nbsp;&nbsp;5. 서비스 이용과정에서 자동으로 수집: IP주소, 쿠키, 기기 정보(모델명, OS), 앱 설정값, 앱 푸시 토큰, 서비스 이용기록, 광고식별자</Font_sm>
                        <Font_sm>&nbsp;&nbsp;6. 유료서비스 이용 시</Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;- 신용카드 결제 시: 카드사명, 카드번호 등 신용카드정보</Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;- 계좌이체 시: 예금주명, 계좌번호, 계좌보유은행 등 은행계좌정보</Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;- 휴대전화번호 결제 시: 휴대전화번호, 통신사 등 결제정보</Font_sm>
                        <Font_sm>&nbsp;&nbsp;7. 고충처리 시: 이용자로부터 위 각 정보 중 필요한 항목 및 해당 고충처리에 필요한 별개 항목을 수집 및 처리</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제3조(개인정보의 처리 및 보유기간)</Font_md>
                        <Font_sm>①회사는 이용자가 서비스를 탈퇴 또는 이용자격을 상실할 경우에는 별도의 요청이 없더라도 수집된 이용자의 정보를 지체없이 삭제 및 파기합니다. 다만, 회원 탈퇴 또는 이용자격 상실에도 불구하고 다음 각 호의 정보에 대해서는 각 호에 기재된 기간동안 보존합니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지</Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 서비스 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 회사가 이용약관에 따라 서비스 이용계약을 해지한 경우 부정한 재가입 및 서비스 이용을 방지하기 위하여 해당 이용자의 서비스 부정이용 기록을 해지 후 1년간 보관</Font_sm>
                        <Font_sm>②전항에도 불구하고 회사는 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지 보존합니다. </Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 서비스 이용 관련 개인정보 (로그기록): 「통신비밀보호법」상 보존기간인 3개월</Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 계약 또는 청약철회 등에 관한 기록 및 대금결제 및 재화 등의 공급에 관한 기록: 「전자상거래 등에서의 소비자보호에 관한 법률」상 보존기간인 5년</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 소비자의 불만 또는 분쟁처리에 관한 기록: 「전자상거래 등에서의 소비자보호에 관한 법률」상 보존기간인 3년</Font_sm>
                        <Font_sm>&nbsp;&nbsp;4. 표시 광고에 관한 기록:  「전자상거래 등에서의 소비자보호에 관한 법률」상 보존기간인 6개월</Font_sm>
                        <Font_sm>&nbsp;&nbsp;5. 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류: 「국세기본법」상 보존기간인 5년</Font_sm>
                        <Font_sm>③회사는 이용자가 별도로 정한 기간 동안 서비스를 이용하지 않은 이용자의 개인정보를 별도로 분리 보관할 수 있습니다.</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제4조(개인정보의 제3자 제공)</Font_md>
                        <Font_sm>회사는 이용자의 동의를 받거나 개인정보 보호법 또는 다른 법률의 특별한 규정이 있는 경우에만 개인정보를 제3자에게 제공합니다.</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제5조(개인정보처리의 위탁) </Font_md>
                        <Font_sm>①회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</Font_sm>
                        <tbody>
                                <tr>
                                    <Td>위탁받는 자 (수탁)</Td>
                                    <Td>위탁하는 업무의 내용</Td>
                                    <Td>위탁정보</Td>
                                    <Td>보유기간</Td>
                                </tr>
                                <tr>
                                    <Td>Amazon Web Services, Inc. 또는 자회사</Td>
                                    <Td>클라우드 서버를 활용한 인프라 운영 및 개인정보가 포함된 파일의 처리</Td>
                                    <Td>서비스 제공을 위하여 처리되는 개인정보</Td>
                                    <Td>이용목적 달성시까지</Td>
                                </tr>
                        </tbody>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제6조(수집목적과 합리적으로 관련된 범위 내의 개인정보 이용 및 제공)</Font_md>
                        <Font_sm>회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 이용자의 동의 없이 개인정보를 이용 또는 제3자에게 제공할 수 있습니다. </Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 당초 수집 목적과 관련성이 있는지 여부: 당초 수집 목적과 추가적 이용·제공 목적이 성질이나 경향에 있어 연관이 있는지 등을 고려하여 따라 판단</Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부: 개인정보처리자와 이용자 간의 관계, 기술 수준 및 발전 속도, 상당한 기간동안 정립된 일반적인 사정(관행) 등을 고려하여 판단</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 이용자의 이익을 부당하게 침해하는지 여부: 추가적인 이용 목적과의 관계에서 이용자의 이익이 실질적으로 침해되는지 및 해당 이익 침해가 부당한지 등을 고려하여 판단</Font_sm>
                        <Font_sm>&nbsp;&nbsp;4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부: 침해 가능성을 고려한 안전 조치가 취해지는지 등을 고려하여 판단</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제7조(이용자와 법정대리인의 권리·의무 및 행사방법) </Font_md>
                        <Font_sm>회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 이용자의 동의 없이 개인정보를 이용 또는 제3자에게 제공할 수 있습니다. </Font_sm>
                        <Font_sm>① 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지요구 등의 권리를 행사할 수 있습니다.</Font_sm>
                        <Font_sm>② 제1항에 따른 권리 행사는 서면, 전자우편 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.</Font_sm>
                        <Font_sm>③ 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여서 하실 수 있습니다. 이 경우 수임인에 대한 위임사실을 확인할 수 있는 위임장을 제출하셔야 합니다.</Font_sm>
                        <Font_sm>④ 개인정보 보호법 등 관계 법령에서 정하는 바에 따라 이용자의 개인정보 열람·정정·삭제·처리정지 요구 등의 권리 행사가 제한될 수 있습니다. 또한 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제8조(개인정보의 파기)</Font_md>
                        <Font_sm>회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 이용자의 동의 없이 개인정보를 이용 또는 제3자에게 제공할 수 있습니다. </Font_sm>
                        <Font_sm>① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. </Font_sm>
                        <Font_sm>② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 제3조 제2항에 기재된 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. </Font_sm>
                        <Font_sm>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. </Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. </Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 파기방법: 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;④ 회사는 1년 또는 이용자가 별도로 동의한 기간 동안 서비스를 이용하지 않은 이용자를 휴면계정으로 분류할 수 있으며, 휴면계정에 해당하는 개인정보를 별도로 분리하여 보관할 수 있습니다. </Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제9조(개인정보의 안전성 확보조치)</Font_md>
                        <Font_sm>회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 이용자의 동의 없이 개인정보를 이용 또는 제3자에게 제공할 수 있습니다. </Font_sm>
                        <Font_sm>①  회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등</Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 기술적 조치: 해킹 등에 대비한 기술적 대책, 개인정보의 암호화, 개인정보처리시스템의 접근권한 관리, 접속기록의 보관 및 위변조 방지 등</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제10조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</Font_md>
                        <Font_sm>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 아래와 같이 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.</Font_sm>
                        <Font_sm>①  회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 쿠키의 사용목적: 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 서비스 사용 패턴 파악 및 자취 추적, 보안 접속 여부, 이용자 규모 등을 통해 보안 관리 및 서비스 개선 및 신규 서비스 개발, 맞춤형 서비스 및 광고 제공에 이용합니다. </Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 쿠키의 설치∙운영 및 거부: 서비스 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 아래와 같이 웹 브라우저에서 옵션의 설정을 변경함으로써 쿠키의 저장을 거부할 수 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 쿠키 저장을 거부할 경우 일부 서비스 이용에 어려움이 발생할 수 있습니다.</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제11조(행태정보의 수집·이용 및 거부 등에 관한 사항)</Font_md>
                        <Font_sm>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 아래와 같이 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.</Font_sm>
                        <Font_sm>①  회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 쿠키의 사용목적: 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 서비스 사용 패턴 파악 및 자취 추적, 보안 접속 여부, 이용자 규모 등을 통해 보안 관리 및 서비스 개선 및 신규 서비스 개발, 맞춤형 서비스 및 광고 제공에 이용합니다. </Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 쿠키의 설치∙운영 및 거부: 서비스 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 아래와 같이 웹 브라우저에서 옵션의 설정을 변경함으로써 쿠키의 저장을 거부할 수 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 쿠키 저장을 거부할 경우 일부 서비스 이용에 어려움이 발생할 수 있습니다.</Font_sm>
                        <Font_sm>①  회사는 서비스 이용과정에서 이용자에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위해서 행태정보를 수집·이용하고 있습니다. </Font_sm>
                        <Font_sm>② 회사는 다음과 같이 행태정보를 수집합니다.</Font_sm>
                        <tbody>
                                <tr>
                                    <Td>항목</Td>
                                    <Td>이용자의 서비스 방문이력, 이용하는 SaaS의 종류 및 기간, 빈도</Td>
                                </tr>
                                <tr>
                                    <Td>수집방법</Td>
                                    <Td>이용자의 서비스 방문/실행 시 자동 수집</Td>
                                </tr>
                                <tr>
                                    <Td>수집목적</Td>
                                    <Td>이용자의 관심과 성향에 기반한 개인 맞춤형 SaaS상품 추천서비스(광고포함)를 제공</Td>
                                </tr>
                                <tr>
                                    <Td>보유·이용기간</Td>
                                    <Td>회원 탈퇴시까지</Td>
                                </tr>
                        </tbody>
                        <Font_sm>③ 회사는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집·처리할 수 있도록 허용하고 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 온라인 맞춤형 광고 사업자명 </Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;- 수집 방법: 이용자가 서비스를 이용할 때 자동 수집 및 전송 </Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;- 수집 항목: 이용자의 서비스 방문이력, 이용하는 SaaS의 종류 및 기간, 빈도 </Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;- 보유·이용기간: 회원 탈퇴시까지</Font_sm>
                        <Font_sm>④ 회사는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.</Font_sm>
                        <Font_sm>⑤ 회사는 만 14세 미만임을 알고 있는 아동이나 만 14세 미만의 아동을 주 이용자로 하는 온라인 서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를 제공하지 않습니다.</Font_sm>
                        <Font_sm>⑥ 회사는 모바일 앱에서 온라인 맞춤형 광고를 위하여 광고식별자를 수집·이용합니다. 이용자는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;{"- 안드로이드: 설정 -> 개인정보보호 -> 광고 -> 광고 ID 재설정 또는 광고ID 삭제"} </Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;{"- 아이폰: 설정 -> 개인정보보호 -> 추적 -> 앱이 추적을 요청하도록 허용 끔"} </Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;{"* 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다."} </Font_sm>
                        <Font_sm>⑦ 이용자는 웹 브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;{"- Internet Explorer: 도구 -> 인터넷 옵션 -> 개인 정보 -> 설정 -> 쿠키의 차단"} </Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;{"- Microsoft Edge: 설정 -> 개인정보, 검색 및 서비스 -> 1) 추적방지 InPrivate를 검색할 때 항상 엄격 추적 방지 사용, 2) 개인정보 -> 추적 안함 요청 보내기"} </Font_sm>
                        <Font_sm>&nbsp;&nbsp;&nbsp;{"- Chrome: 설정 -> 개인정보　및　보안　-> 쿠키　및　기타　사이트　데이터에서　원하는　쿠키　차단　방식　선택"} </Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제12조(가명정보의 처리)</Font_md>
                        <Font_sm>회사는 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명처리하여 다음과 같이 처리하고 있습니다.</Font_sm>
                        <Font_sm>① 가명정보의 처리 목적:</Font_sm>
                        <Font_sm>② 가명정보 처리 및 보유기간:</Font_sm>
                        <Font_sm>③ 가명 처리하는 개인정보의 항목:</Font_sm>
                        <Font_sm>④ 가명정보의 안전성 확보조치에 관한 사항</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등</Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 기술적 조치: 해킹 등에 대비한 기술적 대책, 개인정보의 암호화, 개인정보처리시스템의 접근권한 관리, 접속기록의 보관 및 위변조 방지 등</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 물리적 조치: 서버실, 자료보관실 등의 접근통제</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제13조(개인정보 보호책임자) </Font_md>
                        <Font_sm>① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 이와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;- 성명: 이정호</Font_sm>
                        <Font_sm>&nbsp;&nbsp;- 직책: 대표이사</Font_sm>
                        <Font_sm>&nbsp;&nbsp;- 연락처: contact@list-it.xyz</Font_sm>
                        <Font_sm>② 이용자는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 아래 담당부서로 문의하실 수 있습니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;- 부서명: 개인정보관리팀</Font_sm>
                        <Font_sm>&nbsp;&nbsp;- 연락처: contact@list-it.xyz</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제14조(권익침해 구제방법)</Font_md>
                        <Font_sm>①이용자는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원, 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</Font_sm>
                        <Font_sm>&nbsp;&nbsp;1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</Font_sm>
                        <Font_sm>&nbsp;&nbsp;2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</Font_sm>
                        <Font_sm>&nbsp;&nbsp;3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</Font_sm>
                        <Font_sm>&nbsp;&nbsp;4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</Font_sm>
                        <Font_sm>② 회사는 이용자의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 개인정보 보호책임자나 담당부서로 연락해 주시기 바랍니다.</Font_sm>
                    </ContentItem>
                    <ContentItem>
                        <Font_md isBold>제15조(개인정보 처리방침의 변경)</Font_md>
                        <Font_sm>회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이 변경되는 경우 회사는 효력발생일 이전에 미리 공지하겠습니다.</Font_sm>
                        <Font_sm>부칙 (시행일)</Font_sm>
                        <Font_sm>이 개인정보 처리방침은 2023. 10. 4.부터 적용됩니다.</Font_sm>
                    </ContentItem>
                </Content> : 
        <Content>
        <Font_2xl isBold>List-it Inc. Personal Information Policy</Font_2xl>
        <Font_sm>List-it Inc. (hereinafter referred to as the "Company") complies with the relevant laws to protect the personal information of users who use the "List-it" service (hereinafter referred to as the "Service"). In order to process related complaints promptly and smoothly, we establish and disclose the following personal information policy.</Font_sm>
            <ContentItem>
                <Font_md isBold>Article 1 (Purpose of Processing Personal Information)</Font_md>
                <Font_sm>
                The Company processes personal information for the following purposes:<br/>
                1. Membership Registration and Management: Confirmation of membership registration, personal identification, verification of being over 14 years old, maintenance and management of membership qualifications, prevention of fraudulent use of the service, various notifications, and notices.<br/>
                2. Service Provision: Providing basic/customized services, sending contracts/billing statements, identity verification, age verification, fee payment and settlement, debt collectibles, etc.<br/>
                3. Complaint Handling: Verification of the user's identity, confirmation of complaints, contact and notification for fact-finding, notification of processing results, etc.<br/>
                4. Utilization in Marketing and Advertising: Providing personalized advertising, providing information on events and advertising opportunities, etc.<br/>
                5. Service Improvement and Development: Improvement of existing services, development of new services, development of customized services, etc.<br/>
                </Font_sm>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 2 (Items of Processed Personal Information)</Font_md>
            <Font_sm>
            ① The Company collects and processes the following personal information items of service users:<br/>
            1. Mandatory items at the time of membership registration: ID, Password, Phone number, Date of birth, email address<br/>
            2. Optional items at the time of membership registration: Name, Address<br/>
            3. Additional information received from affiliate companies for the purpose of verifying membership registration based on the selected method of membership registration approval.<br/>
            </Font_sm>
            <Table>
                            <thead>
                                    <tr>
                                        <Td rowSpan={2}>Affiliated services that are selected by users at the time of registration</Td>
                                        <Td colSpan={2}>Items from Affiliated Services</Td>
                                    </tr>
                                    <tr>
                                        <Td>Mandatory</Td>
                                        <Td>Optional</Td>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Td>Google ID</Td>
                                    <Td>Email, name, profile image, locale (region and language)</Td>
                                    <Td></Td>
                                </tr>
                                <tr>
                                    <Td>Apple ID</Td>
                                    <Td>Email</Td>
                                    <Td></Td>
                                </tr>
                            </tbody>
                        </Table>
            </ContentItem>
            <Font_sm>
            4. Collected during the process of using the service: Seller information for sales (name, return address, phone number, business status, business name, business registration number, online sales registration number), sales posting details, bidding and winning bids, purchase history.<br/>
            5. Automatically collected during the process of using the service: IP address, cookies, device information (model name, OS), app settings, app push tokens, service usage records, advertisement identifiers.<br/>
            6. When using paid services<br/>
            - For Credit card payments: Credit card information including the card issuer’s name and card number<br/>
            - For bank transfers: Account holder’s name, account number, and bank’s name<br/>
            - For mobile phone payments: Mobile phone number, mobile carrier information, and etc.<br/>
            7. When handling complaints: Necessary information and additional items required for handling complaints are collected from users among the information mentioned above.<br/>
            </Font_sm>

            <ContentItem>
            <Font_md isBold>Article 3 (Processing and Retention Period of Personal Information)</Font_md>
            <Font_sm>
            ① The Company shall promptly delete and destroy the collected user information without separate request when the user withdraws from the service or loses user qualifications. However, even in the event of withdrawal or loss of user qualifications, the following information shall be retained for the specified period as follows:<br/>
            1. When an investigation or inspection is ongoing due to a violation of related laws, the information will be retained until the conclusion of such investigation or inspection.<br/>
            2. When a remaining debt exists due to the use of the service, the information will be retained until the settlement of such debt relationship.<br/>
            3. In the event that the Company terminates a service usage agreement according to the Terms of Service to prevent improper re-registration and service use by that user, service usage records of the user will be retained for one year after termination.<br/>
            ② Notwithstanding the previous paragraph, the Company shall retain information until the end of the following periods for the following reasons:<br/>
            1. Personal information related to service usage (log records): 3 months, in accordance with the "Act on the Protection of Communication Secrets."<br/>
            2. Records regarding contracts, withdrawals, payment of fees, supply of goods, etc.: 5 years, in accordance with the "Act on Consumer Protection in Electronic Commerce."<br/>
            3. Records regarding consumer complaints or dispute resolutions: 3 years, in accordance with the "Act on Consumer Protection in Electronic Commerce."<br/>
            4. Records regarding display and advertisements: 6 months, in accordance with the "Act on Consumer Protection in Electronic Commerce."<br/>
            5. Books and documentary evidence relating to all transactions as stipulated by tax laws: 5 years, in accordance with the "National Tax Basic Act."<br/>
            ③ The Company may separately store the personal information of users who have not used the service for a period designated by the user.<br/>
            </Font_sm>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 4 (Provision of Personal Information to Third Parties)</Font_md>
            <Font_sm>
            The Company provides personal information to third parties only when obtaining the user's consent or when there is a special provision in the Personal Information Protection Act or other laws.<br/>
            </Font_sm>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 5 (Outsourcing of Personal Information Processing)</Font_md>
            <Font_sm>
            ① The Company entrusts personal information processing tasks as follows:<br/>
            </Font_sm>
            <tbody>
                                <tr>
                                    <Td>Person who is entrusted (trustee)</Td>
                                    <Td>Contents of entrusted work</Td>
                                    <Td>Consignment information</Td>
                                    <Td>Retention period</Td>
                                </tr>
                                <tr>
                                    <Td>Amazon Web Services, Inc. or subsidiary</Td>
                                    <Td>Infrastructure operation using cloud servers and processing of files containing personal information</Td>
                                    <Td>Personal information processed to provide services</Td>
                                    <Td>Until the purpose of use is achieved</Td>
                                </tr>
            </tbody>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 6 (Use and Provision of Personal Information within the Scope Reasonably Related to the Collectibles Purpose)</Font_md>
            <Font_sm>
            The Company may use or provide personal information to third parties without the consent of the user based on the following criteria, considering the nature of the initial purpose of collectibles and within a reasonable scope:<br/>
            1. Relevance to the Initial Collectibles Purpose: Determine whether the additional use or provision of personal information is related to the nature or characteristics of the initial collectibles purpose.<br/>
            2. Predictability of Additional Use or Provision of Personal Information: Consider the relationship between the data subject and the personal information processor, the level of technology, the pace of advancement, general practices established over a substantial period, and other factors.<br/>
            3. Unfair Invasion of User Interests: Determine if the additional use purpose significantly infringes upon the interests of the data subject and if such infringement is unfair.<br/>
            4. Safety Measures such as Pseudonymization or Encryption: Consider whether safety measures are taken into account, and whether such measures are considered in the context of potential infringement.<br/>
            </Font_sm>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 7 (Rights and Obligations of Users and Legal Guardians, and the Method of Exercising Rights)</Font_md>
            <Font_sm>
            ① Users can exercise their rights, such as the right to access, correct, delete, or request the suspension of processing of their personal information, at any time.<br/>
            ② Users can exercise the rights mentioned in clause 1 through written requests, electronic mail, etc., and the Company will take prompt actions in response.<br/>
            ③ Users can exercise the rights mentioned in clause 1 through a legal guardian or a person authorized by the data subject. In this case, you must submit a power of attorney to verify the fact of delegation.<br/>
            ④ Depending on the Personal Information Protection Act and other relevant laws, there may be limitations on exercising rights such as access, correction, deletion, or requesting the suspension of processing. Furthermore, if the law explicitly designates specific personal information as subject to collectibles, the data subject may not request the deletion of such information.<br/>
            </Font_sm>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 8 (Destruction of Personal Information)</Font_md>
            <Font_sm>
            ① When personal information becomes unnecessary due to the expiration of the retention period or the achievement of the processing purpose, the Company shall promptly destroy the relevant personal information.<br/>
            ② If personal information must be retained in accordance with the laws as specified in Article 3, paragraph 2, even after the expiration of the retention period or the achievement of the processing purpose, the Company shall transfer the personal information to a separate database (DB) or store it separately.<br/>
            ③ The procedure and method for the destruction of personal information are as follows:<br/>
            1. Procedure for Destruction: The Company selects personal information for destruction when the need for destruction arises and obtains approval from the Company's Data Protection Officer.<br/>
            2. Method of Destruction: The Company uses technical methods to ensure that electronically stored personal information cannot be reproduced. Personal information recorded on paper documents is destroyed by shredding or incineration.<br/>
            ④ The Company may classify users who have not used the service for one year or during a separately agreed-upon period as "dormant accounts" and may store the personal information corresponding to these dormant accounts separately.<br/>
            </Font_sm>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 9 (Security Measures for Personal Information)</Font_md>
            <Font_sm>
            ① The company takes the following measures to ensure the security of personal information:<br/>
            1. Administrative Measures: Establishment and implementation of internal management plans, regular employee training, etc.<br/>
            2. Technical Measures: Technical countermeasures to prevent hacking and other security breaches, encryption of personal information, access control to the personal information processing system, retention of access logs, and prevention of tampering, etc.<br/>
            3. Physical Measures: Access control to server rooms, data storage rooms, etc.<br/>
            </Font_sm>
            </ContentItem>
            <ContentItem>
            <Font_md isBold>Article 10 (Installation, Operation, and Rejection of Personal Information Automatic Collectibles Devices)</Font_md>
            <Font_sm>
            The Company uses "cookies" to store and retrieve usage information in order to provide individualized and customized services to users. Cookies are small pieces of information sent from the server used to operate the website to the user's web browser, and they may be stored on the user's PC hard drive.<br/>
            1. Purpose of Using Cookies: Cookies are used to analyze the frequency of user visits, visit times, tracking user service usage patterns and behavior, security access status, user scale, and to manage security, improve services, develop new services, and provide customized services and advertisements.<br/>
            2. Installation, Operation, and Rejection of Cookies: Users of the service have the option to reject cookie installation. Therefore, users can refuse to store cookies by changing the settings in the web browser options.<br/>
            3. If you reject the storage of cookies, you may encounter difficulties in using some of the services.<br/>
            </Font_sm>
            </ContentItem>
            <ContentItem>
            <Font_md isBold>Article 11 (Collectibles, Use, and Rejection of Behavioral Information)<br/></Font_md>
            <Font_sm>
            ① The Company collects and uses behavioral information during the service usage process to provide users with optimized customized services, benefits, online customized advertising, and more.<br/>
            ② The company collects behavioral information in the following ways:<br/>
            <tbody>
                                <tr>
                                    <Td>item</Td>
                                    <Td>User's service visit history, type and period of SaaS used , frequency</Td>
                                </tr>
                                <tr>
                                    <Td>Collectibles method</Td>
                                    <Td>Automatically collected when the user visits/runs the service</Td>
                                </tr>
                                <tr>
                                    <Td>Collectibles purpose</Td>
                                    <Td>Personalized SaaS product based on user interests and tendencies Provides recommended services (including advertising)</Td>
                                </tr>
                                <tr>
                                    <Td>Retention and use period</Td>
                                    <Td>Until membership withdrawal</Td>
                                </tr>
            </tbody>

            ③ The Company allows online customized advertising businesses to collect and process behavioral information.<br/>
            1. Online customized advertising business name<br/>
            - Collectibles method: Automatically collected and transmitted when users use the service.<br/>
            - Items collected: User's service visit history, types and durations of SaaS used, frequency.<br/>
            - Retention and use period: Until the user withdraws membership.<br/>
            ④ The Company only collects the minimum required behavioral information for online customized advertising. Sensitive behavioral information with a clear risk of infringing on an individual's rights, interests, personal life, beliefs, family and kinship relationships, education, military service, or other social activity records will not be collected.<br/>
            ⑤ The Company will not collect behavioral information for the purpose of customized advertising from children under 14 or from online services primarily used by children under 14. The Company will also not provide customized advertising to children under 14.<br/>
            ⑥ The Company collects and uses advertising identifiers in mobile apps for online customized advertising. Users can block or allow customized advertising for apps by changing their mobile device settings.<br/>
            {"- Android: Settings -> Privacy -> Advertising -> Reset Advertising ID or Delete Advertising ID"}<br/>
            {"- iPhone: Settings -> Privacy -> Tracking -> Turn off Allow apps to request tracking"}<br/>
            * Menus and methods may differ slightly depending on the mobile OS version. <br/>
            ⑦ Users can block or allow online customized advertising in web browsers by changing cookie settings. However, changing cookie settings may affect the use of some services, such as automatic website logins.<br/>
            {"- Internet Explorer: Tools -> Internet Options -> Privacy -> Settings -> Block Cookies"}<br/>
            {"- Microsoft Edge: Settings -> Privacy, Search & Services -> 1) Always use Strict Tracking Prevention when browsing Tracking Protection InPrivate, 2) Privacy -> Request Do Not Track Send"}<br/>
            {"-Chrome: Select how you want to block cookies in Settings -> Privacy & Security -> Cookies and other site data."}<br/>
            </Font_sm>
            </ContentItem>
            <ContentItem>
            <Font_md isBold>Article 12 (Processing of pseudonym information)</Font_md>
            <Font_sm>
            The company processes personal information collected for statistical purposes, scientific research, public interest record preservation, etc. by pseudonymizing it so that specific individuals cannot be identified, as follows .<br/>
            ① pseudonym information Purpose of processing :<br/>
            ② Processing and retention period of pseudonym information :<br/>
            ③ Items of personal information to be pseudonymized :<br/>
            ④ Matters concerning measures to ensure the safety of pseudonym information<br/>
            1. Management measures: Establishment and implementation of internal management plan, regular employee training, etc.<br/>
            2. Technical measures: Technical measures against hacking, encryption of personal information, management of access rights to personal information processing system, storage of access records and prevention of forgery and alteration, etc.<br/>
            3. Physical measures: Access control to server rooms, data storage rooms, etc.<br/>
            </Font_sm>
            </ContentItem>
            <ContentItem>
            <Font_md isBold>Article 13 (Personal Information Protection Manager )</Font_md>
            <Font_sm>
            ① The company appoints a Personal Information Protection Manager responsible for overseeing personal information processing, handling user complaints, and providing remedies in matters related to personal information. The Personal Information Protection Manager is as follows:<br/>
            -Name: Jeongho Lee<br/>
            -Position: CEO<br/>
            -Contact: Contact@list-it.xyz<br/>
            ②Users can contact the Personal Information Protection Manager or the following department regarding any inquiries, complaints, or requests for remedies related to personal information protection arising from their use of the company's services:<br/>
            -Department Name: Personal Information Management Team<br/>
            -Contact: Contact@list-it.xyz<br/>
            </Font_sm>
            </ContentItem>

            <ContentItem>
            <Font_md isBold>Article 14 (Changes to the Personal Information Processing Policy)</Font_md>
            <Font_sm>
            The company may modify the personal information processing policy for purposes such as reflecting changes in laws or service changes. In the event of changes to the personal information processing policy, the company will notify users in advance before the effective date.<br/>
            <br/>
            Provisions (Effective Date)<br/>
            This personal information processing policy is effective from October 4, 2023<br/>
            </Font_sm>
            </ContentItem>

    </Content>
    }
    </PrivacyPolicyWrapper>
    )
};

const PrivacyPolicyWrapper = styled.div`
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 1rem;
    margin-bottom: 65px;
`

const ContentItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const Table = styled.table `
    border-collapse: collapse;
    width: 100%;
`

const Th = styled.th`
    border: 1px solid black;
    padding: 10px;
    background-color: #ccc;
`

const Td = styled.td`
    border: 1px solid black;
    padding: 10px;
    text-align: center;
    font-size: 12px;
`

export default PrivacyPolicyPage;