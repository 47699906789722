import styled from "styled-components";
import PhoneInput from 'react-phone-number-input'
import ProcessHeader from "components/common/Headers/ProcessHeader";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { isValidPasswordState } from "recoil/signupRecoil";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";
import { Font_md, Font_sm } from "styled/fonts";
import { isChangeSuccessState } from "recoil/mypageRecoil";
import { loginState } from "recoil/loginRecoil";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";

const ChangeNumPage = () => {
  
  const isValid = useRecoilValue(isValidPasswordState);
  const navigate = useNavigate();
  const setIsChangeSuccess = useSetRecoilState(isChangeSuccessState);
  const [user, setLogin] = useRecoilState(loginState);
  const [newNumber, setNewNumber] = useState(user.user.number);
  const handleChangePhoneNumber= (event: any) => {
    if(event !== undefined)
    setNewNumber(event);
  };
  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  
  const HandleClick = async () => {
    try {
      const response = await axios.put(API_ENDPOINT + '/accounts/user/', {
        email:user.user.email,
        number:newNumber,
      }, {
        headers: {Authorization: `Bearer ${user.access}`},
      });

      await setLogin({
        access:user.access,
        addresses: user.user.addresses,
        user:response.data
      })
       
      navigate("/mypage/personal");
      setIsChangeSuccess(2);
    } catch (error) {
      console.error("Change Num Error:", error);
    }
  };

  const { t, i18n } = useTranslation("mypage");
  const language = useRecoilValue(langState);

  const phoneNumberStyle = {
    marginTop:'16px',
    width: 'calc(100% - 2rem)',
    border: '1px solid #E2E8F0',
    borderRadius: '6px 6px 6px 6px',
    height: '48px',
    padding: '0px 12px 0px 16px',
    gap:'1rem',
  }

  useEffect(()=> {
    i18n.changeLanguage(language);
  },[])

  return (
    <ChangeNum>
      <ProcessHeader title={t("휴대폰 번호 변경")} isSearchForm={false} />
      <InputWrapper>
        <SectionTitle>
          <Font_md isBold>{t("휴대폰 번호")}</Font_md>
        </SectionTitle>
        <PhoneInput
          defaultCountry="KR"
          style={phoneNumberStyle}
          placeholder={t("휴대폰 번호")}
          value={newNumber}
          onChange={handleChangePhoneNumber}
        />
      </InputWrapper>
      <ButtonWrapper>
        <ButtonComponent
          onClick={HandleClick}
          isValid={isValid}
          text={t("휴대폰 번호 변경")}
          size={Size.LARGE}
        />
      </ButtonWrapper>
    </ChangeNum>
  );
};

const ChangeNum = styled.div`
  width: 100%;
`;

const SectionTitle = styled.div`
  width: calc(100% - 2rem);
  margin-bottom:0.5rem;
`

const InputWrapper = styled.div`
  margin:32px 0px 32px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  .origin{
    width: calc(100% - 2rem);
    
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
`

export default ChangeNumPage;