import styled from "styled-components";
import { Button } from '@chakra-ui/react';
import send_mail from 'assets/send_mail.svg';
const SendPage = () => {
  

  return (
    <Send>
      <Contents>
        <img src={send_mail} />
        <TopStr>비밀번호 재설정 메일이 발송되었습니다!</TopStr>
        <BottomStr>이메일을 통해 비밀번호를 재설정해주세요.</BottomStr>
      </Contents>
      <ButtonWrapper>
        <Button 
          style={{width:'328px'}}
          size="lg"
          variant='outline'>
          확인
        </Button>
      </ButtonWrapper>
    </Send>
  );
};

const Send = styled.div`

`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`

const TopStr = styled.p`
  font-size: 18px;
  font-weight: 600;
`

const BottomStr = styled.p`
  font-size: 16px;
  font-weight: 400;
`

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 58px;  
  width: 100%;
  display:flex;
  justify-content: center;
`

export default SendPage;