import React, { useState, useEffect } from "react";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import HorizontalCard_Cart from "components/cart/HorizontalCard_Cart";
import {
  Checkbox,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import cancle_img from "assets/cancle.svg";
import LoginReqImg from "assets/login_req.svg";
import { Font_md } from "styled/fonts";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { CartData, Size, VerticalCardType } from "types/types";
import EmptyComponent from "components/cart/EmptyComponent";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { exchangeRateState, payableItemsState } from "recoil/homeRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { isNotLogin } from "api/UserUtil";
import { getCartData, deleteCartData } from "api/UserApi";
import { useNavigate } from "react-router-dom";
import ModalComponent from "components/common/ModalComponent";
import { changeKrwToUsd, formatCurrency } from "api/StringUtils";
import { getPresetData } from "api/api";

const CartPage = () => {
  const user: any = useRecoilValue(loginState);
  const [cartData, setCartData] = useState<CartData[]>([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState);
  const [payableItems, setPayableItems] = useRecoilState(payableItemsState);
  const [isClickedSelectAll, setIsClickedSelectAll] = useState(true);
  const [availableList, setAvailableList] = useState<Array<any>>([]);
  const [checkList, setCheckList] = useState<Array<any>>([]);
  const [deliveryPrice, setDeliverPrice] = useState(0);

  const cartDataToVerticalData = (data: CartData) => {
    return {
      id: data.product.id.toString(),
      author: {},
      name: data.product.name,
      price: data.product.price,
      isAuction: false,
      created: data.created,
      is_selled: data.product.is_selled,
      image_product: [],
      value_product: [],
      content: "",
      end_date: "",
      selling_type: 1,
      start_date: "",
      image1: data.product.image1,
      is_bidded: false,
      is_pending: false,
      bidder:0,
    } as VerticalCardType;
  };

  const onClickPayBtn = async () => {
    if (await isNotLogin(user)) {
      onOpen();
    } else {
      navigate("/pay");
    }
  };

  const fetchCartData = async () => {
    const cartRes: CartData[] = await getCartData(user.access);
    setCartData(cartRes);
    return cartRes;
  };

  const deleteAllCart = () => {
    payableItems.forEach((data) => {
      cartData.forEach(cartData => {
        if(cartData.product.id === Number(data?.id))
          deleteCartItem(cartData.id);
      })
    })
  }

  const deleteCartItem = async (id: number) => {
    await deleteCartData(user.access, id);
    fetchCartData();
  };

  const onClickSelectAll = (e: any) => {
    if (e.target.checked) {
      let idx = 0;
      const tmpList = [];
      for (const data of availableList) {
        const tmpItem: VerticalCardType = cartDataToVerticalData(data);
        tmpList.push(tmpItem);
        checkList[idx] = true;
        idx += 1;
      }
      setPayableItems(tmpList);
      setIsClickedSelectAll(true);
    } else {
      setIsClickedSelectAll(false);
      setCheckList(checkList.map(() => false));
      setPayableItems([]);
    }
  };

  const handleCheckbox = async (
    e: any,
    selectedItem: CartData,
    index: number
  ) => {
    const tmpItem: VerticalCardType = cartDataToVerticalData(selectedItem);

    const newPayableItems = payableItems.filter(
      (item) => item?.id !== tmpItem.id
    );
    if (e.target.checked) {
      newPayableItems.push(tmpItem);
      checkList[index] = true;
    } else {
      checkList[index] = false;
    }
    await setPayableItems(newPayableItems);
    await setIsClickedSelectAll(checkList.every((item) => item === true));
  };

  const canPurchaseItems =
    cartData?.filter((item) => item.product.is_selled === false).length !== 0
      ? true
      : false;

  useEffect(() => {
    setPayableItems([]);
    i18n.changeLanguage(language);

    isNotLogin(user).then((data) => {
      if(data)
        window.location.replace("/");
    });


    fetchCartData().then((cartRes) => {
      const tmpAvailableList = cartRes.filter(
        (item) => item.product.is_selled === false
      );
      setPayableItems(
        cartRes
          .filter((item) => item.product.is_selled === false)
          .map((filteredItem) => cartDataToVerticalData(filteredItem))
      );
      setAvailableList(tmpAvailableList);
      const initialCheckList = Array(tmpAvailableList.length).fill(true);
      setCheckList(initialCheckList);
    });
    (async () => {
      const preset = await getPresetData();
      const findDeliveryPrice = preset.filter((item) => item.key == "delivery");
      setDeliverPrice(Number(findDeliveryPrice[0].value));
    })();
  }, []);

  return cartData.length < 1 ? (
    <>
      <ProcessHeaderComponent title={t("장바구니")} isSearchForm={false} />
      <EmptyComponent />
    </>
  ) : (
    <Cart>
      <ProcessHeaderComponent title={t("장바구니")} isSearchForm={false} />
      {canPurchaseItems && (
        <ChoiceAll>
          <div>
            <Checkbox
              isChecked={isClickedSelectAll}
              size="lg"
              colorScheme="green"
              onChange={(e: any) => onClickSelectAll(e)}
            />
            &nbsp; {t("전체 선택")}
          </div>
          <div onClick={deleteAllCart}>{t("삭제")}</div>
        </ChoiceAll>
      )}
      <Divider />
      {cartData &&
        cartData
          .filter((item) => item.product.is_selled === false)
          .map((filteredItem, index) => (
            <>
              <Row>
                <SideWrapper>
                  <Checkbox
                    onChange={(e) => handleCheckbox(e, filteredItem, index)}
                    size="lg"
                    colorScheme="green"
                    isChecked={checkList[index]}
                  />
                </SideWrapper>
                <HorizontalCard_Cart
                  title={filteredItem.product.name}
                  itemPrice={filteredItem.product.price}
                  deliverPrice={deliveryPrice}
                  isSelling={true}
                  image={filteredItem.product.image1}
                />
                <SideWrapper>
                  <CancelImg
                    src={cancle_img}
                    onClick={() => {
                      deleteCartItem(filteredItem.id);
                    }}
                  />
                </SideWrapper>
              </Row>
              <SmallDivder />
            </>
          ))}
      <LargeDivder />
      {cartData &&
        cartData
          .filter((item) => item.product.is_selled === true)
          .map((filteredItem, index) => (
            <>
              <CantBuyTitle isBold>구매불가 상품</CantBuyTitle>
              <Row>
                <HorizontalCard_Cart
                  title={filteredItem.product.name}
                  itemPrice={filteredItem.product.price}
                  deliverPrice={3000}
                  isSelling={false}
                  image={filteredItem.product.image1}
                />
                <SideWrapper>
                  <CancelImg
                    src={cancle_img}
                    onClick={() => {
                      deleteCartItem(filteredItem.id);
                    }}
                  />
                </SideWrapper>
              </Row>
              <SmallDivder />
            </>
          ))}
      <ButtonWrapper>
        <ButtonComponent
          isValid={payableItems.length > 0}
          text={t("구매하기")}
          size={Size.LARGE}
          onClick={() => onClickPayBtn()}
        />
      </ButtonWrapper>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        content={
          language === "ko-KR" ? `로그인하고 더 많은 기능을` : `Sign up today!`
        }
        bottomContent={language === "ko-KR" ? `이용해보세요!` : ""}
        leftBtnString={t("돌아가기")}
        rightBtnString={t("로그인")}
        onClickSubmit={() => navigate("/onboarding")}
        imageUrl={LoginReqImg}
      />
    </Cart>
  );
};

const Cart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const ChoiceAll = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  width: calc(100% - 2rem);
`;

const Row = styled.div`
  display: flex;
  gap: 1rem;
  width: calc(100% - 2rem);
`;

const SideWrapper = styled.div`
  margin-top: 1rem;
`;

const CantBuyTitle = styled(Font_md)`
  padding: 1rem 0 0 1rem;
  align-self: flex-start;
`;

const LargeDivder = styled.div`
  min-height: 8px;
  width: 100%;
  background-color: #f7fafc;
`;

const SmallDivder = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: #f7fafc;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 1rem 0 1rem 0;
  width: 100%;
`;

const CancelImg = styled.img``;

export default CartPage;
