import { Size } from "types/types";
import { styled } from "styled-components";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import card_img from "assets/card_img.svg";
import { Font_xs } from "styled/fonts";
import { colors } from "styled/colors";
import { calculateEndDate } from "api/DateUtils";
import { differenceInSeconds, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { method } from "lodash";
import { useNavigate } from "react-router-dom";
interface CircleIProps {
  imageUrl: string;
  name: string;
  startDate: string;
  endDate: string;
  id: string;
  type: number;
}

const THREE_DAY_SECONDS = 259200;

const CircleComponent = (props: CircleIProps) => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    // 현재 날짜와 시간을 얻습니다.
    const nowDate = new Date();
    // props에서 받은 startDate, endDate를 Date 객체로 변환합니다.
    const endDate = new Date(props.endDate);
    // 현재 날짜(nowDate)부터 종료 날짜(endDate)까지의 시간 차이 (초)
    const secondsToEndTime = differenceInSeconds(endDate, nowDate);
    if(THREE_DAY_SECONDS >= secondsToEndTime){
      setStep(secondsToEndTime <= 0
        ? 100
        : 100 - Math.round((secondsToEndTime / THREE_DAY_SECONDS) * 100))
    } else setStep(100)
  }, []);
  return (
     (
      <Progress>
        <Circle onClick={() => navigate(`/${ props.type === 1 ? 'item-detail' : 'item-detail-bid'}?id=`+props.id)}>
          <CircularProgressbarWithChildren
            value={props.type !== 1 ? step : 100}
            strokeWidth={4}
            styles={{
              path: {
                stroke: "#E2E8F0",
                strokeLinecap: "butt",
              },
              trail: {
                stroke: step <= 90 ? "#0AB88C" : "#BC120F",
              },
            }}
          >
            <ImageWrapper imageUrl={props.imageUrl || ''}/>
          </CircularProgressbarWithChildren>
        </Circle>
        <TextSection>{props.name}</TextSection>
      </Progress>
    )
  );
};

const Progress = styled.div`
  width: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
`;

const Circle = styled.div`
  width: 88px;
  height: 88px;
`;

const ImageWrapper = styled.div<{imageUrl: string}>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid white;
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
`;

const TextSection = styled.div`
  overflow: hidden; /* 넘치는 부분 감춤 */
  text-overflow: ellipsis; /* 넘치면 "..." 표시 */
  display: -webkit-box; /* Webkit 브라우저 지원 */
  -webkit-line-clamp: 2; /* 표시할 줄 수 (여기서는 2줄) */
  -webkit-box-orient: vertical; /* 세로로 쌓임 */
  width: 80px;
  font-size: 12px;
  text-align: center;
  color: ${colors.GREY[700]};
`;

export default CircleComponent;
