import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useRecoilState, useRecoilValue} from "recoil";
import {searchState} from "../../recoil/searchRecoil";
import {langState} from "../../recoil/loginRecoil";

const RecommendKeyword = (props: any) => {
    const {keyword, HandleSearchClick} = props;
    const {t} = useTranslation('main');
    const [searchVal, setSearchVal] = useRecoilState(searchState);
    const language = useRecoilValue(langState);

    return (
        keyword.length > 0 ?
            <Container>
                <Title>{(t('추천 검색어'))}</Title>
                <KeywordContainer>
                    { keyword.map((e: any) => {
                        return (
                            <Keyword onClick={async () => {
                                setSearchVal(language === "ko-KR" ? e.name : e.en_name);
                                HandleSearchClick(language === "ko-KR" ? e.name : e.en_name);
                            }}>{ language === "ko-KR" ? e.name : e.en_name }</Keyword>
                        );
                    })}
                </KeywordContainer>
            </Container>
            :
            <></>

    );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
`;

const KeywordContainer = styled.div`
  width: 100%;
`;
const Keyword = styled.button`
  border: none;
  width: fit-content;
  color: rgba(74, 85, 104, 1);
  font-size: 14px;
  font-weight: 400;
  background-color: rgba(237, 242, 247, 1);
  padding: 6px 10px;
  border-radius: 99px;
  margin: 4px;
`;

export default RecommendKeyword;
