import axios from "axios";
import {CategoryOptionType, CreateProductType, ReUploadData, UploadData} from "types/types";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const register = async (formData : any, access?: string) => {
    try {
        const response = await axios.post(API_ENDPOINT + '/accounts/v2/request-seller/', formData, {
            headers: {
                'Authorization': `Bearer ${access}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return response;
    } catch (error : any) {
        return error;
    }
}

export const getSellerInfo = async (access?: string) => {
    try {
        const response = await axios.get(API_ENDPOINT + `/accounts/v2/request-seller/`, {
            headers: {
                'Authorization': `Bearer ${access}`,
            }
        });
        console.log(response);
        return response;
    } catch (error: any){
        return error;
    }
}

export const postCategoryOption = async (props: CategoryOptionType) => {
    try {
        const response = await axios.post(API_ENDPOINT + '/service/category/value/', {
            value: props.value,
            product: props.product,
            option: props.option,
        }, {
            headers: {
                'Authorization': `Bearer ${props.access}`,
            }
        });
        return response;
    } catch (error: any){
        return error;
    }
}

export const createProduct = async (props: CreateProductType) => {
    const {access, formData} = props;
    try {
        const response = await axios.post(API_ENDPOINT + '/accounts/v2/upload/', formData, {
            headers: {
                'Authorization': `Bearer ${access}`,
                'Content-Type': 'multipart/form-data',
            }
            });
        return response;
    } catch (error: any) {
        return error;
    }
}

export const getProducts = async(access: string) : Promise<UploadData[]> => {
    try {
        const response = await axios.get(API_ENDPOINT + '/accounts/v2/upload/', {
            headers: {
                'Authorization': `Bearer ${access}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data.filter((e: any) => !e.product.is_deleted);
    } catch (error: any) {
        throw error;
    }
}

export const getProduct = async(access: string, product_id: number) : Promise<UploadData> => {
    try {
        const response = await axios.get(API_ENDPOINT + `/accounts/v2/upload/${product_id}`, {
            headers: {
                'Authorization': `Bearer ${access}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error:any) {
        throw error;
    }
}

export const reUpload = async (access: string, reUploadData: ReUploadData) => {
    try {
        const response = await axios.post(API_ENDPOINT + '/accounts/v2/upload/re/', reUploadData, {
            headers: {
                'Authorization': `Bearer ${access}`,
            }
        })
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const selectBidder =async (access: string, id?: number) => {
    try {
        const response = await axios.patch(API_ENDPOINT + `/accounts/v2/bidding/${id}/select/`,
        {
            is_success: true
        },
        {
            headers: {
                'Authorization': `Bearer ${access}`,
            }
        })
        return response.data;
    } catch (error) {
        throw error;
    }
}