import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_sm, Font_xs } from "styled/fonts";
import expand_more from "assets/expand_more_grey.svg";
import expand_close from "assets/expand_close.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";

const MainFooterComponent = () => {
  const { t, i18n } = useTranslation("main");
  const [isClose, setIsClose] = useState<boolean>(false);
  const navigate = useNavigate();
  const language = useRecoilValue(langState);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);
  return (
    <MainFooter isClose={isClose}>
      <TextWrapper>
        <div style={{ display: "flex" }} onClick={() => setIsClose(!isClose)}>
          <Font_sm color={colors.GREY[500]} isBold>
            {t("(주)리스트잇")}
          </Font_sm>
          <img src={isClose ? expand_more : expand_close}></img>
        </div>
        {!isClose && (
          <>
            {language === "ko-KR" ? (
              <>
                <Font_xs color={colors.GREY[500]}>
                  {t("대표자명")}: 이정호 <br />
                  {t("사업자번호")}: 713-86-03015 <br />
                  {t("통신판매업")}: 2023-서울강남-06200 <br />
                  {t("주소")}: 서울특별시 강남구 테헤란로27길 8-14,
                  1003호(역삼동) <br />
                  {t("전화번호")}: 010-3619-2120 <br />
                </Font_xs>
                <Font_xs color={colors.GREY[500]}>
                  {t("고객센터")} <br />
                  {t("이메일")}: Contact@list-it.xyz
                  <br />
                </Font_xs>
              </>
            ) : (
              <Font_xs color={colors.GREY[500]}>
                Customer service: Contact@list-it.xyz
                <br />
                Address: 1003, 8-14, Teheran-ro 27-gil, Gangnam-gu, Seoul,
                Republic of Korea
                <br />
              </Font_xs>
            )}

            <ButtonWrapper>
              <MiniButton
                color={colors.GREY[500]}
                onClick={() => {
                  navigate("/mypage/terms-of-service");
                }}
              >
                {t("이용약관")}
              </MiniButton>
              <MiniButton
                color={colors.GREY[500]}
                onClick={() => {
                  navigate("/mypage/privacy-policy");
                }}
              >
                <strong>{t("개인정보처리방침")}</strong>
              </MiniButton>
            </ButtonWrapper>
            <Font_xs color={colors.GREY[500]}>
              ⓒ list-it 2023 all rights reserved.
            </Font_xs>
          </>
        )}
      </TextWrapper>
    </MainFooter>
  );
};

const MainFooter = styled.div<{isClose: boolean}>`
  background-color: ${colors.GREY[50]};
  height: ${props => !props.isClose ? "auto" : '56px'} ;
  display: flex;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% - 2rem);
  margin: 1rem 0;
`;

const ButtonWrapper = styled.div`
`

const MiniButton = styled(Font_xs)`

`

export default MainFooterComponent;
