import styled from "styled-components";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import { Font_md, Font_xs, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import { Button, Input } from "@chakra-ui/react";
import { useState } from "react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { isChangeSuccessState } from "recoil/mypageRecoil"
import PhoneInput from 'react-phone-number-input';
import { useRecoilState, useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { updateAddressData, updateUserInfo, getUserAddress, getUserInfo } from "api/UserApi";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";
import { AddressData } from "types/types";
import { getAddressData, createAddressData } from "api/UserApi";

const ChangeAddrPage = () => {
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const from: string = params.get('from') || '';
  
  const setIsChangeSuccess = useSetRecoilState(isChangeSuccessState);
  const [user, setUser] = useRecoilState(loginState);
  const [addressData, setAddressData] = useState<AddressData>({
    address: '',
    detail_address: '',
    name: '',
    phone: '',
    user: user.user.id,
    last_name: '',
  });

  const [addr_1, setAddr_1] = useState<string>('');
  const handleAddr_1 = (e:any) => setAddr_1(e.target.value);

  const [addr_2, setAddr_2] = useState<string>('');
  const handleAddr_2 = (e:any) => setAddr_2(e.target.value);

  const [name, setName] = useState(addressData.name);
  const handleName = (e:any) => setName(e.target.value);

  const [last_name, setLastName] = useState(addressData.last_name);
  const handleLastName = (e:any) => setLastName(e.target.value);

  const [phoneNumber, setPhoneNumber] = useState<string>(user.user.number);
  const handleChangePhoneNumber= (event: any) => {
    setPhoneNumber(event);
  };

  const navigate = useNavigate();

  const HandleClick = async() => {
    if(user.user.addresses && user.user.addresses.length > 0) {
      const updateAddrData = await updateAddressData(user.access, {
        id: user.user.addresses[0].id,
        address: addr_1,
        detail_address: addr_2,
        name: name,
        phone: phoneNumber,
        user: user.user.id,
        last_name: last_name
      })
      setAddressData(updateAddrData);
    } else {
        const newAddrData = await createAddressData(user.access, {
          address: addr_1,
          detail_address: addr_2,
          name: name,
          phone: phoneNumber,
          user: user.user.id,
          last_name: last_name
        })
        setAddressData(newAddrData);
    }

    const userRes = await getUserInfo(user.access);
    if(userRes.error){
        setUser({user:{}, access:""})
        navigate('/');
    }
    setUser((prevUser: any)=> ({
        ...prevUser,
        user: {...userRes.data},
    }));    
    if(from === 'pay')
      navigate("/pay");
    else
      navigate("/mypage/home-addr");

    setIsChangeSuccess(3);
  };

  const fetchAddrData = async() => {
    try {
      const addrData = user.user.addresses[0];
      if(addrData) setAddressData(addrData);
    } catch(error) {
      console.error(error);
    }
  };

  const getIsValidAddress = () => {
    return Boolean(phoneNumber && addr_1 && addr_2 && name);
  }

  const { t, i18n } = useTranslation("mypage");
  const language = useRecoilValue(langState);

  useEffect(()=> {
      fetchAddrData();
      i18n.changeLanguage(language);
      if(user.user){
        getUserAddress(user.access);
      }
  },[])

  useEffect(()=> {
    setAddr_1(addressData.address);
    setAddr_2(addressData.detail_address);
    setName(addressData.name);
    setLastName(addressData.last_name);
    setPhoneNumber(addressData.phone);
  },[addressData])

  const phoneNumberStyle = {
    width: 'calc(100% - 2rem)',
    border: '1px solid #E2E8F0',
    borderRadius: '6px 6px 6px 6px',
    height: '48px',
    padding: '0px 12px 0px 16px',
    gap:'1rem',
  }

  return (
    <HomeAddr>
      <ProcessHeader title={t("배송지 등록")} isSearchForm={false} />
      <Section isAddr={true}>
        <SectionTitle>
          <Font_md isBold>{t("주소")}</Font_md>
        </SectionTitle>
          <InputWrapper>
            <Input
              size='lg'
              value={addr_1}
              onChange={handleAddr_1}
            />
            <Input
              size='lg'
              value={addr_2}
              onChange={handleAddr_2}
              style={{marginTop:'1rem'}}
            />
          </InputWrapper>
      </Section>
      <Section isAddr={false}>
      <SectionTitle>
          <Font_md isBold>{t("수령인")}</Font_md>
        </SectionTitle>
          <InputWrapper>
            <Input
              size='lg'
              value={name}
              onChange={handleName}
            />
          </InputWrapper>
      </Section>
      <Section isAddr={false}>
        <SectionTitle>
          <Font_md isBold>{t("휴대폰 번호")}</Font_md>
        </SectionTitle>
          <PhoneInput
          defaultCountry="KR"
          style={phoneNumberStyle}
          placeholder={t("휴대폰 번호")}
          value={phoneNumber}
          _placeholder={{color: colors.GREY[400]}}
          onChange={handleChangePhoneNumber}/>
          {/* <InputWrapper>
            <Input
              size='lg'
              value={number}
              onChange={handleNumber}
            />
          </InputWrapper> */}
      </Section>
      <ButtonWrapper>
        <ButtonComponent
          onClick={HandleClick}
          text={t("배송지 등록")}
          size={Size.LARGE} 
          isValid={getIsValidAddress()}/>
      </ButtonWrapper>
    </HomeAddr>
  );
};

const HomeAddr = styled.div`

`;

const Section = styled.div<{isAddr:boolean}>`
  margin-top:${props => props.isAddr ? '2rem' : '1rem'};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .addr {
    margin-top:2rem;
  }
`

const SectionTitle = styled.div`
  width:calc(100% - 2rem);
  margin-bottom: 0.5rem;
`

const InputWrapper = styled.div`
  width:calc(100% - 2rem);
`

const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display:flex;
  justify-content: center;
`

export default ChangeAddrPage;