import styled from 'styled-components';
import { Font_lg, Font_md } from 'styled/fonts';
import download_google from 'assets/download_google.svg';
import download_apple from 'assets/download_apple.svg';
import login_logo from 'assets/login_logo.svg'
import { colors } from 'styled/colors';
import { useRecoilValue } from 'recoil';
import { langState } from 'recoil/loginRecoil';

const SidePage = () => {
  /*
    https://play.google.com/store/apps/details?id=xyz.listit&pli=1
    https://apps.apple.com/app/list-it/id6464245172
  */
  const language = useRecoilValue(langState);

  return (
    <Side>
      <SideLogo src={login_logo} />
      <Font_lg color={colors.GREY[500]}>Find Your Collectibles Value</Font_lg>
      {language == "ko-KR" && <SubTitle>컬렉션 전문거래 플랫폼</SubTitle>}
      <ButtonWrapper>
        <a
          href="https://play.google.com/store/apps/details?id=xyz.listit&pli=1"
          target="_blank"
        >
          <img src={download_google} />
        </a>
        <a
          href="https://apps.apple.com/app/list-it/id6464245172"
          target="_blank"
        >
          <img src={download_apple} />
        </a>
      </ButtonWrapper>
    </Side>
  );
};

const Side = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 368px;
  padding-left: 1rem;
  align-items: flex-start;
  background-color:${colors.GREY[50]}
`

const SideLogo = styled.img`
  height: 56px;
  margin-bottom: 1rem;
`

const SubTitle = styled.p`
  margin: 3rem 0;
  font-size:24px;
  line-height:28px;
  font-weight:600;
`

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.5rem;
`

export default SidePage;