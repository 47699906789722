import ProcessHeader from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import { Font_2xl, Font_lg, Font_xl } from "styled/fonts";
import email from "assets/email.svg";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { colors } from "styled/colors";
import AccordionButtonComponent from "components/common/AccordionButtonComponent";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { isNotLogin } from "api/UserUtil";

const AskPage = () => {
    const user : any = useRecoilValue(loginState);

    const { t, i18n } = useTranslation("serviceUsage");
    const language = useRecoilValue(langState);

    const askList = [
      {
        category: t("결제"),
        content: [
          {
            question: t("[결제] 어떤 방식으로 결제할 수 있나요?"),
            answer: t("신용카드, 체크카드, Paypal을 통해 국내외 결제가 가능합니다.")
          },
          {
            question: t("[결제] 가격 제안 거래 방식은 어떻게 진행되나요?"),
            answer: t("List-it 내 가격 제안 거래 방식으로 등록된 제품들은 희망판매가를 참고하여 구매자 분들이 판매자분들에게 가격을 제안할 수 있습니다. 판매자가 설정한 기간 동안 다양한 제안들을 취합하여 최종적으로 판매자가 구매자가 제안한 안을 선택하는 거래 방식입니다. 판매자로부터 선정된 구매자에게는 이메일을 통해 알림을 드리고 있으며, 선정 후 24시간 내 입금이 되지 않을 경우에는 거래가 더 이상 진행되지 않습니다. 또한 저희 List-it의 가격 제안 거래 방식에는 가격만이 아니라 구매자분들께서 제안 하실 때 개인적인 이야기 또는 해당 제품의 필요성을 글로 작성할 수 있는 공간을 마련하여 서로간의 커뮤니케이션을 통해 자유롭게 거래할 수 있는 환경을 조성하여 이용자의 편의를 추구하였습니다.")
          },
          {
            question: t("[결제] 결제 시 환율은 어떻게 되나요?"),
            answer: t("판매 제품이 등록된 시점의 환율을 적용하고 있으며, 제품 상세 페이지내 나와있는 판매가가 최종 환율이 적용된 판매 금액입니다.")
          }
        ]
      },
      {
        category: t("배송"),
        content: [
          {
            question: t("[배송] 배송은 얼마나 걸리나요?"),
            answer: t("상품 배송 기간은 상품은 입금 확인후 배송 시작되며, 판매업체에서 List-it으로 배송 후 List-it에서 상품 감정을 거쳐 최종 구매자분께 배송해드리고 있습니다.")
          },
          {
            question: t("[배송] 해외 배송비는 어떻게 되나요?"),
            answer: t("해외 제품 구매시 국제 택배 운송비 기준에 따른 금액으로 운송되며, 해외에서 List-it 배송비는 구매자 부담, List-it에서 소비자까지는 List-it에서 부담하고 있습니다. \n\n 해외 거래의 경우 구매 금액에 따라 추가 관세 비용이 발생할 수 있습니다. 관세 미지급시 구매 상품은 배송지로 환송될 수 있으며, 발생한 비용은 환불 금액에서 차감됩니다.")
          },
          {
            question: t("[배송] 해외 배송은 얼마나 걸리나요?"),
            answer: t("통상적으로 2-3주 걸리고 있으나, 배송 상황에 따라 지연될 수 있습니다.")
          },
          {
            question: t("[배송] 배송 시작 알림 메일을 받았는데, 배송 추적이 되지 않습니다."),
            answer: t("업체 또는 List-it에서 송장번호 등록 후 1영업일 이내 또는 실제 상품 배송이 진행됨과 동시에 배송 추적이 가능합니다.")
          },
          {
            question: t("[배송] 배송 완료로 확인되는데 제품을 받지 못했습니다."),
            answer: t("택배 추적이 가능한 경우 경비실 또는 무인 택배함을 먼저 확인 부탁드리며, 별도의 위탁 장소가 없는 경우 배송 기사님께서 임의의 장소에 보관하셨을 수 있으니, 기사님께 문의 부탁드립니다. 배송 추적 확인되지 안되거나, 제품을 수령하시지 못하신 경우 Contact@list-it.xyz으로 메일 문의 부탁드립니다.")
          }
        ]
      },
      {
        category: t("주문 취소 / 교환 / 반품"),
        content: [
          {
            question: t("[취소] 주문을 취소하고 싶은데 어떻게 해야하나요?"),
            answer: t("고객님께서 직접 취소 가능한 서비스는 제공되지 않아 번거로우시더라도 이메일 문의를 통해 ID와 주문 번호 알려주시면 취소 처리해드리겠습니다.")
          },
          {
            question: t("[취소] 주문 취소건은 언제 환불이 되나요?"),
            answer: t("체크카드의 경우 일반 신용 카드 결제와 동일한 시스템으로 결제 되며, 카드취소 후 금액이 바로 통장으로 입금되지는 않습니다.\n\n카드 취소와 동일하게 약 1~3일 후 카드사에서 승인 취소 확인이 가능하며, 추후 통장으로 입금되는 내역은 각 카드사마다 환급 방법이 상이합니다.\n\n신용카드의 경우 취소 요청해주시면 확인 후 취소 처리해드리면 카드사에 따라 1~3일 정도 후에 취소 처리가 되고 이에 따른 문의는 각 카드사로 해주시면 되겠습니다.\n\n각 결제 방법마다 환불에 걸리는 소요되는 시간이 다른 점 참고 부탁드립니다.")
          },
          {
            question: t("[교환/반품] 제품 교환 또는 반품을 할 수 있나요?"),
            answer: t("제품 특성상 대부분의 상품 교환 불가하며, 반품만 가능합니다. 상품 수령 후 7일이내에 반품 가능하며, 고객님의 변심에 의한 반품의 경우 배송 비용이 부과될 수 있습니다.\n\n단, 아래의 경우 반품이 불가능 합니다.\n• 고객님의 책임 있는 사유로 상품등이 멸실 또는 훼손된 경우.\n• 고객님의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우\n• 시간의 경과에 의하여 재판매가 곤란할 정도로 상품등의 가치가 현저히 감소한 경우\n• 그 외 반품 관련 사유 문의는 Contact@list-it.xyz로 메일 문의 부탁드립니다.")
          },
          {
            question: t("[교환/반품] 반품은 어디로 할 수 있나요?"),
            answer: t("반품 시 수집품목 제품 특성상 1:1 문의를 통한 확인 먼저 반드시 부탁드리며, List-it과 확인 후 아래 주소로 배송 부탁드립니다.\n\n: 서울특별시 강남구 테헤란로27길 8-14, 1003호(역삼동)")
          },
        ]
      },
      {
        category: t("기타"),
        content: [
          {
            question: t("[기타] 회원 가입은 어떻게 하나요?"),
            answer: t("회원 가입은 아이디, 비밀번호 설정, 닉네임, 휴대폰번호, 이메일 입력만으로 간단하게 가능합니다.\nList-it은 구글 또는 애플 계정을 활용하여 간편하게 SNS를 이용하는 방식으로도 가입할 수 있습니다.")
          },
          {
            question: t("[기타] 입점 및 제휴/광고 문의는 어떻게 하나요?"),
            answer: t("입점 신청은 회원 가입 후 [마이페이지-판매]에서 판매자 신청 부탁드리며, 제휴/광고 문의는 Contact@list-it.xyz로 메일 문의 부탁드립니다.")
          },
          {
            question: t("[기타] 등급이 무엇인가요?"),
            answer: t("등급이란 글로벌 그레이딩 업체인 PSA와 Beckett에서 제공하는 등급을 일컫습니다. 등급은 총 4가지, 표면, 모서리, 사면, 가운데 정렬에 대한 항목을 감정하며, 감정 결과에 따라 1-10점의 점수가 매겨집니다.")
          },
          {
            question: t("[기타] List-it 검수는 어떻게 이루어지나요?"),
            answer: t("List-it 내부 인력과 그레이딩 전문 업체 제휴를 통해 판매 제품의 검수가 이루어지고 있습니다.")
          },
          {
            question: t("[기타] 시세는 어떻게 측정되나요?"),
            answer: t("Ebay, 아마존, 등의 글로벌 이커머스 플랫폼과 가격 연동을 통해 시세를 측정하고 있으며, 판매 거래가 없는 제품은 List-it 알고리즘을 통해 시세를 제공해드리고 있습니다.")
          },
          {
            question: t("[기타] 개인도 판매할 수 있나요?"),
            answer: t("개인 판매자도 판매자 등록을 통해 판매 가능합니다. 회원 가입 후 [마이페이지-판매]를 통해 판매 신청 부탁드립니다.")
          }
        ]
      },
      
    ]

    useEffect(() => {
      i18n.changeLanguage(language);
      // isNotLogin(user).then((data) => {
      //   if(data)
      //     window.location.replace("/");
      // });
    }, []);
    return (
      <AskPageWrapper>
        <ProcessHeader title={t("1:1 문의")} isSearchForm={false} />
        <AskToEmail>
          <Font_2xl>{t("무엇을 도와드릴까요?")}</Font_2xl>
          <Font_2xl isBold>{t("이메일로 문의를 남겨주세요!")}</Font_2xl>
          <EmailButtonWrapper>
            <EmailWrapper>
              <EmailIcon src={email} />
              <Font_xl>Contact@list-it.xyz</Font_xl>
            </EmailWrapper>
          </EmailButtonWrapper>
        </AskToEmail>
        <a href="mailto:email@list-it.kr">
          <ButtonWrapper>
            <ButtonComponent
              isValid={true}
              text={t("문의 메일 남기기")}
              size={Size.LARGE}
            />
          </ButtonWrapper>
        </a>
        <GrayBorder />
        <QuestionWrapper>
          <QuestionHeader isBold>{t("자주 묻는 질문")}</QuestionHeader>
          {
           askList.map((questionList)=>
             <QuestionCategoryWrapper>
               <QuestionCategory isBold>{t(questionList.category)}</QuestionCategory>
               {questionList.content.map((item)=>
                    <AccordionButtonComponent
                      title={t(item.question)}
                      detail={t(item.answer)}
                      />
                      )}
            </QuestionCategoryWrapper>
           )
          }
        </QuestionWrapper>
      </AskPageWrapper>
    );
};

const AskPageWrapper = styled.div`
`

const AskToEmail = styled.div`
    padding: 1.5rem 1rem 2rem 1rem;
`

const EmailButtonWrapper = styled.div`
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`

const EmailWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
`

const EmailIcon = styled.img`
`

const GrayBorder = styled.div`
    height: 0.5rem;
    background-color: ${colors.GREY[50]};
`

const QuestionWrapper = styled.div`
  padding-bottom: 62px;
`

const QuestionCategoryWrapper = styled.div`
`

const QuestionCategory = styled(Font_lg)`
  padding: 1.5rem 0 0.5rem 1rem;
`

const QuestionHeader = styled(Font_lg)`
    padding: 1.5rem 1rem 0.5rem 1rem;
`




export default AskPage;

