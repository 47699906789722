import ProcessHeader from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import profileDefaultImg from "assets/profile_default_img.svg";
import camera from "assets/camera.svg";
import { colors } from "styled/colors";
import NicknameValidComponent from "components/common/NicknameValidComponent";
import { Font_md } from "styled/fonts";
import { Textarea } from '@chakra-ui/react';
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "api/UserApi";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { nicknameState } from "recoil/signupRecoil";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import Loading from "components/Loading";

const SettingProfilePage = () => {
    const [imageFile, setImageFile] = useState<File | null>();
    const [nickname, setnickname] = useRecoilState(nicknameState);
    const [introduce, setIntroduce] = useState('');
    const [user, setUser] = useRecoilState(loginState);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = useState(user.user.thumbnail);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
      i18n.changeLanguage(language);
      setnickname(user.user.name);
      setIntroduce(user.user.summary && user.user.summary);
      if (!user.access) {
        window.location.replace("/");
      }
    }, []);

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
          }
    };

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files;
        if(selectedFile) {
            setSelectedImage(URL.createObjectURL(selectedFile[0]));
            setImageFile(selectedFile[0]);
        }
    }

    const handleIntroduceChange = (event:any) => setIntroduce(event.target.value);

    const handleSuccessButton = async() => {
        const formData = new FormData();
        if(imageFile) {
            formData.append('thumbnail', imageFile);
        }
        formData.append('name', nickname);
        formData.append('summary', introduce);

        setLoading(true);
        const userRes : any = await updateUserInfo({formData, access: user.access});
        if(userRes.error){
            setUser({user:{}, access:""})
            navigate('/');
        }
        setUser((prevUser: any)=> ({
            ...prevUser,
            user: {...userRes.data},
        }));
        setLoading(false);
        navigate('/mypage');
    }

    return (
        <SettingProfile>
            {loading ?
            <Loading/>
            :
            <>
            <ProcessHeader
            title={t("프로필 설정")}
            isSearchForm={false}
            />
            <Content>
                <PhotoWrapper onClick={handleImageClick}>
                    <PostingInput
                    type="file"
                    accept='image/*'
                    ref={fileInputRef}
                    onChange={handleFileChange}/>
                    <ImageWrapper>
                    <ProfilePhoto src={selectedImage || profileDefaultImg}/>
                    </ImageWrapper>
                    <Camera src={camera}/>
                </PhotoWrapper>
                <InputWrapper>
                    <InputName isBold>{t("닉네임")}</InputName>
                    <NicknameValidComponent/>
                </InputWrapper>
                <InputWrapper>
                    <InputName isBold>{t("소개글")}</InputName>
                    <Textarea
                    placeholder={t("나를 소개하는 글을 써보세요!")}
                    value={introduce}
                    onChange={handleIntroduceChange}
                    _placeholder={{color: colors.GREY[400]}}
                    size="lg"
                    rows={4}
                    style={{ width: "calc(100% - 2rem)", margin: "0 auto", padding: "12px 1rem" }}/>
                </InputWrapper>
                <ButtonComponent 
                text={t("완료")} 
                isValid={true} 
                size={Size.LARGE}
                onClick={handleSuccessButton}/>
            </Content>
            </>}
            

        </SettingProfile>
    )
};

const SettingProfile = styled.div`
`

const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    padding: 1.5rem 0;
`

const PhotoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ProfilePhoto = styled.img`
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 120px;
    min-height: 120px;
`

const ImageWrapper = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 70%;
    overflow: hidden;
    position: relative;
`

const Camera = styled.img`
    width: 2rem;
    height: 2rem;
    padding: 4px;
    border: 1px solid ${colors.GREY[200]};
    border-radius: 1rem;
    background-color: #fff;
    position: relative;
    top: -2rem;
`

const PostingInput = styled.input`
    display: none;
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`

const InputName = styled(Font_md)`
    margin-left: 1rem;
`


export default SettingProfilePage;