import styled from "styled-components";
import { Button } from '@chakra-ui/react';
import welcome from 'assets/welcome.svg';
import { colors } from "styled/colors";
import { useNavigate } from "react-router-dom";
import { Size } from "types/types";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";

const WelcomePage = () => {
  const { t, i18n } = useTranslation("signup");
  const language = useRecoilValue(langState);
  
  const navigate = useNavigate();
  const HandleClick = () => {
    navigate('/');
  };
  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])
  return (
    <Welcome>
      <Contents>
        <img src={welcome} />
        <TopStr>{t("리스트잇 가입을 축하합니다!")}</TopStr>
        <BottomStr>{t("지금 바로 인기 컬렉션들을 만나보세요.")}</BottomStr>
      </Contents>
      <ButtonWrapper>
        <ButtonComponent 
          text={t('리스트잇 시작하기')}
          size={Size.LARGE}
          onClick={HandleClick} 
          isValid={true}
          color={colors.GREEN[500]}/>
      </ButtonWrapper>
    </Welcome>
  );
};

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const TopStr = styled.p`
  font-size: 18px;
  font-weight: 600;
`

const BottomStr = styled.p`
  font-size: 16px;
  font-weight: 400;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

export default WelcomePage;