import styled from "styled-components";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { Font_md } from "styled/fonts";
import PostingPictures from "components/common/PostingPictures";
import { useEffect, useState } from "react";
import { Input, Textarea, Select } from '@chakra-ui/react';
import { colors } from "styled/colors";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import {OptionState, SellerType, Size, VerticalCardType} from "types/types";
import {useLocation, useNavigate} from "react-router-dom";
import { categoryState, imageProductState, productContentState, productNameState, sellingTypeState, sportsState, playerNameState, yearState, gradingState, producerState, optionsState} from "recoil/addProductRecoil";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {editCategory, editImage1, editImage2, editProduct, getCategoryData, getItemDetail} from "api/api";
import { useTranslation } from "react-i18next";
import {langState, loginState} from "recoil/loginRecoil";
import { Categories, Category } from "types/types";
import addon from "../../assets/addon.svg";
import addon_dollar from "../../assets/addon_dollar.svg";
import {changeUsdToKrw, fixedExchangePrice} from "../../api/StringUtils";
import {createProduct, getSellerInfo} from "../../api/SellerApi";
import {ca, tr} from "date-fns/locale";
import {editProductState} from "../../recoil/sellerRecoil";

interface CategoriesTitle {
    [key: string]: string;
}

const EditProduct = () => {
    const [sellerInfo, setSellerInfo] = useState<SellerType>();
    const location = useLocation();
    const sch = location.search;
    const params = new URLSearchParams(sch);
    const cardId: string = params.get("id") || "1";
    const [imageProduct, setImageProduct] = useState<any>([]);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [productName, setProductName] = useState('');
    const [productContent, setProductContent] = useState('');
    const [price, setPrice] = useState<number>(0);
    const [year, setYear] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [grading, setGrading] = useState('');
    const [producer, setProducer] = useState('');
    const [options, setOptions] = useRecoilState(optionsState);
    const [sports, setSports] = useState('');
    const user: any = useRecoilValue(loginState);
    const [categories, setCategories] = useState<Categories[]>([]);
    const [valueProduct, setValueProduct] = useState<any>();
    const [category, setCategory] = useState(0);
    const { t, i18n } = useTranslation("addProduct");
    const language = useRecoilValue(langState);
    const [noneEditImage, setNoneEditImage] = useState<any>([]);
    const navigate = useNavigate();
    const setIsEdit = useSetRecoilState(editProductState);

    const categoriesTitle : CategoriesTitle = {
        "스포츠": sports,
        "선수명": playerName,
        "연도": year,
        "그레이딩": grading,
    }
    //스포츠카드 카테고리일때만 제조사 받기
    if (category === 3) categoriesTitle.제조사 = producer;

    const filterOptionData = (optionName: string, selectedOption: string) => {
        const thisOption = categories.filter((item) => item.name === optionName);
        let newVal = valueProduct.filter((e: any) => e.option.name === optionName);
        if (thisOption[0].name === "연도" || thisOption[0].name === "선수명") {
            newVal[0].value = selectedOption;
            newVal[0].eng_value = selectedOption;
        } else {
            const data = thisOption.length !== 0 && thisOption[0].selects.filter((item) => item.name === selectedOption)[0];
            if (data) {
                newVal[0].value = data.name;
                newVal[0].eng_value = data.eng_name;
            }
        }
    }
    useEffect(()=> {
        (async () => {
            i18n.changeLanguage(language);
            const sellerRes = await getSellerInfo(user.access);
            setSellerInfo(sellerRes);
            const response = await getItemDetail(cardId);
            if (response.data) {
                if (response.data.category) {
                    setCategory(response.data.category);
                }
                const image1 = response.data.image1;
                const image2 = response.data.image2;
                const image3 = response.data.image3;
                const selectedImages = [image1, image2, image3].filter(image => image !== null);
                // @ts-ignore
                setSelectedImages(selectedImages);
                // @ts-ignore
                setNoneEditImage(selectedImages);
                const value = response.data.value_product;
                setValueProduct(value);
                setProductName(response.data.name || '');
                setProductContent(response.data.content || '');
                setPrice(response.data.price || 0);
                setPlayerName(value.filter((e) => e.option.name === '선수명')[0].value)
                setYear(value.filter((e) => e.option.name === '연도')[0].value)
                if (language === 'ko-KR') {
                    setSports(value.filter((e) => e.option.name === '스포츠')[0].value);
                    setGrading(value.filter((e) => e.option.name === '그레이딩')[0].value);
                } else {
                    setSports(value.filter((e) => e.option.name === '스포츠')[0].eng_value);
                    setGrading(value.filter((e) => e.option.name === '그레이딩')[0].eng_value);
                }
                if (response.data?.category === 4) {
                    setProducer('없음');
                } else {
                    if (language === 'ko-KR') {
                        setProducer(value.filter((e) => e.option.name === '제조사')[0].value);
                    } else {
                        setProducer(value.filter((e) => e.option.name === '제조사')[0].eng_value);

                    }
                }
            }
            setOptions([]);
            setImageProduct([]);
        })();
    },[])

    useEffect(()=> {
        fetchCategory();
    },[category])

    const handleProductNameChange = (event: any) => setProductName(event.target.value);
    const handleProductContentChange = (event: any) => setProductContent(event.target.value);
    const handleSportsChange = (event: any) => {
        setSports(event.target.value);
        filterOptionData('스포츠', event.target.value);
    };
    const handlePlayerNameChange = (event: any) => {
        setPlayerName(event.target.value);
        filterOptionData('선수명', event.target.value);
    }
    const handleYearChange = (event: any) => {
        setYear(event.target.value);
        filterOptionData('연도', event.target.value);
    }
    const handleGradingChange = (event: any) => {
        setGrading(event.target.value);
        filterOptionData('그레이딩', event.target.value);
    }
    const handleProducerChange = (event: any) => {
        setProducer(event.target.value);
        filterOptionData('제조사', event.target.value);
    }

    const photoExists = selectedImages.length !== 0;
    const inputs = [productName, productContent, sports, playerName, year, grading, producer, photoExists]

    const isValid = inputs.every(input => Boolean(input));

    const fetchCategory = async () => {
        const categoryRes = await getCategoryData();
        const myCategoryList = categoryRes[(category === 3 ? 0 : 1) || 0].options;
        setCategories(myCategoryList);
    };

    const FindCategory = (categories: Category[] , name: string) => {
        const emptyCategory = {
            name: '',
            eng_name: '',
            selects: [],
        }
        const thisCategory = categories.filter((category) => category.name === name)[0];
        return thisCategory ? thisCategory : emptyCategory;
    }
    function findRemovedIndexes(original: any, updated: any) {
        const removedIndexes = original
            .map((item: any, index: number) => updated.includes(item) ? -1 : index) // 변화된 배열에 없는 경우, 원래 인덱스 반환
            .filter((index: number) => index !== -1); // -1이 아닌 인덱스만 필터링 (실제로 제거된 요소의 인덱스)

        return removedIndexes;
    }
    const handleButton = async () => {
        const formData = new FormData();
        const removedIndexes = findRemovedIndexes(noneEditImage, selectedImages);
        if (removedIndexes.length > 0) {
            for (const value of removedIndexes) {
                await editProduct(cardId, user, {
                    [`image${value+1}`]: null
                });
                if (value === 0) {
                    if (noneEditImage[1]) {
                        await editProduct(cardId, user, {
                            [`image${value+1}`]: null
                        });
                        await editImage1(cardId, user, noneEditImage[1]);
                    }
                }
                if (value === 1) {
                    if (noneEditImage[2]) {
                        await editProduct(cardId, user, {
                            [`image${value+1}`]: null
                        });
                        await editImage2(cardId, user, noneEditImage[2]);
                    }
                }
            }
        }
        imageProduct.forEach((image: any, index: any) => {
            const fieldName = `image${index + 1}`;
            formData.append(fieldName, image);
        })

        formData.append('name', productName);
        formData.append('content', productContent);
        if (sellerInfo?.swift_code) {
            formData.append('price', changeUsdToKrw(price, fixedExchangePrice).toString());
        } else {
            formData.append('price', price.toString());
        }
        try {
            for (const value of valueProduct) {
                await editCategory(value.id, user, value.value, value.eng_value);
            }
            await editProduct(cardId, user, formData);
            setIsEdit(true);
            navigate(-1);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <AddProductInfoWrapper>
            <ProcessHeaderComponent
                title={t("상품 수정")}
                isSearchForm={false}
            />
            <ProductInfo>
                <Title isBold>{t("상품 예시 사진")}</Title>
                <PostingPictures
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    selectedFiles={imageProduct}
                    setSelectedFiles={setImageProduct}
                    isEdit={true}/>
                <ProductInputs>
                    <Input
                        size="lg"
                        value={productName}
                        onChange={handleProductNameChange}
                        placeholder={t("상품명")}
                        _placeholder={{color: colors.GREY[400]}}
                    />
                    <Textarea
                        size="lg"
                        value={productContent}
                        onChange={handleProductContentChange}
                        rows={4}
                        placeholder={t("상품 설명")}
                        _placeholder={{color: colors.GREY[400]}}
                    />
                </ProductInputs>
            </ProductInfo>
            <GrayBorder/>
            <Categorys>
                <CategoryWrapper>
                    <Font_md isBold>{t("판매가")}</Font_md>
                    <PriceInput>
                        <img src={true ? addon : addon_dollar}/>
                        <Input
                            size="lg"
                            placeholder="0"
                            _placeholder={{color: colors.GREY[400]}}
                            value={price}
                            onChange={(e) => setPrice(Number(e.target.value))}
                        />
                    </PriceInput>
                </CategoryWrapper>
                <CategoryWrapper>
                    <Title isBold>{t("스포츠")}</Title>
                    <CategorySelect
                        size="lg"
                        defaultValue=""
                        value={sports}
                        onChange={handleSportsChange}
                    >
                        <option value="" disabled hidden >{t("스포츠 선택")}</option>
                        {categories.length > 0 && FindCategory(categories, "스포츠").selects.map((category: any)=> <option value={language === 'ko-KR' ? category.name : category.eng_name}>{language === 'ko-KR' ? category.name : category.eng_name}</option>)}
                    </CategorySelect>
                </CategoryWrapper>
                <InputWrapper>
                    <Title isBold>{t("선수명")}</Title>
                    <Input
                        size='lg'
                        value={playerName}
                        onChange={handlePlayerNameChange}
                        placeholder={t("선수명")}
                        _placeholder={{color: colors.GREY[400]}}/>
                </InputWrapper>
                <InputWrapper>
                    <Title isBold>{t("연도")}</Title>
                    <Input
                        size='lg'
                        value={year}
                        onChange={handleYearChange}
                        placeholder={t("연도")}
                        _placeholder={{color: colors.GREY[400]}}/>
                </InputWrapper>
                <CategoryWrapper>
                    <Title isBold>{t("그레이딩")}</Title>
                    <CategorySelect
                        size="lg"
                        defaultValue=""
                        value={grading}
                        onChange={(e) => handleGradingChange(e)}
                    >
                        <option value="" disabled hidden >{t("그레이딩 선택")}</option>
                        {categories.length > 0 && FindCategory(categories, "그레이딩").selects.map((category : any)=> <option value={category.name}>{language === 'ko-KR' ? category.name : category.eng_name}</option>)}
                    </CategorySelect>
                </CategoryWrapper>
                {category === 3 &&
                    <CategoryWrapper>
                        <Title isBold>{t("제조사")}</Title>
                        <CategorySelect
                            size="lg"
                            defaultValue=""
                            value={producer}
                            onChange={handleProducerChange}
                        >
                            <option value="" disabled hidden >{t("제조사 선택")}</option>
                            {categories.length > 0 && FindCategory(categories, "제조사").selects.map((category : any)=> <option value={category.name}>{language === 'ko-KR' ? category.name : category.eng_name}</option>)}
                        </CategorySelect>
                    </CategoryWrapper>
                }
            </Categorys>
            <ButtonWrapper>
                <ButtonComponent
                    text={t("수정 완료")}
                    size={Size.LARGE}
                    isValid={isValid}
                    onClick={()=> handleButton()}
                />
            </ButtonWrapper>
        </AddProductInfoWrapper>
    )
}

const AddProductInfoWrapper = styled.div`
`

const ProductInfo = styled.div`
    padding: 0 1rem;
`

const Title = styled(Font_md)`
    padding-bottom: 0.5rem;
`

const ProductInputs = styled.div`
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
const GrayBorder = styled.div`
    height: 0.5rem;
    background-color: ${colors.GREY[50]};
`

const Categorys = styled.div`
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`

const CategoryWrapper = styled.div`
`

const CategorySelect = styled(Select)`
  option {
    color: black;
  }

  option[value=""][disabled] {
    color: gray;
  }
`

const InputWrapper = styled.div`
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`
const PriceInput = styled.div`
    display: flex;
    margin-top: 0.5rem;
`
export default EditProduct;