import { useEffect, useState } from "react";
import styled from "styled-components";
import backArrow from "assets/backArrow.svg";
import { Font_2xl, Font_sm, Font_md } from "styled/fonts";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";

interface TermsIProps {
    onClick:Function;
    title:string;
}

const TermsofUseForSeller = (props: TermsIProps) => {

    const language = useRecoilValue(langState);
    
    return (
      <TermsofUseWrapper>
        <Header>
          <ArrowBtnWrapper>
            <ArrowBtn onClick={()=>props.onClick()} src={backArrow}></ArrowBtn>
          </ArrowBtnWrapper>
          <TextWrapper>
            <Font_md isBold>{props.title}</Font_md>
          </TextWrapper>
          <EmptyIcon />
        </Header>
        {language == "ko-KR" ? (
          <Content>
            <Font_2xl isBold>리스트잇 판매자용 이용약관</Font_2xl>
            <ContentItem>
              <Font_md isBold>제1장 총칙</Font_md>
              <Font_sm isBold>제1조 (목적)</Font_sm>
              <Font_sm>
                이 약관은 주식회사 리스트잇(이하 “회사”라 한다)이 운영하는
                “리스트잇(List-it)”의 거래중개 서비스 이용과 관련하여,
                통신판매업자인 판매자(이하 “판매자”라 한다)와 회사 사이에
                권리·의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로
                합니다.
              </Font_sm>
              <Font_sm isBold>제2조 (약관의 효력)</Font_sm>
              <Font_sm>
                ① 이 약관에서 정하고 있지 아니한 정의규정 및 사항에 대하여는
                리스트잇 서비스 이용약관(이하 "일반 이용약관"이라 한다)을
                따르며, 서비스와 관련하여 이 약관과 일반 이용약관 사이에
                상충되는 사항이 존재하는 경우, 이 약관을 우선하여 적용합니다.{" "}
                <br />
                ② 회사는 이 약관 및 일반 이용약관에서 정하고 있지 아니한
                세부적인 내용에 대하여 판매자와의 별도 계약을 체결하거나
                개별적인 운영정책 등을 제정하는 방식으로 서비스를 운영할 수
                있으며, 개별적인 운영정책을 제정하는 경우 이를 플랫폼에 게재하는
                등의 방식을 통하여 판매자에게 고지합니다. <br />
              </Font_sm>
              <Font_sm isBold>제3조 (이용계약의 체결 및 적용)</Font_sm>
              <Font_sm>
                ① 회사와 판매자 사이의 이용계약은 본 서비스를 이용하고자 하는
                판매자가 이 약관의 내용에 대하여 동의를 한 다음 서비스 이용
                신청을 하고, 회사가 그 신청에 대해서 승낙함으로써 체결됩니다.{" "}
                <br />
                ② 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는
                승낙을 거절할 수 있습니다. <br />
                1. 일반 이용약관상의 승낙 거절사유에 해당하는 경우
                <br />
                2. 일반 이용약관에 따라 회원가입을 하지 아니한 자에 해당하는
                경우
                <br />
                3. 이 약관 제4조에 따라 판매자가 정보를 제공하지 아니하거나
                제공한 정보가 사실이 아닌 것으로 드러난 경우
                <br />
                4. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는
                경우 <br />
                ③ 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한
                시점(통상적으로 이용자가 플랫폼의 로그인 화면에서 제휴사의
                회원정보를 입력하고 필요한 동의절차를 이행하여 로그인 상태로
                서비스를 이용할 수 있는 상태를 의미함)으로 합니다. <br />
                ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간
                이내에 플랫폼에 대하여 회원정보 수정 등의 방법으로 그 변경사항을
                알려야 합니다. <br />
                ⑤ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상
                문제가 있는 경우에는 그 사유가 해소될 때까지 회원 등록신청에
                대한 승낙을 유보할 수 있습니다. <br />
              </Font_sm>
              <Font_sm isBold>제4조 (판매자의 정보 제공)</Font_sm>
              <Font_sm>
                ① 판매자는 회사와의 이용계약 체결 과정에서 다음의 사항들에 대한
                정보를 회사에게 제공하여야 합니다: <br />
                - 성명(판매자가 법인인 경우에는 그 명칭과 대표자의 성명) <br />
                - 사업장 소재지(반품주소가 사업장 소재지와 구분되는 경우 사업장
                소재지 및 반품주소) <br />
                - 전화번호 <br />
                - 전자우편주소 <br />
                - 통신판매업신고번호
                <br />
                - 사업자등록번호 <br />
                ② 회사는 필요한 경우 전항의 정보를 확인할 수 있는 증빙자료의
                제출을 요구할 수 있습니다. <br />
                ③ 판매자는 폐업신고를 하였을 경우 해당 사실을 즉시 회사에게
                통지하여야 하며, 판매자의 통지가 지연됨으로 인하여 판매자에게
                발생하는 여하한의 손실에 대하여 회사는 책임을 부담하지
                아니합니다. <br />
                ④ 판매자는 서비스와 관련하여 세금계산서 등의 발급이 필요한 경우
                회사가 지정한 절차에 따라 신청하고 회사가 요구하는 증빙자료를
                추가로 제출하여야 합니다. <br />
              </Font_sm>
              <Font_sm isBold>제5조 (판매 컬렉션의 정보 제공)</Font_sm>
              <Font_sm>
                ① 판매자는 거래중개 서비스를 통하여 컬렉션을 판매하기 위해
                판매등록하는 과정에서, 구매자와의 계약체결 전에 거래하는
                컬렉션에 대한 거래조건을 정확하게 이해하고 실수나 착오 없이
                거래할 수 있도록 다음 각 호의 사항을 적절한 방법으로 표시,
                광고하거나 고지하여야 하며, 계약이 체결되면 구매자에게 다음 각
                호의 사항이 기재된 계약내용에 관한 서면(전자서면의 방식을
                포함함, 이하 같음)을 컬렉션을 공급할 때까지 교부하여야 합니다:{" "}
                <br />
                1. 공급자 및 판매자의 상호, 대표자의 성명, 주소 및 전화번호 등{" "}
                <br />
                2. 컬렉션의 명칭, 종류 및 내용 <br />
                3. 컬렉션의 정보에 관한 사항. 이 경우 제품에 표시된 기재로
                계약내용에 관한 서면에의 기재를 갈음할 수 있다. <br />
                4. 컬렉션의 가격(가격이 결정되어 있지 아니한 경우에는 가격을
                결정하는 구체적인 방법)과 그 지급방법 및 지급시기 <br />
                5. 컬렉션의 공급방법 및 공급시기 <br />
                6. 청약의 철회 및 계약의 해제(이하 "청약철회등"이라 한다)의
                기한, 행사방법 및 효과에 관한 사항(청약철회등의 권리를 행사하는
                데에 필요한 서식을 포함한다) <br />
                7. 컬렉션의 교환, 반품, 보증과 그 대금 환불, 환불의 지연에 따른
                배상금 지급의 조건, 절차 <br />
                8. 전자매체로 공급할 수 있는 컬렉션의 전송, 설치 등을 할 때
                필요한 기술적 사항 <br />
                9. 소비자피해보상의 처리, 컬렉션에 대한 불만 처리, 구매자와
                판매자 사이의 분쟁 처리에 관한 사항 <br />
                10. 거래에 관한 약관(그 약관의 내용을 확인할 수 있는 방법을
                포함한다) <br />
                11. 구매자가 구매의 안전을 위하여 원하는 경우에는 컬렉션을
                공급받을 때까지 제3자에게 그 재화등의 결제대금을 예치하는
                것(이하 "결제대금예치"라 한다)의 이용을 선택할 수 있다는 사항
                또는 통신판매업자의 소비자피해보상보험계약등의 체결을 선택할 수
                있다는 사항 <br />
                12. 컬렉션의 가격 외에 교환, 반품 비용 등 구매자가 추가로
                부담하여야 할 사항이 있는 경우 그 내용 및 금액 <br />
                13. 판매일시, 판매지역, 판매수량, 인도지역 등 판매조건과
                관련하여 제한이 있는 경우 그 내용 <br />
                ② 전 항에도 불구하고 (1) 구매자가 컬렉션을 공급받는 자에게
                계약내용에 관한 서면(전자문서를 포함한다)을 교부하도록 동의한
                경우, 또는 (2) 판매자가 고의 또는 과실 없이 구매자의
                주소(전자우편주소를 포함한다)를 알 수 없어 구매자에게 계약내용에
                관한 서면(전자문서를 포함한다)을 교부할 수 없는 경우에는
                구매자에 갈음하여 컬렉션을 공급받는 자에게 계약내용에 관한
                서면을 교부할 수 있습니다. <br />
              </Font_sm>
              <Font_sm isBold>제6조 (수수료의 부과)</Font_sm>
              <Font_sm>
                ① 회사는 판매자에게 일반 이용약관상 통신판매업자에 해당하지
                아니하는 이용자에게 부과되는 수수료율과 별도의 수수료율을
                산정하여 수수료를 부과할 수 있습니다. <br />
                ② 회사는 필요한 경우 판매자가 서비스를 이용하는 과정에서
                발생하는 수수료 등을 신규로 설정하거나 변경할 수 있으며,
                수수료에 대한 세부사항은 플랫폼에 게재하는 등의 방식으로
                판매자에게 사전에 공지합니다. <br />
              </Font_sm>
              <Font_sm isBold>제7조 (컬렉션의 진위여부에 대한 책임)</Font_sm>
              <Font_sm>
                ① 판매자는 가품에 해당하는 컬렉션을 서비스를 통하여 판매하여서는
                아니된다. <br />
                ② 회사는 판매자가 컬렉션을 판매하는 과정에서 요구되는 제품검수
                서비스에 대한 서 진행 방식(생략 유무를 포함하며 이에 국한되지
                아니함), 수수료 등에 대하여 판매자와 별도로 협의할 수 있습니다.{" "}
                <br />
                ③ 만약 제품검수 서비스에서 판매자가 등록한 컬렉션이 가품에
                해당한다는 결과가 도출된 경우, 판매자는 해당 컬렉션이 진품에
                해당한다는 객관적인 자료를 제공하는 방식으로 컬렉션의 진위여부에
                대하여 회사와 협의할 수 있습니다. <br />
                ④ 회사는 판매자가 등록한 컬렉션에 대하여 제품검수 서비스 결과
                사전에 협의한 횟수 이상으로 가품에 해당한다는 결과가 도출된
                경우(전 항에 따라 협의 결과 가품에 해당하는 것으로 인정된 경우를
                의미함), 회사는 판매자와의 별도 계약을 체결하는 방식으로
                판매자의 계약 위반행위에 대해 위약벌을 부과할 수 있습니다.{" "}
                <br />
              </Font_sm>
              <Font_sm isBold>제8조 (판매자에 대한 서비스 이용제한)</Font_sm>
              <Font_sm>
                ① 회사는 판매자가 게시하거나 등록하는 서비스 내의 게시물이 다음
                각 호 중 하나에 해당하는 경우, 사전 통지 없이 해당 판매자가 게시
                또는 등록한 모든 게시물을 서비스 내에서 비공개 처리할 수
                있습니다. <br />
                1. 일반 이용약관 제9조 제1항 각 호에 규정된 금지행위 중 어느
                하나에 해당하는 경우 <br />
                2. 판매자가 판매등록한 컬렉션에 관한 정보가 사실과 다른 경우{" "}
                <br />
                ② 회사는 제1항의 사유가 발생하거나, 다음의 사유에 해당하는
                방식으로 서비스의 정상적인 운영을 방해한 경우 회원의 서비스
                이용을 제한할 수 있습니다. <br />
                1. 회사가 제공하는 서비스 이외의 서비스나 공간에서 컬렉션의
                거래를 유도하거나 거래를 진행하는 경우 2. 기타 회사가 허용하지
                않는 방식으로 컬렉션의 거래 등을 유도하거나 거래를 진행하는 경우{" "}
                <br />
                ③ 회사가 제2항에서 정한 이용제한 조치를 하는 경우에는 다음 각
                호의 사항을 회원에게 통지합니다. <br />
                1. 이용제한 조치의 사유 2. 이용제한 조치의 유형 및 기간 3.
                이용제한 조치에 대한 이의신청 방법 <br />
                ④ 회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당
                계정의 서비스 이용을 정지할 수 있습니다. <br />
                1. 계정이 해킹 또는 도용당했거나, 범죄에 이용되었다는 정당한
                신고가 접수된 경우 2. 그 밖에 이에 준하는 사유로 서비스 이용의
                잠정조치가 필요한 경우 <br />
              </Font_sm>
              <Font_sm isBold>제9조 (재판권 및 준거법)</Font_sm>
              <Font_sm>
                ① 이 약관은 대한민국 법률에 따라 규율되고 해석되며, 서비스와
                관련된 회원자와 회사의 관계에는 대한민국 법률이 적용됩니다.{" "}
                <br />
                ② 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는
                회원의 주소를 관할하는 지방법원을 제1심 전속 관할법원으로 하고,
                회원의 주소가 없는 경우에는 거소를 관할하는 지방법원을 제1심
                전속 관할법원으로 합니다. <br />
                ③ 소 제기 당시 소비자의 주소 또는 거소가 없거나 분명하지 아니한
                경우 및 회원의 주소(주소가 없는 경우 거소)가 외국인 경우에는
                제2항은 적용하지 아니하며, 회사의 본점 소재지를 관할하는
                지방법원을 제1심의 전속 관할법원으로 합니다. <br />
              </Font_sm>
              <Font_sm>
                부칙 (2023.10.4.) <br />
                이 약관은 2023년 10월 4일부터 시행합니다. <br />
              </Font_sm>
            </ContentItem>
          </Content>
        ) : (
          <Content>
            <Font_2xl isBold>List -it Inc. Terms of Use for Sellers</Font_2xl>
            <ContentItem>
              <Font_md isBold>Chapter 1: General Provisions</Font_md>
              <Font_sm isBold>Article 1 (Purpose)</Font_sm>
              <Font_sm>
                This agreement is intended to regulate the rights, obligations,
                and responsibilities between List-it Inc., hereinafter referred
                to as "The Company" and the sellers, hereinafter referred to as
                "The Seller" who are e-commerce vendors, in connection with the
                use of the transaction brokerage service of The Company.
              </Font_sm>
              <Font_sm isBold>Article 2 (Effect of the Agreement)</Font_sm>
              <Font_sm>
                ① In matters not provided for in this agreement, definitions,
                and provisions, the General Terms of Use of the List-it Inc.
                service, hereinafter referred to as the “General Terms” shall
                apply. In the event of any conflict between this agreement and
                the General Terms in connection with the service, this agreement
                shall take precedence.
                <br />
                ② The Company may operate the service by entering into a
                separate contract with The Seller regarding specific details not
                provided for in this agreement or by establishing individual
                operational policies. In the case of establishing individual
                operational policies, The Company shall notify The Seller by
                posting them on the platform.
                <br />
              </Font_sm>
              <Font_sm isBold>
                Article 3 (Conclusion and Application of Service Agreement)
              </Font_sm>
              <Font_sm>
                ① The service agreement between the company and the seller is
                concluded when the seller, who wishes to use this service,
                agrees to the contents of this agreement, and applies for
                service use. The agreement is concluded when the company
                approves the application.
                <br />
                ② The Company may refuse approval for following types of
                applications:
                <br />
                1. Cases that correspond to reasons for refusal as specified in
                the general terms and conditions.
                <br />
                2. Cases involving individuals who have not completed membership
                registration in accordance with the general terms and
                conditions.
                <br />
                3. Cases in which the seller, as per Article 4 of this
                Agreement, fails to provide information or where the information
                provided is found to be false.
                <br />
                4. Any other circumstances where approval is considered
                inappropriate in accordance with each item above.
                <br />
                5. The time of formation of the membership registration
                agreement is when the company's approval reaches the member
                (commonly understood as the point when the user can use the
                service in a logged-in state after entering the affiliate's
                membership information on the platform's login screen and
                completing the necessary consent process).
                <br />
                6. If there are any changes to the information provided during
                membership registration, the member must notify the platform of
                these changes through the method of modifying member information
                or in a similar way within a reasonable period.
                <br />
                7. If there is a lack of available service-related equipment, or
                if there are technical or operational issues, the company may
                withhold approval of the member registration application until
                the reasons are resolved.
                <br />
              </Font_sm>
              <Font_sm isBold>
                Article 4 (Provision of Seller Information)
              </Font_sm>
              <Font_sm>
                ① The Seller must provide the following information to The
                Company during the process of entering into a service agreement:
                <br />
                1. Name (In the case of a corporate seller, the name, and the
                name of the representative)
                <br />
                2. Business address (In cases where the return address is
                different from the business address, both the business address
                and the return address)
                <br />
                3. Phone number
                <br />
                4. Email address
                <br />
                ② If necessary, The Company may request the submission of
                supporting documents to verify the information in the previous
                clause.
                <br />
                ③ If The Seller has closed their business, they must immediately
                notify The Company of this fact. The company shall not be
                responsible for any losses incurred by the seller due to delays
                in their business closure notification.
                <br />
                ④ If The Seller needs to issue a tax invoice, etc. in relation
                to the service, he or she must apply according to the procedures
                specified by The Company and submit additional supporting
                materials requested by The Company.
                <br />
              </Font_sm>
              <Font_sm isBold>
                Article 5 (Provision of Information on the Sale of Collectibless)
              </Font_sm>
              <Font_sm>
                ① In the process of registering collectibles for sale through the
                transaction intermediary service, the seller must accurately
                present or notify the following items through appropriate means
                so that the buyer can clearly understand the terms of the
                transaction without error or confusion before entering into a
                contract. Upon contract formation, the seller must provide the
                buyer with written content (including electronic documents)
                regarding the contract until the collectibles is supplied, which
                should include the following:
                <br />
                1. Name, address, and contact information of The Seller and
                seller's representative.
                <br />
                2. Name, type, and content of the collectibles
                <br />
                3. Information about the collectibles. In this case, the
                information presented on the product may replace the written
                content of the contract.
                <br />
                4. Price of the collectibles (or the specific method of
                determining the price if not already determined), payment
                method, and payment timing.
                <br />
                5. Method and timing of collectibles delivery.
                <br />
                6. Information regarding the right of withdrawal and
                cancellation of the contract, including the deadline, method,
                and required format to exercise the right of withdrawal.
                <br />
                7. Conditions and procedures for exchange, return, warranty of
                the collectibles, and reimbursement of the collectibles price, as
                well as compensation for delay in reimbursement.
                <br />
                8. Technical specifications necessary for transmission,
                installation, etc., of the collectibles via electronic media.
                <br />
                9. Information on consumer damage compensation, handling of
                collectibles complaints, and dispute resolution between the buyer
                and seller.
                <br />
                10. Terms and conditions of the transaction, including methods
                to access their content.
                <br />
                11. The option for the buyer to choose the use of third-party
                payment deposit (hereafter "payment deposit") to secure payment
                for the goods or enter into a consumer damage compensation
                insurance contract, if desired, to ensure the safety of the
                purchase.
                <br />
                12. Additional expenses that the buyer may be required to bear,
                such as exchange or return costs, besides the price of the
                collectibles.
                <br />
                13. Restrictions on sale conditions related to the date of sale,
                sales region, sales quantity, delivery area, etc.
                <br />
                ② Notwithstanding the above provision, in the following cases,
                (1) if the buyer has agreed to receive written content regarding
                the contract (including electronic documents) from the entity
                supplying the collectibles or (2) if the seller, without intent or
                negligence, is unable to provide written content regarding the
                contract to the buyer due to not knowing the buyer's address
                (including email address), the seller may provide written
                content regarding the contract to the entity receiving the
                collectibles on behalf of the buyer.
                <br />
              </Font_sm>
              <Font_sm isBold>
                Article 6 (Commission Fees to the Company)
              </Font_sm>
              <Font_sm>
                ① The Company may calculate and impose commission fees to The
                Sellers at rates separate from those applied to users who do not
                qualify as online sellers under the general terms and
                conditions.
                <br />
                ② The company may, when necessary, establish new fees or make
                changes to fees incurred by sellers during their use of the
                service. Details regarding these fees will be communicated to
                sellers in advance, such as by posting them on the platform.
                <br />
              </Font_sm>
              <Font_sm isBold>
                Article 7 (Responsibility for the Authenticity of Collectibles)
              </Font_sm>
              <Font_sm>
                ① The Sellers must not sell counterfeit collectibles through The
                Company’s service.
                <br />
                ② The Company may engage in separate discussions with sellers
                regarding the manner of conducting the product inspection
                service, including whether to omit it and the associated fees,
                during the process of selling collectibles.
                <br />
                ③ If the results of the product inspection service indicate that
                the collectibles registered by the seller are counterfeit, the
                seller may discuss the authenticity of the collectibles with the
                company by providing objective evidence that the collectibles is
                genuine.
                <br />
                ④ If, in the case of collectibles registered by the seller, the
                results of the product inspection service indicate that the
                collectibles are counterfeit on more than the pre-discussed
                number of occasions (as determined through discussions as
                counterfeit according to the previous clause), the company may
                impose penalties on the seller for the breach of their contract
                through a separate agreement.
                <br />
              </Font_sm>
              <Font_sm isBold>
                Article 8 (Restrictions on The Seller’s Service Usage)
              </Font_sm>
              <Font_sm>
                ① If the content posted or registered by a seller within the
                service falls under any of the following cases, the company may,
                without prior notice, make all content posted or registered by
                that seller private within the service:
                <br />
                1. When the seller engages in any of the prohibited acts as
                defined in Article 9, Paragraph 1 of the general terms and
                conditions.
                <br />
                2. When the information regarding collectibles registered by The
                Seller is found to be untrue.
                <br />
                ② The Company may restrict The Seller's service usage if any of
                the following situations occur or if The Seller disrupts the
                normal operation of the service in a manner that falls under any
                of the following cases:
                <br />
                1. When The Seller induces or engages in collectibles transactions
                in a service or space provided by the company outside the
                services offered.
                <br />
                2. When The Seller induces or engages in collectibles transactions
                in a manner not permitted by The Company
                <br />
                ③ When the company takes the measures of service restriction as
                specified in Paragraph 2, The Company shall notify The Seller of
                the following:
                <br />
                1. The reason for the service restriction.
                <br />
                2. The type and duration of the service restriction.
                <br />
                3. The method for appealing the service restriction.
                <br />
                ④ The company may suspend service usage of the account until an
                investigation is completed in the following cases:
                <br />
                1. When there is a legitimate report that the account has been
                hacked, used for identity theft, or involved in criminal
                activities.
                <br />
                2. In other cases where temporary measures for service usage are
                necessary for reasons similar to those described above.
                <br />
              </Font_sm>
              <Font_sm isBold>
                Article 9 (Jurisdiction and Governing Law)
              </Font_sm>
              <Font_sm>
                ① This Agreement shall be governed by and interpreted in
                accordance with the laws of the Republic of Korea, and the
                relationship between the member and the company concerning the
                service shall be subject to the laws of the Republic of Korea.
                <br />
                ② In the event of a lawsuit arising from a dispute between the
                company and a member, the court having jurisdiction over the
                member's address shall be the exclusive court of first instance.
                If the member does not have an address in Korea, the court
                having jurisdiction over the member's place of residence shall
                be the exclusive court of first instance.
                <br />
                ③ If, at the time of filing a lawsuit, the consumer has no
                address or place of residence or if it is not clear, or if the
                member's address (or place of residence if there is no address)
                is a foreign national, the provisions of Paragraph 2 shall not
                apply. In such cases, the court having jurisdiction over the
                location of the company's headquarters shall be the exclusive
                court of first instance.
                <br />
              </Font_sm>
            </ContentItem>
            <Font_sm>
              Supplementary Provisions (Effective from October 4, 2023) <br />
              This Agreement shall be effective from October 4, 2023
            </Font_sm>
          </Content>
        )}
      </TermsofUseWrapper>
    );
};

const TermsofUseWrapper = styled.div`
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 1rem;
    margin-bottom: 65px;
`

const ContentItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`


const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  z-index: 11;
`;

const ArrowBtn = styled.img`

`;

const ArrowBtnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyIcon = styled.div`
  width: 40px;
`

export default TermsofUseForSeller;