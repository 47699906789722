import SaleCard from "components/sellerHistory/SaleCard";
import styled from "styled-components";
import card_img from "assets/card_img.svg";
import { useEffect, useState } from "react";
import { UploadData } from "types/types";
import { getProducts } from "api/SellerApi";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useNavigate } from "react-router-dom";
import EmptyMessage from "components/common/EmptyMessage";
import Loading from "components/Loading";

const ForSaleHistory = () => {
    const [forSaleData, setForSaleData] = useState<UploadData[]>([]);
    const [loading, setLoading] = useState(false);
    const user: any = useRecoilValue(loginState);

    const navigate = useNavigate();

    const fetchForSaleData = async () => {
        try {
            setLoading(true);
            const dataRes = await getProducts(user.access);
            const filterData = dataRes.filter((item)=> item.product.selling_type == 1 && item.purchase == null && !item.product.is_selled).reverse();
            setForSaleData(filterData);
            setLoading(false);
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(()=> {
      fetchForSaleData();  
    },[])

    return (
        <ForSaleHistoryWrapper>
            {!loading ?
            forSaleData.length !== 0 ?
            forSaleData.map((list)=>
            <SaleCard
            name={list.product.name}
            price={list.product.price}
            image={list.product.image1}
            onClick={()=> {navigate(`/item-detail?id=${list.product.id}`)}}
            currency={list.product.author.swift_code ? 'USD' : 'KRW'}/>) :
            <EmptyMessage/> : 
            <Loading/>}
        </ForSaleHistoryWrapper>    
    )
};

const ForSaleHistoryWrapper = styled.div`
`

export default ForSaleHistory;