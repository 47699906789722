import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_sm } from "styled/fonts";

interface greenBackgroundButtonTypes {
    photoUrl : string;
    name: string;
    onClick?: Function
}

const GreenBackgroundButton = (props: greenBackgroundButtonTypes) => {
    const tmpFunction = () => {}
    const buttonHandler = props.onClick || tmpFunction;
    return (
        <ButtonWrapper onClick={()=> buttonHandler()}>
            <Icon src={props.photoUrl}/>
            <Font_sm>{props.name}</Font_sm>
        </ButtonWrapper>
    )
}

const ButtonWrapper = styled.div`
    background-color: ${colors.GREY[50]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    flex: 1;
    gap: 8px;
    cursor: pointer;
`

const Icon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`

export default GreenBackgroundButton;