import GreenBackgroundButton from "components/GreenBackgroundButton";
import MenuItemComponent from "components/common/MenuItemComponent";
import styled from "styled-components";
import { Font_lg, Font_sm } from "styled/fonts";
import shopping_bag from "assets/shopping_bag.svg";
import auction from "assets/auction.svg";
import stamp from "assets/stamp.svg";
import { useNavigate } from "react-router-dom";
import { loginState } from "recoil/loginRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { logout } from "api/UserApi";
import { colors } from "styled/colors";
import { useState } from "react";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";
import MainFooterComponent from "components/main/MainFooter";
import Loading from "components/Loading";

type PurchasePageProps = {
    handleTabsChange: () => void;
};

const PurchaseComponent = (props: PurchasePageProps) => {
    const [user, setUser] = useRecoilState(loginState);
    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation("mypage");
    const language = useRecoilValue(langState);
    
    const navigate = useNavigate();
    const logoutButton = async() => {
        setLoading(true);
        const logoutRes = await logout(user.access);
        setLoading(false);
        setUser({access:null, user:{}})
        window.location.replace('/');
    }

    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <PurchasePageWrapper>
            {loading ?
            <Loading/> :
            <>
            <PurchaseInfoWrapper>
                <GreenButtonWrapper>
                    <GreenBackgroundButton photoUrl={shopping_bag} name={t("구매 내역")} onClick={()=> {navigate('/purchace-hist')}}></GreenBackgroundButton>
                    <GreenBackgroundButton photoUrl={auction} name={t("제안 내역")} onClick={()=> {navigate('/auction-history/0')}}></GreenBackgroundButton>
                    <GreenBackgroundButton photoUrl={stamp} name={t("선정 내역")} onClick={()=> {navigate('/auction-history/1')}}></GreenBackgroundButton>
                </GreenButtonWrapper>
                <MenuHeader isBold>{t("계정관리")}</MenuHeader>
                <MenuItemComponent name={t("개인정보 설정")} onClick={()=> {navigate('/mypage/personal')}}/>
                <MenuItemComponent name={t("배송지 관리")} onClick={()=> {navigate('/mypage/home-addr')}}/>
                <MenuItemComponent name={t("판매자 신청")} onClick={props.handleTabsChange}/>
                <MenuHeader isBold>{t("서비스 이용")}</MenuHeader>
                <MenuItemComponent name={t("공지사항・이벤트")} onClick={()=> {navigate('/mypage/notice')}}/>
                <MenuItemComponent name={t("1:1 문의")} onClick={()=> {navigate('/mypage/ask')}}/>
                <MenuItemComponent name={t("언어 설정 (Language)")} onClick={()=> {navigate('/mypage/lang')}}/>
                <MenuHeader isBold>{t("기타")}</MenuHeader>
                <MenuItemComponent name={t("이용약관")} onClick={()=> {navigate('/mypage/terms-of-service')}}/>
                <MenuItemComponent name={t("개인정보처리방침")} onClick={()=> {navigate('/mypage/privacy-policy')}}/>
                <MenuItemComponent name={t("로그아웃")} onClick={logoutButton}/>
            </PurchaseInfoWrapper>
        
            </>}
    </PurchasePageWrapper>
    )
};

//todo: footer 바꾸기

const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const PurchasePageWrapper = styled.div`

`

const PurchaseInfoWrapper = styled.div`
`

const GreenButtonWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
`

const MenuHeader = styled(Font_lg)`
    padding-top: 24px;
    padding-bottom: 8px;
`

const FooterWrapper = styled.div`
    background-color: ${colors.GREY[50]};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    margin: 1rem -1rem -1rem -1rem;
`

const FooterTitle = styled.div`
    display: flex;
`

const FooterSection = styled.div`
    display: flex;
    gap: 0.5rem;
`

const FooterButton = styled(Font_sm)`
`

const FooterText = styled.div`
`

export default PurchaseComponent;