import {RefObject, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_md, Font_sm } from "styled/fonts";
import FilterComponent from "./FilterComponent";
import { Checkbox, Select } from "@chakra-ui/react";
import expand_more_black from "assets/expand_more_black.svg";
import VerticalCardListComponent from "components/common/VerticalCardListComponent";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { filterDataState, itemListState, selectedFilterDataState } from "recoil/homeRecoil";
import { getItemList } from "api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useInView } from 'react-intersection-observer';
import {checkItemsState, filterPageState, isFirstState, sortDataState, typeDataState} from "../../recoil/filterRecoil";
import {HandleNavigateType} from "../../pages/explore/ExplorePage";

const DETECT_PAGE_END_MSG = '페이지가 유효하지 않습니다.';

const CardFilterComponent = (props: { onClickHandler: HandleNavigateType }) => {

  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState)
  const navigate = useNavigate();
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const category = params.get('category');
  const isBuy = params.get('isBuy');
  const isAuction = params.get('isAuction');
  const value_product = params.get('value_product');
  // const [clickedSide, setClickedSide] = useState(category ? Number(category) : 1);
  const [itemList, setItemList] = useRecoilState(itemListState);
  const [checkedItems, setCheckedItems] = useRecoilState(checkItemsState);
  const [page, setPage] = useRecoilState(filterPageState);
  const [isFirst, setIsFirst] = useRecoilState(isFirstState);
  const [type, setType] = useRecoilState(typeDataState);
  const [sort, setSort] = useRecoilState(sortDataState);
  const filterData = useRecoilValue(filterDataState);
  const [refDisabled, setRefDisabled] = useState<boolean>(false);
  const [pointerEvent, setPointerEvent] = useState(false);
  const [ref, inView] = useInView({
    rootMargin: '500px',
    threshold: 0,
  });
  const getItems = async (type:string, page:string, from: string, sort: string) => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; 
    const date = new Date(Date.now()-offset).toISOString().split('.')[0].replaceAll(":", "%3A");
    try {
      const response = await getItemList({
        selling_type: type,
        page_size: '20',
        page: page,
        category: category ? Number(category) : filterData[1].id,
        value_product: value_product || undefined,
        sort: sort,
        endDate: date,
        isDeleted: false,
      });
      if (!response.error){
        if (from === 'inview')
          setItemList([...itemList, ...response.items.results]);
        else
          setItemList(response.items.results);
      } else {
        setRefDisabled(true)
        console.error(response.error);
        return "error";
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onChangeSort = async (field: string) => {
    let sortTarget = '-id'
    if (field === "높은 가격순" || field==="High to Low Price"){
      sortTarget = '-price'
    } else if(field === "낮은 가격순" || field === "Low to High Price"){
      sortTarget = 'price'
    }
    await getItems(type, '1', 'sort', sortTarget)
    setPage(1);
    setRefDisabled(false);
    setSort(sortTarget)
  }

  useEffect(()=>{
    (async () => {
        setPointerEvent(true);
        let type = "";
        if ((checkedItems[0] && checkedItems[1]) || (!checkedItems[0] && !checkedItems[1])) type = ""
        else if(checkedItems[0]) type = "1"
        else if(checkedItems[1]) type = "2"
        if (itemList.length === 0) {
          await getItems(type, '1', 'init', sort);
        }
        setType(type);
        setPointerEvent(false);
    })();
  }, [checkedItems[0], checkedItems[1]])

  useEffect(()=>{
    if(!inView){
      return;
    } else{
      if (isFirst) {
        setIsFirst(false);
      } else{
        (async () => {
          let type = "";
          if ((checkedItems[0] && checkedItems[1]) || (!checkedItems[0] && !checkedItems[1])) type = ""
          else if (checkedItems[0]) type = "1"
          else if (checkedItems[1]) type = "2"
          const status = await getItems(type, (page + 1).toString(), 'inview', sort);
          if (status !== 'error')
            setPage(page + 1)
          setType(type);
        })();
      }
    }
  }, [inView])

  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])

  return (
    <CardList className={'CARD'}>
      <TabSection>
        <TabChild onClick={() => window.location.replace("/explore?category="+filterData[1].id)} isClick={category == filterData[1].id || category === null}>
          <Font_md isBold={category === String(filterData[1].id) || category === null ? true : undefined}>
            {t("스포츠 컬렉션")}
          </Font_md>
        </TabChild>
        <TabChild onClick={() => window.location.replace("/explore?category="+filterData[0].id)} isClick={category == filterData[0].id}>
          <Font_md isBold={category === String(filterData[0].id) ? true : undefined}>
            {t("스포츠 카드")}
          </Font_md>
        </TabChild>
      </TabSection>
      <FilterComponent
        isBuy={checkedItems[0] ? "true" : ""}
        isAuction={checkedItems[1] ? "true" : ""}
        category={category === String(filterData[0].id) || category === null ? "card" : "collection"}
      />
      <CardListHeader>
        <SellStatusCheckbox>
          <Checkbox
            isChecked={checkedItems[0]}
            onChange={(e) => {
              setItemList([]);
              setIsFirst(true);
              setPage(1);
              setRefDisabled(false);
              setCheckedItems([e.target.checked, checkedItems[1]]);
            }}
            colorScheme="green"
          >
            <Font_sm>{t("즉시 구매")}</Font_sm>
          </Checkbox>
          <Checkbox
            isChecked={checkedItems[1]}
            onChange={(e) => {
              setIsFirst(true);
              setItemList([]);
              setPage(1);
              setRefDisabled(false);
              setCheckedItems([checkedItems[0], e.target.checked]);
            }}
            colorScheme="green"
            style={{ pointerEvents: pointerEvent ? 'none' : 'auto' }}
          >
            <Font_sm>{t("가격 제안")}</Font_sm>
          </Checkbox>
        </SellStatusCheckbox>
        <Select
            value={sort === "-id" ? t("최근 등록순") : sort === "-price" ? t("높은 가격순") : t("낮은 가격순")}
            size='sm'
            variant='unstyled'
            width={'30'}
            onChange={(e) => onChangeSort(e.target.value)}
          >
            <option>{t("최근 등록순")}</option>
            <option>{t("낮은 가격순")}</option>
            <option>{t("높은 가격순")}</option>
          </Select>
      </CardListHeader>
      <Contents>
        <VerticalCardListComponent onClickHandler={props.onClickHandler} />
      </Contents>
      {
        !refDisabled &&
          <div style={{ height: "1000px" }} ref={ref} />
      }
    </CardList>
  );
};

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3.125rem 1rem;
  justify-content: center;
  width: 100%;
`;

const TabSection = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 40px;
  position:sticky;
  top:56px;
  z-index:11;
  background-color: white;
`;
const TabChild = styled.div<{ isClick: boolean }>`
  display : flex;
  justify-content : center;
  align-items : center;
  min-width: 50%;
  border-bottom: ${(props) =>
    props.isClick
      ? `2px solid ${colors.GREY[700]}`
      : `1px solid ${colors.GREY[200]}`};
`;

const CardListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 1rem;
`

const SellStatusCheckbox = styled.div`
  display: flex;
  gap: 1rem;
`

const SortBtn = styled.div`
  display: flex;
`

export default CardFilterComponent;
