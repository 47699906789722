import React, {useState, useEffect} from "react";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import SignupTextWrapper from "components/SignupTextComponent";
import {Input} from "@chakra-ui/react";
import styled from "styled-components";
import NicknameValidComponent from "components/common/NicknameValidComponent";
import {Size} from "types/types";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import {useLocation, useNavigate} from "react-router-dom";
import {nicknameState, emailState, passwordState, secretState, isValidNicknameState} from "recoil/signupRecoil";
import {useRecoilValue, useRecoilState, useSetRecoilState} from "recoil";
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {useTranslation} from "react-i18next";
import {langState, loginState} from "recoil/loginRecoil";
import {login} from "api/UserApi";

const PersonalInfoPage = () => {

    const {t, i18n} = useTranslation("signup");
    const language = useRecoilValue(langState);

    const location = useLocation();
    const sch = location.search;
    const params = new URLSearchParams(sch);
    const type = params.get('type');
    const oauth_email = params.get('email');

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();
    const isValidNickname = useRecoilValue(isValidNicknameState)
    const [phoneNumber, setPhoneNumber] = useState('');
    const handleChangePhoneNumber = (event: any) => {
        if (event !== undefined)
            setPhoneNumber(event);
    };
    const [nickname, setNickname] = useRecoilState(nicknameState);
    const email = useRecoilValue(emailState);
    const password = useRecoilValue(passwordState);
    const secret = useRecoilValue(secretState);
    const setLogin = useSetRecoilState(loginState);

    let storage_data: any;
    useEffect(() => {
        if (!(type === 'social') && !(email && password && secret)) {
            navigate('/onboarding');
        }
        setPhoneNumber('');
        setNickname('');
        i18n.changeLanguage(language);
    }, [])

    const HandleClick = async () => {

        try {
            if (type === 'social') {
                storage_data = await JSON.parse(localStorage.getItem('o_auth') || '');
                const data = await axios.patch(
                    apiEndpoint + "/accounts/v2/user/",
                    {
                        email: oauth_email,
                        name: nickname,
                        number: phoneNumber,
                        lang: language.slice(0, 2).toUpperCase()
                    },
                    {headers: {Authorization: `Bearer ${storage_data.access}`}}
                );
                const loginData = {access: storage_data.access, user: data.data};
                await setLogin(loginData);
                navigate('/signup/welcome');
                return;
            } else {
                await axios.post(
                    apiEndpoint + "/accounts/email-auth/register/?is_en=" + (language.indexOf('ko') === -1),
                    {
                        name: nickname,
                        email: email,
                        number: phoneNumber,
                        password1: password,
                        password2: password,
                        lang: language.slice(0, 2).toUpperCase()
                    },
                    {
                        headers: {Authorization: `Email ${secret}`},
                    }
                );
            }
            const loginRes: any = await login({email, password})
            const {access, user} = loginRes;
            const loginData = {access, user};
            await setLogin(loginData);
            navigate('/signup/welcome');
        } catch (e: any) {
            console.error(e);
        }
    };
    const phoneNumberStyle = {
        marginTop: '16px',
        width: 'calc(100% - 2rem)',
        border: '1px solid #E2E8F0',
        borderRadius: '6px 6px 6px 6px',
        height: '48px',
        padding: '0px 12px 0px 16px',
        gap: '1rem',
    }

    return (
        <PersonalInfo>
            <ProcessHeader isSearchForm={false} title={t("회원가입")} stepPercent={100}/>
            <SignupTextWrapper
                topString="리스트잇에서 사용할"
                highlight="닉네임과 휴대폰 번호"
                bottomString="를 입력해주세요."/>
            <InputWrapper>
                <NicknameValidComponent email={email}/>
                <PhoneInput
                    defaultCountry="KR"
                    style={phoneNumberStyle}
                    placeholder={t("휴대폰 번호")}
                    value={phoneNumber}
                    onChange={handleChangePhoneNumber}/>
            </InputWrapper>
            <ButtonWrapper>
                <ButtonComponent
                    text={t('가입완료')}
                    isValid={Boolean(phoneNumber) && isValidNickname}
                    size={Size.LARGE}
                    onClick={() => HandleClick()}/>
            </ButtonWrapper>
        </PersonalInfo>
    );
};

const PersonalInfo = styled.div`

`

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px 16px 24px 0px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default PersonalInfoPage;