import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { Divider, Button, Input } from "@chakra-ui/react";
import HorizontalCard_Pay from "components/item/HorizontalCard_Pay";
import expand_more from "assets/expand_more_grey.svg";
import expand_close from "assets/expand_close.svg";
import { loadPaymentWidget, PaymentWidgetInstance } from "@tosspayments/payment-widget-sdk"
import {formatCurrency, changeKrwToUsd, fixedExchangePrice, changeUsdToKrw, formatCurrencyUsd} from "api/StringUtils";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useTranslation } from "react-i18next";
import { payableItemsState, exchangeRateState, shippingMemoState } from "recoil/homeRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { langState, loginState } from "recoil/loginRecoil";
import { useNavigate } from "react-router-dom";
import { generateOrderId } from "api/StringUtils";
import KrwPaymentComponent from "components/item/KrwPaymentComponent";
import UsdPaymentComponent from "components/item/UsdPaymentComponent";
import { getUserInfo } from "api/UserApi";
import { getPresetData } from "api/api";

const PayPage = () => {
  const [user, setUser] = useRecoilState(loginState);
  const { t, i18n } = useTranslation("main");
  
  const [isAddition, setIsAddition] = useState(true);
  const clientKey = process.env.REACT_APP_CLIENT_KEY || "";
  const customerKey = user.user.uuid;
  const [payableItems, setPayableItems] = useRecoilState(payableItemsState);
  const [shippingMemo, setShippingMemo] = useRecoilState(shippingMemoState);
  const language = useRecoilValue(langState);
  const exchangeRate = useRecoilValue(exchangeRateState);
  const navigate = useNavigate();
  const [deliveryPrice, setDeliverPrice] = useState(0);

  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance["renderPaymentMethods"]
  > | null>(null)
  let totalPrice_item = 0;
  let totalPrice_deliver = 0;
  let productsId = '';


  const calculatePayPrice = (price : number) => {
    if (payableItems[0]?.dollar_price) { //경매
      if (language !== 'ko-KR') return Math.round(payableItems[0]?.dollar_price * 10)/10;
      else return payableItems[0]?.price
    } else { //즉시구매
      if (payableItems[0]?.author?.swift_code && exchangeRate) { //해외계좌가 있을 때
        //스위프트 코드가 있을 경우
        if (language !== 'ko-KR') return price / fixedExchangePrice; //원래 판매자가 올린 달러
        else return changeUsdToKrw(price / fixedExchangePrice, exchangeRate.basePrice);
      } else {
        //스위프트 코드가 없을 경우
        if (language !== 'ko-KR') return changeKrwToUsd(price, exchangeRate?.basePrice, true);
        else return price;
      }
    }
  };
  
  payableItems.forEach(item => {
    totalPrice_item += item?.price || 0;
    totalPrice_deliver += deliveryPrice // item.deliver_price
    productsId += item?.id + '@';
  })
  const price = totalPrice_item + totalPrice_deliver;
  const orderName = payableItems.length > 1 ? payableItems?.[0]?.name + `외 ${payableItems.length - 1}건` : payableItems?.[0]?.name;

  const onClickPayBtn = async () => {
    if (payableItems.length > 0) {
      const paymentWidget = paymentWidgetRef.current;
      try {
        let paymentData : any = {
          orderId: generateOrderId(),
          orderName: orderName || "",
          customerName: user.user.name,
          customerEmail: user.user.email,
          successUrl: `${window.location.origin}/pay-success?ids=${productsId.slice(0, productsId.length - 1)}`,
          failUrl: `${window.location.origin}/pay-fail`,
        }
        if (language !== 'ko-KR'){
          paymentData['products'] = payableItems.map(item => ({
            name: item?.name || '',
            quantity: 1,
            unitAmount: item && calculatePayPrice(item?.price),
            currency: 'USD',
            description: item?.content,
          }))
          paymentData['shipping'] = {
            fullName: user.user.name,
            address: {
              country: language !== 'ko-KR' ? 'US' : 'KR',
              area1: user.user.addresses[0].address,
              area2: user.user.addresses[0].detail_address,
              postalCode: '00000',
            },
          }
          paymentData['paymentMethodOptions'] = {
            // PayPal에서 요구하는 추가 파라미터
            paypal: {
              setTransactionContext: {  // PayPal STC 파라미터 예시 (구매자의 로그인 정보)
                sender_account_id: user.user.id,
                sender_first_name: user.user.addresses[0].name,
                sender_last_name: user.user.addresses[0].last_name || '1',
                sender_email: user.user.email,
                sender_phone: user.user.phone || '(1) 562 254 5591',
                sender_country_code: 'US',
                sender_create_date: user.user.created
              }
            }
          }
        }
        await paymentWidget?.requestPayment(paymentData);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    if (payableItems.length === 0){
      navigate('/');
    }
    (async () => {
      const preset = await getPresetData();
      const findDeliveryPrice = preset.filter((item) => item.key == "delivery");
      let needToPayAmount = 0;
      await setDeliverPrice(Number(findDeliveryPrice[0].value));
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey);

      await payableItems.forEach((item) => {
        if(item) {
          needToPayAmount += calculatePayPrice(item.price) || 0;
          needToPayAmount += (language !== 'ko-KR' ? changeKrwToUsd(Number(findDeliveryPrice[0].value), exchangeRate?.basePrice): Number(findDeliveryPrice[0].value));
        }
      });

      const paymentMethodsWidget = await paymentWidget.renderPaymentMethods(
          "#payment-widget",
          {
            value: needToPayAmount,
            currency: language !== "ko-KR" ? 'USD' : 'KRW',
            country: language !== "ko-KR" ? 'US' : 'KR',
          },
          language !== "ko-KR" ? { variantKey: 'PAYPAL' } : undefined,
        );
      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
      const userRes = await getUserInfo(user.access);
      if (userRes.error) {
        setUser({ user: {}, access: "" });
        navigate("/");
      }
      setUser((prevUser: any) => ({
        ...prevUser,
        user: { ...userRes.data },
      }));
    })()
  }, [])
  return (
    <Pay>
      <ProcessHeaderComponent isSearchForm={false} title={t("결제")} />
      <OrderItems onClick={() => setIsAddition(!isAddition)}>
        <div className="title">{t("주문상품")}</div>
        <div className="content">
          {payableItems.length > 1
            ? payableItems.length + "개"
            : payableItems[0]?.name}
        </div>
        <img src={isAddition ? expand_close : expand_more} />
      </OrderItems>
      <div style={{ width: "calc(100% - 2rem)" }}>
        {isAddition &&
          payableItems.map((item) => (
            <HorizontalCard_Pay
              image={item?.image1 || ""}
              title={item?.name || ""}
              itemPrice={item ? calculatePayPrice(item?.price) : 0}
              deliverPrice={deliveryPrice}
              isPayPage
            />
          ))}
      </div>
      <Divider />
      <AddressSection>
        <div className="title">{t("배송지")}</div>
        <AddressBody>
          <div style={{ width: "80%" }}>
            {user.user.addresses && user.user.addresses.length !== 0 ? (
              <>
                <div className="content">{user.user.addresses[0].address}</div>
                <div className="content">
                  {user.user.addresses[0].detail_address}
                </div>
              </>
            ) : (
              <>{t("배송지를 등록 해주세요.")}</>
            )}
          </div>
          <div>
            <Button
              onClick={() => navigate("/mypage/change-addr?from=pay")}
              size="sm"
              colorScheme="gray"
              variant="outline"
            >
              {user.user.addresses && user.user.addresses.length !== 0 ? t("변경") : t("등록")}
            </Button>
          </div>
        </AddressBody>
        {user.user.addresses && user.user.addresses.length !== 0 && (
          <Input
            value={shippingMemo}
            onChange={(e: any) => setShippingMemo(e.target.value)}
            size="lg"
            placeholder={t("배송메모 입력")}
          />
        )}
      </AddressSection>
      <PayinfoSection>
        <div className="title">{t("결제금액")}</div>
        <div>
          <AddressBody>
            <div>{t("상품금액")}</div>
            <div>
              {language === "ko-KR"
                ? formatCurrency(calculatePayPrice(totalPrice_item)) + "원"
                : "$" +
                  formatCurrencyUsd(
                    calculatePayPrice(totalPrice_item)
                  )}
            </div>
          </AddressBody>
          <AddressBody>
            <div>{t("배송비")}</div>
            <div>
              {language === "ko-KR"
                ? formatCurrency(totalPrice_deliver) + "원"
                : "$" +
                  formatCurrencyUsd(
                    changeKrwToUsd(totalPrice_deliver, exchangeRate?.basePrice)
                  )}
            </div>
          </AddressBody>
          <AddressBody>
            <div className="title">{t("총 결제금액")}</div>
            <div>
              {language === "ko-KR"
                ? formatCurrency(calculatePayPrice(totalPrice_item) + totalPrice_deliver) + "원"
                : "$" +
                  formatCurrencyUsd(
                    calculatePayPrice(totalPrice_item) + changeKrwToUsd(totalPrice_deliver, exchangeRate?.basePrice)
                  )}
            </div>
          </AddressBody>
        </div>
      </PayinfoSection>
      <PaymentContainer>
        <PaymentSection>
          <div className="title">{t("결제방법")}</div>
          <div id="payment-widget"></div>
        </PaymentSection>
      </PaymentContainer>
      <ButtonWrapper>
        <ButtonComponent
          isValid={user.user.addresses && user.user.addresses.length > 0}
          text={user.user.addresses && user.user.addresses.length > 0
              ? t("결제하기")
              : t("배송지를 입력해주세요")}
          size={Size.LARGE}
          onClick={onClickPayBtn}
        />
      </ButtonWrapper>
    </Pay>
  );
};

const Pay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .title{
    font-weight: 600;
    white-space : nowrap;
  }
  .content{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const OrderItems = styled.div`
  padding: 1rem 0;
  width: calc(100% - 2rem);
  display: flex;
  justify-content: space-between;
  gap: 40px;
  .content{
    width: calc(100% - 119px);
  }
`

const AddressSection = styled.div`
  padding: 1rem 0;
  width: calc(100% - 2rem);
`

const AddressBody = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

const PayinfoSection = styled.div`
  padding: 1rem 0;
  width: calc(100% - 2rem);
`

const PaymentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PaymentSection = styled.div`
  padding: 1rem 0;
  width: calc(100% - 2rem);
`

const ButtonWrapper = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
`

export default PayPage;
