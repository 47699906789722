import { styled } from "styled-components";
import { Font_md, Font_sm } from "styled/fonts";
import { AuctionType, BiddingData, DeliverStatus, Size } from "types/types";
import { colors } from "styled/colors";
import DeliverProgressComponent from "components/puchaceHist/DeliverProgressComponent";
import { langState, loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useDisclosure } from "@chakra-ui/react";
import ModalComponent from "components/common/ModalComponent";
import { useRecoilState } from "recoil";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import { Font_xs } from "styled/fonts";
import { useNavigate } from "react-router-dom";
import { MakeIsDoneDataTrueForBidding } from "api/UserHistoryApi";
import { addDaysToDate } from "api/StringUtils";
import SmallButtonComponent from "components/common/buttons/SmallButtonComponent";
import notify from "components/common/toast/ToastComponent";
import { ToastContainer } from "react-toastify";

interface DetailCardType {
    date: string;
    name: string;
    auctionType: AuctionType;
    orderNumber?: number;
    image: string;
    auctionStatus?: string;
    deliverStatus?: string;
    data: BiddingData;
}

const DetailCard = (props: DetailCardType) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(isCompletePurchaceState);

    const user: any = useRecoilValue(loginState);

    const StatusGroup : number = props.auctionStatus === "결제대기" || props.auctionStatus === "구매확정" || props.auctionStatus === "낙찰취소" ? 1 : 2;
    const ChooseStatusColor = (status: string) => {
        if(status == "결제대기") {
            return "#E53E3E";
        } else if(status == "구매확정") {
            return colors.GREY[700];
        }
        return colors.GREY[400];
    }

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const navigate = useNavigate();

    const onClickCompliteBtn = () => {
        onOpen();
      };
    
      const handleModalBtn = async() => {
        const response = await MakeIsDoneDataTrueForBidding(user.access, props.data.purchase);
        notify(t("구매확정이 완료되었습니다."));
        setTimeout(() => {setIsCompletePurchace(true)}, 2000);
      }

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])

    return (
        <DetailCardWrapper>
            <ToastContainer />
            <Title>
                <Font_sm>{props.date} {t(props.auctionType)}</Font_sm>
                { props.orderNumber && <Font_sm color={colors.GREY[500]}>{t("주문번호")} {props.orderNumber}</Font_sm>}
            </Title>
            <ProductInfo>
            {(StatusGroup == 1 && props.auctionStatus) && <Font_sm isBold color={props.auctionStatus && ChooseStatusColor(props.auctionStatus)}>{t(props.auctionStatus)}</Font_sm>}
                <Product>
                    <ProductImg src={props.image}/>
                    <Font_md>{props.name}</Font_md>
                </Product>
                {props.auctionStatus === "배송중" &&
                <>
                    <ButtonWrapper>
                        <SmallButtonComponent
                        isReverse={true}
                        text={t("배송조회")}
                        cnt={3}
                        />
                        <SmallButtonComponent
                        isReverse={true}
                        text={t("1:1 문의")}
                        onClick={() => {navigate('/mypage/ask')}}
                        cnt={3}
                        />
                        <SmallButtonComponent
                        isReverse={false}
                        text={t("구매확정")}
                        onClick={onClickCompliteBtn}
                        cnt={3}
                        />
                    </ButtonWrapper>
                    <ComfirmationDate>
                </ComfirmationDate>
                </>}
                {props.auctionStatus === "배송완료" &&
                <>
                <ButtonWrapper>
                    <SmallButtonComponent
                    isReverse={true}
                    text={t("배송조회")}
                    cnt={3}
                    />
                    <SmallButtonComponent
                    isReverse={true}
                    text={t("1:1 문의")}
                    onClick={() => {navigate('/mypage/ask')}}
                    cnt={3}
                    />
                    <SmallButtonComponent
                    isReverse={false}
                    text={t("구매확정")}
                    onClick={onClickCompliteBtn}
                    cnt={3}
                    />
                </ButtonWrapper>
                <ComfirmationDate>
                <Font_xs color={colors.GREEN[500]}>{addDaysToDate(props.data.product.carried_date, 3, false, false)} {t("자동 구매확정")}</Font_xs>
                </ComfirmationDate>
                </>}
                {props.auctionStatus === "구매확정" &&
                <ButtonWrapper>
                        <SmallButtonComponent
                        isReverse={true}
                        text={t("배송조회")}
                        cnt={2}
                        />
                        <SmallButtonComponent
                        isReverse={true}
                        text={t("1:1 문의")}
                        onClick={() => {navigate('/mypage/ask')}}
                        cnt={2}
                        />
                </ButtonWrapper>
                }
            {props.deliverStatus && <DeliverProgressComponent deliverStatus={props.deliverStatus}/>}
            </ProductInfo>
            <ModalComponent
            isOpen={isOpen}
            onClose={onClose}
            content={t(`상품 확인 후 구매확정을 눌러주세요! 판매자에게 정산이 이뤄집니다.`)}
            leftBtnString={t("취소")}
            rightBtnString={t("구매확정")}
            onClickSubmit={handleModalBtn}
        />
        </DetailCardWrapper>
    )
}

const DetailCardWrapper = styled.div`
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const ProductImg = styled.img`
    min-width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    object-fit: cover;
`

const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const Product = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const ButtonWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    width: calc(100% - 1rem);
`

const ComfirmationDate = styled.div`
    text-align: center;
`

export default DetailCard;