import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const userLang = localStorage.getItem("langState")
const defaultLang = window.navigator.language

export const loginState = atom<any>({
  key: 'loginState',
  default: {
    access:undefined,
    user:{},
  },
  effects_UNSTABLE: [persistAtom],
});

export const langState = atom<string>({
  key: 'langState',
  default: userLang ? userLang : defaultLang === 'ko' ? 'ko-KR' : defaultLang,
  effects_UNSTABLE: [persistAtom],
})