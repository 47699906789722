// src/recoil/todo.ts
import { atom } from "recoil";

// interface 지정 가능
// export interface ITodoTypes {
//   count:number;
// }

// key의 값은 항상 고유값이어야 합니다.
export const passwordState = atom<string>({
  key: 'passwordState',
  default: '',
});

export const checkItemsState = atom<Array<boolean>>({
  key: 'checkItemsState',
  default: [false, false, false, false],
});

// TodoInput에서 입력하는 값을 atom으로 관리하는 방식
export const nicknameState = atom<string>({
  key: 'nicknameState',
  default: '',
});

export const isValidNicknameState = atom<boolean>({
  key: 'isValidNicknameState',
  // key의 값은 항상 고유값이어야 합니다.
  default: true,
});

export const emailState = atom<string>({
  key: 'emailState',
  // key의 값은 항상 고유값이어야 합니다.
  default: '',
});

export const isValidPasswordState = atom<boolean>({
  key: 'isValidPasswordState',
  // key의 값은 항상 고유값이어야 합니다.
  default: true,
});

export const isEqualPasswordState = atom<boolean>({
  key: 'isEqualPasswordState',
  // key의 값은 항상 고유값이어야 합니다.
  default: false,
});

export const secretState = atom<string>({
  key: 'secretState',
  // key의 값은 항상 고유값이어야 합니다.
  default: '',
});


export const countdownState = atom<string>({
  key: 'countdownState',
  default: '10:00',
});