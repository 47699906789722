import styled from "styled-components";
import OutlineButtonComponent from "components/common/OutlineButtonComponent";
import { Size } from "types/types";
import FailContent from "components/common/FailContent";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { colors } from "styled/colors";

const PayFailPage = () => {
  const navigate = useNavigate();
  return (
    <Success>
      <FailContent
        mainText="결제에 실패했습니다!"
        subText={"결제 중 오류가 발생했습니다. \n 다시 시도해주세요."}
      />

      <>
        <ButtonComponent
          text="확인"
          size={Size.LARGE}
          onClick={() => navigate("/")}
          isValid={true}
          color={colors.GREEN[500]}
        />
      </>
    </Success>
  );
};

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  padding-bottom: 16px;
`;

export default PayFailPage;
