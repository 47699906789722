import { useEffect, useState } from "react";
import styled from "styled-components";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Input } from "@chakra-ui/react";
import { Size } from "types/types";
import { useNavigate } from "react-router-dom";
import EmailInputComponent from "components/common/EmailValidComponent";
import { useRecoilValue } from "recoil";
import { countdownState, emailState, secretState } from "recoil/signupRecoil";
import SecretKeyComponent from "components/common/SecretKeyComponent";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { sendEmailForValid, validSecretForReset } from "api/UserApi";

const EmailPage = () => {
  const { t, i18n } = useTranslation("findpwd");
  const language = useRecoilValue(langState);

  const countdown = useRecoilValue(countdownState);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidSecret, setIsValidSecret] = useState(true);

  const [emailErrMsg, setEmailErrMsg] = useState("이미 가입된 이메일입니다.");
  const [secretErrMsg, setSecretErrMsg] =
    useState("유효하지 않은 인증번호 입니다.");

  const email = useRecoilValue(emailState);
  const secret = useRecoilValue(secretState);
  const [isSend, setIsSend] = useState(false);

  const HandleConfirmBtn = async () => {
    // call authorization
    const res = await validSecretForReset(email, secret);
    if (res.error) {
      // throw Error("asd")
      setSecretErrMsg("유효하지 않은 인증번호 입니다.");
    } else {
      navigate("/reset/pwd");
    }
  };

  const HandleSendBtn = async () => {
    const emailRes = await sendEmailForValid(email, false, language.indexOf('ko') === -1);
    if(emailRes.error){
      setEmailErrMsg(emailRes.error);
      setIsValidEmail(false);
      return;
    }
    setIsSend(true);
  };
  useEffect(() => {
    if (secret.length == 5) setIsValidSecret(true);
    setIsValidEmail(true);
  }, [email, secret]);

  useEffect(() => {
    if (countdown == "00:00") {
      setIsValidSecret(false);
      setSecretErrMsg("인증 시간이 지났습니다. 인증 메일을 재발송해주세요.");
    } else if (countdown == "10:00") {
      setIsValidSecret(true);
      // 디자인 확인을 위해 임시로 설정합니다.
      // prod 로 넘어갈 땐 다른 처리를 진행합니다.
      setSecretErrMsg("유효하지 않은 인증번호 입니다.");
    }
  }, [countdown]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  const navigate = useNavigate();
  const HandleClick = () => {
    // something to do
    if (isSend) {
      try {
        HandleConfirmBtn();
      } catch {
        setIsValidSecret(false);
      }
    } else {
      HandleSendBtn();
    }
  };

  return (
    <Email>
      <ProcessHeader
        isSearchForm={false}
        title={language === "ko-KR" ? "비밀번호 재설정" : "Reset password"}
      />
      <StringWrapper>
        <p>{t("비밀번호 재설정을 위해")}</p>
        <p>
          {t("가입하신")} <span className="highlight">{t("이메일")}</span>
          {t("을 인증해주세요.")}
        </p>
      </StringWrapper>
      <InputWrapper>
        <EmailInputComponent
          isSend={isSend}
          errMsg={t(emailErrMsg)}
          isValidEmail={isValidEmail}
        />
        {isSend && (
          <SecretKeyComponent
            isValidSecret={isValidSecret}
            errMsg={secretErrMsg}
            isSend={false}
            sendEmailAgain={() => HandleSendBtn()}
          />
        )}
      </InputWrapper>
      <ButtonWrapper>
        <ButtonComponent
          text={t(isSend ? "인증번호 확인" : "인증 메일 발송")}
          isValid={Boolean(email) && isValidEmail && isValidSecret}
          size={Size.LARGE}
          onClick={HandleClick}
        />
      </ButtonWrapper>
    </Email>
  );
};

const Email = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 35px;
  width: 100%;
  margin: 0px 16px 24px 0px;
`;

const StringWrapper = styled.div`
  margin: 25px 0px 25px 0px;
  padding-left: 1rem;
  font-size: 20px;
  font-weight: 400;
  .highlight {
    font-weight: 600;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default EmailPage;
