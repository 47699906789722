import { styled } from "styled-components";
import { Size } from "types/types";
import {colors} from "styled/colors";

interface ButtonTypes {
    isValid:boolean;
    text:string;
    size:Size;
    onClick?:Function;
  }

const ButtonComponentMd = (props: ButtonTypes) => {
    const tmpFunction = () => {}
    const buttonHandler = props.onClick || tmpFunction;

    return (
        <Button
            isValid={props.isValid}
            size={props.size}
            onClick={()=> {
                if(props.isValid)
                    buttonHandler();
            }}>
            {props.text}
        </Button>
    )
}

const Button = styled.div<{ isValid: boolean; size: string; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.isValid ? colors.GREY[700] : colors.GREY[50])};
    color: ${(props) => (props.isValid ? 'white' : colors.GREY[400])};
    width: ${(props) => (props.size === 'LARGE' ? 'calc(100% - 2rem)' : '50%')};
    font-weight: 600;
    height: 40px;
    border-radius: 6px;
    cursor: pointer;
`

export default ButtonComponentMd;