import { Routes, Route } from "react-router-dom";
import PurchaceHistPage from "./PurchaceHistPage";
import PurchaceDetailPage from "./PurchaceDetailPage";
import DeliverStatusPage from "./DeliverStatusPage";

const PurchaseHistRouter = () => {

  return (
    <Routes>
      <Route path='/purchace-hist' element={<PurchaceHistPage />}/>
      <Route path='/purchace-detail/:id' element={<PurchaceDetailPage />}/>
      <Route path='/deliver' element={<DeliverStatusPage />}/>
    </Routes>
  );
};

export default PurchaseHistRouter;