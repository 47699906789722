import styled from "styled-components";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { Font_md } from "styled/fonts";
import PostingPictures from "components/common/PostingPictures";
import { useEffect, useState } from "react";
import { Input, Textarea, Select } from '@chakra-ui/react';
import { colors } from "styled/colors";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { OptionState, Size } from "types/types";
import { useNavigate } from "react-router-dom";
import { categoryState, imageProductState, productContentState, productNameState, sellingTypeState, sportsState, playerNameState, yearState, gradingState, producerState, optionsState} from "recoil/addProductRecoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { getCategoryData } from "api/api";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { Categories, Category } from "types/types";

interface CategoriesTitle {
    [key: string]: string;
  }

const AddProductInfo = () => {
    const [imageProduct, setImageProduct] = useRecoilState(imageProductState);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [productName, setProductName] = useRecoilState(productNameState);
    const [productContent, setProductContent] = useRecoilState(productContentState);

    const [sports, setSports] = useState('');
    const [year, setYear] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [grading, setGrading] = useState('');
    const [producer, setProducer] = useState('');
    const [options, setOptions] = useRecoilState(optionsState);

    const [categories, setCategories] = useState<Categories[]>([]);

    const sellingType = useRecoilValue(sellingTypeState);
    const category = useRecoilValue(categoryState);

    const { t, i18n } = useTranslation("addProduct");
    const language = useRecoilValue(langState);

    const navigate = useNavigate();

    const categoriesTitle : CategoriesTitle = {
        "스포츠": sports,
        "선수명": playerName,
        "연도": year,
        "그레이딩": grading,
    }

    //스포츠카드 카테고리일때만 제조사 받기
    if(category == 0) categoriesTitle.제조사 = producer;

    const filterOptionData = (optionName: string, selectedOption: string) => {
        const thisOption = categories.filter((item) => item.name === optionName);
        if(thisOption[0].name === "연도" || thisOption[0].name === "선수명") {
            setOptions((prevOptions) => [...prevOptions, {
                id: thisOption[0].id,
                eng_value: selectedOption,
                value: selectedOption
            }])
        } else {
            const data = thisOption.length !== 0 && thisOption[0].selects.filter((item) => item.name === selectedOption)[0];
            data && setOptions((prevOptions) => [...prevOptions, {
                id: thisOption[0].id,
                eng_value: data.eng_name,
                value: data.name
            }]);
        }
    }

    // 새로고침 막기 변수
    const preventClose = (e:BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    }

    useEffect(()=> {
        setOptions([]);
        setProductName('');
        setProductContent('');
        i18n.changeLanguage(language);
        setImageProduct([]);
        if(category === 1) setProducer('없음');

        //새로고침해서 이전 페이지 데이터가 없을 경우
        if(!category && category !== 0) navigate('/add-product/category');

        //새로고침 방지 코드
        (() => {
            window.addEventListener("beforeunload", preventClose);    
        })();

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    },[])

    useEffect(()=> {
        fetchCategory();
    },[category])

    const handleProductNameChange = (event: any) => setProductName(event.target.value);
    const handleProductContentChange = (event: any) => setProductContent(event.target.value);
    const handleSportsChange = (event: any) => setSports(event.target.value);
    const handlePlayerNameChange = (event: any) => setPlayerName(event.target.value);
    const handleYearChange = (event: any) => setYear(event.target.value);
    const handleGradingChange = (event: any) => setGrading(event.target.value);
    const handleProducerChange = (event: any) => setProducer(event.target.value);

    const photoExists = selectedImages.length !== 0;
    const inputs = [productName, productContent, sports, playerName, year, grading, producer, photoExists]

    const isValid = inputs.every(input => Boolean(input));

    const fetchCategory = async () => {
        const categoryRes = await getCategoryData();
        const myCategoryList = categoryRes[category || 0].options;
        setCategories(myCategoryList);
    };

    const FindCategory = (categories: Category[] , name: string) => {
        const emptyCategory = {
            name: '',
            eng_name: '',
            selects: [],
        }
        const thisCategory = categories.filter((category) => category.name == name)[0];
        return thisCategory ? thisCategory : emptyCategory;
    }

    const handleButton = (sellingType : number | undefined) => {
        Object.keys(categoriesTitle).forEach((item) => {filterOptionData(item, categoriesTitle[item]);});
        if(sellingType == 0) {
            navigate('/add-product/price/buy-now')
            return
        }
        navigate('/add-product/price/auction')
    }

    return (
        <AddProductInfoWrapper>
            <ProcessHeaderComponent
            title={t("상품등록")}
            isSearchForm={false}
            path="/mypage"
            />
            <ProductInfo>
                <Title isBold>{t("상품 예시 사진")}</Title>
                <PostingPictures
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                        selectedFiles={imageProduct}
                        setSelectedFiles={setImageProduct}/>
                <ProductInputs>
                    <Input
                    size="lg"
                    value={productName}
                    onChange={handleProductNameChange}
                    placeholder={t("상품명")}
                    _placeholder={{color: colors.GREY[400]}}
                    />
                    <Textarea
                    size="lg"
                    value={productContent}
                    onChange={handleProductContentChange}
                    rows={4}
                    placeholder={t("상품 설명")}
                    _placeholder={{color: colors.GREY[400]}}
                    />
                </ProductInputs>
            </ProductInfo>
            <GrayBorder/>
            <Categorys>
            <CategoryWrapper>
                <Title isBold>{t("스포츠")}</Title>
                <CategorySelect
                size="lg"
                defaultValue=""
                value={sports}
                onChange={handleSportsChange}
                >
                <option value="" disabled hidden >{t("스포츠 선택")}</option>
                {categories.length > 0 && FindCategory(categories, "스포츠").selects.map((category: any)=> <option value={category.name}>{language === 'ko-KR' ? category.name : category.eng_name}</option>)}
                </CategorySelect>
            </CategoryWrapper>
            <InputWrapper>
                <Title isBold>{t("선수명")}</Title>
                <Input
                size='lg'
                value={playerName}
                onChange={handlePlayerNameChange}
                placeholder={t("선수명")}
                _placeholder={{color: colors.GREY[400]}}/>
            </InputWrapper>
            <InputWrapper>
                <Title isBold>{t("연도")}</Title>
                <Input
                size='lg'
                value={year}
                onChange={handleYearChange}
                placeholder={t("연도")}
                _placeholder={{color: colors.GREY[400]}}/>
            </InputWrapper>
            <CategoryWrapper>
                <Title isBold>{t("그레이딩")}</Title>
                <CategorySelect
                size="lg"
                defaultValue=""
                value={grading}
                onChange={(e) => handleGradingChange(e)}
                >
                <option value="" disabled hidden >{t("그레이딩 선택")}</option>
                {categories.length > 0 && FindCategory(categories, "그레이딩").selects.map((category : any)=> <option value={category.name}>{language === 'ko-KR' ? category.name : category.eng_name}</option>)}
                </CategorySelect>
            </CategoryWrapper>
            {category == 0 &&
            <CategoryWrapper>
                <Title isBold>{t("제조사")}</Title>
                <CategorySelect
                size="lg"
                defaultValue=""
                value={producer}
                onChange={handleProducerChange}
                >
                <option value="" disabled hidden >{t("제조사 선택")}</option>
                {categories.length > 0 && FindCategory(categories, "제조사").selects.map((category : any)=> <option value={category.name}>{language === 'ko-KR' ? category.name : category.eng_name}</option>)}
            </CategorySelect>
            </CategoryWrapper>
            }
        </Categorys>
            <ButtonWrapper>
                <ButtonComponent
                text={t("다음")}
                size={Size.LARGE}
                isValid={isValid}
                onClick={()=> {handleButton(sellingType)}}
                />
            </ButtonWrapper>
        </AddProductInfoWrapper>
    )
}

const AddProductInfoWrapper = styled.div`
`

const ProductInfo = styled.div`
    padding: 0 1rem;
`

const Title = styled(Font_md)`
    padding-bottom: 0.5rem;
`

const ProductInputs = styled.div`
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
const GrayBorder = styled.div`
    height: 0.5rem;
    background-color: ${colors.GREY[50]};
`

const Categorys = styled.div`
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`

const CategoryWrapper = styled.div`
`

const CategorySelect = styled(Select)`
  option {
    color: black;
  }

  option[value=""][disabled] {
    color: gray;
  }
`

const InputWrapper = styled.div`
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

//todo: default option 글자색 회색으로 바꾸기

export default AddProductInfo;