import { Routes, Route } from "react-router-dom";
import ExplorePage from "./ExplorePage";
import FilterPage from "./FilterPage";
import SearchPage from "./SearchPage";

const ExploreRouter = () => {

  return (
    <Routes>
      <Route path='/explore' element={<ExplorePage />}/>
      <Route path='/filter' element={<FilterPage />}/>
      <Route path='/search' element={<SearchPage />}/>
    </Routes>
  );
};

export default ExploreRouter;