import styled from "styled-components";
import OutlineButtonComponent from "components/common/OutlineButtonComponent";
import { Size } from "types/types";
import SuccessContent from "components/common/SuccessContent";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { colors } from "styled/colors";
import { useEffect } from "react";
import { getPresetData, requestAcceptPayment } from "api/api";
import { useRecoilState, useRecoilValue } from "recoil";
import { langState, loginState } from "recoil/loginRecoil";
import { payableItemsState, shippingMemoState } from "recoil/homeRecoil";

const PaySuccessPage = () => {
  const user: any = useRecoilValue(loginState);
  const navigate = useNavigate();
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const language = useRecoilValue(langState);
  const [payableItems, setPayableItems] = useRecoilState(payableItemsState);
  const [shippingMemo, setShippingMemo] = useRecoilState(shippingMemoState);
  const initPayInformation = () => {
    setPayableItems([]);
    setShippingMemo('');
  }
  useEffect(()=> {
    const paymentKey: string = params.get("paymentKey") || "";
    const orderId: string = params.get("orderId") || "";
    const amount: string = params.get("amount") || "0";
    const ids: string = params.get("ids") || "";

    (async function (){
      if(ids){
        const preset = await getPresetData();
        const findDeliveryPrice = preset.filter((item)=> item.key == "delivery");
  
        const itemIdList = ids.split('@');
        const currency = language === "ko-KR" ? "KRW" : "USD";
        
        const purchasesData = payableItems.map((item) => {
          return {
            price: item ? item.price + Number(findDeliveryPrice[0].value) : Number(findDeliveryPrice[0].value),
            currency,
            product: item?.id,
            address: user.user.addresses[0].address,
            detail_address: user.user.addresses[0].detail_address,
            phone: user.user.addresses[0].phone,
            is_done: true,
            name: user.user.addresses[0].name,
            memo: shippingMemo
          }
        })
        
        const purchases = purchasesData;
        const resAcceptPay = await requestAcceptPayment(
          user,
          orderId,
          Number(amount),
          purchases,
          paymentKey,
        );
        await initPayInformation();
        if(resAcceptPay.error){
          initPayInformation();
          navigate('/pay-fail');
        }
      }
    })()
    
  }, [])
  return (
    <Success>
      <SuccessContent
        mainText="결제가 완료되었습니다!"
        subText="상품이 도착하면 구매 확정을 눌러주세요."
      />

      <>
        <ButtonComponent
          text="더 둘러보기"
          size={Size.LARGE}
          onClick={() => navigate("/explore")}
          isValid={true}
          color={colors.GREEN[500]}
        />
        <OutlineButtonComponent
          text="구매 내역 보기"
          size={Size.LARGE}
          onClick={() => navigate("/")}
        />
      </>
    </Success>
  );
};

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  padding-bottom: 16px;
`;

export default PaySuccessPage;
