import { Routes, Route } from "react-router-dom";
import ItemDetailPage from "./ItemDetailPage";
import ItemDetailPage_Bid from "./ItemDetailPage_Bid";
import BidPage from "./BidPage";
import SuccessPage from "./SuccessPage";
import PayPage from "./PayPage";
import PaySuccessPage from "./PaySuccessPage";
import PayFailPage from "./PayFailPage";
import TradingHistoryPage from "./TradingHistoryPage";
import EditProduct from "../editProduct/EditProduct";

const ItemRouter = () => {

  return (
    <Routes>
      <Route path='/item-detail' element={<ItemDetailPage />}/>
      <Route path='/item-detail-bid' element={<ItemDetailPage_Bid />}/>
      <Route path='/edit-product' element={<EditProduct />} />
      <Route path='/trading-history' element={<TradingHistoryPage/>}/>
      <Route path='/bid' element={<BidPage />}/>
      <Route path='/bid-success' element={<SuccessPage />}/>
      <Route path='/pay' element={<PayPage />}/>
      <Route path='/pay-success' element={<PaySuccessPage />}/>
      <Route path='/pay-fail' element={<PayFailPage />}/>
    </Routes>
  );
};

export default ItemRouter;