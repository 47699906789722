import { Routes, Route } from "react-router-dom";
import EmailPage from "./EmailPage";
import SendSuccessPage from "./SendPage"
import ResetpwdPage from "./ResetpwdPage";
import SuccessPage from "./SuccessPage";

const SignupRouter = () => {

  return (
    <Routes>
      <Route path='/findpwd/email' element={<EmailPage />}/>
      <Route path='/findpwd/send' element={<SendSuccessPage />}/>
      <Route path='/reset/pwd' element={<ResetpwdPage />}/>
      <Route path='/reset/success' element={<SuccessPage />}/>
    </Routes>
  );
};

export default SignupRouter;