import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import styled from "styled-components";
import SaleCard from "components/sellerHistory/SaleCard";
import { Font_lg, Font_md, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import card_img from "assets/card_img.svg";
import { SaleHistoryStatus, Size, UploadData } from "types/types";
import { checkAndConvertCurrency, formatCurrency, isoStringToDate } from 'api/StringUtils';
import BidMessage from "components/sellerHistory/BidMessage";
import DeliveryMessage from "components/sellerHistory/DeliveryMessage";
import CalculateInfo from "components/sellerHistory/CalculateInfo";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getProduct } from "api/SellerApi";
import { loginState } from "recoil/loginRecoil";
import { useNavigate, useParams } from "react-router-dom";
import { exchangeRateState } from "recoil/homeRecoil";


const SellerEndDetailPage = () => {

    const { id } = useParams();

    const [auctionData, setAuctionData] = useState<UploadData>();
    const [status, setStatus] = useState<string>();

    const StatusGroup : number = (status === "정산예정" || status === "정산완료") ? 0 : 1;

    const user: any = useRecoilValue(loginState);

    const navigate = useNavigate();

    const identifyStatus = (list : UploadData) : SaleHistoryStatus => {
        if(list.product.status == 1) {
          return SaleHistoryStatus.결제예정
        } else if(list.product.status == 6) {
            return SaleHistoryStatus.선정취소
        } else if(list.product.status == 7) {
            return SaleHistoryStatus.제안없음
        } else if(list.product.calculate_status == 1) {
          return SaleHistoryStatus.정산예정
        } else if(list.product.calculate_status == 2) {
          return SaleHistoryStatus.정산완료
        } else {
          return SaleHistoryStatus.발송요청
        }
      }

    const chooseAuctionStatusColor = (status : string) => {
        if(status === "발송요청") {
            return colors.GREEN[500];
        } else if(status === "선정취소" || status === "제안없음") {
            return colors.GREY[400];
        }
        return colors.GREY[700];
    }

    const fetchAuctionData = async() => {
        try {
            const dataRes = await getProduct(user.access, Number(id));
            setAuctionData(dataRes);
            console.log(dataRes);
        } catch (error) {
            console.log(error);
        }
    }

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const exchangeRate = useRecoilValue(exchangeRateState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
        fetchAuctionData();
        console.log(status);
    },[])

    useEffect(()=> {
        if(auctionData) setStatus(identifyStatus(auctionData));
    },[auctionData])
    
    return (
        <EndDetailWrapper>
            <ProcessHeaderComponent
            title={t("판매 상세 내역")}
            isSearchForm={false}/>
            {(auctionData && status)&&
            <>
            <Title>
            <Font_sm>{isoStringToDate(auctionData.product.end_date, false, false)} {t("선정")}</Font_sm>
            {auctionData.purchase && <Font_sm color={colors.GREY[500]}>{t("주문번호")} {auctionData.purchase.order_id}</Font_sm>}
            </Title>
            {StatusGroup ? <StatusInfo isBold color={chooseAuctionStatusColor(status)}>{t(status)}</StatusInfo> : ''}
            <SaleCard
            name={auctionData.product.name}
            price={auctionData.product.price}
            image={auctionData.product.image1}
            currency={auctionData.product.author.swift_code ? 'USD' : 'KRW'}/>
            {status !== "제안없음" &&
            <Info>
            <Font_md>{t("선정 정보")}</Font_md>
            <PriceList>
                <ListItem>
                    <Font_sm>{t("제안가")}</Font_sm>
                    {auctionData.product.success_price && <Font_sm>{checkAndConvertCurrency(language, auctionData.product.success_price, auctionData.product.author.swift_code ? 'USD' : 'KRW', exchangeRate?.basePrice)}</Font_sm>}
                </ListItem>
            </PriceList>
            </Info>            
            }
            {status === "결제예정" && <BidMessage/>}
            {status === "발송요청" && <DeliveryMessage/>}
                {!StatusGroup && 
                <BottomInfoWrapper>
                    <CalculateInfo price={auctionData.product.success_price} currency={auctionData.product.author.swift_code ? 'USD' : 'KRW'}/>
                    <GrayBorder/>
                    {status === "정산예정" &&
                        <GreenMessage>
                            <Font_sm isBold color={colors.GREEN[500]}>{t("구매확정 후 정산 예정")}</Font_sm>
                        </GreenMessage>
                    }
                    {status === "정산완료" &&
                        auctionData.product.calculated_date &&
                        <ListItem>
                            <Font_sm>{t("정산일")}</Font_sm>
                            <Font_sm>{isoStringToDate(auctionData.product.calculated_date, false, true)}</Font_sm>
                        </ListItem>
                        }
                </BottomInfoWrapper>}
                {(status === "선정취소" || status === "제안없음") &&
                    <CancelMessage>
                        <Font_sm>{status === "선정취소" ? t("선정자가 결제를 진행하지 않아 선정이 취소되었습니다.") : t("제안자가 없어 미선정되었습니다.")}</Font_sm>
                        <ButtonComponent
                        isValid={true}
                        text={t("다시 등록하기")}
                        size={Size.LARGE}
                        onClick={()=> {navigate("/add-product/price/auction", {state: {
                            uploadData: auctionData
                        }})}}/>
                    </CancelMessage>}
            </>}
        </EndDetailWrapper>
    )
};

const EndDetailWrapper = styled.div`
`

const StatusInfo = styled(Font_sm)`
    padding: 1rem 0 0 1rem;
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const Info = styled.div`
    gap: 0.5rem;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`
const PriceList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
`

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
`

const BottomInfoWrapper = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const GreenMessage = styled.div`
    text-align: center;
`

const GrayBorder = styled.div`
    height: 1px;
    background-color: ${colors.GREY[50]};
    margin: 1rem 0;
`

const CancelMessage = styled.div`
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    border-bottom: 1px solid ${colors.GREY[100]};
    text-align: center;
`


export default SellerEndDetailPage;