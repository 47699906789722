import React, {useEffect} from "react";
import { colors } from "styled/colors";
import styled from "styled-components";


interface IProgressStatus {
  stepPercent: number;
}

const ProgressBarComponent = (props: IProgressStatus) => {
  useEffect(() => {
    // 애니메이션 시작
    startAnimation();
  }, []);

  const startAnimation = () => {
    // 애니메이션 시작 로직을 구현 (예: state를 사용하여 progress-bar의 width를 조정)
    // 예시로는 width를 0%에서 25%로 증가시키는 로직을 구현합니다.
    const progressBar:any = document.querySelector(".progress-bar2");
    if (progressBar) {
      progressBar.style.width = `${props.stepPercent}%`;
    }
  };
  return (
    <Container stepPercent={props.stepPercent}>
      <div className="progress2 progress-moved">
        <div className="progress-bar2"></div>
      </div>
    </Container>
  );
};

const Container = styled.div<{ stepPercent:number }>`
  width: 100%;
  text-align: center;
  .progress-moved .progress-bar2 {
    width: 0%;
    background-color: ${colors.GREEN[500]};
    animation: progressAnimation 1s;
  }
  .progress-bar2 {
    height: 4px;
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  @keyframes progressAnimation {
    from {
      width: 0%;
    }
    to {
      width: ${(props) => (props.stepPercent)}%;
    }
  }
`;

export default ProgressBarComponent;
