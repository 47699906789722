import { propNames } from "@chakra-ui/react";
import styled from "styled-components";
import { Font_md } from "styled/fonts";
import expandMore from "assets/expand_more.svg";
import { useState } from "react";
import { colors } from "styled/colors";

interface AccordionButtonTypes {
    title:string;
    detail:string;
}
const AccordionButtonComponent = (props: AccordionButtonTypes) => {
    const [isActive, setIsActive] = useState(false);
    const handleButton = () => {
        setIsActive(!isActive);
    }
    return (
        <AccordionButton>
            <Title
            onClick={handleButton}>
                <Font_md>{props.title}</Font_md>
                <ExpandIcon 
                isActive = {isActive}
                src={expandMore}/>
            </Title>
            {isActive && <Detail>{props.detail}</Detail>}
        </AccordionButton>
    )
};

const AccordionButton = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const Title = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
`

const ExpandIcon = styled.img<{isActive : boolean}>`
    transition: transform 0.3s ease;
    transform: ${({ isActive }) => (isActive ? 'rotate(0deg)' : 'rotate(180deg)')};
`

const Detail = styled.div`
    padding-top: 10px;
    white-space:pre-line;
`

export default AccordionButtonComponent;