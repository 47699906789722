import styled from "styled-components";
import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { useRecoilValue, useRecoilState } from "recoil";
import { loginState, langState } from "recoil/loginRecoil";
import { useCallback, useEffect, useState } from "react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size } from "types/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isNotLogin } from "api/UserUtil";
import axios from "axios";

const SetLanguagePage = () => {
  
  const user : any = useRecoilValue(loginState);
  const [lang, setGlobalLanguage] = useRecoilState(langState);
  const { t, i18n } = useTranslation("main");
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const toggleLocales = useCallback(
    async (locale: string) => {
      // i18n.changeLanguage(locale);
      const data = await axios.patch(
          apiEndpoint + "/accounts/v2/user/",
          {
            lang: locale.slice(0,2).toUpperCase()
          },
          { headers: { Authorization: `Bearer ${user.access}` } }
      );
      setGlobalLanguage(locale)
    },
    [i18n]
  );

  const handleChange = (selectedLang: string) => {
    toggleLocales(selectedLang === "ko" ? "ko-KR" : selectedLang)
  }

  useEffect(()=>{
    isNotLogin(user).then((data) => {
      if(data)
        window.location.replace("/");
    });
    if(lang === 'ko')
      setGlobalLanguage('ko-KR')
    i18n.changeLanguage(lang);
  }, [])
  const radioStyle = {
    marginTop:'1.5rem',
    width:"calc(100% - 2rem)"
  }

  return (
    <SetLanguage>
      <ProcessHeaderComponent title={t("언어 설정")} isSearchForm={false} />
      <BodySection>
        <RadioGroup value={lang} defaultValue="en-US" style={radioStyle}>
          <Stack>
            <Radio onChange={()=>handleChange("ko-KR")} size="lg" value="ko-KR" colorScheme="green">
              한국어
            </Radio>
            <Radio onChange={()=>handleChange("en-US")} size="lg" value="en-US" colorScheme="green">
              English
            </Radio>
          </Stack>
        </RadioGroup>
        <ButtonComponent onClick={()=>{window.location.replace('/mypage');}} size={Size.LARGE} text={t("완료")} isValid={true}/>
      </BodySection>
    </SetLanguage>
  );
}

const SetLanguage = styled.div`
  
`

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`

export default SetLanguagePage