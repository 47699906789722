import { Size } from "types/types";
import { styled } from "styled-components";
import {colors} from "../../../styled/colors";

interface ButtonTypes {
  text:string;
  size:Size;
  onClick?:Function;
}

const ButtonComponentGreen = (props:ButtonTypes) => {
  const tmpFunction = () => {}
  const buttonHandler = props.onClick || tmpFunction;
  return (
    <Button
      size={props.size}
      onClick={()=> {buttonHandler()}}>
      {props.text}
    </Button>
  );
};

const Button = styled.div<{ size: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.GREEN[500]};
  color: white;
  width: ${(props) => (props.size === 'LARGE' ? 'calc(100% - 2rem)' : '50%')};
  font-weight: 600;
  height: 48px;
  margin-top: 5px;
  padding: 0px 24px;
  border-radius: 6px;
  cursor: pointer;
`;

export default ButtonComponentGreen;
