import ProcessHeaderComponent from "components/common/Headers/ProcessHeader";
import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_lg, Font_md, Font_sm } from "styled/fonts";
import card_img from "assets/card_img.svg"
import { getOneNoticeData } from "api/api";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NoticeData } from "types/types";
import { isoStringToDate } from "api/StringUtils";

const NoticeDetailPage = () => {
    const testData = {
        title: "리스트잇 서비스 점검 일정 안내",
        date: "2023.08.08",
        image: card_img,
        content: "Corem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus."
    }

    const user : any = useRecoilValue(loginState);
    const [noticeData, setNoticeData] = useState<NoticeData>();

    const { id } = useParams();
    function removeQueryParameters(htmlString: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const images = doc.querySelectorAll('img');
        images.forEach(img => {
            const src = img.getAttribute('src');
            const url = new URL(src as any);
            url.search = '';
            img.setAttribute('src', url.href);
        });
        return doc.body.innerHTML;
    }
    const fetchOneNoticeData =async () => {
        try {
            const dataRes = await getOneNoticeData(user.access, Number(id));
            setNoticeData(dataRes);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(()=> {
        fetchOneNoticeData();
    },[])

    return (
        <NoticeDetailWrapper>
            <ProcessHeaderComponent
            title=""
            isSearchForm={false}/>
            {noticeData &&
            <>
            <Title>
                <Font_lg isBold>{noticeData.title}</Font_lg>
                <Font_sm color={colors.GREY[400]}>{isoStringToDate(noticeData.created, false, true)}</Font_sm>
            </Title>
            <Content>
                {/* {testData.image && <ContentImg src={testData.image}/>} */}
                <Font_md dangerouslySetInnerHTML={{ __html: removeQueryParameters(noticeData.content) }}></Font_md>
            </Content>
            </>}
        </NoticeDetailWrapper>
    )
};

const NoticeDetailWrapper = styled.div`
`

const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 65px;
`

const ContentImg = styled.img`
    width: 100%;
`

export default NoticeDetailPage;