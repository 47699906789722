import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_md } from "styled/fonts";
import VerticalCardListComponent from "components/common/VerticalCardListComponent";
// import { verticalCardDummies } from "recoil/Dummies";
import { getItemList } from "api/api";
import {useRecoilState, useSetRecoilState} from "recoil";
import {itemListState, mainTabState, scrollRecoilState} from "recoil/homeRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import {useNavigate} from "react-router-dom";

interface CardListIProps {
  size: number;
}

const CardListComponent = (props: CardListIProps) => {
  const [itemList, setItemList] = useRecoilState(itemListState);
  const navigate = useNavigate();
  const [scrollValue, setScrollValue] = useRecoilState(scrollRecoilState);
  const [sideText, setSideText] = useRecoilState(mainTabState);

  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState);

  useEffect(()=> {
    i18n.changeLanguage(language);
    setScroll();
  },[])
  useEffect(()=>{
    (async () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const date = new Date(Date.now()-offset).toISOString().split('.')[0].replaceAll(":", "%3A");

      const response = await getItemList({
        page_size: props.size.toString(),
        page: "1",
        endDate: date,
        category: sideText === '스포츠 카드' ? 3 : 4,
        sort: 'priority'
      });
      setItemList(response.items.results);
    })();
  }, [sideText])
  useEffect(()=>{
    (async () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const date = new Date(Date.now()-offset).toISOString().split('.')[0].replaceAll(":", "%3A");

      if (itemList.length === 0) {
        const response = await getItemList({
          page_size: props.size.toString(),
          page: "1",
          endDate: date,
          category: sideText === '스포츠 카드' ? 3 : 4,
          sort: 'priority'
        });
        setItemList(response.items.results);
      }
    })();
  }, [])
  const handleNavigate = (type: number, id: string) => {
    const scroll = document.getElementById("application")?.scrollTop;
    if (scroll) {
      setScrollValue(scroll);
    }
    navigate(`/item-detail${type === 2 ? "-bid" : ""}?id=${id}`);
  };

  const setScroll = () => {
    if (scrollValue) {
      document.getElementById("application")?.scroll(0, scrollValue - 430);
    }
  }
  return (
    <CardList>
      <TabSection>
        <TabChild onClick={() => setSideText('스포츠 컬렉션')} isClick={sideText === '스포츠 컬렉션'}>
          <Font_md isBold={ sideText === '스포츠 컬렉션' ? true : undefined}>{t("스포츠 컬렉션")}</Font_md>
        </TabChild>
        <TabChild onClick={() => setSideText('스포츠 카드')} isClick={sideText === '스포츠 카드'}>
          <Font_md isBold={ sideText === '스포츠 카드' ? true : undefined}>{t("스포츠 카드")}</Font_md>
        </TabChild>
      </TabSection>
      <VerticalCardListComponent onClickHandler={handleNavigate}/>
    </CardList>
  );
};

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3.125rem 1rem;
  justify-content: center;
`;

const TabSection = styled.div`
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  height: 40px;
  position:sticky;
  top:48px;
  z-index:11;
  background-color: white;
`;
const TabChild = styled.div<{ isClick: boolean }>`
  display : flex;
  justify-content : center;
  align-items : center;
  min-width: 50%;
  border-bottom: ${(props) =>
    props.isClick
      ? `2px solid ${colors.GREY[700]}`
      : `2px solid ${colors.GREY[200]}`};
`;

export default CardListComponent;
