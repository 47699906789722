import { styled } from "styled-components";
import { Font_md, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import next_img from "assets/chevron_right.svg";
import { checkAndConvertCurrency, formatCurrency } from 'api/StringUtils';
import { BiddingData, Size, VerticalCardType } from "types/types";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import OutlineButtonComponent from "components/common/OutlineButtonComponent";
import { useNavigate } from "react-router-dom";
import { langState, loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import ModalComponent from "components/common/ModalComponent";
import { useDisclosure } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { isCompletePurchaceState } from "recoil/mypageRecoil";
import notify from "components/common/toast/ToastComponent";
import { ToastContainer } from "react-toastify";
import { MakeIsDoneDataTrueForBidding } from "api/UserHistoryApi";
import { CartData } from "types/types";
import { exchangeRateState, payableItemsState } from "recoil/homeRecoil";

interface CardType {
    date: string;
    name: string;
    price: number;
    payStatus?: string;
    auctionStatus: string;
    image: string;
    onClick: Function;
    bidData: BiddingData;
}

const BidCard = (props: CardType) => {
    let Lock = false;
    const [payableItems, setPayableItems] = useRecoilState(payableItemsState);
    
    const cartDataToVerticalData = (data: CartData) => {
        return {
          id: data.product.id.toString(),
          author: {},
          name: data.product.name,
          price: data.product.price,
          isAuction: false,
          created: data.created,
          is_selled: data.product.is_selled,
          image_product: [],
          value_product: [],
          content: "",
          end_date: "",
          selling_type: 1,
          start_date: "",
          image1: data.product.image1,
          is_bidded: false,
          is_pending: false,
          bidder:0,
          dollar_price: data.dollar_price,
        } as VerticalCardType;
      };
    const exchangeRate = useRecoilValue(exchangeRateState);
    const tmpFunction = () => {};
    const buttonHandler = props.onClick || tmpFunction;
    const navigate = useNavigate();
    const cutText = (text : string) => {
        if(text.length > 30) {
            return text.substring(0,30) + "...";
        }
        return text;
    }

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCompletePurchace, setIsCompletePurchace] = useRecoilState(isCompletePurchaceState);

    const user : any = useRecoilValue(loginState);

    const onClickCompliteBtn = () => {
        onOpen();
      };

    const onClickPayBtn = () => {
        navigate("/pay");
        const selectedItem : CartData= {
            created: "",
            id: 0,
            modified: "",
            product: {
                id: props.bidData.product.id,
                name: props.name,
                image1: props.image,
                is_selled: props.bidData.product.is_selled,
                price: props.bidData.price,
            },
            dollar_price: props.bidData.dollar_price,
        }
        const tmpItem: VerticalCardType = cartDataToVerticalData(selectedItem);
        const newPayableItems = [];
        newPayableItems.push(tmpItem);

        setPayableItems(newPayableItems);
    }

    const handleModalBtn = async() => {
        const response = await MakeIsDoneDataTrueForBidding(user.access, props.bidData.purchase);
        notify(t("구매확정이 완료되었습니다."));
        setTimeout(() => {setIsCompletePurchace(true)}, 2000);
      }

    const chooseStatusColor = (status : string) => {
        if(status === "결제대기") {
            return '#E53E3E';
        } else if (status === "배송준비" || status === "배송중"){
            return colors.GREEN[500];
        } else if (status === "낙찰취소"){
            return colors.GREY[400];
        }
        return colors.GREY[700];
    }

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
    },[])



    return (
        <BiddingCardWrapper>
            <ToastContainer />
            <Card>
                <ListTitle onClick={() => {buttonHandler()}}>
                    <Font_sm>{props.date} {t("선정")}</Font_sm>
                    <Arrow src={next_img}/>
                </ListTitle>
                <Font_sm isBold color={chooseStatusColor(props.auctionStatus)}>{t(props.auctionStatus)}</Font_sm>
                <ListMain>
                    <ProductImg src={props.image}/>
                    <ListInfo>
                        <ProductName>{cutText(props.name)}</ProductName>
                        {props.bidData.purchase ?
                        <Font_sm isBold>{checkAndConvertCurrency(language, props.price, props.bidData.purchase.currency, exchangeRate?.basePrice)}</Font_sm> :
                        <Font_sm isBold>{language !== 'ko-KR' ? checkAndConvertCurrency(language, props.bidData.dollar_price ? props.bidData.dollar_price : 0, 'USD') : checkAndConvertCurrency(language, props.bidData.price, 'KRW')}</Font_sm>}
                        {props.auctionStatus == "낙찰취소" ?
                        <Font_sm>{t("미결제")}</Font_sm> :
                        <Font_sm color={props.auctionStatus === "결제대기" ? '#E53E3E' : colors.GREY[700]}>{props.payStatus ? <div>{t("결제기한") + " " + props.payStatus}</div>: t("결제완료")}</Font_sm>}
                    </ListInfo>
                </ListMain>
            </Card>
            <ButtonWrapper>
                {props.auctionStatus === "결제대기" && 
                <ButtonComponent
                isValid={true}
                text={t("결제하기")}
                size={Size.LARGE}
                onClick={onClickPayBtn}/>
                }
                {(props.auctionStatus === "배송중" || props.auctionStatus === "배송완료") &&
                <OutlineButtonComponent
                text={t("구매확정")}
                size={Size.LARGE}
                onClick={onClickCompliteBtn}/>}
            </ButtonWrapper>
            <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        content={t(`상품 확인 후 구매확정을 눌러주세요! 판매자에게 정산이 이뤄집니다.`)}
        leftBtnString={t("취소")}
        rightBtnString={t("구매확정")}
        onClickSubmit={handleModalBtn}
      />
        </BiddingCardWrapper>
    )
};

const BiddingCardWrapper = styled.div`
    border-bottom: 1px solid ${colors.GREY[100]};
`

const Card = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const ListTitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const Arrow = styled.img`
`

const ListMain = styled.div`
    display: flex;
    gap: 1rem;
`

const ProductImg = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    object-fit: cover;
`

const ListInfo = styled.div`
    flex: 1;

`

const ProductName = styled(Font_md)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
`



export default BidCard;