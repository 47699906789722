import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { colors } from "styled/colors";
import camera from "assets/camera.svg";
import cancel from "assets/cancel.svg"
import { Font_xs } from "styled/fonts";
import OneButtonModal from "components/mypage/OneButtonModal";
import { useDisclosure } from "@chakra-ui/react";

interface PostingPicturesTypes {
    selectedImages: string[];
    setSelectedImages: React.Dispatch<React.SetStateAction<string[]>>;
    selectedFiles: File[],
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
    isEdit?: boolean
}

const PostingPictures = (props: PostingPicturesTypes) => {
    const [selectedFiles, setSelectedFiles] = [props.selectedFiles, props.setSelectedFiles]
    const [selectedImages, setSelectedImages] = [props.selectedImages, props.setSelectedImages];
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const checkPlatform = () => {
        const filter = "win16|win32|win64|mac|macintel";

        if(0 > filter.indexOf(navigator.platform.toLowerCase())){
            return "Mobile";
        }else{
            return "PC";
        }
    }

    const checkMobile = () => {

        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.indexOf('android') > -1) {
            //안드로이드
            return "android";
        } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            //IOS
            return "ios";
        } else {
            //아이폰, 안드로이드 외
            return "other";
        }

    };

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
          }
      };



    const handleFileChange = (event: any) => {
        if((checkMobile() == "ios") && (checkPlatform() == "Mobile")) {
            window.webkit.messageHandlers.permission.postMessage('camera');
        }

        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const filesArray = Array.from(selectedFiles) as File[];

            const imageUrls = filesArray.map(file => URL.createObjectURL(file));
            if(selectedImages.length + imageUrls.length > 3) {
                onOpen();
                return;
            }
            setSelectedFiles((prevFiles) => [...prevFiles, ...event.target.files]);
            setSelectedImages(prevImages => [...prevImages, ...imageUrls]);
        }
    };

    const handleDeleteButton = (index : Number) => {
        setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
        setSelectedFiles(prevFiles => prevFiles.filter((_,i) => i!== index));
    }

    useEffect(()=> {
        console.log(selectedFiles);
    },[selectedFiles])

    return (
        <PostingWrapper>
            <PostingIcon onClick={handleImageClick}>
                <PostingInput 
                type="file" 
                accept='image/*'
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple/>
                <Camera src={camera}/>
                <Font_xs color={colors.GREY[400]}>{selectedImages.length}/3</Font_xs>
            </PostingIcon>
            {selectedImages.map((imageURL, index) => (
                <PhotoButton>
                    <Photo key={index} src={imageURL} alt={`Preview ${index}`}/>
                    <DeleteButton src={cancel} onClick={() => handleDeleteButton(index)}/>
                </PhotoButton>
            ))}
            <OneButtonModal
            text="이미지는 최대 3장까지 올릴 수 있어요."
            buttonText="닫기"
            isOpen={isOpen}
            onClose={onClose}/>
        </PostingWrapper>
    )
}

const PostingWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`

const PostingIcon = styled.div`
    width: 4rem;
    height: 4rem;
    background-color: ${colors.GREY[50]};
    border: 1px solid ${colors.GREY[200]};
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
`

const PostingInput = styled.input`
    display: none;
    background-color: transparent;
    border: none;
`

const Camera = styled.img`
`

const PhotoButton = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
`

const Photo = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    overflow: hidden;
`

const DeleteButton = styled.img`
    position: absolute;
    right: -8px;
    top: -6px;

`
export default PostingPictures;