import BiddingCard from "components/auctionHistory/BiddingCard";
import styled from "styled-components";
import card_img from "assets/card_img.svg";
import { getBiddingData } from "api/UserHistoryApi";
import { useRecoilValue } from "recoil";
import { loginState } from "recoil/loginRecoil";
import { useState } from "react";
import { useEffect } from "react";
import { BiddingData } from "types/types";
import { isoStringToDate } from "api/StringUtils";
import { useNavigate } from "react-router-dom";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import EmptyMessage from "components/common/EmptyMessage";
import Loading from "components/Loading";

const BiddingHistory = () => {
    const user : any = useRecoilValue(loginState);
    const [biddingData, setBiddingData] = useState<BiddingData[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchBiddingData = async() => {
        setLoading(true);
        const data = await getBiddingData(user.access);
        setBiddingData(data.reverse());
        setLoading(false);
    }

    const navigate = useNavigate();

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const identifyBiddingStatus = (list: BiddingData) : string => {
        if(list.is_success) {
            return "선정";
        } else if (!list.product.is_pending) {
            return t("제안 마감") + ' ' + isoStringToDate(list.product.end_date, true, false);
        } else if (list.product.status == 8) {
            return "제안 마감"
        }else {
            return "미선정";
        }
    }

    useEffect(()=> {
        fetchBiddingData();
        i18n.changeLanguage(language);
    },[])

    return (
        <BiddingHistoryWrapper>
            { (biddingData && !loading) ?
            biddingData.length !== 0 ?
                biddingData.map((list) => 
                <BiddingCard
                date={isoStringToDate(list.created, false, false)}
                name={list.product.name}
                price={list.price}
                productPrice={list.product.price}
                auctionStatus={identifyBiddingStatus(list)}
                image={list.product.image1}
                onClick={() => {navigate(`/bidding-detail/${list.id}`)}}
                dollarPrice={list.dollar_price}
                currency={list.product.author.swift_code ? 'USD' : 'KRW'}/>) : 
                <EmptyMessage/>
            :
            <Loading/>
            }
        </BiddingHistoryWrapper>
    )
};

const BiddingHistoryWrapper = styled.div`
`

export default BiddingHistory;