import { styled } from "styled-components";
import { Font_sm, Font_md} from "styled/fonts";
import { checkAndConvertCurrency, fixedExchangePrice, formatCurrency } from 'api/StringUtils';
import { colors } from "styled/colors";
import { langState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { exchangeRateState } from "recoil/homeRecoil";

interface ForSaleCardType {
    name: string;
    price: number;
    image: string;
    bidStatus?: string;
    bidDate?: string;
    onClick?: Function;
    isEndPage?: boolean;
    currency?: string;
}

const SaleCard = (props: ForSaleCardType) => {

    const { t, i18n } = useTranslation("history");
    const language = useRecoilValue(langState);

    const tmpFunction = () => {};
    const buttonHandler = props.onClick || tmpFunction;

    const exchangeRate = useRecoilValue(exchangeRateState);

  
    useEffect(()=> {
        i18n.changeLanguage(language);
        console.log(props.currency)
    },[])

    const cutText = (text : string) => {
        if(props.bidStatus && text.length > 30) {
            return text.substring(0,30) + "...";
        }
        return text;
    }

    return (
        <ForSaleCardWrapper isEndPage={props.isEndPage || false}>
            <ListMain onClick={()=> {buttonHandler()}}>
                <ProductImg src={props.image}/>
                <ListInfo>
                    <ProductName>{cutText(props.name)}</ProductName>
                    {props.currency && <Font_sm isBold>{checkAndConvertCurrency(language, (props.currency === 'USD' ? Math.round(props.price / fixedExchangePrice * 100) / 100 : props.price), props.currency, exchangeRate?.basePrice)}</Font_sm>}
                    {props.bidStatus &&
                    <BidInfo>
                        <Font_sm>{t(props.bidStatus)}</Font_sm>
                        <Font_sm>{props.bidDate}</Font_sm>
                    </BidInfo>}
                </ListInfo>
            </ListMain>
        </ForSaleCardWrapper>
    )
};

const ForSaleCardWrapper = styled.div<{isEndPage: boolean}>`
    padding: 1rem;
    border-bottom: ${(props)=> props.isEndPage ? '' : `1px solid ${colors.GREY[100]}`};
`

const ListMain = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`

const ProductImg = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    object-fit: cover;
`

const ListInfo = styled.div`
    flex: 1;

`

const ProductName = styled(Font_md)`
`

const BidInfo = styled.div`
    display: flex;
    gap: 4px;
`


export default SaleCard;