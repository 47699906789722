import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Button } from '@chakra-ui/react';
import MideumButtonComponent from 'components/common/buttons/MideumButtonComponent';
import styled from 'styled-components';
import { Font_lg } from 'styled/fonts';

interface ModalIProps {
  imageUrl?: string;
  title?: string;
  content: string;
  bottomContent?: string;
  leftBtnString: string;
  rightBtnString: string;
  isOpen: boolean;
  onClose: () => void; // Use a function type for onClose
  onClickSubmit: () => void;
}

const ModalComponent = (props: ModalIProps) => {
  const { isOpen, onClose, onClickSubmit } = props; // Extract isOpen and onClose from props
  const handleSubmit = () => {
    onClickSubmit();
    onClose();
  }
  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        {/* Modal Body */}
        {props.imageUrl && (
          <ImageWrapper>
            <img src={props.imageUrl} />
          </ImageWrapper>
        )}
        <ModalBody>
          {props.title && <Title isBold>{props.title}</Title>}
          <TextSection>
            {props.content}
            <br />
            {props.bottomContent}
          </TextSection>
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <ButtonWrapper>
            <MideumButtonComponent
              cnt={2}
              isReverse={true}
              text={props.leftBtnString}
              onClick={onClose}
            />
            <MideumButtonComponent
              cnt={2}
              isReverse={false}
              text={props.rightBtnString}
              onClick={handleSubmit}
            />
          </ButtonWrapper>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Title = styled(Font_lg)`
  text-align: center;
  padding-top: 1rem;
`
const TextSection = styled.div`
  padding:16px 16px 0px 16px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export default ModalComponent;