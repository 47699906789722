const MySvgComponent = ({ isClicked }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.83325 2C9.93325 2 10.8333 2.9 10.8333 4C10.8333 5.1 9.93325 6 8.83325 6C7.73325 6 6.83325 5.1 6.83325 4C6.83325 2.9 7.73325 2 8.83325 2ZM8.83325 12C11.5333 12 14.6333 13.29 14.8333 14H2.83325C3.06325 13.28 6.14325 12 8.83325 12ZM8.83325 0C6.62325 0 4.83325 1.79 4.83325 4C4.83325 6.21 6.62325 8 8.83325 8C11.0433 8 12.8333 6.21 12.8333 4C12.8333 1.79 11.0433 0 8.83325 0ZM8.83325 10C6.16325 10 0.833252 11.34 0.833252 14C0.833252 15.1046 1.72868 16 2.83325 16H14.8333C15.9378 16 16.8333 15.1046 16.8333 14C16.8333 11.34 11.5033 10 8.83325 10Z"
      fill={ isClicked ? '#0AB88C' : 'black' }
    />
  </svg>
);

export default MySvgComponent;
