import { Routes, Route } from "react-router-dom";
import MainPage from "./MainPage";

const MainRouter = () => {

  return (
    <Routes>
      <Route path='/' element={<MainPage />}/>
    </Routes>
  );
};

export default MainRouter;