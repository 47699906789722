import React, { useEffect, useState } from "react";
import styled from "styled-components";
import VerticalCardListComponent from "components/common/VerticalCardListComponent";
import { getItemList } from "api/api";
import {useRecoilState, useRecoilValue} from "recoil";
import {categoryState, itemListState, scrollRecoilState} from "recoil/homeRecoil";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useInView} from "react-intersection-observer";
import ProcessHeaderComponent from "../../components/common/Headers/ProcessHeader";

const ItemCategoryPage = () => {
    const location = useLocation();
    const sch = location.search;
    const params = new URLSearchParams(sch);
    const tagId = params.get('tagId');
    const [itemList, setItemList] = useRecoilState(itemListState);
    const [ref, inView] = useInView();
    const [isFirst, setIsFirst] = useState(true);
    const [page, setPage] = useState(1);
    const [category, setCategory] = useRecoilState(categoryState);
    const [bannerImg, setBannerImg] = useState<string>('');
    const [scrollValue, setScrollValue] = useRecoilState(scrollRecoilState);
    const navigate = useNavigate();

    const getItems = async (page: string, from?: string) => {
        const response = await getItemList({
            page_size: '20',
            page: page,
            tag: Number(tagId),
        });
        if (!response.error){
            if (from === 'inview')
                setItemList([...itemList, ...response.items.results]);
            else
                setItemList(response.items.results);
        }else{
            console.error(response.error);
            return "error";
        }
    }
    useEffect(() => {
        (async () => {
            setBannerImg(category.filter((e: any) => e.id === Number(tagId))[0]?.banner_image);
            console.log('!');
            if (itemList.length === 0) {
                await getItems('1');
            }
        })();
        setScroll();
    }, [])
    useEffect(() => {
        if (!inView) {
            return;
        } else{
            if (isFirst) {
                setIsFirst(false);
            } else {
                (async () => {
                    const status = await getItems((page + 1).toString(), 'inview');
                    if (status !== 'error')
                        setPage(page + 1)
                })();
            }
        }
    }, [inView])
    const handleNavigate = (type: number, id: string) => {
        const scroll = document.getElementById("application")?.scrollTop;
        if (scroll) {
            setScrollValue(scroll);
        }
        navigate(`/item-detail${type === 2 ? "-bid" : ""}?id=${id}`);
    };

    const setScroll = () => {
        if (scrollValue) {
            document.getElementById("application")?.scroll(0, scrollValue);
        }
    }
    return (
        <>
            <ProcessHeaderComponent
                isReset={true}
                path={'/'}
                isSearchForm={false}
            />
            <CardList>
                <BannerContainer imgSrc={bannerImg} />
                <VerticalCardListComponent onClickHandler={handleNavigate} />
                <div style={{ height: "1px" }} ref={ref} />
            </CardList>
        </>
    );
};

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BannerContainer = styled.div<{imgSrc: string}>`
  width: 100%;
  aspect-ratio: 4 / 3;
  background-image: url(${props => props.imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 16px;
`;

export default ItemCategoryPage;
