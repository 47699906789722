import styled from "styled-components";
import CardComponent from "components/common/CardComponent";
import { useRecoilValue } from "recoil";
import { itemListState } from "recoil/homeRecoil";
import {HandleNavigateType} from "../../pages/explore/ExplorePage";
import {RefObject} from "react";
const VerticalCardListComponent = (props: { onClickHandler: HandleNavigateType }) => {
  const cardList = useRecoilValue(itemListState) || [];
  return (
    <CardList>
      <Contents>
        {cardList && cardList.map((cardInfo:any) => (
          <CardComponent onClickHandler={props.onClickHandler} item={cardInfo} isVisible={true}/>
        ))}
        {
          cardList.length % 2 === 1 && <CardComponent onClickHandler={props.onClickHandler} item={cardList[0]} isVisible={false}/>
        }
      </Contents>
    </CardList>
  );
};

const CardList = styled.div`
  min-width: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3.125rem 1rem;
  justify-content: center;
`;

export default VerticalCardListComponent;
