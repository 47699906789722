import { Routes, Route } from "react-router-dom";
import SellerHistoryPage from "./SellerHistoryPage";
import SoldHistory from "../../components/sellerHistory/SoldHistory";
import SellerHistoryDetailPage from "./SellerHistoryDetailPage";
import SellerAuctionHistoryPage from "./SellerAuctionHistoryPage";
import SellerAuctionDetailPage from "./SellerAuctionDetailPage";
import SellerEndDetailPage from "./SellerEndDetailPage";
import SuccessProposalPage from "./SuccessProposalPage";

const SellerHistoryRouter = () => {
    return (
        <Routes>
            <Route path="/seller-history/:id" element={<SellerHistoryPage/>}/>
            <Route path="/seller-history-detail/:id" element={<SellerHistoryDetailPage/>}/>
            <Route path="/seller-auction-history/:id" element={<SellerAuctionHistoryPage/>}/>
            <Route path="/seller-auction-history/detail/:id" element={<SellerAuctionDetailPage/>}/>
            <Route path="/auction-closed/:id" element={<SellerEndDetailPage/>}/>
            <Route path="/seller-auction-history/proposal-success" element={<SuccessProposalPage/>}/>
        </Routes>
    )
};

export default SellerHistoryRouter;