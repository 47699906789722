import styled from "styled-components";
import OutlineButtonComponent from "components/common/OutlineButtonComponent";
import { Size } from "types/types";
import SuccessContent from "components/common/SuccessContent";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { colors } from "styled/colors";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { langState } from "recoil/loginRecoil";
import { useEffect } from "react";

const SuccessPage = () => {
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const from = params.get('from');
  const cardId: string = params.get("id") || "1";
  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState)
  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])
  const navigate = useNavigate();
  return (
    <Success>
      <SuccessContent
        mainText={t("제안이 완료되었습니다!")}
        subText={t("제안 마감 후 결과를 확인해주세요.")}
      />
      {from === "bid" ? (
        <ButtonComponent
          text={t("확인")}
          size={Size.LARGE}
          onClick={() => window.location.replace("/item-detail-bid?origin-path=/&id="+cardId)}
          isValid={true}
          color={colors.GREEN[500]}
        />
      ) : (
        <>
          <ButtonComponent
            text={t("더 둘러보기")}
            size={Size.LARGE}
            onClick={() => navigate("/")}
            isValid={true}
            color={colors.GREEN[500]}
          />
          <OutlineButtonComponent
            text={t("구매 내역 보기")}
            size={Size.LARGE}
            onClick={() => navigate("/item-detail")}
          />
        </>
      )}
    </Success>
  );
};

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  padding-bottom: 16px;
`;

export default SuccessPage;
