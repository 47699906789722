import { styled } from "styled-components";
import appleLogo from "assets/appleLogo.svg";
import emailLogo from "assets/emailLogo.svg";
import googleLogo from "assets/googleLogo.svg";
import {colors} from "styled/colors";
import { Font_lg } from "styled/fonts";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import { langState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";

interface LoginBtnTypes {
  type: string;
  onClick: Function;
  from: string;
  isDisabled:Boolean;
}

const LogoImg = styled.img<{ type: string, isDisabled:Boolean}>`
  width: 22px;
  height: 22px;
  filter: ${props => props.isDisabled ? "grayscale(100%)" : ""};
`;

const SignupBtnComponent = (props:LoginBtnTypes) => {

  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState)
  
  LogoImg.defaultProps = {
    src: props.type === 'apple' ? appleLogo :
      props.type === 'email' ? emailLogo :
      props.type === 'google' ? googleLogo : `<div></div>`
  };
  const brandHangul = props.type === 'apple' ? "애플" :
  props.type === 'email' ? "이메일" :
  props.type === 'google' ? "구글" : `<div></div>`

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <SignupBtn isDisabled={props.isDisabled} onClick={() => props.onClick(props.type)}>
      <LogoImg isDisabled={props.isDisabled} type="google" />
      <Font_lg color={props.isDisabled ? colors.GREY[400] : ""} isBold>
        {
          props.from === "signup" ? t(`${brandHangul}로 시작하기`) : t(`${brandHangul}로 로그인`)
        }
      </Font_lg>
    </SignupBtn>
  );
};

const SignupBtn = styled.div<{isDisabled:Boolean}>`
  pointer-events : ${props => props.isDisabled ? "none" : ""};
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  border-color: ${colors.GREY[200]};
  width: calc(100% - 2rem);
  height: 48px;
  margin-top:5px;
  padding: 0px 24px 0px 24px;
  border-radius: 6px;
  gap: 8px;
  cursor: pointer;
`;


export default SignupBtnComponent;
