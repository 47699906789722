import styled from "styled-components";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import { useLocation, useNavigate } from "react-router-dom";
import card_img from "assets/item_detail_img.svg";
import { Checkbox, Divider, InputGroup, InputLeftAddon, Textarea } from "@chakra-ui/react";
import { Font_md, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import { Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ButtonComponent from "components/common/buttons/ButtonComponent";
import { Size, VerticalCardType } from "types/types";
import {
  changeKrwToUsd,
  changeUsdToKrw,
  fixedExchangePrice,
  formatCurrency,
  formatCurrencyUsd,
  formatDateStringToEnglish,
  formatDateToKorean
} from "api/StringUtils";
import { useTranslation } from "react-i18next";
import { getItemDetail, createBid, updateBid } from "api/api";
import { useRecoilValue } from "recoil";
import { langState, loginState } from "recoil/loginRecoil";
import { exchangeRateState } from "recoil/homeRecoil";
import { getBiddingDataUser } from "api/UserHistoryApi";

const BidPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const cardId: string = params.get("id") || "1";
  const isReBid: string = params.get("isReBid") || "";
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const user: any = useRecoilValue(loginState);
  const language = useRecoilValue(langState);
  const exchangeRate = useRecoilValue(exchangeRateState);

  const [isCheck, setIsCheck] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [bidPrice, setBidPrice] = useState<string | undefined>(undefined);
  const [bidPriceAboutDollar, setBidPriceAboutDollar] = useState<string | undefined>(undefined);
  const [itemInfo, setItemInfo] = useState<VerticalCardType | any>();
  const [bidId, setBidId] = useState(0);
  const [bidCause, setBidCause] = useState<string | undefined>();

  const { t, i18n } = useTranslation("main");

  const onClickBidConfirm = async () => {
    const dollarPrice = Number(bidPriceAboutDollar) || changeKrwToUsd(Number(bidPrice), exchangeRate?.basePrice, true);
    const krwPrice = Number(bidPrice) || changeUsdToKrw(Number(bidPriceAboutDollar), exchangeRate?.basePrice);
    if (exchangeRate) {
      if (bidId === 0) { // this user never create a bid
        await createBid(user, itemInfo, krwPrice , dollarPrice, bidCause);
      } else {
        // This user already create bids
        await updateBid(user, itemInfo, krwPrice, dollarPrice, bidId, bidCause);
      }

      window.location.replace('/bid-success?from=bid&id=' + cardId)
    }
  }

  const bidCauseHandler = (e:any) => setBidCause(e.target.value);

  const inputs = [!isInvalid, bidCause]

  const PriceAboutDollar = changeKrwToUsd(
    itemInfo?.price || 0,
    exchangeRate?.basePrice
  );

  useEffect(()=>{
    if (language !== 'ko-KR') PriceAboutDollar && setIsInvalid(PriceAboutDollar > Number(bidPriceAboutDollar))
    else bidPrice && setIsInvalid(Number(itemInfo?.price) > Number(bidPrice))
  }, [bidPrice, PriceAboutDollar, bidPriceAboutDollar])


  useEffect(() => {
    (async () => {
      const response = await getItemDetail(cardId, user);
      if (response.error) {
        console.error(response.error);
      }
      else{
        setItemInfo(response.data);
        if (response.data?.is_bidded) {
          const biddedData = await getBiddingDataUser(
            user.access,
            response.data?.id || "1"
          );
          setBidId(biddedData[0].id);
        }
      }
      setIsLoading(false);
    })();
  }, []);
  return (
    <Bid>
      <ProcessHeader isSearchForm={false} title={t("가격 제안")} />
        { !isLoading ? <>
            <ItemSection>
                <HorizontalCard>
                    <ImageSection cardImg={itemInfo ? itemInfo.image1 : card_img}/>
                    <TextSection>{itemInfo?.name}</TextSection>
                </HorizontalCard>
                <BidInfoSection>
                    <tr>
                        <td className="title">{t("판매 희망가")}</td>
                        <td className="content">
                            {itemInfo && itemInfo.price && itemInfo?.author.swift_code ?
                                "US " + itemInfo.price / fixedExchangePrice
                                : (language !== 'ko-KR' ?
                                    "KRW " + (itemInfo.price).toLocaleString()
                                    : (itemInfo.price).toLocaleString() + "원")}
                        </td>
                    </tr>
                    <tr>
                        <td className="title">{t("제안 마감")}</td>
                        <td className="content">
                            {language !== 'ko-KR' ? formatDateStringToEnglish(itemInfo?.end_date.toString()) : formatDateToKorean(itemInfo?.end_date.toString())}
                        </td>
                    </tr>
                </BidInfoSection>
            </ItemSection>
            <Divider style={{ marginTop: "1rem" }} />
            <BidSection>
                <Bid_ isInvalid={isInvalid}>
                    <Font_md isBold color={colors.GREY[900]}>
                        {t("제안가")}
                    </Font_md>
                    <InputGroup
                        className="input-group"
                        style={{ marginTop: "0.5rem" }}
                        size="lg"
                    >
                        {itemInfo?.author.swift_code ?
                            <>
                                <InputLeftAddon className="unit" children="$" color="gray.500" />
                                <Input
                                    placeholder={(itemInfo.price / fixedExchangePrice).toString()}
                                    size="lg"
                                    value={bidPriceAboutDollar}
                                    onChange={(e) => setBidPriceAboutDollar(e.target.value)}
                                    type="number"
                                    className="input-form"
                                />
                            </> :
                            <>
                                <InputLeftAddon className="unit" children="₩" color="gray.500" />
                                <Input
                                    placeholder={formatCurrency(itemInfo?.price || 0)}
                                    size="lg"
                                    value={bidPrice}
                                    onChange={(e) => setBidPrice(e.target.value)}
                                    type="number"
                                    className="input-form"
                                />
                            </>}
                    </InputGroup>
                    <RealPrice color={colors.GREY[400]}>
                        {itemInfo?.author.swift_code ?
                            (language === 'ko-KR' ? formatCurrency(changeUsdToKrw(itemInfo?.price / fixedExchangePrice , exchangeRate?.basePrice)) + "원" : "KRW" + formatCurrency(changeUsdToKrw(itemInfo?.price / fixedExchangePrice , exchangeRate?.basePrice))) :
                            (language === 'ko-KR' ? "US $" + formatCurrencyUsd(changeKrwToUsd(itemInfo?.price , exchangeRate?.basePrice, true)) : "$" + formatCurrencyUsd(changeKrwToUsd(itemInfo?.price , exchangeRate?.basePrice, true)))
                        }</RealPrice>
                    {isInvalid && (
                        <div style={{ marginTop: "0.5rem" }}>
                            <Font_md color="#E53E3E">
                                {t("제안 시작가보다 낮은 금액입니다.")}
                            </Font_md>
                        </div>
                    )}
                </Bid_>
                <Bid_ isInvalid={false}>
                    <Font_md isBold color={colors.GREY[900]}>
                        {t("구매 이유")}
                    </Font_md>
                    <CauseTextArea
                        placeholder={t("물건을 아껴주실 분에게 상품을 판매해요. 상품을 구매하고 싶은 이유를 작성해주세요!")}
                        rows={5}
                        value={bidCause}
                        onChange={bidCauseHandler}
                    />
                </Bid_>
                {/* <Caution>
          <Font_md isBold color={colors.GREY[900]}>
            {t("제안 전 주의사항")}
          </Font_md>
          <ul>
            <li>{t("리스트잇의 경매는 비공개 입찰로 진행됩니다.")} </li>
            <li>
              {t(
                "입찰자 간 입찰가를 확인할 수 없으며, 경매 마감 시에 가장 높은 가격의 입찰가를 제시한 입찰자에게 낙찰됩니다."
              )}
            </li>
            <li style={{ color: colors.GREEN[500] }}>
              {t(
                "최고 입찰가를 제시한 입찰자가 여러명 있을 시에는 가장 먼저 입찰한 사람에게 낙찰됩니다."
              )}
            </li>
            <li>{t("선정 시 하루 이내로 결제를 진행해주세요.")}</li>
          </ul>
        </Caution>
        <Checkbox
          isChecked={isCheck}
          onChange={() => setIsCheck(!isCheck)}
          size="lg"
          colorScheme="green"
          width="calc(100% - 2rem)"
        >
          <Font_md>{t("주의사항을 모두 확인했습니다.")}</Font_md>
        </Checkbox> */}
                <ButtonComponent
                    // 제안하기 주의사항이 완료 시 사용
                    // isValid={isCheck && !isInvalid && bidPrice !== ""}
                    isValid={!isInvalid && Boolean(bidCause) && (bidPrice !== undefined || bidPriceAboutDollar !== undefined)}
                    text={t("가격 제안하기")}
                    size={Size.LARGE}
                    onClick={() => onClickBidConfirm()}
                />
            </BidSection>
        </> : <></> }
    </Bid>
  );
};

const Bid = styled.div`
  width: 100%;
`;

const ItemSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HorizontalCard = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  align-items: center;
`;

const ImageSection = styled.div<{cardImg: string}>`
  min-width: 64px;
  min-height: 64px;
  border-radius: 8px;
  background-image: url(${(props) => props.cardImg});
  background-repeat: no-repeat;
  background-size: cover;
`;

const TextSection = styled.div`
  margin-left: 1rem;
  height: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const BidInfoSection = styled.table`
  margin-top: 1rem;
  width: calc(100% - 2rem);
  tr {
    td.title {
      width: 35%;
      font-weight: 600;
    }

    td.content {
      width: calc(65% - 10px);
    }
  }
`;

const BidSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 0;
`

const Bid_ = styled.div<{isInvalid: boolean}>`
  width: calc(100% - 2rem);
  .unit{
    border-color:${props => (props.isInvalid ? '#E53E3E' : '')};
  };
  .input-form{
    border-top-color:${props => (props.isInvalid ? '#E53E3E' : '')};
    border-right-color:${props => (props.isInvalid ? '#E53E3E' : '')};
    border-bottom-color:${props => (props.isInvalid ? '#E53E3E' : '')};
  }
`

const Caution = styled.div`
  width: calc(100% - 2rem);
  ul{
    margin-left: 1.5rem;
  }
  li {
    margin-top: 1rem;
  }
`

const CauseTextArea = styled(Textarea)`
  margin-top: 0.5rem;
`

const RealPrice = styled(Font_sm)`
  margin-top: 0.5rem;
`

export default BidPage;
