import { styled } from "styled-components";
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState, useEffect } from "react";
import {StartCountdown} from "./CountdownComponent"
import { useRecoilValue, useRecoilState } from 'recoil';
import { countdownState, secretState } from "recoil/signupRecoil";
import { Font_sm } from "styled/fonts";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { colors } from "styled/colors";

interface SecretInputIProps {
  isValidSecret:boolean,
  errMsg?:string,
  isSend:boolean,
  sendEmailAgain:Function
}
const SecretKeyComponent = (props:SecretInputIProps) => {

  const { t, i18n } = useTranslation("main");
  const language = useRecoilValue(langState)

  const [countdown, setCountdown] = useRecoilState(countdownState);
  const [secret, setSecret] = useRecoilState(secretState);

  const handleSecretInput = (event: any) => {
    if(event.target.value.length <= 6)
      setSecret(event.target.value)
  };

  const handleResendBtn = () => {
    // send email again
    props.sendEmailAgain();
    setCountdown('10:00');
  }

  const isShowBtnStyle = {
    minWidth:'5rem',
    right:"32px",
  }

  useEffect(()=>{
    i18n.changeLanguage(language);
  }, [])

  StartCountdown();

  return (
    <SecretInput>
      <InputGroup style={{ width: "calc(100% - 2rem)" }} size="lg">
        <Input
          size="lg"
          placeholder={t("인증번호")}
          value={secret}
          onChange={handleSecretInput}
          maxLength={6}
          isInvalid={!props.isValidSecret}
          variant={props.isSend ? "filled" : "outline"}
          disabled={props.isSend}
        />
        <InputRightElement>
          <Button
            style={isShowBtnStyle}
            size="xs"
            colorScheme="gray"
            variant="outline"
            onClick={handleResendBtn}
          >
            {countdown} {t("재발송")}
          </Button>
        </InputRightElement>
      </InputGroup>
      {!props.isValidSecret ? (
        <ErrMsg>
          <Font_sm color="#E53E3E">{props.errMsg}</Font_sm>
        </ErrMsg>
      ) : (
        <ErrMsg>
          <Font_sm color={colors.GREY[400]}>{t("이메일에서 인증번호를 확인해주세요.")}</Font_sm>
        </ErrMsg>
      )}
    </SecretInput>
  );
};

const SecretInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`
const ErrMsg = styled.div`
  width:100%;
  padding-left: 1rem;
`
export default SecretKeyComponent;
