import React, {useState, useEffect, useRef} from "react";
import StaticHeader from "components/common/Headers/StaticHeader";
import styled from "styled-components";
import {Input} from "@chakra-ui/react";
import CardFilterComponent from "components/search/CardFilterComponent";
import FooterComponent from "components/common/FooterComponent";
import { useNavigate } from "react-router";
import { getFilterData } from "api/api";
import { useRecoilValue, useRecoilState } from "recoil";
import {filterDataState, itemListState, scrollRecoilState} from "recoil/homeRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import {useLocation} from "react-router-dom";

export type HandleNavigateType = (type: number, id: string) => void;

const ExplorePage = () => {
  const navigate = useNavigate();
  const [filterData, setFilterData] = useRecoilState(filterDataState);
  const { t, i18n } = useTranslation("home");
  const language = useRecoilValue(langState)
  const [scrollValue, setScrollValue] = useRecoilState(scrollRecoilState);
  const init = async () =>{
    const filterDataRes = await getFilterData();
    await setFilterData(filterDataRes.data);
  }
  const [itemList, setItemList] = useRecoilState(itemListState);
  useEffect(()=>{
    init();
    i18n.changeLanguage(language);
    setScroll();
  },[])

  const setScroll = () => {
    if (scrollValue) {
      document.getElementById("application")?.scroll(0, scrollValue);
    }
  }
  const handleNavigate = (type: number, id: string) => {
    const scroll = document.getElementById("application")?.scrollTop;
    if (scroll) {
      setScrollValue(scroll);
    }
    navigate(`/item-detail${type === 2 ? "-bid" : ""}?id=${id}`);
  };

  return (
    <Explore>
      <StaticHeader name={t("탐색")} />
      <InputWrapper onClick={() => {
        setItemList([]);
        navigate("/search");
      }}>
        <Input
          size="md"
          width="calc(100% - 2rem)"
          placeholder={t("상품명 검색")}
          _placeholder={{ color: "#A0AEC0" }}
          variant="filled"
        />
      </InputWrapper>
      {Object.keys(filterData).length !== 0 && <CardFilterComponent onClickHandler={handleNavigate}/>}
      <FooterComponent isClick={1} />
    </Explore>
  );
};

const Explore = styled.div`
  margin-bottom: 64px;
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;
`

export default ExplorePage;