import React, { useState, useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import PasswordPage from "./PasswordPage";
import TermsPage from "./TermsPage";
import PersonalInfoPage from "./PersonalInfoPage";
import EmailPage from "./EmailPage";
import WelcomePage from "./WelcomePage";
const SignupRouter = () => {

  return (
    <Routes>
      <Route path='/signup/terms' element={<TermsPage />}/>
      <Route path='/signup/email' element={<EmailPage />}/>
      <Route path='/signup/password' element={<PasswordPage />}/>
      <Route path='/signup/personal' element={<PersonalInfoPage />}/>
      <Route path='/signup/welcome' element={<WelcomePage />}/>
    </Routes>
  );
};

export default SignupRouter;