import styled from "styled-components";
import { useNavigate } from "react-router";
import ProcessHeader from "components/common/Headers/ProcessHeader";
import { Font_md, Font_xs, Font_sm } from "styled/fonts";
import { colors } from "styled/colors";
import { Button } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import notify from "components/common/toast/ToastComponent";
import { useRecoilValue, useRecoilState } from "recoil";
import { isChangeSuccessState } from "recoil/mypageRecoil";
import { loginState } from "recoil/loginRecoil";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { createAddressData, getAddressData } from "api/UserApi";
import { AddressData, Size } from "types/types";
import SmallButtonComponent from "components/common/buttons/MideumButtonComponent";
type User = {
  name: string;
  phoneNumber: string;
  addr: string;
}

const isShowBtnStyle = {
  right: "12px",
};

const HomeAddrPage = () => {
  let Lock = false;
  const navigate = useNavigate();
  const [isChangeSuccess, setIsChangeSuccess] = useRecoilState(isChangeSuccessState);
  const [user, setUser] = useRecoilState(loginState);

  // recoil을 이용해서 유저 정보 가져오기
  const tmpUser: User = {
    name: '홍길동',
    phoneNumber: '01012341234',
    addr: '서울시 강남구 학동로 1길 8, 302호',
  };
  // -----------------------------
  useEffect(()=>{
    if(!Lock && isChangeSuccess !== 0){
      notify(t('배송지가 변경되었습니다.'));
      Lock = true;
      setIsChangeSuccess(0);
    }
  }, [isChangeSuccess])

  const { t, i18n } = useTranslation("mypage");
  const language = useRecoilValue(langState);

  useEffect(()=> {
    i18n.changeLanguage(language);
  },[])
  
  return (
    <HomeAddr>
      <ToastContainer />
      <ProcessHeader
        title={t("배송지 관리")}
        isSearchForm={false}
        path="/mypage"
      />
      <Section>
        {user.user.addresses && user.user.addresses.length !== 0 ? (
          <>
            <TextWrapper>
              <Font_sm>{`${user.user.addresses[0].name} ・ ${user.user.addresses[0].phone}`}</Font_sm>
              <Font_sm>
                {user.user.addresses[0].address} {user.user.addresses[0].detail_address}
              </Font_sm>
            </TextWrapper>
            <ModifyBtn>
              <Button
                style={isShowBtnStyle}
                size="sm"
                onClick={() => navigate("/mypage/change-addr?from=home-addr")}
                colorScheme="gray"
                variant="outline"
              >
                {t("변경")}
              </Button>
            </ModifyBtn>
          </>
        ) : (
          <EmptyAddrWrapper>
            <Font_md color={colors.GREY[400]}>{t("등록된 배송지가 없습니다.")}</Font_md>
            <SmallButtonComponent
              onClick={() => navigate("/mypage/change-addr?from=home-addr")}
              isReverse={false}
              text={t("배송지 등록하기")}
              cnt={3}
            />
          </EmptyAddrWrapper>
        )}
      </Section>
    </HomeAddr>
  );
};

const HomeAddr = styled.div`

`;

const Section = styled.div`
  display:flex;
  justify-content: space-between;
  padding:1rem;
`

const ModifyBtn = styled.div`
  display:flex;
  align-items: center;
`

const TextWrapper = styled.div`

`

const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyAddrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: calc(100vh - 56px);
  gap: 1.5rem;
  text-align: center;
`
export default HomeAddrPage;