import React, { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import { colors } from "styled/colors";
import { Font_md, Font_sm } from "styled/fonts";
import expand_more from "assets/expand_more_grey.svg";
import expand_close from "assets/expand_close.svg";
import { useTranslation } from "react-i18next";
import { Divider } from "@chakra-ui/layout";

interface SellerInfoIProps{
  author: any;
}

const SellerInfoComponent = (props: SellerInfoIProps) => {
  const [isClose, setIsClose] = useState<boolean>(true);
  const { t } = useTranslation("main");
  return (
    <SellerInfo>
      <SellerInfoHeader>
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          onClick={() => setIsClose(!isClose)}
        >
          <Font_md color={colors.GREY[700]} isBold>
            {t("판매자 정보")}
          </Font_md>
          <img src={isClose ? expand_more : expand_close}></img>
        </div>
      </SellerInfoHeader>
      <Divider />
      {!isClose && (
        <SellerInfoBody>
          <AttrSection>
            <Font_sm>{t("이름")}</Font_sm>
            <Font_sm>{props.author?.name}</Font_sm>
          </AttrSection>
          <AttrSection>
            <Font_sm>{t("생년월일")}</Font_sm>
            {/*<Font_sm>{"-제공 예정-"}</Font_sm>*/}
          </AttrSection>
          <AttrSection>
            <Font_sm>{t("전화번호")}</Font_sm>
            <Font_sm>{props.author?.phone_number}</Font_sm>
          </AttrSection>
          <AttrSection>
            <Font_sm>{t("주소")}</Font_sm>
            {/*<Font_sm>{"-제공 예정-"}</Font_sm>*/}
          </AttrSection>
          {props.author?.business_number && (
            <>
              <AttrSection>
                <Font_sm>{t("사업자등록번호")}</Font_sm>
                <Font_sm>{props.author?.business_number}</Font_sm>
              </AttrSection>
              <AttrSection>
                <Font_sm>{t("통신판매업신고번호")}</Font_sm>
                <Font_sm>{props.author?.mailordersales_number}</Font_sm>
              </AttrSection>
            </>
          )}
        </SellerInfoBody>
      )}
    </SellerInfo>
  );
};

const SellerInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
`

const SellerInfoHeader = styled.div`
  width: calc(100% - 2rem);
  padding-bottom: 1rem;
`;

const SellerInfoBody = styled.div`
  width: calc(100% - 2rem);
  margin-top: 1rem;
`

const AttrSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
`

export default SellerInfoComponent;