import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom"; // useParams 가져오기
import {googleLogin} from "api/api";
import {useSetRecoilState} from "recoil";
import {loginState} from "recoil/loginRecoil";
import {appleLogin} from "api/UserApi";

const DUP_ERROR_MSG = "User is already registered with this e-mail address.";

const AuthPage = () => {
    const setLogin = useSetRecoilState(loginState);
    const navigate = useNavigate();
    const location = useLocation();
    const sch = location.search;
    const params = new URLSearchParams(sch);
    const encodedCode: string = params.get('code') || '';
    const encodedIdToken: string = params.get('id_token') || '';
    const state: string = params.get('state') || '';
    const code = encodeURI(encodedCode);
    let OauthRes: any;

    const getAuthFromBackend = async () => {
        if (state === 'google') {
            OauthRes = await googleLogin(code, encodedIdToken);
        } else {
            const anchor = location.hash;
            const appleParams = new URLSearchParams(anchor);
            const idToken = appleParams.get('id_token') || encodedIdToken || code;
            const authCode = code || null;
            OauthRes = await appleLogin(idToken, authCode);
        }
        if (OauthRes.error === DUP_ERROR_MSG)
            navigate("/login?dupSignup=true");

        if (OauthRes.access !== "") {
            const {access, user} = OauthRes;
            console.log(OauthRes);
            if (user.name === null) {
                if (!user.email) {
                    const randomString = Math.random().toString(36).substring(7);
                    const newEmail = `user_${randomString}@generate.com`;
                    await localStorage.setItem("o_auth", JSON.stringify({access}));
                    navigate(
                        "/signup/terms?type=social&email=" + newEmail + "&access=" + access
                    );
                } else {
                    await localStorage.setItem("o_auth", JSON.stringify({access}));
                    navigate(
                        "/signup/terms?type=social&email=" + user.email + "&access=" + access
                    );
                }
            } else {
                const loginData = {access, user};
                await setLogin(loginData);
                navigate("/");
            }
        }

    }
    useEffect(() => {
        getAuthFromBackend();
    }, [])


    return (
        <>

        </>
    );
};

export default AuthPage;
