import styled from "styled-components";
import { Button, Divider, useDisclosure } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import ProfileComponent from "components/common/ProfileComponent";
import PurchaseComponent from "../../components/mypage/PurchaseComponent";
import StaticHeader from "components/common/Headers/StaticHeader";
import SaleComponent from "components/mypage/SaleComponent";
import LoginReqImg from "assets/login_req.svg"
import { loginState } from "recoil/loginRecoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FooterComponent from "components/common/FooterComponent";
import { useTranslation } from "react-i18next";
import { langState } from "recoil/loginRecoil";
import { isNotLogin } from "api/UserUtil";
import ModalComponent from "components/common/ModalComponent";
import MainFooterComponent from "components/main/MainFooter";
import { useRecoilState } from "recoil";
import { mypageTabIndexState } from "recoil/mypageRecoil";
import { colors } from "styled/colors";

const Mypage = () => {
  const [tabIndex, setTabIndex] = useRecoilState(mypageTabIndexState);
  const user = useRecoilValue(loginState);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t, i18n } = useTranslation("mypage");
  const language = useRecoilValue(langState);

  const handleSettingProfileButton = () => {
    navigate('/mypage/setting-profile');
  }

  const handleTabsChange = (index : number) => {
    setTabIndex(index);
  }

  const onClickBackBtn = () => {
    onClose();
    navigate("/onboarding");
  };

  useEffect(()=>{
    (async () => {
      const data = await isNotLogin(user);
      if (data){
        onOpen();
      }
      i18n.changeLanguage(language);
    })();
  }, [])

  return (
    <MypageWrapper>
      <StaticHeader name={t("마이페이지")} />
      <ProfileWrapper>
        <ProfileComponent
          photo={user.user.thumbnail}
          nickname={user.user.name}
          code={user.user.code}
        />
        <Button
          size="sm"
          variant="outline"
          onClick={handleSettingProfileButton}
        >
          {t("프로필 설정")}
        </Button>
      </ProfileWrapper>
      <MypageTabsWrapper>
        <Tabs
          variant="line"
          size="md"
          isFitted
          colorScheme="gray"
          index={tabIndex}
        >
          <MypageTabList>
            <MypageTab
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => handleTabsChange(0)}
            >
              {t("구매")}
            </MypageTab>
            <MypageTab
              _selected={{
                fontWeight: 600,
                borderBottom: `2px solid ${colors.GREY[700]}`,
              }}
              onClick={() => handleTabsChange(1)}
            >
              {t("판매")}
            </MypageTab>
          </MypageTabList>
          <TabPanels>
            <TabPanel>
              <PurchaseComponent handleTabsChange={() => handleTabsChange(1)} />
            </TabPanel>
            <TabPanel>
              <SaleComponent />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </MypageTabsWrapper>
      <FooterComponent isClick={2} />
      <Divider />
      <MainFooterComponent />
      <ModalComponent
        isOpen={isOpen}
        onClose={() => onClickBackBtn()}
        content={
          language === "ko-KR" ? `로그인하고 더 많은 기능을` : `Sign up today!`
        }
        bottomContent={language === "ko-KR" ? `이용해보세요!` : ""}
        leftBtnString={t("돌아가기")}
        rightBtnString={t("로그인")}
        onClickSubmit={() => navigate("/onboarding")}
        imageUrl={LoginReqImg}
      />
    </MypageWrapper>
  );
};

const MypageWrapper = styled.div`
  margin-bottom: 64px;
`;

const ProfileWrapper = styled.div`
  display:flex;
  padding: 19px 16px;
  justify-content: space-between;
`;

const MypageTabList = styled(TabList)`
  position: sticky;
  top: 48px;
  z-index: 11;
  background-color: white;
`

const MypageTabsWrapper = styled.div`
`

const MypageTab = styled(Tab)`
  font-weight: 400;
`

export default Mypage;