import { checkAndConvertCurrency } from "api/StringUtils";
import styled from "styled-components";
import { colors } from "styled/colors";
import { Font_sm } from "styled/fonts";
import profileDefaultImg from "assets/profile_default_img.svg";
import { isValid } from "date-fns";

interface ProposalListTypes {
    isvalid: boolean;
    image?: string;
    price: string;
    text: string;
    onClick?: Function;
}

const ProposalList = (props: ProposalListTypes) => {
    const buttonClickHandler = () => props.onClick && props.onClick();

     return (
        <ProposalListWrapper onClick={buttonClickHandler} isValid={props.isvalid}>
            <ImageWrapper>
                <ProfileImg src={props.image || profileDefaultImg}/>
            </ImageWrapper>
            <ListContent>
                <Font_sm isBold>{props.price}</Font_sm>
                <Font_sm>{props.text}</Font_sm>
            </ListContent>
        </ProposalListWrapper>
    )
};

const ProposalListWrapper = styled.div<{isValid: boolean}>`
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid ${colors.GREY[100]};
    border: ${(props)=> (props.isValid && `1px solid ${colors.GREEN[500]}`)};
    background-color: ${(props)=> (props.isValid && colors.GREEN[50])}
    cursor: pointer;
`

const ImageWrapper = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 70%;
    overflow: hidden;
    position: relative;
    border: 1px solid ${colors.GREY[100]};
`

const ProfileImg = styled.img`
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 4rem;
    min-height: 4rem;
`

const ListContent = styled.div`
`

export default ProposalList;